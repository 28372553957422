import {
    Button,
    Checkbox,
    Form,
    Input,
    message,
    Row
} from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { Dispatch, FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateUser } from "store/auth/authSlice";
import * as TEACHER_SERVICES from "../../../api/services/teacher/teacher.services";

//!STYLES
import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import { useDispatch, useSelector } from "react-redux";
import st from "./assets/register.module.css";

import { uploadToCloudinary } from "api/services/teacher/teacher.services";
import docsPicture from '../../../assets/images/app/docs.svg';
import badgeCert from '../../../assets/images/app/validation.svg';

type Props = {
    stepThreeData: any;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

const StepFour: FC<Props> = ({ stepThreeData, setCurrent }) => {
    //!HOOKS
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [formInfos] = Form.useForm();

    //!STATES
    const [isDivVisible, setIsDivVisible] = useState(true);
    const [certificationCount, setCertificationCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [dataList, setDataList] = useState<any>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const [updateIndex, setUpdateIndex] = useState<number>(0);

    const [fileExtensions, setFileExtensions] = useState<string[]>([]);

    const [stepFourData, setStepFourData] = useState<any>({});
    var userCertifications = [{}];
    var [userCertificateUrl, setCertificateUrl] = useState<any>([]);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    //!FUNCTIONS
    const certificationFormVisibility = () => {
        setIsDivVisible(!isDivVisible);
    }

    ///Certification Div
    const [divs, setDivs] = useState([{ index: certificationCount }]);

    ///Add New Certificate
    const handleAddDiv = (index: number) => {
        const newDivs = [...divs, { index: index }];
        setDivs(newDivs);
    };

    const handleAddPreviousDiv = (index: number) => {
        const newDivs = [...divs, { index: index }];
        setDivs(newDivs);

        var element: any = {};
        setCertificationCount((certCount) => certCount + 1);

        for (let i = 0; i < userCertifications.length; i++) {
            element = userCertifications[i];

            const certificationKey = `certification${i}`;
            const descriptionKey = `description${i}`;
            const deliverKey = `deliver${i}`;
            const startingDateKey = `startedYear${i}`;
            const endingDateKey = `endedYear${i}`;
            const certificateKey = `certificateUrl${i}`;

            formInfos.setFieldsValue({
                [certificationKey]: element.certification,
                [descriptionKey]: element.description,
                [deliverKey]: element.delivredBy,
                [startingDateKey]: element.dateDebut,
                [endingDateKey]: element.dateFin,
                [certificateKey]: element.certificationUrl,
            });

            userCertificateUrl.push(element.certificationUrl);

            const extension = getFileExtension(element.diplomeUrl);
            if (extension) {
                setFileExtensions((prev) => [...prev, extension]);
            }
        }
        setCertificationCount(userCertifications.length);
    };

    const setDivPreviousElement = async () => {
        var element: any = {};
        element = userCertifications[0];

        formInfos.setFieldsValue({
            certification0: element.certification,
            description0: element.description,
            deliver0: element.delivredBy,
            startedYear0: element.dateDebut,
            endedYear0: element.dateFin,
            certificateUrl0: element.certificationUrl,
        });

        userCertificateUrl.push(element.certificationUrl);
        setCertificationCount(userCertifications.length);

        const extension = getFileExtension(element.diplomeUrl);
        if (extension) {
            setFileExtensions((prev) => [...prev, extension]);
        }
    }

    const addNewCertificate = (index: number) => {
        setCertificationCount((certCount) => certCount + 1);
        handleAddDiv(index);
    }

    ///Remove Certificate
    const handleRemoveCertifcateDiv = (index: number) => {
        const newDivs = [...divs];
        newDivs.splice(index, 1);
        setDivs(newDivs);
        setCertificationCount((certCount) => certCount - 1);
    };

    const setCertificateInputValue = (url: string) => {
        const certificateKey = `certificateUrl${updateIndex}`;
        const newArray = [...userCertificateUrl];

        formInfos.setFieldsValue({
            [certificateKey]: url,
        });

        newArray.splice(updateIndex, 0, url);
        setCertificateUrl(newArray);
        setIsLoading(false);
    }

    ///Trigger Form
    const moveToStepFive = async (values: any) => {
        if (isDivVisible) {
            for (let i = 0; i < certificationCount; i++) {
                const certificateUrlKey = `certificateUrl${i}`;
                if (values[certificateUrlKey]) {
                    setIsLoading(true);

                    var certificate = {};
                    const certifications = [];

                    for (let i = 0; i < certificationCount; i++) {

                        const certificationKey = `certification${i}`;
                        const descriptionKey = `description${i}`;
                        const deliverKey = `deliver${i}`;
                        const startingDateKey = `startedYear${i}`;
                        const endingDateKey = `endedYear${i}`;
                        const certificateKey = `certificateUrl${i}`;

                        certificate = {
                            "certification": values[certificationKey],
                            "description": values[descriptionKey],
                            "delivredBy": values[deliverKey],
                            "dateDebut": values[startingDateKey],
                            "dateFin": values[endingDateKey],
                            "certificationUrl": values[certificateKey],
                        }
                        certifications.push(certificate);

                    }
                    updateUserInfos(certifications);
                }
                else {

                }
            }
        }
        else {
            updateUserInfos([]);
        }

    }

    ///Update Teacher Certifications
    const updateUserInfos = async (certifications: any) => {
        setStepFourData(user);
        const finalData = {
            certifications: certifications,
            step: user && user?.step === "completed" ? "completed" : "certificate",
        };

        try {
            const response = await AUTH_SERVICES.updateTeacher(user?._id, {
                ...finalData,
            });
            if (response?.statusCode === StatusCode.OK) {
                dispatch(
                    updateUser({
                        ...user,
                        certifications: certifications,
                        step: user && user?.step === "completed" ? "completed" : "certificate"
                    })
                );
                message.success({
                    content: "Modification effectuée ",
                });
            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        } finally {
            setIsLoading(false);
            setCurrent(5);
        }
    };

    //Drag & Drop Functions
    function handleFile(files: any) {
        const file = files[0];
        const maxSize = 5 * 1024 * 1024;
        const reader = new FileReader();
        const allowedTypes = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];

        if (file && file.size <= maxSize && allowedTypes.includes(file.type)) {
            const fileExtension = file.name.split('.').pop();
            if (fileExtension) {
                setFileExtensions((prev) => [...prev, fileExtension]);
            }
            reader.onload = () => {
                uploadCertificatePicture(file);
            };
            reader.readAsDataURL(file);
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
    }

    ///Upload Certificate
    const uploadCertificatePicture = async (file: any) => {
        try {
            const response = await uploadToCloudinary(file, "certification");
            if (response?.statusCode === StatusCode.CREATED) {
                setCertificateInputValue(response?.data);
            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (let i = 0; i < error?.response?.data?.message?.length; i++) {
                content = error?.response?.data?.message[i] + ", " + content;
            }
            message.error({
                content: content,
            });
        }
    }

    // triggers when file is selected with click
    const handleChange = (e: any) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = (index: number) => {
        if (inputRef.current != null) {
            inputRef.current.click();
            setUpdateIndex(index);
        }
    };

    const getStaticLists = useCallback(
        async (type: string, dataList: any, state: Dispatch<any>) => {
            const response = await TEACHER_SERVICES.getDataList(type);

            if (response?.statusCode === StatusCode.OK) {
                state(response?.data);
            }
        },
        [dataList]
    );

    function getFileExtension(url: string): string | null {
        const parts = url.split('?')[0];
        const extension = parts.substring(parts.lastIndexOf('.') + 1);
        return extension || null;
    }

    //!EFFECTS
    useEffect(() => {
        getStaticLists('dates', dataList, setDataList);
        if (user) {
            userCertifications = user?.certifications;
            if (userCertifications.length !== 0) {
                if (userCertifications.length === 1) {
                    setDivPreviousElement();
                }
                else {
                    for (let i = 1; i < userCertifications.length; i++) {
                        handleAddPreviousDiv(i);
                    }
                }
            }
            else {
                setCertificationCount((certCount) => certCount + 1);
            }
        }
        else {
            setCertificationCount((certCount) => certCount + 1);
        }
    }, [user]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div style={{ height: "auto", margin: "0px auto", }}>
            <Form
                validateTrigger={["onFinish"]}
                name="registerTeacher"
                autoComplete="off"
                form={formInfos}
                validateMessages={validateMessages}
                initialValues={{ noCertification: false }}
                onFinish={moveToStepFive}
            >
                <div className={st.certificateDiplomaFormBox}>
                    <div className={st.termesToAccept} style={{ margin: "30px auto" }}>
                        <Form.Item
                            name="noCertification"
                            valuePropName="checked"
                            style={{ marginBottom: "20px" }}
                        >
                            <Checkbox
                                style={{ color: "#636363" }}
                                className={st.policy}
                                onClick={certificationFormVisibility}
                            >
                                {" "}
                                Je n’ai pas encore de certification ni de diplôme dans le domaine de l’enseignement{" "}
                            </Checkbox>
                        </Form.Item>
                    </div>

                    {isDivVisible &&
                        divs.map((div, index) => (
                            <div key={div.index} className={st.formContainer}>
                                {index !== 0 && <div style={{ height: "1px", width: "100%", border: "1px solid #333", borderStyle: "dashed", marginTop: "20px", marginBottom: "20px" }}></div>}
                                <Form.Item
                                    name={`certification${index}`}
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "20px" }}
                                >
                                    <Input
                                        className={st.field}
                                        placeholder="Certification"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={`description${index}`}
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "20px" }}
                                >
                                    <Input
                                        className={st.field}
                                        placeholder="Description"
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: "20px" }}
                                    name={`deliver${index}`}
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        className={st.field}
                                        placeholder="Délivrée par"
                                    />
                                </Form.Item>

                                <div className={st.inputRow}>
                                    <div className={st.inputRowChild}>
                                        <Form.Item
                                            style={{ marginBottom: "20px" }}
                                            name={`startedYear${index}`}
                                            rules={[{ required: true }]}
                                        >
                                            <select
                                                style={{ padding: "7px", fontSize: "16px" }}
                                                className={st.selectInput}
                                            >
                                                <option value="">Années d'études</option>
                                                {dataList.map((item: any, i: any) => (<option key={i} value={item}>{item}</option>))}
                                            </select>
                                        </Form.Item>
                                    </div>
                                    <div className={st.inputRowChild}>
                                        <Form.Item
                                            style={{ marginBottom: "20px" }}
                                            name={`endedYear${index}`}
                                            rules={[{ required: true }]}
                                        >
                                            <select
                                                style={{ padding: "7px", fontSize: "16px" }}
                                                className={st.selectInput}
                                            >
                                                <option value="">Années d'études</option>
                                                {dataList.map((item: any, i: any) => (<option key={i} value={item}>{item}</option>))}
                                            </select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className={st.docBox}>
                                    <div className={st.docBoxImage}>
                                        <img
                                            height={20}
                                            width={20}
                                            src={badgeCert}
                                            alt="Badge"
                                        />
                                        <a className={st.docBoxTitle}>{userCertificateUrl.length !== 0 && userCertificateUrl[index] !== undefined ? "Certificat ajouté" : "Télécharge ton certificat ici pour booster ta crédibilité !"}</a>
                                    </div>
                                    <a className={st.docBoxMessage}>Notre équipe l'examinera afin de pouvoir ajouter un badge à ton profil. Une fois examinés et validés, tes fichiers (ou documents) personnels seront supprimés.</a>

                                    <div className={st.uploadDocBox}>
                                        <Form.Item
                                            className={st.uploadInputForm}
                                            name={`certificateUrl${index}`}
                                            rules={[{ required: true, message: 'Veuillez ajouter le scan du certificat' }]}
                                        >
                                            <Input readOnly={true} className={st.uploadFileInput} />
                                        </Form.Item>
                                    </div>

                                    <div className={st.addDocPictureButton}>
                                        <div className={st.uploadFileButton}>
                                            <AppButton loading={isLoading} onClick={() => onButtonClick(index)} className={st.newMoveButton}>{userCertificateUrl.length !== 0 && userCertificateUrl[index] !== undefined ? "Modifier" : "Importer"}</AppButton>
                                            <input style={{ visibility: "hidden", width: "1px" }} name={`certificate${index}`} value={''} ref={inputRef} type="file" accept=".jpg,.jpeg,.png,.doc,.docx,.pdf" id={st.inputFileUpload} multiple={false} onChange={handleChange} />
                                        </div>

                                        {userCertificateUrl[index] && <div className={st.previewCertificateDiploma}>
                                            {fileExtensions[index] === "jpg" || fileExtensions[index] === "jpeg" || fileExtensions[index] === "png" ? <img
                                                height={120}
                                                width={120}
                                                src={userCertificateUrl[index]}
                                                alt="Docs"
                                            /> : <img
                                                height={80}
                                                width={80}
                                                src={docsPicture}
                                                alt="Docs"
                                            />}
                                        </div>}
                                    </div>

                                    <p className={st.docBoxMessageFormat}>Format JPG, PNG, DOC, DOCX, PDF Taille maximale 20 Mo</p>

                                </div>
                                {index !== 0 && <Row style={{ justifyContent: "end" }}><button onClick={() => handleRemoveCertifcateDiv(index)} className={st.removeButton}>Retirer</button></Row>}
                            </div>
                        ))
                    }

                    {isDivVisible && <div className={st.newDocumentbuttonBox}><Button className={st.newActionButton} onClick={() => addNewCertificate(certificationCount + 1)} >Ajouter un autre certificat</Button></div>}
                </div>
                <div className={st.rowButtons}>
                    <Button onClick={() => setCurrent((n) => n - 1)} className={st.newMoveButton}>Retour</Button>
                    <Form.Item style={{ margin: 0, padding: 0 }}>
                        <Button loading={isLoading} htmlType="submit" className={st.newMoveButton}>Suivant</Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default StepFour;

