import {
  ArrowLeftOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Typography,
  message,
} from "antd";
import * as CHAPTERS_API from "api/services/chapters/chapters.services";
import { CreateCourse } from "api/services/courses/courses.interface";
import * as TOPICS_SERVICES from "api/services/topics/topics.services";
import { CKEditor } from "ckeditor4-react";
import { HandlerInputError, StatusCode, StatusEntity } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import Container from "components/container";
import HelpPopover from "components/help";
import AppButton from "components/libs/button";
import AppSelectGroup from "components/libs/selectGroup";
import H2 from "components/libs/subtitle";
import H1 from "components/libs/title";
import MathScan from "components/maths";
import UploadCloudinary from "components/uploadCloudinary";
import * as appRoutes from "config/routes.config";
import useCourse from "hooks/useCourse.hook";
import useCourseChapters from "hooks/useCourseChapters.hook";
import "katex/dist/katex.min.css";
import { FC, useCallback, useEffect, useState } from "react";
import Latex from "react-latex-next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import s from "screens/teacher/createCours/assets/createCours.module.css";
import AppScreen from "../ScreenWithSidebar";
import TableCours from "../createCours/table";

const { Title } = Typography;
const { Step } = Steps;
const { Option } = Select;

const CourseDuplicate: FC = () => {
  //!DUMMY DATA
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Créé le",
      key: "createdAt",
      dataIndex: "createdAt",
    },
    {
      title: "Action",
      key: "action",
      render: (chapter: any) => {
        return (
          <Space size="middle">
            <Link
              to={`${appRoutes.TEACHER_COURSES}/${chapter?.course?._id}/edit/chapters/${chapter?._id}`}
            >
              <Button type="primary" ghost icon={<EditOutlined />} />
            </Link>
          </Space>
        );
      },
    },
  ];

  //!HOOKS
  const { user } = useSelector((state) => state.auth);

  const levels = useSelector((state) => state.levels);
  const topics = useSelector((state) => state.topics);
  const navigate = useNavigate();
  const params = useParams<"id">();
  const { loading, course } = useCourse(params.id!);

  const {
    chapters,
    loading: chaptersLoading,
    fetchCourseChapters,
  } = useCourseChapters(params.id!);
  const [form] = Form.useForm();
  //!STATE
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [addChapterLoading, setAddChapterLoading] = useState(false);
  const [editorLoading, setEditorLoading] = useState(true);

  //
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [courses, setCourses] = useState<any>([]);
  const [firstStepData, setFirstStepData] = useState<any>({});
  const [display, setDisplay] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [tempTopic, setTempTopic] = useState<any>();
  const [tempForm, setTempForm] = useState<any>([]);
  const [defaultValue, setDefaultValue] = useState<any>([]);
  const [tempContentCkEditor, setTempContentCkEditor] = useState<any>([]);
  const [tempValues, setTempValues] = useState<any>(null);
  const [first, setfirst] = useState("1");
  const [isAddingCourseBrouillon, setIsAddingCourseBrouillon] =
    useState<boolean>(false);
  const [isAddingCourse, setIsAddingCourse] = useState<boolean>(false);
  const [isloadingTopic, setIsloadingTopic] = useState<boolean>(false);
  const [addCourseLoading, setAddCourseLoading] = useState(false);

  //!FUNCTIONS
  const goToCourses = () => navigate(appRoutes.TEACHER_COURSES);

  const createCourse = useCallback(async (values: CreateCourse) => {
    const finalData = {
      topicId:
        values.topicId === undefined ? course.course?._id : values.topicId,
      levelId:
        values.levelId === undefined ? course.course?.level : values.levelId,
      title: values.title,
    };
    try {
      setFirstStepData(finalData);
      setCurrentStep((curr) => curr + 1);
    } catch (error) {
    } finally {
      setAddCourseLoading(false);
    }

    localStorage.setItem("firstStepTempData", JSON.stringify(course));
  }, []);

  const handleFilterValues = (value: any) => {
    value?.map((field: any) => {
      delete field?.updatedAt;
      delete field?.__v;
      delete field?._id;
      delete field?.createdAt;

      if (field?.subtitles?.length <= 0) {
        return false;
      }

      field.subtitles?.map((subtitle: any) => {
        delete subtitle?.updatedAt;
        delete subtitle?.__v;
        delete subtitle?._id;
        delete subtitle?.createdAt;

        handleFilterValues(field.subtitles);
      });
    });
    return value;
  };

  const createChapters = async (values: any) => {
    setIsAddingCourse(true);
    const newValues = handleFilterValues(values?.contents);
    values.contents = newValues;

    const createStatus =
      first === "1" ? StatusEntity.PENDING : StatusEntity.DRAFT;

    try {
      const response = await CHAPTERS_API.create({
        ...values,
        status: createStatus,
        teacherId: user?._id,
        courseId:
          firstStepData.topicId === undefined
            ? course.course?._id
            : firstStepData.topicId,
        levelId:
          firstStepData.levelId === undefined
            ? course.course?.level
            : firstStepData.levelId,
        title: firstStepData.title,
      });

      if (response?.statusCode === StatusCode.CREATED) {
        const successMessage =
          first === "1"
            ? "Cours créé avec succès"
            : "Cours enregistré dans les brouillons";

        message.success(successMessage);

        localStorage.removeItem("firstStepTempData");
        localStorage.removeItem("cours");

        navigate(appRoutes.TEACHER_COURSES);
      } else {
        message.warning("Une erreur est survenue lors de la création du cours");
      }
    } catch (error) {
      message.error("Une erreur est survenue lors de la création du cours");
    } finally {
      if (first === "1") {
        setIsAddingCourse(false);
      } else {
        setIsAddingCourseBrouillon(false);
      }
    }
  };

  const handleTempForm = useCallback(
    (_?: any, allFields?: any) => {
      let content = allFields
        .filter((field: any) => {
          return field.name.join() === "contents";
        })
        .map((c: any) => c.value);

      setTempForm(content);
    },
    [defaultValue, tempValues, tempForm]
  );

  const showLargeDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      setIsloadingTopic(true);
      try {
        const response = await TOPICS_SERVICES.findAll(levelId);
        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
        }
      } catch (error) {
      } finally {
        setIsloadingTopic(false);
      }
    },
    [tempTopic]
  );

  useEffect(() => {
    setDefaultValue(course?.contents);
  }, [
    handleTempForm,
    tempContentCkEditor,
    tempForm,
    tempValues,
    defaultValue,
    course,
  ]);

  const successMessageCopy = () => {
    message.success("Copier avec succès");
  };

  const onFill = () => {
    form.setFieldsValue(course);
  };

  //!RETURN
  if (loading)
    return (
      <Container>
        <div
          style={{
            width: "100%",
            height: "100vh",
          }}
        >
          <Row justify="center">
            <Spin />
          </Row>
        </div>
      </Container>
    );

  return (
    <div style={{ overflow: "hidden" }}>
      <Breadcrumbs isMycontent={true} />

      {currentStep === 1 && (
        <AppScreen className={s.cours}>
          <H1>{course?.title}</H1>
          <div className={s.teacher__maa} />
          <div className={s.form__container}>
            <Form autoComplete="off" form={form} onFinish={createCourse}>
              <Form.Item>
                <Steps current={currentStep}>
                  <Step />
                  <Step />
                </Steps>
              </Form.Item>

              <div className={`${s.select}`}>
                <Form.Item
                  name="levelId"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est requis",
                    },
                  ]}
                >
                  <AppSelectGroup
                    placeholder="Choisir un niveau"
                    onChange={(value: string) => OnSelectLevel(value)}
                  />
                </Form.Item>
              </div>

              <div className={`${s.select}`}>
                <Form.Item
                  name="topicId"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est requis",
                    },
                  ]}
                // initialValue={course.course._id}
                >
                  <Select
                    placeholder={"Choisir une matière"}
                    allowClear
                    loading={isloadingTopic}
                    style={{ marginBottom: "var(--mb-3)" }}
                    bordered={false}
                  >
                    {tempTopic?.map((topic: any) => (
                      <Option key={topic._id} value={topic._id}>
                        {topic.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className={s.field}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est requis",
                    },
                  ]}
                  initialValue={course.title}
                >
                  <Input
                    bordered={false}
                    type="text"
                    placeholder={"Titre du cours "}
                  />
                </Form.Item>
              </div>

              <Row justify="center" style={{ marginTop: "1.5em" }}>
                <Form.Item>
                  <AppButton htmlTypeSubmit={true} loading={addCourseLoading}>
                    suivant
                  </AppButton>
                </Form.Item>
              </Row>
            </Form>
          </div>
        </AppScreen>
      )}

      {currentStep === 2 && (
        <AppScreen>
          <div className={s.header}>
            <div className={s.left}>
              <H2 className={s.subtitle}>
                Titre du cours : {firstStepData.title || course?.title}
              </H2>
            </div>

            <div className={s.center} />

            <div style={{ position: "relative", top: "0px", marginRight: "10px" }}><HelpPopover id={"creer_un_cours_apercu"} /></div>

            <div className={s.right}>
              <AppButton
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
              >
                Retour
              </AppButton>
            </div>
          </div>

          <div className={s.main}>
            <Form
              name="dynamic_form_item"
              onFieldsChange={handleTempForm}
              onFinish={createChapters}
              initialValues={course}
              form={form}
            >
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12} className={s.____left}>
                  <Form.List name="contents">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, h1Key) => (
                          <div style={{ position: "relative" }}>
                            <Form.Item
                              {...restField}
                              name={[name, "title"]}
                              rules={[
                                {
                                  required: true,
                                  message: HandlerInputError.title,
                                },
                              ]}
                            >
                              <Input
                                className={s.field}
                                placeholder="grand titre"
                              />
                            </Form.Item>

                            {/* <Form.Item {...restField} name={[h1Key]}>
                              <Input className={s.field} placeholder="FAKE" />
                            </Form.Item> */}

                            <Form.Item
                              {...restField}
                              name={[name, "type"]}
                              className={s.__input__type}
                              initialValue="h1"
                            ></Form.Item>
                            <MinusCircleOutlined
                              style={{
                                position: "absolute",
                                right: "-16px",
                                top: "20px",
                              }}
                              onClick={() => remove(name)}
                            />

                            <Form.Item name={[name, "subtitles"]}>
                              <Form.List name={[name, "subtitles"]}>
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(
                                      ({ key, name, ...restField }, h2Key) => (
                                        <div
                                          style={{ position: "relative" }}
                                          key={key}
                                        >
                                          <Form.Item
                                            {...restField}
                                            name={[name, "title"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Missing subtitle",
                                              },
                                            ]}
                                          >
                                            <Input
                                              className={s.field}
                                              placeholder="sous titre"
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            {...restField}
                                            className={s.__input__type}
                                            name={[name, "type"]}
                                            initialValue="h2"
                                          ></Form.Item>
                                          <MinusCircleOutlined
                                            style={{
                                              position: "absolute",
                                              right: "-16px",
                                              top: "20px",
                                            }}
                                            onClick={() => remove(name)}
                                          />

                                          <Form.Item name={[name, "subtitles"]}>
                                            <Form.List
                                              name={[name, "subtitles"]}
                                            >
                                              {(fields, { add, remove }) => (
                                                <>
                                                  {fields.map(
                                                    (
                                                      {
                                                        key,
                                                        name,
                                                        ...restField
                                                      },
                                                      sectionKey
                                                    ) => (
                                                      <div
                                                        key={key}
                                                        style={{
                                                          position: "relative",
                                                        }}
                                                      >
                                                        <Form.Item
                                                          {...restField}
                                                          name={[name, "title"]}
                                                          rules={[
                                                            {
                                                              required: true,
                                                              message:
                                                                "Missing section",
                                                            },
                                                          ]}
                                                        >
                                                          <Input
                                                            className={s.field}
                                                            placeholder="Section"
                                                          />
                                                        </Form.Item>
                                                        <div
                                                          className={
                                                            s.__custom__ck_editor
                                                          }
                                                        >
                                                          {/* MAthPix */}
                                                          <Space>
                                                            <UploadCloudinary />
                                                            <MathScan />
                                                          </Space>

                                                          <Form.Item
                                                            name={[
                                                              name,
                                                              "content",
                                                            ]}
                                                            valuePropName="data"
                                                            getValueFromEvent={(
                                                              event,
                                                              _
                                                            ) => {
                                                              const data =
                                                                event.editor.getData();

                                                              return data;
                                                            }}
                                                          >
                                                            {tempContentCkEditor !=
                                                              [] && (
                                                                <CKEditor
                                                                  initData={
                                                                    course
                                                                      ?.contents[
                                                                      h1Key
                                                                    ]?.subtitles[
                                                                      h2Key
                                                                    ]?.subtitles[
                                                                      sectionKey
                                                                    ]?.content
                                                                  }
                                                                  config={{
                                                                    language: 'fr',
                                                                  }}
                                                                  style={{
                                                                    border:
                                                                      "none",
                                                                  }}
                                                                />
                                                              )}
                                                          </Form.Item>
                                                        </div>
                                                        <Form.Item
                                                          {...restField}
                                                          name={[name, "type"]}
                                                          className={
                                                            s.__input__type
                                                          }
                                                          initialValue="section"
                                                        ></Form.Item>
                                                        <MinusCircleOutlined
                                                          style={{
                                                            position:
                                                              "absolute",
                                                            right: "-16px",
                                                            top: "18px",
                                                          }}
                                                          onClick={() =>
                                                            remove(name)
                                                          }
                                                        />
                                                      </div>
                                                    )
                                                  )}
                                                  <Form.Item>
                                                    <Button
                                                      className={
                                                        s.__add__input__button
                                                      }
                                                      onClick={() => add()}
                                                      block
                                                      icon={<PlusOutlined />}
                                                    >
                                                      ajouter une section
                                                    </Button>
                                                  </Form.Item>
                                                </>
                                              )}
                                            </Form.List>
                                          </Form.Item>
                                        </div>
                                      )
                                    )}
                                    <Form.Item>
                                      <Button
                                        className={s.__add__input__button}
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                      >
                                        ajouter un sous-titre
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </Form.Item>
                          </div>
                        ))}
                        <Form.Item>
                          <Button
                            className={s.__add__input__button}
                            onClick={() => {
                              add();
                              setDisplay(true);
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Ajouter un Grand titre
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>

                <Drawer
                  title={null}
                  placement="right"
                  onClose={onClose}
                  width="70%"
                  visible={visible}
                  footer={false}
                >
                  <div className={s.appercue__continute}>
                    <h3>Aperçu : {courses?.title}</h3>
                    {(tempForm.length > 0 ? tempForm[0] : defaultValue)?.map(
                      (field: any, i: number) => (
                        <div key={i}>
                          <div className={s.__big__title__content}>
                            <h3 className={s.__big__title}>
                              {i + 1} - {field?.title}
                            </h3>
                          </div>

                          <div className={s.appercue__continute__content}>
                            {field?.subtitles?.map((x: any) => (
                              <>
                                <h4>{x?.title}</h4>
                                {x?.subtitles?.map((item: any) => (
                                  <>
                                    <div className={s.section__content}>
                                      <h4>{item?.title}</h4>
                                    </div>
                                    <Latex>
                                      {item?.content
                                        ? item?.content
                                          ?.replaceAll("amp;", "")
                                          ?.replaceAll("<br />", "")
                                        : ""}
                                    </Latex>
                                  </>
                                ))}
                              </>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </Drawer>

                <Col span={12}>
                  <div className={s.appercue__continute}>
                    <h3>Aperçu : {courses?.title}</h3>

                    {(tempForm.length > 0 ? tempForm[0] : defaultValue)?.map(
                      (field: any, i: number) => (
                        <div key={i}>
                          <div className={s.__big__title__content}>
                            <h3 className={s.__big__title}>
                              {i + 1} - {field?.title}
                            </h3>
                          </div>

                          <div className={s.appercue__continute__content}>
                            {field?.subtitles?.map((x: any) => (
                              <>
                                <h4>{x?.title}</h4>
                                {x?.subtitles?.map((item: any) => (
                                  <>
                                    <div className={s.section__content}>
                                      <h4>{item?.title}</h4>
                                    </div>
                                    <Latex>
                                      {item?.content
                                        ? item?.content
                                          ?.replaceAll("amp;", "")
                                          ?.replaceAll("<br />", "")
                                        : ""}
                                    </Latex>
                                  </>
                                ))}
                              </>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </Col>
              </Row>

              {/* <Form.Item name="courseId" initialValue={courses?.courseId}>
                <Input placeholder="sous titre" hidden />
              </Form.Item> */}

              <Form.Item
                name="courseId"
                hidden
                initialValue={course?.course?._id}
              ></Form.Item>

              <Form.Item name="title" hidden initialValue={courses?.title}>
                <Input
                  className={s.field}
                  value={courses?.title}
                  placeholder="titre"
                />
              </Form.Item>

              <Form.Item name="description" hidden>
                <Input className={s.field} placeholder="description" />
              </Form.Item>

              <Form.Item
                name="language"
                hidden
                initialValue={courses?.language}
              >
                <Input className={s.field} placeholder="language" />
              </Form.Item>

              <Row justify="center" style={{ marginTop: "3em" }} gutter={12}>
                <Col>
                  <AppButton onClick={showLargeDrawer}>Aperçu</AppButton>
                </Col>
                <Col>
                  <AppButton
                    htmlTypeSubmit={true}
                    loading={isAddingCourse}
                    onClick={() => setfirst("2")}
                  >
                    enregistrer
                  </AppButton>
                </Col>

                <Col>
                  <AppButton
                    loading={isAddingCourse}
                    htmlTypeSubmit={true}
                    onClick={() => setfirst("1")}
                  >
                    Soumettre pour validationn
                  </AppButton>
                </Col>
              </Row>

              <Form.Item name="teacherId" initialValue={user?._id}>
                <Input placeholder="sous titre" hidden />
              </Form.Item>
            </Form>

            <TableCours />
          </div>
        </AppScreen>
      )}
    </div>
  );
};

export default CourseDuplicate;
