import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Image,
  Menu,
  Row,
  Select,
  Space,
} from "antd";
import Card from "components/card";
import * as appRoutes from "config/routes.config";
import React, { FC, useState } from "react";
//!IMAGES
import BackIconBlack from "../../../../assets/images/app/backBlack.svg";
import { ReactComponent as IconDropDown } from "../../../../assets/images/app/dropDownIcon.svg";

//!STYLES
import { Link } from "react-router-dom";
import CardChallenge from "screens/teacher/Room/components/CardChallenge";
import CardQuizz from "screens/teacher/Room/components/cardQuizz/cardQuizz";
import s from "./assets/card.module.css";

//!STYLES
import AppDrawer from "components/appDrawer";
import HelpPopover from "components/help";
import Matiers from "components/matiers";
import DrawerPublications from "../drawerPublications";
import styles from "./assets/drawer.module.css";

type Props = {
  onClick?: any;
  course?: object | any;
};

const CardClasses: FC<Props> = ({ onClick, course }) => {
  //!STATE
  const [isDrawerChallenge, setIsDrawerChallenge] = useState<boolean>(false);
  const [openDrawerPublications, setOpenDrawerPublications] = useState(false);
  const [childrenDrawerVisble, setChildrenDrawerVisible] =
    useState<boolean>(false);
  const { Option } = Select;

  //!FUNCTION
  const handleShowChildrenDrawer = () =>
    setChildrenDrawerVisible(!childrenDrawerVisble);

  const menu = (
    <Menu className={s.__down}>
      <Menu.Item>
        <Link to={`${appRoutes.TEACHER_ROOMS}/${course?._id}`}> Dashboard</Link>
      </Menu.Item>

      <Menu.Item>
        <Link to={`${appRoutes.TEACHER_ROOMS}/${course?._id}/challenge`}>
          Challenge
        </Link>
      </Menu.Item>
      <Menu.Item onClick={() => setOpenDrawerPublications(true)}>
        Publication
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <Card className={s.card}>
        <div className={s.card__content}>
          <div style={{ position: "relative", top: "-30px", right: "-35%" }}><HelpPopover id={"dashboard_activites_resource_personnalises"} /></div>
          <div className={s.code}>
            <span>
              Code : {course?.accessCode?.split("-")[0].toUpperCase()}
            </span>
          </div>

          <Matiers
            cours={course?.course?.title.slice(0, 4) || "null"}
            level={course?.level?.title || "null"}
          />

          <div>
            <span>{course?.title || course?.name || ""}</span>
          </div>

          <Dropdown
            className={`${s.dropdown}`}
            overlay={menu}
            placement="bottomCenter"
          >
            <Button>
              <Space>
                Action
                <IconDropDown
                  style={{
                    position: "relative",
                    left: "15px",
                  }}
                />
              </Space>
            </Button>
          </Dropdown>

          {/* <div className={s.states}>
          <Image
            preview={false}
            width={20}
            src={Classes}
            alt="logo"
            onClick={onClick}
          />
          <span>25</span>
        </div> */}
        </div>
      </Card>

      <AppDrawer
        title={false}
        onClose={() => setIsDrawerChallenge(!isDrawerChallenge)}
        visible={isDrawerChallenge}
      >
        <div className={styles.__first_drawer}>
          <div className={styles.drawer__content}>
            <div className={styles.drawer___header}>
              <h3>Challenger cette classe</h3>
            </div>

            <Form name="create-room" autoComplete="off">
              <div className={styles.filter}>
                {/* <div className={styles.filter__left}></div> */}

                <div className={styles.select__field}>
                  <Row justify="center">
                    <Col className={styles.custom__select}>
                      <Form.Item name="levelId">
                        <Select
                          placeholder="Choisir un niveau"
                          allowClear
                          // loading={levels.loading}
                          bordered={false}
                          className={`${styles.select}`}
                        >
                          {/* {levels.items.map((level) => (
                              <Option key={level._id} value={level._id}>
                                {level.title}
                              </Option>
                            ))} */}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col className={styles.custom__select}>
                      <Form.Item name="levelId">
                        <Select
                          placeholder="Choisir un niveau"
                          allowClear
                          // loading={levels.loading}
                          bordered={false}
                          className={`${styles.select}`}
                        >
                          {/* {levels.items.map((level) => (
                              <Option key={level._id} value={level._id}>
                                {level.title}
                              </Option>
                            ))} */}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item name="topicId">
                        <Select
                          placeholder="Choisir une matière"
                          allowClear
                          // loading={topics.loading}
                          bordered={false}
                          className={`${styles.select}`}
                        >
                          {/* {topics.items.map((topic) => (
                              <Option key={topic._id} value={topic._id}>
                                {topic.title}
                              </Option>
                            ))} */}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className={styles.__row}>
                <CardChallenge
                  handleShowChildrenDrawer={handleShowChildrenDrawer}
                />
                <CardChallenge
                  handleShowChildrenDrawer={handleShowChildrenDrawer}
                />
              </div>
            </Form>
            <div
              className={styles.__first_back}
              onClick={() => setIsDrawerChallenge(!isDrawerChallenge)}
            >
              <Image preview={false} src={BackIconBlack} width={40} />
            </div>
          </div>
        </div>

        <Drawer
          title={false}
          placement="right"
          width={"70%"}
          onClose={handleShowChildrenDrawer}
          visible={childrenDrawerVisble}
          closable={false}
        >
          <div className={styles.__first_drawer}>
            <div className={styles.drawer__content}>
              <div className={styles.drawer___header}>
                <h3>Aperçu du quiz </h3>
              </div>

              <Form
                name="create-room"
                // labelCol={{ span: 7 }}
                // wrapperCol={{ span: 17 }}
                autoComplete="off"
              // validateTrigger={["onFinish"]}
              // onFinish={addCourse}
              // form={addCourseForm}
              >
                <div className={styles.__overflow__Y}>
                  <div className={styles.content}>
                    <div className={styles.faq}>
                      <h4>
                        Dans quel cas une écriture fractionnaire est-elle une
                        fraction ?
                      </h4>
                    </div>

                    <CardQuizz />
                    <CardQuizz />
                    <CardQuizz />
                  </div>
                  <div className={styles.content}>
                    <div className={styles.faq}>
                      <h4>
                        Dans quel cas une écriture fractionnaire est-elle une
                        fraction ?
                      </h4>
                    </div>

                    <CardQuizz />
                    <CardQuizz />
                    <CardQuizz />
                  </div>
                  <div className={styles.content}>
                    <div className={styles.faq}>
                      <h4>
                        Dans quel cas une écriture fractionnaire est-elle une
                        fraction ?
                      </h4>
                    </div>

                    <CardQuizz />
                    <CardQuizz />
                    <CardQuizz />
                  </div>
                </div>
              </Form>
              <div
                className={styles.__first_back}
                onClick={() => setChildrenDrawerVisible(!childrenDrawerVisble)}
              >
                <Image preview={false} src={BackIconBlack} width={40} />
              </div>
            </div>
          </div>
        </Drawer>
      </AppDrawer>

      <DrawerPublications
        open={openDrawerPublications}
        currRoom={course}
        setOpen={setOpenDrawerPublications}
        currLevel={course.level}
      />
    </React.Fragment>
  );
};

export default CardClasses;
