import { Alert, Button, Form, Image, Input } from "antd";
import { loginUserDto } from "api/services/auth/dto/login-user.dto";
import { AuthMethods, UserRoles } from "common/enums";
import * as appRoutes from "config/routes.config";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import {
  loginWithEmailAndPassword
} from "store/auth/authSlice";
import s from "../../teacher/login/assets/login.module.css";

//!IMAGES
import SendUpdatePasswordRequestDrawer from "components/updatePasswordDrawer/sendUpdateLink";
import AnimatedPage from "utils/animatedPage";
import AlbertLogin from "../../../assets/images/app/albert_icon_login.svg";
import organisation from "../../../assets/images/app/home/organisation.svg";
import ArrowDown from "../../../assets/images/app/login/arrow_down_login.svg";
import ArrowRight from "../../../assets/images/app/login/arrow_right.svg";
import EllipseOne from "../../../assets/images/app/login/ellipse1.svg";
import EllipseTwo from "../../../assets/images/app/login/ellipse2.svg";
import EllipseThree from "../../../assets/images/app/login/ellipse3.svg";
import EllipseFour from "../../../assets/images/app/login/ellipse4.svg";
import EllipseFive from "../../../assets/images/app/login/ellipse5.svg";
import EmailIcon from "../../../assets/images/app/login/email_icon.svg";
import Lines from "../../../assets/images/app/login/lines.svg";
import PasswordIcon from "../../../assets/images/app/login/password_icon.svg";

const LoginOrganisme: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.appChat);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<"id">();

  //!CONSTANTS
  const validateMessages = {
    required: t("auth.error.required"),
    types: {
      email: t("auth.error.emailInvalid"),
    },
  };

  //!FUNCTIONS
  const loginUser = async (user: loginUserDto) => {
    await dispatch(loginWithEmailAndPassword(user));
  };

  const [passwordDrawerVisible, setPasswordDrawerVisible] = useState(false);
  const onClosePasswordDrawer = () => {
    setPasswordDrawerVisible(false);
  };

  const handleAccountCreation = () => {
    navigate(appRoutes.REGISTER_ORGANISME);
  }

  //!EFFECTS
  useEffect(() => {
    const isLoggedIn = auth.isLoggedIn;
    const isTeacher = auth.user?.roles?.includes(UserRoles.TEACHER);
    const isStudent = auth.user?.roles?.includes(UserRoles.STUDENT);
    const isOrganism = auth.user?.roles?.includes(UserRoles.ORGANISME);
    const isCompleted = auth.user?.step === "completed";
    if (isLoggedIn && auth.lastRouteActionAuth) {
      navigate(auth.lastRouteActionAuth);
    } else {
      if (isLoggedIn)
        return isTeacher && isCompleted ? navigate(appRoutes.TEACHER_ACTIVITIES) :
          isTeacher && !isCompleted ? navigate(appRoutes.COMPLETE_PROFILE) :
            isStudent ? navigate(appRoutes.STUDENT_DASHBOARD) :
              isOrganism ? navigate(appRoutes.ORGANISME_DASHBOARD) :
                navigate(appRoutes.PARENT_DASHBOARD);
    }
  }, [auth.isLoggedIn, navigate, auth.user?.roles]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  //!COMPONENT RETURN
  return (
    <AnimatedPage>
      <AppScreen className={s.login}>
        <div className={s.mainContainer}>
          {!isMobile && <img src={Lines} height={72} className={s.lines}></img>}
          {!isMobile && <img src={EllipseOne} height={171} className={s.ellipseOne}></img>}
          {!isMobile && <img src={EllipseTwo} height={171} className={s.ellipseTwo}></img>}
          {!isMobile && <img src={EllipseThree} height={54} className={s.ellipseThree}></img>}
          <div className={s.loginBox}>
            <div className={s.avatarBox}>
              <Image src={organisation} preview={false} width={90} />
            </div>

            <div className={s.infos}>
              Je suis un{" "}
              <strong>
                "Organisme"
              </strong>
              .<Link to={appRoutes.LOGINS}>Modifier</Link>
            </div>

            <div className={s.actionTitle}>Se connecter</div>

            {!isMobile && <img src={ArrowDown} height={77} className={s.arrowDown}></img>}

            <Form
              validateTrigger={["onFinish"]}
              validateMessages={validateMessages}
              name="loginUser"
              autoComplete="off"
              onFinish={loginUser}
              className={s.formContainer}
            >
              <Form.Item
                name="email"
                rules={[{ required: true }, { type: "email" }]}
                style={{ marginBottom: "20px" }}
              >
                <Input
                  type="email"
                  className={s.inoutField}
                  placeholder={t("auth.email")}
                  prefix={<img src={EmailIcon} height={isMobile ? 15 : 25} style={{ marginRight: "10px" }}></img>}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true },
                  {
                    pattern: /^.{6,}$/,
                    message:
                      "Le mot de passe doit contenir au moins 6 caractères !",
                  },
                ]}
              >
                <Input.Password
                  className={s.inoutField}
                  placeholder={t("auth.password")}
                  prefix={<img src={PasswordIcon} height={isMobile ? 15 : 25} style={{ marginRight: "10px" }}></img>}
                />
              </Form.Item>

              {auth.loginErrors.length > 0 && <div className={s.form__container}>
                {auth.loginErrors.map((error, index) => (
                  <Alert
                    key={index}
                    message={error}
                    type="error"
                    showIcon
                    closable
                  />
                ))}
              </div>}

              <div className={s.submitButtonBox}>
                <Form.Item>
                  <Button
                    ghost
                    loading={
                      auth.isLogging && auth.authMethod === AuthMethods.EMAIL
                    }
                    block
                    type="primary"
                    htmlType="submit"
                    className={s.submitButton}
                  >
                    Se connecter
                  </Button>
                </Form.Item>
              </div>
              <div style={{ margin: "20px auto", display: "none" }}>
                <a className={s.forgotPasswordButton} onClick={() => setPasswordDrawerVisible(true)}>Mot de passe oublié ?</a>
              </div>
            </Form>
          </div>
          <div className={s.newAccountBox}>
            {isMobile && <span className={s.noAccountMessage}>Vous n’avez pas de compte ?</span>}
            <img src={AlbertLogin} height={isMobile ? 100 : 120} className={s.albertHead}></img>
            {!isMobile && <span className={s.noAccountMessage}>Vous n’avez pas de compte ?</span>}
            {!isMobile && <img src={ArrowRight} height={43} width={55} className={s.arrowRight}></img>}
            <Button type="primary" className={s.newAccountButton} onClick={handleAccountCreation}>Créer votre compte</Button>
          </div>

          {!isMobile && <img src={EllipseFour} height={66} className={s.ellipseFour}></img>}
          {!isMobile && <img src={EllipseFive} height={54} className={s.ellipseFive}></img>}
        </div>
        <SendUpdatePasswordRequestDrawer visible={passwordDrawerVisible} onClose={onClosePasswordDrawer} />
      </AppScreen>
    </AnimatedPage>
  );
};

export default LoginOrganisme;
