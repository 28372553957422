import { createSlice } from "@reduxjs/toolkit";

export interface AppChatState {
  openAppChat: boolean;
  conversation: any;
  talkId: string;
  idReceiver: string;
  idSender: string;
  socket: any;
  currentChat: any;
  startChat: boolean;
  selectUserChat: boolean;
  chatReceiverId: string;
}

const initialState: AppChatState = {
  openAppChat: false,
  conversation: null,
  talkId: "",
  idReceiver: "",
  idSender: "",
  socket: null,
  currentChat: null,
  startChat: false,
  selectUserChat: false,
  chatReceiverId: "",
};

export const appChatSlice = createSlice({
  name: "appChat",
  initialState,
  reducers: {
    toggleAppChat: (state, action) => {
      state.openAppChat = action.payload;
    },
    setConversationStore: (state, action) => {
      state.conversation = action.payload;
    },
    setTalkId: (state, action) => {
      state.talkId = action.payload;
    },
    setIdReceiver: (state, action) => {
      state.idReceiver = action.payload;
    },
    setIdSender: (state, action) => {
      state.idSender = action.payload;
    },
    initSocket: (state, action) => {
      state.socket = action.payload;
    },
    setCurrentChat: (state, action) => {
      state.currentChat = action.payload;
    },
    setToggleStartChat: (state, action) => {
      state.startChat = action.payload;
    },
    setSelectUserChat: (state, action) => {
      state.selectUserChat = action.payload;
    },
    setChatReceiverId: (state, action) => {
      state.chatReceiverId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleAppChat,
  setConversationStore,
  setTalkId,
  setIdReceiver,
  initSocket,
  setIdSender,
  setCurrentChat,
  setToggleStartChat,
  setSelectUserChat,
  setChatReceiverId,
} = appChatSlice.actions;

export default appChatSlice.reducer;
