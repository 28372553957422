import { AutoComplete, Image, Skeleton, notification } from 'antd';
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import * as appRoutes from "config/routes.config";
import React, { useCallback, useState } from 'react';

import AppResult from 'components/appResults';
import InvitationDrawer from 'components/invitationDrawer';
import RadarChartComponent from 'screens/student/stats/components/radarChart';
import s from "./style.module.css";

//IMAGES
import { UserRoles } from 'common/enums';
import { useNavigate } from 'react-router-dom';
import Search from "../../assets/images/app/search.svg";
import Star from "../../assets/images/app/start.svg";


type Props = {
    sponsorData: any;
    user: any;
    loading: boolean;
}

const ParentOrganismeDashboard: React.FC<Props> = ({ sponsorData, user, loading }) => {

    const navigate = useNavigate();
    const isParent = user?.roles?.includes(UserRoles.PARENT);

    const [invitationDrawerVisible, setInvitationDrawerVisible] = useState(false);
    const onCloseInvitationDrawer = () => {
        setInvitationDrawerVisible(false);
    };

    const [studentsData, setStudentsData] = useState<any>([]);

    const FindStudent = useCallback(async (userNameOrEmail: string, item) => {
        if (userNameOrEmail.length >= 3) {
            try {
                const res = await AUTH_SERVICES.getSponsoredStudents(userNameOrEmail, "", user?._id, "1");
                const data = res?.data?.results;
                setStudentsData(data);
                if (res?.data?.results.length === 0) {
                    notification["info"]({
                        message: "Information",
                        description: "Utilisateur non trouvé : )",
                        style: {
                            width: 300,
                        },
                    });
                }
            } catch (error) {
                if (userNameOrEmail.length >= 6) {
                    notification["info"]({
                        message: "Information",
                        description: "Utilisateur non trouvé : )",
                        style: {
                            width: 300,
                        },
                    });
                }
            }
        }
        else {
            setStudentsData([]);
        }
    }, []);

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                <div style={{
                    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", border: "1px solid #D5D5D5", borderRadius: "16px", marginRight: "20px", position: "relative", bottom: "12.5px", width: "30%", paddingRight: "10px"
                }}>
                    <AutoComplete
                        bordered={false}
                        style={{ margin: "0px", paddingLeft: "10px", paddingRight: "10px", width: "100%" }}
                        onChange={FindStudent}
                        placeholder="Trouver un élève"
                        showSearch={true}
                    >
                    </AutoComplete>
                    <img src={Search} height={15}></img>
                </div>
                <button className={s.defaultButtonPositionned} onClick={() => { setInvitationDrawerVisible(true) }}>Ajouter des élèves</button>
            </div>
            {loading ? <Skeleton active /> : <div className={sponsorData.length >= 3 ? s.studentsBox : s.studentBox} style={{ justifyContent: sponsorData.length === 0 ? "center" : "flex-start" }}>
                {sponsorData.length === 0 && <AppResult
                    status="new"
                    title={isParent ? `Hello ${user?.firstname}, Tu n'as pas encore d'élèves inscrits ! ` : `Hello ${user?.firstname}, vous n’avez pas encore d'élèves inscrits ! `}
                />}
                {studentsData.length !== 0 ? studentsData.map((statsData: any) => (
                    <div className={studentsData.length >= 3 ? s.radarsContainer : s.radarContainer} onClick={() => isParent ? navigate(`${appRoutes.STUDENT_HISTORY}/${statsData['uid']}`, { state: statsData }) : navigate(`${appRoutes.ORGANISME_STUDENT_HISTORY}/${statsData['uid']}`, { state: statsData })}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "20px", marginTop: "20px" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <Image
                                    preview={false}
                                    src={statsData['profile']}
                                    width={70}
                                    alt="Avatar"
                                />
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                    <span className={s.boxTitle}>{statsData['lastname'] + " " + statsData['firstname']}</span>
                                    <div style={{ marginLeft: "20px", background: "#D5D5D5", paddingLeft: "10px", paddingRight: "10px", paddingTop: "2.5px", paddingBottom: "2.5px", borderRadius: "8px" }}>
                                        {statsData['levelId']['title']}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.rateBox}>
                            <div className={s.dashedBox}>
                                <span style={{ color: "#7FB1B2" }}>Cours</span>
                                <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{Number(statsData?.rateCours).toString().includes(".") ? Number(statsData?.rateCours).toFixed(2) : statsData?.rateCours}</span>
                            </div>
                            <div className={s.dashedBox}>
                                <span style={{ color: "#7FB1B2" }}>Application</span>
                                <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{Number(statsData?.rateApplication).toString().includes(".") ? Number(statsData?.rateApplication).toFixed(2) : statsData?.rateApplication}</span>
                            </div>
                            <div className={s.dashedBox}>
                                <span style={{ color: "#7FB1B2" }}>Exercice</span>
                                <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{Number(statsData?.rateExercice).toString().includes(".") ? Number(statsData?.rateExercice).toFixed(2) : statsData?.rateExercice}</span>
                            </div>
                        </div>
                        <div className={s.radarTitle}>MOYENNE GENERALE DES QUIZZ</div>
                        <div className={s.radarBox}>
                            <div className={s.radarMarkBox}>
                                <a className={s.radarAVG}>{statsData['avgGenerale']} </a>
                                <a className={s.radarMaxAVG}> / 20</a>
                            </div>
                            <div className={s.radarChartBox} style={{ cursor: "pointer" }}><RadarChartComponent data={statsData['courseData']} /></div>
                        </div>
                    </div>)) : sponsorData.length !== 0 && sponsorData.map((statsData: any) => (
                        <div className={sponsorData.length >= 3 ? s.radarsContainer : s.radarContainer} onClick={() => isParent ? navigate(`${appRoutes.STUDENT_HISTORY}/${statsData['uid']}`, { state: statsData }) : navigate(`${appRoutes.ORGANISME_STUDENT_HISTORY}/${statsData['uid']}`, { state: statsData })}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "20px", marginTop: "20px" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                    <Image
                                        preview={false}
                                        src={statsData['profile']}
                                        width={70}
                                        alt="Avatar"
                                    />
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                        <span className={s.boxTitle}>{statsData['lastname'] + " " + statsData['firstname']}</span>
                                        <div style={{ marginLeft: "20px", background: "#D5D5D5", paddingLeft: "10px", paddingRight: "10px", paddingTop: "2.5px", paddingBottom: "2.5px", borderRadius: "8px" }}>
                                            {statsData['levelId']['title']}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.rateBox}>
                                <div className={s.dashedBox}>
                                    <span style={{ color: "#7FB1B2" }}>Cours</span>
                                    <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{Number(statsData?.rateCours).toString().includes(".") ? Number(statsData?.rateCours).toFixed(2) : statsData?.rateCours}</span>
                                </div>
                                <div className={s.dashedBox}>
                                    <span style={{ color: "#7FB1B2" }}>Application</span>
                                    <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{Number(statsData?.rateApplication).toString().includes(".") ? Number(statsData?.rateApplication).toFixed(2) : statsData?.rateApplication}</span>
                                </div>
                                <div className={s.dashedBox}>
                                    <span style={{ color: "#7FB1B2" }}>Exercice</span>
                                    <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{Number(statsData?.rateExercice).toString().includes(".") ? Number(statsData?.rateExercice).toFixed(2) : statsData?.rateExercice}</span>
                                </div>
                            </div>
                            <div className={s.radarTitle}>MOYENNE GENERALE DES QUIZZ</div>
                            <div className={s.radarBox}>
                                <div className={s.radarMarkBox}>
                                    <a className={s.radarAVG}>{statsData['avgGenerale']} </a>
                                    <a className={s.radarMaxAVG}> / 20</a>
                                </div>
                                <div className={s.radarChartBox} style={{ cursor: "pointer" }}><RadarChartComponent data={statsData['courseData']} /></div>
                            </div>
                        </div>))
                }
            </div>}
            <InvitationDrawer visible={invitationDrawerVisible} onClose={onCloseInvitationDrawer} />
        </>
    );
};

export default ParentOrganismeDashboard;