import { Image, Input, Popconfirm, message } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import React, { useEffect, useState } from "react";
import portefeuille from "./portefeuille.svg";

// !!REDUX
import { useDispatch, useSelector } from "react-redux";

// !!STYLES
import style from "./style.module.css";
// !ICONS
import { PlusOutlined } from "@ant-design/icons";
import questionMark from "./question_mark.svg";
// !STORE
import { closePaiementDrawer } from "store/chat/mainSlice";

// !COMPONENTS
import { UserRoles } from "common/enums";
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import PaymentTable from "components/paiementHistory";
import { setShowDrawerPaiementOneMoreTimeHandlerToFalse } from "store/auth/authSlice";
import {
  getConvertedAmountFunction,
  getStripeUrlFunction,
} from "store/paiement/paiementSlice";
// !CONSTANTS
const SENTENCE_LABEL = "Solde : ";

type Props = {
  currentUser?: any;
  type: string;
}

// !MEDIA_QUERIES
const smallDevices = window.matchMedia("only screen and (max-width: 992px)");
const mediumDevices = window.matchMedia("only screen and (min-width: 992px)");

export const SponsorDrawerPaiement: React.FC<Props> = ({ currentUser, type }) => {
  // !HOOKS
  const [width, setwidth] = useState<string>();
  const { user } = useSelector((state) => state.auth);
  const { stripeInfos } = useSelector((state) => state.paiement);
  const dispatch = useDispatch();
  const { paiementDrawerOpen } = useSelector((state) => state.main);
  const [montant, setMontant] = useState(user?.coins);
  const visibleHandler = () => {
    if (user?.roles.includes("student")) dispatch(closePaiementDrawer());
  };

  const [page, setPage] = useState<any>(1);
  const [historyData, setHistoryData] = useState([]);

  const s_videoSizeController = (x: any) => {
    if (x.matches) {
      setwidth("100%");
    }
  };

  const m_videoSizeController = (x: any) => {
    if (x.matches) {
      setwidth("35%");
    }
  };

  const isParent = user?.roles?.includes(UserRoles.PARENT);

  // !USE_EFFECT
  useEffect(() => {
    s_videoSizeController(smallDevices);
    smallDevices.addListener(s_videoSizeController);
    m_videoSizeController(mediumDevices);
    mediumDevices.addListener(m_videoSizeController);
  }, []);
  const getConvertedAmount = (amount: String) => {
    if (amount !== "" && amount) {
      dispatch(
        getConvertedAmountFunction({
          amount,
        })
      );
    } else {
      dispatch(getConvertedAmountFunction(0));
    }
  };
  const getStripeUrl = (
    userId: string,
    amount: number,
    success: string,
    cancel: string
  ) => {
    if (amount === 0)
      message.success({
        content: "Veuillez ne pas rentrer un montant égale à 0",
      });
    else {
      dispatch(
        getStripeUrlFunction({
          userId,
          amount,
          success,
          cancel,
        })
      );
    }
  };

  const getStudentPaiementHistory = async () => {
    try {
      const history = await AUTH_SERVICES.getStudentPaiementHistory(currentUser._id, page);
      if (history?.data) {
        setHistoryData(history['data']['results']);
      } else {
        throw new Error("L'utilisateur n'existe pas");
      }
    } catch (error) { }
  }

  useEffect(() => {
    getConvertedAmount("0");
    setTimeout(() => {
      dispatch(setShowDrawerPaiementOneMoreTimeHandlerToFalse());
    }, 10000);
  }, []);

  useEffect(() => {
    if (stripeInfos.statusCode === 201) {
      window.open(stripeInfos.data.url);
      /* location.reload(); */
    }
  }, [stripeInfos]);

  useEffect(() => {
    getStudentPaiementHistory();
  }, [page]);

  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "40px" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
        <Image
          fallback={`http://gravatar.com/avatar${currentUser?.uid}?d=identicon`}
          preview={false}
          src={
            !!currentUser?.profile
              ? currentUser?.profile
              : `https://gravatar.com/avatar/${currentUser?._id}?d=identicon`
          }
        />
        <span className={style.boxTitle}>{currentUser?.lastname + " " + currentUser?.firstname}</span>
      </div>
      <div className={`${style.__columnArrangement} ${style.convertedAmount}`}>
        <div>
          <Image
            preview={false}
            src={portefeuille}
            style={{ width: "30px", marginRight: "10px" }}
          />
          {SENTENCE_LABEL}
          <span>{currentUser?.coins} €</span>
        </div>
        <>
          {/* {currentUser?.coins === 0 ? (
            <>
              penses à recharger le portefeuille pour réserver des cours
              particuliers
            </>
          ) : (
            <div style={{ visibility: "hidden" }}>
              penses à recharger le portefeuille pour réserver des cours
              particuliers
            </div>
          )} */}
        </>
      </div>
      <div className={`${style.__topBlock}`}>
        <div>Recharger mon solde</div>
        <div className={`${style.__topBlockEntry}`}>
          <Input
            className={style.field}
            type="number"
            placeholder="Montant de recharge"
            min="0"
            onChange={(e) => {
              if (e.target.value === "") {
                setMontant(0);
              } else {
                setMontant(parseInt(e.target.value));
              }
            }}
          />

          <Popconfirm
            placement="top"
            title={"Voulez-vous continuer ?"}
            onConfirm={() =>
              getStripeUrl(
                currentUser?._id || "",
                montant,
                isParent ? "https://monamialbert.com/parent/achat/success" : "https://monamialbert.com/organisme/achat/success",
                isParent ? "https://monamialbert.com/parent/achat/echec" : "https://monamialbert.com/organisme/achat/echec"
              )
            }
            okText="Oui"
            cancelText="Non"
            icon={
              <img
                src={questionMark}
                alt=""
                style={{ float: "left", width: "15px" }}
              />
            }
          >
            <AppButton icon={<PlusOutlined />}>Recharger</AppButton>
          </Popconfirm>

        </div>
      </div>
      {type === "student" && <div style={{ marginTop: "40px" }}>
        <H1>Historique des paiements</H1>
      </div>}
      {type === "student" && <div style={{ marginTop: "40px" }}>
        <PaymentTable payments={historyData} />
      </div>}
    </div>
  );
};
