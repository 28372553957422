import * as appRoutes from "config/routes.config";
import { FC, useState } from "react";

//!IMAGES
import instaBadges from "assets/images/app/InstaProf_Icon.png";
import instaBadgesRed from "assets/images/students/instaProfBadgeFalse.svg";
import ActivitiesIcon from "./assets/images/ads.svg";
import Calendrier from "./assets/images/calendar_icon.svg";
import Content from "./assets/images/content.svg";
import Dashboard from "./assets/images/dashboard.svg";
import MenuIcon from "./assets/images/menu.svg";
import Msg from "./assets/images/msg.svg";
import Prof from "./assets/images/prof.svg";
import Wallet from "./assets/images/wallet.svg";

//!STYLES
import { UserRoles } from "common/enums";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MenuModal from "screens/teacher/components/menu";
import { toggleAppChat } from "store/AppChat/appChat";
import {
  closePorteFeuilleDrawer__,
  closeProfileDrawer,
  openPorteFeuilleDrawer__,
  openProfileDrawer
} from "store/auth/authSlice";
import {
  closeChatDrawerStudent,
  closePaiementDrawer,
  openPaiementDrawer,
} from "store/chat/mainSlice";
import {
  ACTIVE_DRAWER_ALERT_INSTAPROF,
  ACTIVE_MODE_INSTAPROF,
  INIT_MODAL,
} from "store/notification";
import style from "./assets/user.header.module.css";

type Props = {
  openMessagerieDrawer: () => void;
  visible: boolean;
  setVisible: any;
};

const NavUser: FC<Props> = ({
  openMessagerieDrawer,
  visible,
  setVisible,
}) => {
  //STATE
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const showModalInfoInstaProf = () => {
    dispatch(ACTIVE_MODE_INSTAPROF(true));
    dispatch(INIT_MODAL());
  };

  const closeModalInfoInstaProf = () => {
    dispatch(ACTIVE_MODE_INSTAPROF(false));
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const isStudent = user?.roles?.includes(UserRoles.STUDENT);
  const isParent = user?.roles?.includes(UserRoles.PARENT);
  const isOrganism = user?.roles?.includes(UserRoles.ORGANISME);

  const [menuVisible, setMenuVisible] = useState(false);
  const openDrawer = () => dispatch(openProfileDrawer());
  const openPaiementStudentDrawer = () => {
    dispatch(openPaiementDrawer());
  };
  const openPorteFeuilleDrawer = () => dispatch(openPorteFeuilleDrawer__());

  const STUDENT_MOBILE = [
    /* {
      id: 0,
      name: "cours",
      icon: Content,
      link: appRoutes.STUDENT_MY_CONTENT,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeChatDrawerStudent());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
      },
      size: "30px",
    }, */
    {
      id: 0,
      name: "Accueil",
      icon: ActivitiesIcon,
      link: appRoutes.TEACHER_ACTIVITIES,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeChatDrawerStudent());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        setMenuVisible(false);
      },
      size: "45px",
    },
    {
      id: 1,
      name: "profs",
      icon: Prof,
      link: appRoutes.STUDENT_SEARCH_BEST_TEACHER,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeChatDrawerStudent());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        setMenuVisible(false);
      },
      size: "30px",
    },
    {
      id: 4,
      name: "Menu",
      icon: MenuIcon,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeChatDrawerStudent());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        setMenuVisible(true);
      },
      link: "#",
      size: "30px",
    },
    {
      id: 2,
      name: "Dashboard",
      icon: Dashboard,
      link: appRoutes.STUDENT_DASHBOARD,
      size: "29px",
      demoModal: () => {
        dispatch(toggleAppChat(false));
        setMenuVisible(false);
      },
    },

    {
      id: 3,
      name: "message",
      icon: Msg,
      demoModal: () => {
        dispatch(toggleAppChat(true));
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
        setMenuVisible(false);
      },
      link: "#",
      size: "20px",
    },
    /* {
      id: 3,
      name: "classes",
      icon: classes,
      link: appRoutes.STUDENT_MY_CLASS,
      size: "20px",
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeChatDrawerStudent());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
      },
    }, */
  ];

  const TEACHER_MOBILE = isMobile ? [
    {
      id: 1,
      name: "Accueil",
      icon: ActivitiesIcon,
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : appRoutes.TEACHER_ACTIVITIES,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "45px",
    },
    {
      id: 2,
      name: "Dashboard",
      icon: Dashboard,
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : appRoutes.TEACHER_DASHBOARD,
      size: "26px",
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
    },
    {
      id: 4,
      name: "Menu",
      icon: MenuIcon,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeChatDrawerStudent());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        setMenuVisible(true);
      },
      link: "#",
      size: "30px",
    },
    {
      id: 0,
      name: "Calendrier",
      icon: Calendrier,
      link: appRoutes.TEACHER_SET_CALENDAR,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "26px",
    },
    /* {
      id: 0,
      name: "mon contenu",
      icon: Content,
      link: appRoutes.COURSESBYLEVELS,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "26px",
    }, */
    {
      id: 3,
      name: "message",
      icon: Msg,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(user?.step !== "completed" ? false : true));
        closeModalInfoInstaProf();
      },
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : "#",
      size: "20px",
    },
  ] : [
    {
      id: 1,
      name: "Accueil",
      icon: ActivitiesIcon,
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : appRoutes.TEACHER_ACTIVITIES,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "45px",
    },
    {
      id: 2,
      name: "Dashboard",
      icon: Dashboard,
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : appRoutes.TEACHER_DASHBOARD,
      size: "26px",
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
    },
    {
      id: 0,
      name: "mon contenu",
      icon: Content,
      link: /* user?.step !== "completed" ?  */appRoutes.COURSESBYLEVELS/*  : appRoutes.TEACHER_COURSES */,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "26px",
    },
    {
      id: 3,
      name: "message",
      icon: Msg,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(user?.step !== "completed" ? false : true));
        closeModalInfoInstaProf();
      },
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : "#",
      size: "20px",
    },
    {
      id: 3,
      name: "insta prof",
      icon: user?.instaProf === true ? instaBadges : instaBadgesRed,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        user?.instaProf === true ? dispatch(ACTIVE_DRAWER_ALERT_INSTAPROF()) : showModalInfoInstaProf();
      },
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : "#",
      size: "25px",
    },
  ];

  const INACTIVE_TEACHER_MOBILE = [
    {
      id: 1,
      name: "Accueil",
      icon: ActivitiesIcon,
      link: appRoutes.TEACHER_ACTIVITIES,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "45px",
    },
    {
      id: 2,
      name: "Dashboard",
      icon: Dashboard,
      link: appRoutes.TEACHER_DASHBOARD,
      size: "26px",
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
    },
    {
      id: 0,
      name: "mon contenu",
      icon: Content,
      link: appRoutes.COURSESBYLEVELS,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "26px",
    },
    {
      id: 3,
      name: "message",
      icon: Msg,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(user?.step !== "completed" ? false : true));
        closeModalInfoInstaProf();
      },
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : "#",
      size: "20px",
    },
  ];

  const PARENT_ORGANISM_MOBILE = [
    {
      id: 0,
      name: "Profs",
      icon: Prof,
      link: isParent ? appRoutes.FIND_STUDENT_TEACHER : appRoutes.ORGANISM_FIND_STUDENT_TEACHER,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "26px",
    },
    {
      id: 1,
      name: "Portefeuille",
      icon: Wallet,
      link: isParent ? appRoutes.WALLETS : appRoutes.ORGANISM_WALLETS,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "25px",
    },
    {
      id: 2,
      name: "Dashboard",
      icon: Dashboard,
      link: isParent ? appRoutes.PARENT_DASHBOARD : appRoutes.ORGANISME_DASHBOARD,
      size: "26px",
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
    },

    {
      id: 3,
      name: "message",
      icon: Msg,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(true));
        closeModalInfoInstaProf();
      },
      link: "#",
      size: "20px",
    },
  ];

  return (
    <>
      <nav className={`${style.nav__bar_mobile} `} id="mobile-menu">
        <ul className={style.nav__item}>
          {isStudent ? STUDENT_MOBILE.map((item) => {
            return (
              <li className={style.menuItemStudent}>
                <ul>
                  <li className={item?.id === 4 ? style.navbarItemMenu : style.navbarItemBox}>
                    <Link to={item?.link} onClick={item.demoModal}>
                      <img
                        src={item.icon}
                        height={item.size}
                      />
                    </Link>
                  </li>
                  <li onClick={item.demoModal}>
                    <Link to={item?.link}>{item.name}</Link>
                  </li>
                </ul>
              </li>
            );
          }) : isParent ? PARENT_ORGANISM_MOBILE.map((item) => {
            return (
              <li>
                <ul>
                  <li className={style.navbarItemBox}>
                    <Link to={item?.link} onClick={item.demoModal}>
                      <img
                        src={item.icon}
                        height={item.size}
                      />
                    </Link>
                  </li>
                  <li onClick={item.demoModal}>
                    <Link to={item?.link}>{item.name}</Link>
                  </li>
                </ul>
              </li>
            );
          }) : isOrganism ? PARENT_ORGANISM_MOBILE.map((item) => {
            return (
              <li>
                <ul>
                  <li className={style.navbarItemBox}>
                    <Link to={item?.link} onClick={item.demoModal}>
                      <img
                        src={item.icon}
                        height={item.size}
                      />
                    </Link>
                  </li>
                  <li onClick={item.demoModal}>
                    <Link to={item?.link}>{item.name}</Link>
                  </li>
                </ul>
              </li>
            );
          }) : user?.isActive ? TEACHER_MOBILE.map((item) => {
            return (
              <li className={style.menuItem}>
                <ul>
                  <li className={item?.id === 4 ? style.navbarItemMenu : style.navbarItemBox}>
                    <Link to={item?.link} onClick={item.demoModal}>
                      <img
                        src={item.icon}
                        height={item.size}
                      />
                    </Link>
                  </li>
                  <li onClick={item.demoModal}>
                    <Link to={item?.link}>{item.name}</Link>
                  </li>
                </ul>
              </li>
            );
          }) : !user?.isActive && INACTIVE_TEACHER_MOBILE.map((item) => {
            return (
              <li className={style.menuItem}>
                <ul>
                  <li className={style.navbarItemBox}>
                    <Link to={item?.link} onClick={item.demoModal}>
                      <img
                        src={item.icon}
                        height={item.size}
                      />
                    </Link>
                  </li>
                  <li onClick={item.demoModal}>
                    <Link to={item?.link}>{item.name}</Link>
                  </li>
                </ul>
              </li>
            );
          })}
        </ul>
      </nav>

      <MenuModal
        visible={menuVisible}
        setVisible={setMenuVisible}
        openDrawer={openDrawer}
        openMessagerieDrawerStudent={openMessagerieDrawer}
        openPaiementDrawer={openPaiementStudentDrawer}
        openPorteFeuilleDrawer__={openPorteFeuilleDrawer}
      />
    </>
  );
};

export default NavUser;
