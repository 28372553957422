import { FC, useState } from "react";

import StartIcon from "assets/images/students/teacherStart.svg";
import Teacher from "assets/images/teacher/teacher.svg";
import QuoteIcon from "../../../../../assets/images/app/quote_icon.svg";

//!STYLES
import s from "./assets/style.module.css";

type Props = {
  av: any;
};
const CardAvis: FC<Props> = ({ av }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  return (
    <div className={s.card}>
      <div className={s.avatarBox}>
        <img src={av?.studId?.profile || Teacher} alt="" className={s.avatarPicture} />
      </div>
      <div className={s.description}>
        <span className={s.name}>{av?.studId?.firstname?.split(" ")[0]}{" "} {av?.studId?.lastname?.split(" ")[0]}</span>
        {isMobile && <span>
          <strong>{av?.rate || 0}</strong>
          <img
            src={StartIcon}
            alt=""
            height={15}
            style={{ position: "relative", top: "-2px", marginLeft: "5px" }}
          />
        </span>}
        {isMobile && <img src={QuoteIcon} height={20} style={{ position: "absolute", right: "20px", bottom: "15px" }}></img>}
        <span className={s.content}>{av?.content}</span>
        {!isMobile && <span>
          <strong>{av?.rate || 0}</strong>
          <img
            src={StartIcon}
            alt=""
            height={15}
            style={{ position: "relative", top: "-2px", marginLeft: "5px" }}
          />
        </span>}
      </div>
      {!isMobile && <img src={QuoteIcon}></img>}
    </div>
  );
};

export default CardAvis;
