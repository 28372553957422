import { Avatar, Button, Dropdown, Input, Menu, message, Modal, Popconfirm, Spin } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { calculateTimeDifference, StatusCode } from 'common/enums';
import Matiers from "components/matiers";
import ZoomMeeting from 'components/zoom';
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TeacherCalendarForStudent from 'screens/student/components/calendarTeacher';
import { setIsReservationsUpdated } from 'store/app/appSlice';
import { openProfileDetailsDrawer, setProfileDetailsID, setProfileDetailsType } from 'store/auth/authSlice';
import MoreOptions from "../../../../assets/images/app/chat_options.svg";
import ClockIcon from "../../../../assets/images/app/clock_icon.svg";
import EllipseTeacherProfileFour from "../../../../assets/images/app/ellipse_teacher_profile4.png";
import EllipseTeacherProfileFive from "../../../../assets/images/app/ellipse_teacher_profile5.png";
import CancelIcon from "../../../../assets/images/app/i_icon.svg";
import MeetIcon from "../../../../assets/images/app/meet_now_icon.svg";
import s from "../assets/calendar.module.css";

type Props = {
  course: any;
  isHistorique: boolean;
};

const CardReservations: FC<Props> = ({ course, isHistorique }) => {

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  /* const [scheduleData, setScheduleData] = useState<any>(null); */
  /* const [openScheduleMeetingDrawer, setOpenScheduleMeetingDrawer] = useState<boolean>(false); */
  const [showDrawerTeacherCalendar, setShowDrawerTeacherCalendar] = useState<boolean>(false);
  const [isZoomMeetingVisible, setIsZoomMeetingVisible] = useState(false);
  const [currentMeetingId, setCurrentMeetingId] = useState('');

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [teacherData, setTeacherData] = useState<any>();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  const handleMenuClick = (e: any) => {
    /* if (e.key === "1") {
      setDropdownVisible(false);
      handleMeetingClick();
    } else  */if (e.key === "1") {
      setPopconfirmVisible(true);
      setDropdownVisible(false);
    }
    else if (e.key === "2") {
      setDropdownVisible(false);
      setIsLoadingData(true);
      getTeacherData(course?.profId?._id);
    }
  };

  const handleStatMeeting = () => {
    setDropdownVisible(false);
    handleMeetingClick();
  };

  const handleConfirm = () => {
    setPopconfirmVisible(false);
    showModal();
  };

  const handleCancel = () => {
    setPopconfirmVisible(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelMessage, setCancelMessage] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  const handleScheduleMeeting = () => {
    setIsLoadingData(true);
    getTeacherData(course?.profId?._id);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {/* <Menu.Item key="1">Rejoindre le meet</Menu.Item> */}
      <Menu.Item key="1">Annuler le meet</Menu.Item>
      <Menu.Item key="2">Replanifier le meet</Menu.Item>
    </Menu>
  );

  const cancelMeeting = async () => {
    setIsLoading(true);
    try {
      if (cancelMessage !== "") {
        const response = await AUTH_SERVICES.cancelMeeting(course?._id, cancelMessage);
        if (response?.statusCode === StatusCode.CREATED) {
          message.success({
            content: "Meet annulé ",
          });
          setTimeout(() => {
            dispatch(setIsReservationsUpdated(true));
          }, 3000);
          setIsLoading(false);
          setIsModalOpen(false);
        }
        else {
          message.warning({
            content: "Ooops, erreur lors de l'annulation",
          });
          setIsLoading(false);
        }
      }
      else {
        message.warning({
          content: "Ooops, entre le message de l'annulation",
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
      setIsLoading(false);
    }
  }

  /* const openUrlInNewTab = () => {
    window.open(course?.studentLink, '_blank');
  }; */

  const handleMeetingClick = () => {
    if (!course?.studentLink) {
      message.error("Lien de réunion non trouvé pour ce cours");
      return;
    }

    // Extract meeting ID from Zoom link - handle multiple URL formats
    let meetingId;
    try {
      const url = new URL(course.studentLink);
      // Handle different Zoom URL formats
      if (url.pathname.includes('/j/')) {
        meetingId = url.pathname.split('/j/')[1]?.split('?')[0];
      } else if (url.pathname.includes('/s/')) {
        meetingId = url.pathname.split('/s/')[1]?.split('?')[0];
      } else {
        // Try to find any number sequence that could be a meeting ID
        meetingId = url.pathname.match(/\d{10,11}/)?.[0];
      }

      if (!meetingId) {
        throw new Error('Meeting ID not found in URL');
      }

      setCurrentMeetingId(meetingId);
      setIsZoomMeetingVisible(true);

    } catch (error) {
      message.error("Format du lien de réunion invalide");
    }
  };

  const getTeacherData = async (teacherId: string) => {
    try {
      const response = await AUTH_SERVICES.getTeacherData(user?._id, teacherId);
      setTeacherData(response?.data);

      setTimeout(() => {
        setIsLoadingData(false);
        setIsModalOpen(false);
        setShowDrawerTeacherCalendar(true);
      }, 2000);
    } catch (error: any) {
      setIsLoadingData(false);
    }
  };

  const handleProfileDetails = () => {
    dispatch(setProfileDetailsID(course?.profId?.uid));
    dispatch(setProfileDetailsType("teacher"));
    dispatch(openProfileDetailsDrawer());
  }

  return (
    <>
      {!isMobile && <img src={EllipseTeacherProfileFour} height={106} style={{ position: "absolute", top: "25%", left: "0px", zIndex: "-1" }}></img>}
      {!isMobile && <img src={EllipseTeacherProfileFive} height={54} style={{ position: "absolute", top: "35%", left: "94%", zIndex: "-1" }}></img>}
      <div className={`${course?.cancelMessage !== "" ? s.classeCardDeleted : s.classeCard}`} style={{ cursor: "pointer" }}>
        <div className={s.cardClassBox}>
          <div className={s.avatarNameBox} onClick={handleProfileDetails}>
            <Avatar.Group
              maxCount={3}
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
              }}
              style={{ marginRight: isMobile ? "0px" : "5px" }}
            >
              <Avatar
                size={45}
                style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
                src={course?.profId?.profile}
              />
            </Avatar.Group>
            <div style={{ position: "relative", left: isMobile ? "0px" : "15px", fontSize: isMobile ? "0.8em" : "13px", textTransform: "capitalize" }}>{course?.profId?.firstname + " " + course?.profId?.lastname}</div>
          </div>
          {isMobile && <Matiers
            cours={course?.topic?.title.slice(0, 4) || "null"}
            level={course?.level?.title || "null"}
          />}
          <div className={s.detailsBox}>
            <div className={s.timeBox}>
              {!isMobile && <img src={ClockIcon} height={15} style={{ marginRight: "10px" }}></img>}
              <div className={s.times}>
                <span className={s.time}>{isMobile && <img src={ClockIcon} height={10} style={{ marginRight: "5px" }}></img>} De {course?.range[0]} à {course?.range[course?.range?.length - 1]}</span>
                <span className={s.timeDescription}>Séance de “{calculateTimeDifference(course?.range[0], course?.range[course?.range.length - 1])}”</span>
              </div>
            </div>
            {!isMobile && <Matiers
              cours={course?.topic?.title.slice(0, 4) || "null"}
              level={course?.level?.title || "null"}
            />}
            <div className={s.amountBox}>
              Séance réservée pour
              <div className={s.priceBox}>{course?.byCredits === "oui" ? course?.creditedValue + " Crédit" : course?.price + " €"}</div>
            </div>
          </div>

          {isLoading || isLoadingData ? <div>
            <Spin />
          </div> : !isHistorique && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            {course?.cancelMessage === "" && <Button onClick={handleStatMeeting} className={s.meetButton}>
              {!isMobile && "Rejoindre le meet"} <img src={MeetIcon} height={isMobile ? 14 : 18} style={{ marginLeft: isMobile ? "0px" : "5px" }}></img>
            </Button>}
            {!isMobile && course?.cancelMessage === "" && <Popconfirm
              title={`Veux-tu annuler ce meet?`}
              visible={popconfirmVisible}
              onVisibleChange={(visible) => {
                if (!isLoading) {
                  setPopconfirmVisible(visible);
                }
              }}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              okText="Oui"
              cancelText="Non"
            >
              <Button type="primary" />
            </Popconfirm>}
            {course?.cancelMessage === "" && <Dropdown overlay={menu} visible={dropdownVisible} onVisibleChange={setDropdownVisible} overlayStyle={{ border: "none" }}>
              <Button onClick={() => setDropdownVisible(true)} className={s.moreOptionsButton}>
                <img src={MoreOptions} height={isMobile ? 14 : 18}></img>
              </Button>
            </Dropdown>}
            {course?.cancelMessage !== "" && <div className={s.cancelBox}>
              <div className={s.horizontalLineDeleted}></div>
              <span>
                <img src={CancelIcon} height={isMobile ? 10 : 18} style={{ marginRight: "5px", marginLeft: isMobile ? "0px" : "20px" }}></img>
                Meet annulé
              </span>
            </div>}
          </div>}
        </div>
      </div>

      {isMobile && <Popconfirm
        title={`Veux-tu annuler ce meet?`}
        visible={popconfirmVisible}
        onVisibleChange={(visible) => {
          if (!isLoading) {
            setPopconfirmVisible(visible);
          }
        }}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        okText="Oui"
        cancelText="Non"
      >
        <Button type="primary" />
      </Popconfirm>}

      {/* <ScheduleMeeting setScheduleData={setScheduleData} setOpenScheduleMeetingDrawer={setOpenScheduleMeetingDrawer} openScheduleMeetingDrawer={openScheduleMeetingDrawer} id={course?._id} /> */}
      <TeacherCalendarForStudent
        open={showDrawerTeacherCalendar}
        setOpen={setShowDrawerTeacherCalendar}
        currentTeacher={teacherData}
        currentReservation={course}
      />

      <Modal
        title={
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
            <Avatar.Group
              maxCount={3}
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
              }}
              style={{ marginRight: "5px" }}
            >
              <Avatar
                size={45}
                style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
                src={user?.profile}
              />
            </Avatar.Group>
            Annulation de meet
          </div>
        }
        visible={isModalOpen}
        onCancel={handleCancelModal}
        footer={null}
      >
        <div style={{ marginBottom: "16px", textTransform: "capitalize" }}>
          <strong>
            {new Intl.DateTimeFormat("fr-FR", {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric",
            }).format(new Date(course?.day))}
          </strong>
        </div>
        <div style={{ marginBottom: "8px" }}>
          <span>Message pour {course?.profId?.firstname}</span>
        </div>
        <Input.TextArea
          rows={3}
          placeholder="J'annule le meet parce que..."
          value={cancelMessage}
          onChange={(e) => setCancelMessage(e.target.value)}
          style={{ borderRadius: "10px" }}
          required={true}
        />
        <div
          style={{
            marginTop: "24px",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: isMobile ? "center" : "space-between",
          }}
        >
          <Button className={s.scheduleButton} loading={isLoadingData} onClick={handleScheduleMeeting}>
            Reprogrammer plutôt
          </Button>
          <Button className={s.cancelButton} loading={isLoading || isLoadingData} onClick={cancelMeeting}>
            Confirmer l'annulation
          </Button>
        </div>
      </Modal>

      {isZoomMeetingVisible && (
        <ZoomMeeting
          meetingNumber={currentMeetingId}
          userName={user?.firstname + " " + user?.lastname}
          sdkKey={process.env.REACT_APP_ZOOM_SDK_KEY ?? ""}
          sdkSecret={process.env.REACT_APP_ZOOM_SDK_SECRET ?? ""}
          onClose={() => {
            console.log('Closing Zoom meeting');
            setIsZoomMeetingVisible(false);
          }}
        />
      )}
    </>
  );
};

export default CardReservations;
