import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
  isFirsload: boolean;
  isFullscreen: boolean;
  isRecording: boolean;
  isCorrectingLevel: boolean;
  isRegistering: boolean;
  isCalendarUpdated: boolean;
  isReservationsUpdated: boolean;
  isBotCalled: boolean;
  userHavingCalendar: boolean;
}

const initialState: AppState = {
  isFirsload: true,
  isFullscreen: false,
  isRecording: false,
  isCorrectingLevel: false,
  isRegistering: false,
  isCalendarUpdated: false,
  isReservationsUpdated: false,
  isBotCalled: false,
  userHavingCalendar: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    isFirsload: (state, action: PayloadAction<boolean>) => {
      state.isFirsload = action.payload;
    },
    setFullscreen: (state, action: PayloadAction<boolean>) => {
      state.isFullscreen = action.payload;
    },
    setRecording: (state, action: PayloadAction<boolean>) => {
      state.isRecording = action.payload;
    },
    setCorrectingLevel: (state, action: PayloadAction<boolean>) => {
      state.isCorrectingLevel = action.payload;
    },
    setIsRegistering: (state, action: PayloadAction<boolean>) => {
      state.isRegistering = action.payload;
    },
    setIsCalendarUpdated: (state, action: PayloadAction<boolean>) => {
      state.isCalendarUpdated = action.payload;
    },
    setIsReservationsUpdated: (state, action: PayloadAction<boolean>) => {
      state.isReservationsUpdated = action.payload;
    },
    setIsBotCalled: (state, action: PayloadAction<boolean>) => {
      state.isBotCalled = action.payload;
    },
    setUserHavingCalendar: (state, action: PayloadAction<boolean>) => {
      state.userHavingCalendar = action.payload;
    },
  },
});

/* export const { isFirsload } = appSlice.actions;
export const { setFullscreen } = appSlice.actions;

export default appSlice.reducer; */

export const {
  isFirsload,
  setFullscreen,
  setRecording,
  setCorrectingLevel,
  setIsRegistering,
  setIsCalendarUpdated,
  setIsReservationsUpdated,
  setIsBotCalled,
  setUserHavingCalendar,
} = appSlice.actions;

const { reducer } = appSlice;
export default reducer;
