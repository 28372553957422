import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Switch,
  Table,
  Typography,
  message
} from "antd";
import { updateProposition } from "api/services/quizz/quizz.services";
import { CKEditor } from "ckeditor4-react";
import Container from "components/container";
import * as appRoutes from "config/routes.config";
import useQuizz from "hooks/useQuizz.hook";
import useQuizzProposition from "hooks/useQuizzProposition.hook";
import { useEffect, useState } from "react";
import Latex from "react-latex-next";
import { Link, useParams } from "react-router-dom";

const { Title } = Typography;

const EditQuizzProposition = () => {
  //!HOOKS
  const params = useParams();
  const [form] = Form.useForm();
  const { data, loading: propositionLoading } = useQuizzProposition(
    params.id!,
    params.propositionId!
  );
  const { data: quizzPropositions, loading: quizzPropositionsLoading } =
    useQuizz(params.id!);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text?: string) => <span>{!!text ? text : "-"}</span>,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Créé le",
      key: "createdAt",
      dataIndex: "createdAt",
    },
    {
      title: "Correct",
      key: "isCorrect",
      dataIndex: "isCorrect",
      render: (isCorrect?: boolean) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={isCorrect}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (quizz: any) => (
        <Link
          to={`${appRoutes.TEACHER_QUIZZ}/${params.id}/edit/propositions/${quizz?._id}`}
        >
          <Button type="primary" ghost icon={<EditOutlined />} />
        </Link>
      ),
    },
  ];

  //!STATE
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [hideEditor, setHideEditor] = useState(true);
  const [editorLoading, setEditorLoading] = useState(true);
  const [updatePropositionLoading, setUpdatePropositionLoading] =
    useState(false);

  //!FUNCTIONS
  const update = async (values: any) => {
    try {
      setUpdatePropositionLoading(true);
      const description =
        values?.description?.editor?.getData() || data?.description;
      values.description = description;
      await updateProposition(params.id!, params.propositionId!, values);
      message.success("Proposition updated");
    } catch (error) {
    } finally {
      setUpdatePropositionLoading(false);
    }
  };

  //!EFFECTS
  useEffect(() => {
    if (data) {
      setTitle(data?.title || "");
      setDescription(data?.description || "");
      form.setFieldsValue({
        title: data?.title || "",
        description: data?.description || "",
        isCorrect: data?.isCorrect || false,
      });
    }

    if (!propositionLoading && data) {
      setTimeout(() => {
        setHideEditor(false);
      }, 10);
    }

    return () => {
      setHideEditor(true);
    };
  }, [data, form, propositionLoading]);

  return (
    <div>
      <Container>
        <Row>
          <Col span={14}>
            <Title level={3}>Title de la proposition : {data?.title}</Title>
          </Col>
          <Col span={10}>
            <Row justify="end">
              <Button type="primary" icon={<LeftOutlined />}>
                Revenir à la liste de cours
              </Button>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row gutter={20}>
          <Col span={12}>
            <Card bordered={false}>
              <Form form={form} layout="vertical" onFinish={update}>
                <Form.Item name="title" label="Titre du chapitre">
                  <Input onChange={(e) => setTitle(e.target.value)} />
                </Form.Item>

                {/* {editorLoading &&
                                    <Form.Item>
                                        <Card loading />
                                    </Form.Item>
                                } */}
                <Form.Item name="description" label="Détail du chapitre">
                  {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
                  {!hideEditor && (
                    <CKEditor
                      // value={chapter?.description || ''}
                      name={data?._id}
                      config={{
                        language: 'fr', // Set the language to French
                      }}
                      initData={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.description || "",
                          }}
                        />
                      }
                      // data={chapter?.description || ''}
                      onChange={(changes: any) => {
                        const content = changes.editor.getData();
                        setDescription(content);
                      }}
                      onInstanceReady={() => {
                        setEditorLoading(false);
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item valuePropName="checked" name="isCorrect">
                  <Checkbox>Réponse correcte ?</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    icon={<EditOutlined />}
                    loading={updatePropositionLoading}
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    Mettre à jour le chapitre
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Title level={3}>Apperçu de la proposition: {data?.title}</Title>
              <Title level={5}>{title}</Title>
              <Latex>
                {description
                  ? description
                    ?.replaceAll("amp;", "")
                    ?.replaceAll("<br />", "")
                  : ""}
              </Latex>
            </Card>
          </Col>
        </Row>

        <Divider />

        <Title level={3}>Autres Propositions</Title>

        <Card
          loading={!!quizzPropositionsLoading}
          title={data?.title}
          extra={
            <Button icon={<LeftOutlined />} type="primary" ghost>
              Retourner au quiz
            </Button>
          }
        >
          <Table
            loading={!!quizzPropositionsLoading}
            columns={columns}
            dataSource={quizzPropositions.propositions?.filter((prop: any) => {
              return prop._id !== params.propositionId;
            })}
          />
        </Card>
      </Container>
    </div>
  );
};

export default EditQuizzProposition;
