import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Steps,
  Switch
} from "antd";
import { Language, StatusCode } from "common/enums";
import { useWindowSize } from "hooks/useWindowSize.hook";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchTeacherMutation } from "store/services/student/ads.services";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import Grid from "components/libs/grid";
import H1 from "components/libs/title";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import CardAdsTeacher from "./components";

//!IMAGES
import { ReactComponent as SarchIcon } from "../../../assets/images/app/search.svg";

//!STYLES
import AppResult from "components/appResults";
import { useLocation } from "react-router-dom";
import { setUserConnect } from "store/auth/authSlice";
import { useGetAllLevelsQuery } from "store/services/app/levels.services";
import Pagination from "../../../components/pagination/index";
import s from "./assets/searchTeacher.module.css";

const { Option } = Select;
const { Step } = Steps;
const { Search } = Input;

const SearchTeacher: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const topics = useSelector((state) => state.topics);
  const { data: levelsData } = useGetAllLevelsQuery("");
  const size = useWindowSize();
  const [searchTeacher, { data: teacher, isLoading, isError, isSuccess }] =
    useSearchTeacherMutation();
  const location = useLocation();
  // State to hold the input value
  const [inputValue, setInputValue] = useState('');
  const [formInfos] = Form.useForm();

  //!STATE
  const [openDrawerInstaProf, setOpenDrawerInstaProf] =
    useState<boolean>(false);
  const [tempTopic, setTempTopic] = useState<any>();
  const refSubmiteBtn = useRef<any>(null);
  const [top, setTop] = useState(10);
  const [showDrawerCreProf, setShowDrawerCreProf] = useState<boolean>(false);
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [isBtnInstaProf, setIsBtnInstaProf] = useState<boolean>(false);
  const [idTeacher, setIdTeacher] = useState<any>(null);
  const [currentPage, setcurrentPage] = useState<number>(1);
  const searchParams = new URLSearchParams(location.search);
  const inputParams = searchParams.get('s');

  const [selectedValue, setSelectedValue] = useState<string>('');

  const [teachersAdded, setTeachersAdded] = useState(user?.listProfs);

  const handleSelectChange = (value: string) => {
    if (value !== undefined) {
      const [minValue, maxValue] = value.split('-');
      const minField = 'minTarif';
      const maxField = 'maxTarif';
      if (maxValue === 'plus') {
        formInfos.setFieldsValue({
          [minField]: parseInt(minValue)
        });
        formInfos.setFieldsValue({
          ['maxTarif']: undefined
        });
      } else {
        formInfos.setFieldsValue({
          [minField]: parseInt(minValue),
          [maxField]: parseInt(maxValue)
        });
      }

      formInfos.setFieldsValue({
        ['tarif']: undefined
      });
      onSubmit();
    }
  };

  const onShowDrawerCalendarProf = () => {
    if (isLoggedIn) {
      setShowDrawerCreProf(true);
    } else {
      dispatch(setUserConnect(true));
    }
  };

  const showDrawerSearchInstaProf = () => {
    if (isLoggedIn) {
      setOpenDrawerInstaProf(!openDrawerInstaProf);
    } else {
      dispatch(setUserConnect(true));
    }
  };

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      const response = await TOPICS_SERVICES.findAll(levelId);
      if (response?.statusCode === StatusCode.OK) {
        setTempTopic(response?.data);
      }
    },
    [tempTopic]
  );

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const onSubmit = () => {
    refSubmiteBtn?.current?.click();
  };

  const handleSubmit = (e: any) => {
    handleInputChange(e);
    onSubmit();
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  useEffect(() => {
    if (size.width > 700) {
      setDisplayFilter(true);
    }
  }, [displayFilter, size.width]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const s = searchParams.get('s');

    if (s) {
      setInputValue(s);
      onSubmit();
    }
    else {
      searchTeacher({ page: currentPage });
    }
  }, [currentPage, location.search, inputValue]);

  const levels = levelsData?.data;

  return (
    <React.Fragment>
      <Breadcrumbs isTeachers />
      <AppScreen className={s.__searchTeacher}>
        <div className={s.header}>
          <Row justify="center" align="middle">
            <Col>
              <H1>Trouver le prof parfait</H1>
            </Col>
          </Row>
          <Form
            layout="inline"
            name="searchTeacher"
            autoComplete="off"
            form={formInfos}
            initialValues={{ search: inputParams }}
            onFinish={searchTeacher}
          >
            <Button
              htmlType="submit"
              ref={refSubmiteBtn}
              style={{ position: "absolute", left: "-100%" }}
            >
              Go
            </Button>
            <div className={s.filter}>
              <div className={s.filter__left}>
                <div className={s.filed__search}>
                  <Form.Item name="page" initialValue={1} hidden></Form.Item>
                  <Form.Item name="search">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                      <Input
                        type="text"
                        onChange={() => onSubmit()}
                        style={{ border: "0px", marginLeft: "5px" }}
                      />
                      <button
                        style={{ border: "unset", background: "transparent" }}
                      >
                        <SarchIcon
                          style={{
                            position: "relative",
                            width: "30px",
                            top: "3px",
                          }}
                        />
                      </button>
                    </div>
                  </Form.Item>
                </div>
                <div className={s.display__filter__mobile}>
                  <Switch
                    onChange={() => setDisplayFilter(!displayFilter)}
                    checkedChildren={
                      <span style={{ marginLeft: "10px" }}>Masquer</span>
                    }
                    unCheckedChildren={
                      <span style={{ marginRight: "8px" }}>Filrer</span>
                    }
                  />
                </div>
              </div>

              {displayFilter && (
                <div className={`${s.filter__right} `}>
                  <Row gutter={12}>
                    {/* Levels */}
                    <Col>
                      <div className={`${s.select}`}>
                        <Form.Item name="level">
                          <Select
                            placeholder="Niveau"
                            allowClear
                            bordered={false}
                            onChange={(value: string) => {
                              OnSelectLevel(value);
                              onSubmit();
                            }}
                          >
                            {levels?.Primaire?.map((level: any) => (
                              <Option key={level._id} value={level._id}>
                                {level.title}
                              </Option>
                            ))}
                            {levels?.Collège?.map((level: any) => (
                              <Option key={level._id} value={level._id}>
                                {level.title}
                              </Option>
                            ))}
                            {levels?.Lycée?.map((level: any) => (
                              <Option key={level._id} value={level._id}>
                                {level.title}
                              </Option>
                            ))}
                            {levels?.Prépa?.map((level: any) => (
                              <Option key={level._id} value={level._id}>
                                {level.title}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    {/* Matieres */}
                    <Col>
                      <div className={`${s.select}`}>
                        <Form.Item name="matiere">
                          <Select
                            placeholder="Matière"
                            allowClear
                            bordered={false}
                            onChange={() => onSubmit()}
                          >
                            {tempTopic?.map((topic: any) => (
                              <Option key={topic._id} value={topic._id}>
                                {topic.title}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    {/* Prices */}
                    <Col>
                      <div className={`${s.select}`}>
                        <Form.Item name="tarif">
                          <Select
                            placeholder="Tarif par cours "
                            allowClear
                            bordered={false}
                            onChange={handleSelectChange}
                          >
                            <Option value={"1-15"}>1 - 15 €</Option>
                            <Option value={"15-30"}>15 - 30 €</Option>
                            <Option value={"30-50"}>30 - 50 €</Option>
                            <Option value={"50-100"}>50 - 100 €</Option>
                            <Option value={"100-plus"}>100 et plus</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    {/* Language */}
                    <Col>
                      <div className={`${s.select}`}>
                        <Form.Item name="language">
                          <Select
                            placeholder="Langue"
                            allowClear
                            bordered={false}
                            onChange={() => onSubmit()}
                          >
                            <Option value={Language.ENGLISH}>Anglais</Option>
                            <Option value={Language.FRENCH}>Français</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>

                    <div className={`${s.select}`} style={{ visibility: "hidden", width: "1px" }}>
                      <Form.Item name="minTarif">
                        <Select
                          placeholder="Tarif min "
                          allowClear
                          bordered={false}
                        >
                          <Option value={0}>0</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div className={`${s.select}`} style={{ visibility: "hidden", width: "1px" }}>
                      <Form.Item name="maxTarif">
                        <Select
                          placeholder="Tarif max "
                          allowClear
                          bordered={false}
                        >
                          <Option value={0}>0</Option>
                        </Select>
                      </Form.Item>
                    </div>

                  </Row>
                </div>
              )}
            </div>
          </Form>
        </div>

        <div className={s.main}>
          {isLoading && (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}

          {!isLoading && (
            <Grid className={s.grid}>
              {teacher?.data?.result?.map((ad: any, index: number) => (
                <div key={ad?._id} onClick={() => setIdTeacher(ad)}>
                  <CardAdsTeacher
                    handleDisplayTeacher={() => null}
                    resetDrawerInstaProf={() => null}
                    handleOpenDrwerProfileTeacher={() => null}
                    onShowDrawerCalendarProf={onShowDrawerCalendarProf}
                    ad={ad}
                    isBtnInstaProf={isBtnInstaProf}
                    setIsBtnInstaProf={setIsBtnInstaProf}
                    showDrawerSearchInstaProf={showDrawerSearchInstaProf}
                    teacherAdded={teachersAdded.includes(ad?.teacher?._id) ? true : false}
                  />
                </div>
              ))}
            </Grid>
          )}

          {!isLoading && teacher?.data?.result.length <= 0 && (
            <AppResult status="info" title="Aucun résultat " />
          )}
        </div>
      </AppScreen>

      {!isLoading && teacher?.data?.result.length > 0 && (
        <Pagination
          setcurrentPage={setcurrentPage}
          totalPages={teacher?.data?.totalPages}
          currentPage={currentPage}
        />
      )}
    </React.Fragment>
  );
};

export default SearchTeacher;
