import { resourcesClient } from "common/axios.config";
import { useCallback, useEffect, useState } from "react";

const useGetQuizWithInswers = (id: string) => {
  const [quizWithInswers, setQuizWithInswers] = useState<any>([]);
  const [loadingQuery, setLoadingQuery] = useState<boolean>(true);
  const [errorQuery, setErrorQuery] = useState<any[]>();

  const getQuizWithInswers = useCallback(async (idQuiz: string) => {
    setLoadingQuery(true);
    try {
      const response = await resourcesClient.get(`/quizz/${idQuiz}/withInswers`);
      if (response) {
        setQuizWithInswers(response.data);
      }
    } catch (error: any) {
      setErrorQuery(error);
    } finally {
      setLoadingQuery(false);
    }
  }, []);

  useEffect(() => {
    getQuizWithInswers(id);
  }, [id]);

  return { quizWithInswers, loadingQuery, errorQuery };
};

export default useGetQuizWithInswers;
