import { Col, Modal, Row } from "antd";
import React, { useMemo } from "react";

//!COMPONENTS

//!ASSETS
import AlbertSad from "assets/images/albert/albert-sad.svg";
import AstuceIcon from "assets/images/app/astuce.svg";
import Student from "assets/images/students/student.svg";

//!STYLES
import H1 from "components/libs/title";
import { useDispatch, useSelector } from "react-redux";
import { INIT_MODAL } from "store/notification";
import s from "./style.module.css";

/* enum InstaProfStates {
  INIT_MODAL = "INIT_MODAL",
} */

type Props = {};

const ModalInstaProfBadResp: React.FC<Props> = ({ }) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { currentStudentInstaProf } = useSelector((state) => state.auth);
  const { openModalBadResp } = useSelector((state) => state.notifications);

  const handleClose = () => {
    dispatch(INIT_MODAL());
  };

  return useMemo(() => {
    return (
      <Modal
        title={false}
        visible={openModalBadResp}
        footer={false}
        width={"60%"}
        onOk={handleClose}
        onCancel={handleClose}
        centered
        className={s.modal}
      >
        <div className={s.content}>
          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "1.5em" }}
          >
            <Col>
              <div className={s.avatar}>
                <img
                  src={
                    currentStudentInstaProf?.data?.student?.profile || Student
                  }
                  alt=""
                />
              </div>
            </Col>
            <Col>
              <H1>
                {currentStudentInstaProf?.data?.student?.firstname} a choisi un
                autre prof
              </H1>
            </Col>
          </Row>

          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "0.5em" }}
          >
            <Col>
              <div className={s.albert}>
                <img src={AlbertSad} alt="" width={"55%"} />
              </div>
            </Col>
          </Row>

          <div className={s.main}>
            <img src={AstuceIcon} alt="" />
            <h3>Astuce</h3>
            <p>
              Invite tes élèves à donner leur avis sur tes cours ! Cela permet d'améliorer<br />notre contenu, de te faire gagner des points et d'augmenter tes chances.
            </p>
          </div>
        </div>
      </Modal>
    );
  }, [openModalBadResp, currentStudentInstaProf]);
};

export default ModalInstaProfBadResp;
