import { Button, Form, Image, InputNumber, Popover } from "antd";
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import { StatusCode } from "common/enums";
import AppLatex from "components/appLatex";
import AppButton from "components/libs/button";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import style from "./style.module.css";
import target from "./target.svg";

interface MyInterface {
  handler: any;
  quizz: string;
  title: string;
  setDrawerConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setTempsChallenge: React.Dispatch<React.SetStateAction<number>>;
}

const ChallengeSubmitted: React.FC<MyInterface> = ({
  handler,
  quizz,
  title,
  setDrawerConfirm,
  setTempsChallenge,
}) => {
  const auth = useSelector((state: RootState) => state.auth);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);

  const challengerUneClasse = useCallback(
    async (
      classeId: string | undefined,
      quizzId: string | undefined,
      quizzDuration: number,
      challengeExpiration: number
    ) => {
      try {
        if (auth.user?._id) {
          const teacherId = auth.user?._id;
          const response = await ROOMS_SERVICES.challengerUneClasse({
            teacherId,
            classeId,
            quizzId,
            quizzDuration: Number(quizzDuration),
            challengeExpiration: Number(challengeExpiration),
          });
          if (response?.statusCode === StatusCode.CREATED) {
            handler(response.data);
            setDrawerConfirm(true);
          }
        }
      } catch (error: any) {
      }
    },
    [auth.user?._id, handler]
  );

  const onFinishFailed = (errorInfo: any) => { };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <>
      <Popover
        content={
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={(values) => {
              challengerUneClasse(
                id,
                quizz,
                values.quizzDuration,
                values.challengeExpiration
              );
              setTempsChallenge(values.challengeExpiration);
            }}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item>
              <h3 style={{ maxWidth: "280px" }}>
                <AppLatex content={title} />
              </h3>
            </Form.Item>
            <Form.Item
              label="Durée d’expiration du challenge (en h)"
              name="challengeExpiration"
              rules={[{ required: true, message: "Ce champs est requis" }]}
            >
              <InputNumber<string>
                style={{ width: "100%" }}
                defaultValue="0"
                min="0"
                max="24"
                stringMode
              />
            </Form.Item>

            <Form.Item
              label="Durée du quiz (en minute)"
              name="quizzDuration"
              rules={[{ required: true, message: "Ce champs est requis" }]}
            >
              <InputNumber<string>
                style={{ width: "100%" }}
                defaultValue="0"
                min="0"
                max="60"
                stringMode
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Lancer
              </Button>
            </Form.Item>
          </Form>
        }
        title={false}
        trigger="click"
        placement="top"
        visible={open}
        onVisibleChange={handleOpenChange}
      >
        <AppButton className={style.customButton}>
          <Image
            preview={false}
            width={20}
            src={target}
            alt="success"
            style={{ position: "absolute", top: "-15px", right: "10px" }}
          />
          Lancer le challenge
        </AppButton>
      </Popover>
    </>
  );
};

export default ChallengeSubmitted;
