import ANGLAIS from "../assets/images/app/iconMatiers/Anglais.png";
import FRANCAIS from "../assets/images/app/iconMatiers/Francais.png";
import HISTOIREGEO from "../assets/images/app/iconMatiers/Histoire-geo.png";
import MATH from "../assets/images/app/iconMatiers/Mathematiques.png";
import PHILO from "../assets/images/app/iconMatiers/Philosophie.png";
import PHYSIQUE from "../assets/images/app/iconMatiers/Physique-chimie.png";
import SVT from "../assets/images/app/iconMatiers/SVT.png";

import moment from "moment";
import belgiumFlag from "../assets/images/app/belgium-flag.png";
import frenchFlag from "../assets/images/app/french-flag.png";
import moroccoFlag from "../assets/images/app/morocco-flag.png";

export enum UserRoles {
  ADMIN = "admin",
  TEACHER = "teacher",
  STUDENT = "student",
  PARENT = "parent",
  ORGANISME = "organisme",
}

export enum AuthMethods {
  EMAIL = "email",
  GOOGLE = "google",
  FACEBOOK = "facebook",
}

export enum FLAGS {
  GROUP_FLAG = "GROUP_FLAG",
  SINGLE_FLAG = "SINGLE_FLAG",
}

export enum StatusCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export enum HandlerInputError {
  title = "Le champ titre est requis",
  subtitles = "Le champ sous titre est requis",
  section = "Le champ section est requis",
  content = "Le champ contenu est requis",
  default = "Un champ n'a pas ete renseigner",
}

export const enum CHOICE {
  SAVE_AS_DRAFT = "SAVE_AS_DRAFT",
  SAVE_PERMANENTLY = "SAVE_PERMANENTLY",
}
export enum Language {
  ENGLISH = "en",
  FRENCH = "fr",
}
export enum educSystem {
  BELGE = "Système Belge",
  FRENCH = "Système Français",
  MOROCCO = "Système Marocain",
}

export enum PlayLevel {
  EASY = "Facile",
  MEDIUM = "Moyen",
  HARD = "Difficile",
}

export enum TypeExercices {
  ApplicationsDeCours = "Applications de cours",
  Classiques = "Classiques",
  Annales = "Annales",
  Entraînement = "Entraînement",
}

export enum StatusEntity {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
}
export const converterMoneyCommission = (montant: number) => {
  if (montant <= 1000) return montant - montant * 0.3;
  else {
    if (montant <= 5000) return montant - montant * 0.22;
    else {
      if (montant <= 15000) return montant - montant * 0.15;
      return montant - montant * 0.5;
    }
  }
};

export enum TypeMatiers {
  mathematique = "Mathematiques",
  PhysiqueChimie = "Physique-chimie",
  SVT = "SVT",
  Francais = "Francais",
  Anglais = "Anglais",
  HistoireGeo = "Histoire-geo",
  Philosophie = "Philosophie",
}

export enum TypeCours {
  mathematique = "Mathématiques",
  PhysiqueChimie = "Physique-chimie",
  SVT = "SVT",
  Francais = "Français",
  Anglais = "Anglais",
  HistoireGeo = "Histoire-géo",
  Philosophie = "Philosophie",
}

export const StepTitle = (current: number) => {
  if (current === 1) {
    return "À PROPOS";
  }
  else if (current === 2) {
    return "PHOTO DE PROFIL";
  }
  else if (current === 3) {
    return "FORMATION";
  }
  else if (current === 4) {
    return "CERTIFICATION D’ENSEIGNEMENT";
  }
  else if (current === 5) {
    return "DESCRIPTION DU PROFIL";
  }
  else if (current === 6) {
    return "VIDÉO DE PRÉSENTATION";
  }
  else if (current === 8) {
    return "PROGRAMME ET DISPONIBILITÉ";
  }
}

export const StepMessage = (current: number) => {
  if (current === 1) {
    return "Commence à créer ton profil public en tant que professeur ici! Tes modifications seront automatiquement enregistrées à mesure que tu complètes chaque section. Tu pourras y revenir à tout moment afin de finaliser ton inscription.";
  }
  else if (current === 2) {
    return "";
  }
  else if (current === 3) {
    return "Tu as obtenu des certifications ou des diplômes dans le domaine de l'enseignement ? Si oui, décris-les pour gagner en crédibilité et attirer plus d'élèves!";
  }
  else if (current === 4) {
    return "Dis-en plus aux élèves sur les diplômes de l'enseignement supérieur que tu as obtenus ou que tu prépares actuellement.";
  }
  else if (current === 5) {
    return "Crée ou mets à jour ton titre et une nouvelle description de ton profil. Ces éléments apparaîtront sur ton profil dans la page 'Trouver un professeur'.";
  }
  else if (current === 6) {
    return "";
  }
}

export enum StaticLevels {
  staticLeveId = "62e00770bbf0e80cf966552d",
}

export const Languages = (shortcode: string) => {
  if (shortcode === 'en') {
    return "Anglais";
  }
  if (shortcode === 'fr') {
    return "Français";
  }
}

export const currentStep = (step: string) => {
  if (step === "a_propos") {
    return 2;
  }
  else if (step === "photo") {
    return 3;
  }
  else if (step === "certificate") {
    return 4;
  }
  else if (step === "diploma") {
    return 5;
  }
  else if (step === "description") {
    return 6;
  }
  else if (step === "video") {
    return 7;
  }
  else if (step === "cost") {
    return 8;
  }
  else {
    return 1;
  }
}

export const setIcon = (course: string) => {
  if (course === "Mathématiques") {
    return MATH;
  }
  else if (course === "Physique-chimie") {
    return PHYSIQUE;
  }
  else if (course === "SVT") {
    return SVT;
  }
  else if (course === "Français") {
    return FRANCAIS;
  }
  else if (course === "Anglais") {
    return ANGLAIS;
  }
  else if (course === "Histoire géo") {
    return HISTOIREGEO;
  }
  else if (course === "Philosophie") {
    return PHILO;
  }
}

export const setCourseName = (course: string) => {
  if (course === "Mathématiques") {
    return "Maths";
  }
  else if (course === "Physique-chimie") {
    return "Physique\nChimie";
  }
  else if (course === "SVT") {
    return SVT;
  }
  else if (course === "Français") {
    return "Français";
  }
  else if (course === "Anglais") {
    return "Anglais";
  }
  else if (course === "Histoire géo") {
    return "Histoire\nGeographie";
  }
  else if (course === "Philosophie") {
    return "Philo";
  }
}

export const setCountryFlag = (system: string) => {
  if (system === "Système Belge") {
    return belgiumFlag;
  }
  else if (system === "Système Français") {
    return frenchFlag;
  }
  else if (system === "Système Marocain") {
    return moroccoFlag;
  }
}

export const translateDayOfWeek = (day: string) => {
  if (day === "Monday") {
    return "Lundi";
  }
  if (day === "Tuesday") {
    return "Mardi";
  }
  if (day === "Wednestday") {
    return "Mercredi";
  }
  if (day === "Thursday") {
    return "Jeudi";
  }
  if (day === "Friday") {
    return "Vendredi";
  }
  if (day === "Saturday") {
    return "Samedi";
  }
  if (day === "Sunday") {
    return "Dimanche";
  }
}

export const teacherFaqData = [
  {
    "title": "Devenir Ami d’Albert",
    "data": [
      {
        "question": "Comment puis-je devenir professeur sur Mon Ami Albert?",
        "answer": "Pour enseigner sur Mon Ami Albert, vous devez avoir un diplôme ou qualification reconnus dans la matière que vous souhaitez enseigner ainsi qu'une expérience pertinente. Un processus de vérification et un entretien en ligne sont également requis pour valider votre expertise."
      },
      {
        "question": "Comment puis-je rendre mon profil plus attractif pour les élèves?",
        "answer": "Pour rendre votre profil plus attractif, assurez-vous d'inclure une biographie détaillée mettant en avant votre expérience et vos méthodes d'enseignement. Choisir une photo professionnelle ainsi qu'une vidéo de présentation pédagogue contribuera également à booster l'attractivité de votre profil. Enfin, recueillir des avis positifs de la part de vos élèves précédents augmentera votre visibilité et votre attractivité sur la plateforme."
      },
      {
        "question": "Est-il possible de mettre mon profil en pause?",
        "answer": "Oui, si vous devez prendre une pause pour quelque raison que ce soit, vous pouvez temporairement désactiver votre profil. Votre profil ne sera pas visible pour les nouveaux élèves pendant cette période, mais vous pourrez le réactiver à tout moment.<br/> Toutefois, conformément à notre système de gain de points lié à votre participation active sur la plateforme, tout mois d'inactivité vous 'coûte' des points... et donc l'opportunité de générer davantage de revenus sur notre plateforme! C'est dommage.<br/> Pour connaître le détail du système de gain de points, connectez-vous à votre compte Prof et cliquez ici."
      },
    ],
  },
  {
    "title": "Gestion du Profil",
    "data": [
      {
        "question": "Comment puis-je mettre à jour mon profil de professeur?",
        "answer": "Connectez-vous à votre compte et accédez à la section 'Profil'. Ici, vous pouvez mettre à jour vos informations personnelles, ajouter des diplômes ou qualifications, modifier votre photo de profil et ajuster votre bio pour mieux refléter votre expertise et vos méthodes d'enseignement."
      },
      {
        "question": "Comment puis-je ajouter de nouveaux niveaux et/ou matières à mon offre d'enseignement?",
        "answer": "Pour ajouter un nouveau cours, connectez-vous à votre espace Prof, sélectionnez l'option 'Modifier mon profil', allez sur 'Tarifs' et suivez les instructions pour ajouter le niveau et/ou la matière ainsi que d'autres détails sur votre offre.."
      },
    ],
  },
  {
    "title": "Planification des Cours",
    "data": [
      {
        "question": "",
        "answer": ""
      },
    ],
  },
  {
    "title": "Paiements et Rémunération",
    "data": [
      {
        "question": "",
        "answer": ""
      },
    ],
  },
  {
    "title": "InstaProf",
    "data": [
      {
        "question": "",
        "answer": ""
      },
    ],
  },
  {
    "title": "Assistance Technique",
    "data": [
      {
        "question": "",
        "answer": ""
      },
    ],
  }
];

export const studentFaqData = [
  {
    "title": "Inscription et Connexion",
    "data": [
      {
        "question": "Comment puis-je créer un compte sur Mon Ami Albert?",
        "answer": "C'est très simple! Visitez notre site internet, cliquez sur 'S´inscrire', et suivez les instructions pour compléter votre inscription. Vous pouvez également simplement cliquer ici."
      },
      {
        "question": "Comment puis-je réinitialiser mon mot de passe si je l'oublie?",
        "answer": "Sur la page de connexion, cliquez sur 'Mot de passe oublié?' et suivez les instructions pour réinitialiser votre mot de passe via votre adresse email."
      },
      {
        "question": "Puis-je changer mon adresse email après l'inscription?",
        "answer": "Oui, vous pouvez changer votre adresse email depuis les paramètres de votre compte. Assurez-vous de confirmer le changement via un email de vérification que nous vous enverrons."
      },
    ],
  },
  {
    "title": "Utilisation de la Plateforme",
    "data": [
      {
        "question": "",
        "answer": ""
      },
      {
        "question": "",
        "answer": ""
      },
    ],
  },
  {
    "title": "Réservation, déroulement, paiement et annulation de cours",
    "data": [
      {
        "question": "",
        "answer": ""
      },
    ],
  },
  {
    "title": "InstaProf",
    "data": [
      {
        "question": "",
        "answer": ""
      },
    ],
  },
  {
    "title": "Suivi des Progrès",
    "data": [
      {
        "question": "",
        "answer": ""
      },
    ],
  },
  {
    "title": "Assistance Technique",
    "data": [
      {
        "question": "",
        "answer": ""
      },
    ],
  },
  {
    "title": "Sécurité et Confidentialité",
    "data": [
      {
        "question": "",
        "answer": ""
      },
    ],
  }
];

export const parentFaqData = [
  {
    "title": "Création de Compte",
    "data": [
      {
        "question": "Comment puis-je créer un compte parent sur Mon Ami Albert?",
        "answer": "Pour créer un compte Parent, visitez notre site web et cliquez sur l'option d'inscription en tant que 'Parent'. Suivez les instructions pour remplir les informations nécessaires et activer votre compte."
      },
    ],
  },
  {
    "title": "Inviter votre Enfant",
    "data": [
      {
        "question": "Comment puis-je inscrire mon enfant sur la plateforme?",
        "answer": "Pour ajouter votre enfant à la plateforme, connectez-vous à votre compte Parent et cliquez sur l'option 'Ajouter des élèves'. Suivez ensuite les instructions pour compléter l'inscription de votre enfant."
      },
    ],
  },
  {
    "title": "Suivi de l'Enfant",
    "data": [
      {
        "question": "Comment puis-je suivre les progrès de mon enfant sur la plateforme?",
        "answer": "Vous pouvez suivre les progrès de votre enfant en vous connectant à votre compte Parent et en accédant au tableau de bord dédié. Ce tableau affiche les cours suivis, les notes obtenues, et d'autres indicateurs de performance."
      },
    ],
  },
  {
    "title": "Paiement des Cours",
    "data": [
      {
        "question": "Comment puis-je payer pour les cours de mon enfant?",
        "answer": "Les paiements pour les cours peuvent être effectués via votre compte Parent. Ajoutez une méthode de paiement et suivez les instructions pour recharger le portefeuille de votre enfant et régler les frais des cours sélectionnés."
      },
    ],
  },
  {
    "title": "Communication avec Enseignants",
    "data": [
      {
        "question": "Est-il possible de communiquer directement avec les professeur de mon enfant?",
        "answer": "Il est tout à fait possible pour vous d'échanger avec les enseignants de votre enfant à travers la fonction Messagerie sur le plateforme."
      },
    ],
  },
];

export const organisationFaqData = [
  {
    "title": "Partenariats",
    "data": [
      {
        "question": "Comment notre organisation peut-elle devenir partenaire de Mon Ami Albert?",
        "answer": "Pour explorer les possibilités de partenariat avec Mon Ami Albert, veuillez contacter notre équipe de développement des partenariats à l'adresse partenariat@monamialbert.com. Nous discuterons des options qui peuvent inclure des collaborations sur des programmes éducatifs, des projets de recherche, ou d'autres initiatives."
      },
      {
        "question": "Quelles sont les différentes manières pour notre organisme de collaborer avec Mon Ami Albert?",
        "answer": "Nous sommes ouverts et ravis de discuter afin de co-créer une collaboration fructueuse et enrichissante ensemble. Nous vous invitons à contacter notre équipe de développement de partenariats à l'adresse partnerships@monamialbert.com. "
      },
    ],
  },
  {
    "title": "Utilisation de la Plateforme",
    "data": [
      {
        "question": "Comment notre organisme peut-il utiliser la plateforme pour ses membres?",
        "answer": "Mon Ami Albert offre des solutions personnalisées pour les organismes ou fondations qui souhaitent fournir un accès éducatif à leurs membres. Vous pouvez créer un compte Organisme et nous pouvons configurer des accès groupés selon vos besoins spécifiques."
      },
    ],
  },
  {
    "title": "Rapports et Analyses",
    "data": [
      {
        "question": "Fournissez-vous des rapports sur l'utilisation de la plateforme par notre organisme?",
        "answer": "Oui, nous fournissons des rapports détaillés qui peuvent aider votre organisme à suivre l'engagement et les progrès de vos membres."
      },
    ],
  },
];

export const albertFaqData = [
  {
    "title": "MonAmiAlbert",
    "data": [
      {
        "question": "Mon Ami Albert, c'est quoi?",
        "answer": "Mon Ami Albert est une plateforme éducative en ligne dédiée à l'amélioration de l'apprentissage des élèves, du niveau primaire à la classe prépa. Notre mission est d'offrir un accès facile, direct et flexible à des cours de soutien académique de qualité, à des ressources éducatives innovantes et à une assistance personnalisée. Chez Mon ami Albert, nous mettons en contact des élèves motivés avec des professeurs expérimentés à travers des sessions en ligne interactives adaptées aux besoins et aux rythmes de chacun. Que ce soit pour des révisions, l’apprentissage de nouvelles compétences ou la préparation à des examens, Mon Ami Albert s'engage à offrir une expérience éducative bénéfique, enrichissante et accessible à tous."
      },
      {
        "question": "Quels sont les services offerts par Mon Ami Albert?",
        "answer": "Mon Ami Albert offre une gamme variée de services éducatifs destinés à répondre aux différents besoins d’apprentissage et de consolidation des acquis des élèves : 1. Cours en ligne personnalisés : des sessions individuelles et/ou collectives avec des profs qualifiés;"
      },
    ],
  },
];

export const isToday = (someDate: string) => {
  const today = new Date();
  const givenDate = new Date(someDate);
  return (
    givenDate.getDate() === today.getDate() &&
    givenDate.getMonth() === today.getMonth() &&
    givenDate.getFullYear() === today.getFullYear()
  );
};

export const calculateAvg = (application: any, excercise: any, courses: any) => {
  if (application !== 0 && excercise !== 0 && courses !== 0) {
    const note = application + excercise + courses;
    const avg = note / 3;
    return avg.toFixed(2);
  }
  else {
    return 0;
  }
}

export function calculateTimeDifference(start: string, end: string): string {
  const [startHours, startMinutes] = start.split(":").map(Number);
  const [endHours, endMinutes] = end.split(":").map(Number);

  // Create Date objects
  const startTime = new Date(0, 0, 0, startHours, startMinutes);
  const endTime = new Date(0, 0, 0, endHours, endMinutes);

  // Calculate difference in milliseconds
  const diff = endTime.getTime() - startTime.getTime();

  // Convert to hours and minutes
  const diffHours = Math.floor(diff / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  if (diffHours !== 0 && diffMinutes === 0) {
    return `${diffHours} h`;
  }
  else if (diffHours === 0 && diffMinutes !== 0) {
    return `${diffMinutes} mins`;
  }
  else {
    return `${diffHours} h ${diffMinutes}`;
  }
}

export const getCountryCode = (country: any) => {

  const countriesList = [
    { name: "Afghanistan", code: "AF" },
    { name: "Afrique du Sud", code: "ZA" },
    { name: "Albanie", code: "AL" },
    { name: "Algérie", code: "DZ" },
    { name: "Allemagne", code: "DE" },
    { name: "Andorre", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Arabie saoudite", code: "SA" },
    { name: "Argentine", code: "AR" },
    { name: "Arménie", code: "AM" },
    { name: "Australie", code: "AU" },
    { name: "Autriche", code: "AT" },
    { name: "Azerbaïdjan", code: "AZ" },
    { name: "Belgique", code: "BE" },
    { name: "Bénin", code: "BJ" },
    { name: "Bhoutan", code: "BT" },
    { name: "Biélorussie", code: "BY" },
    { name: "Birmanie", code: "MM" },
    { name: "Bolivie", code: "BO" },
    { name: "Bosnie-Herzégovine", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Brésil", code: "BR" },
    { name: "Bulgarie", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cameroun", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cap-Vert", code: "CV" },
    { name: "Chili", code: "CL" },
    { name: "Chine", code: "CN" },
    { name: "Chypre", code: "CY" },
    { name: "Colombie", code: "CO" },
    { name: "Comores", code: "KM" },
    { name: "Congo-Brazzaville", code: "CG" },
    { name: "Congo-Kinshasa", code: "CD" },
    { name: "Corée du Nord", code: "KP" },
    { name: "Corée du Sud", code: "KR" },
    { name: "Costa Rica", code: "CR" },
    { name: "Côte d'Ivoire", code: "CI" },
    { name: "Croatie", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Danemark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominique", code: "DM" },
    { name: "Égypte", code: "EG" },
    { name: "Émirats arabes unis", code: "AE" },
    { name: "Équateur", code: "EC" },
    { name: "Érythrée", code: "ER" },
    { name: "Espagne", code: "ES" },
    { name: "Estonie", code: "EE" },
    { name: "États-Unis", code: "US" },
    { name: "Eswatini", code: "SZ" },
    { name: "Éthiopie", code: "ET" },
    { name: "Fidji", code: "FJ" },
    { name: "Finlande", code: "FI" },
    { name: "France", code: "FR" },
    { name: "Gabon", code: "GA" },
    { name: "Gambie", code: "GM" },
    { name: "Géorgie", code: "GE" },
    { name: "Ghana", code: "GH" },
    { name: "Grèce", code: "GR" },
    { name: "Guatemala", code: "GT" },
    { name: "Guinée", code: "GN" },
    { name: "Guinée-Bissau", code: "GW" },
    { name: "Guinée équatoriale", code: "GQ" },
    { name: "Haïti", code: "HT" },
    { name: "Honduras", code: "HN" },
    { name: "Hongrie", code: "HU" },
    { name: "Inde", code: "IN" },
    { name: "Indonésie", code: "ID" },
    { name: "Irak", code: "IQ" },
    { name: "Iran", code: "IR" },
    { name: "Irlande", code: "IE" },
    { name: "Islande", code: "IS" },
    { name: "Israël", code: "IL" },
    { name: "Italie", code: "IT" },
    { name: "Japon", code: "JP" },
    { name: "Jordanie", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kirghizistan", code: "KG" },
    { name: "Kiribati", code: "KI" },
    { name: "Koweït", code: "KW" },
    { name: "Laos", code: "LA" },
    { name: "Lesotho", code: "LS" },
    { name: "Lettonie", code: "LV" },
    { name: "Liban", code: "LB" },
    { name: "Libéria", code: "LR" },
    { name: "Libye", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lituanie", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Madagascar", code: "MG" },
    { name: "Malaisie", code: "MY" },
    { name: "Malawi", code: "MW" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malte", code: "MT" },
    { name: "Maroc", code: "MA" },
    { name: "Maurice", code: "MU" },
    { name: "Mauritanie", code: "MR" },
    { name: "Mexique", code: "MX" },
    { name: "Moldavie", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolie", code: "MN" },
    { name: "Mozambique", code: "MZ" },
    { name: "Namibie", code: "NA" },
    { name: "Népal", code: "NP" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Norvège", code: "NO" },
    { name: "Nouvelle-Zélande", code: "NZ" },
    { name: "Oman", code: "OM" },
    { name: "Ouganda", code: "UG" },
    { name: "Ouzbékistan", code: "UZ" },
    { name: "Pakistan", code: "PK" },
    { name: "Palaos", code: "PW" },
    { name: "Palestine", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papouasie-Nouvelle-Guinée", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Pays-Bas", code: "NL" },
    { name: "Pérou", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pologne", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Qatar", code: "QA" },
    { name: "République centrafricaine", code: "CF" },
    { name: "République tchèque", code: "CZ" },
    { name: "Roumanie", code: "RO" },
    { name: "Royaume-Uni", code: "GB" },
    { name: "Russie", code: "RU" },
    { name: "Rwanda", code: "RW" },
    { name: "Sénégal", code: "SN" },
    { name: "Serbie", code: "RS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapour", code: "SG" },
    { name: "Slovaquie", code: "SK" },
    { name: "Slovénie", code: "SI" },
    { name: "Somalie", code: "SO" },
    { name: "Soudan", code: "SD" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Suède", code: "SE" },
    { name: "Suisse", code: "CH" },
    { name: "Suriname", code: "SR" },
    { name: "Syrie", code: "SY" },
    { name: "Tadjikistan", code: "TJ" },
    { name: "Tanzanie", code: "TZ" },
    { name: "Tchad", code: "TD" },
    { name: "Thaïlande", code: "TH" },
    { name: "Togo", code: "TG" },
    { name: "Tunisie", code: "TN" },
    { name: "Turquie", code: "TR" },
    { name: "Ukraine", code: "UA" },
    { name: "Uruguay", code: "UY" },
    { name: "Vatican", code: "VA" },
    { name: "Venezuela", code: "VE" },
    { name: "Viêt Nam", code: "VN" },
    { name: "Yémen", code: "YE" },
    { name: "Zambie", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" }
  ];

  const countryData = countriesList.find((data: any) => data.name === country);
  return countryData?.code;

}

export const removeDuplicates = (array: any) => {
  const seen = new Set();
  if (array) {
    return array?.filter((item: any) => {
      const key = `${item.levelGroup}-${item.price}`;
      if (seen.has(key)) {
        return false; // Skip duplicate
      }
      seen.add(key);
      return true; // Keep unique
    });
  }
};

export const formatFrenchDate = (dateString: string): string => {
  // Explicitly parse the date with a given format
  const parsedDate = moment(dateString);

  // Check if the date is valid
  if (!parsedDate.isValid()) {
    throw new Error('Invalid date format. Expected DD-MM-YYYY.');
  }

  // Format the date in French
  return parsedDate.locale('fr').format('dddd D MMMM YYYY');
};
