import { Image, Skeleton } from "antd";
import SkillBar from "components/skillbar";
import { FC } from "react";
import Physique from "../../../../assets/images/students/physique.svg";

//!STYLES
import s from "./assets/cardTracked.module.css";

type Props = {
  id: string;

  name?: string;
  className?: string;
  widthSkill?: number;
  icon?: any;
  widthImage?: number;
  skillBarClassName?: string;
  progress?: any;
};

const CardTracked: FC<Props> = ({
  className,
  id,
  icon,
  name,
  widthImage,
  skillBarClassName,
  progress,
}) => {

  return (
    <div className={`${s.cardTracked} ${className}`} style={{ border: "1px solid #80808017", borderRadius: "15px" }}>
      <div className={s.left}>
        {name ? (
          <Image
            preview={false}
            width={widthImage || 100}
            src={icon || Physique}
            alt="course icon"
          />
        ) : (
          <Skeleton.Avatar active style={{ width: "50px", height: "50px" }} />
        )}
      </div>
      <div className={s.right}>
        <div className={s.top}>
          {name ? (
            <span>{name}</span>
          ) : (
            <Skeleton.Input
              active
              style={{
                width: "300px",
              }}
            />
          )}
        </div>
        <div className={s.bottom}>
          {name && progress[id] !== 0 && (
            <SkillBar className={skillBarClassName} width={progress[id] || 0} />
          )/*  : (
            <Skeleton.Input
              active
              style={{
                height: "10px",
                borderRadius: "10px",
              }}
            />
          ) */}
        </div>
      </div>
    </div>
  );
};

export default CardTracked;
