import "katex/dist/katex.min.css";
import { FC, useEffect, useRef, useState } from "react";

//!STYLES
import ReadMore from "components/readMoreText";
import { useSelector } from "react-redux";
import s from "./assets/style.module.css";

type Props = {
  teacher: any;
};

const removeHtmlTags = (htmlString: any) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || "";
};

const AboutProfileTeacherCard: FC<Props> = ({ teacher }) => {

  const { user } = useSelector((state) => state.auth);
  const [videoPreview, setVideoPreview] = useState(
    user?.videoPresentation
      ? user.videoPresentation
      : ''
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);


  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const description = removeHtmlTags(teacher?.career);

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold as needed
    };

    const handleIntersection: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      const videoElement = videoRef.current;

      if (!isVisible) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    }
  }, [isVisible]);

  return (
    <div className={s.card} style={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "start", justifyContent: "space-between" }}>
      <div style={{ maxWidth: videoPreview !== '' && !isMobile ? "53%" : "100%" }}>
        {user?._id === teacher?._id ? <h3>A propos de moi</h3> : <h3>A propos de <span className={s.teacherName}>{teacher?.firstname}</span></h3>}

        <div className={s.description}>
          <ReadMore text={teacher?.career} maxLength={850} />
        </div>

      </div>
      {videoPreview !== '' && <div style={{ maxWidth: videoPreview !== '' && !isMobile ? "42%" : "100%" }}>
        <video width="100%" height="auto" style={{ borderRadius: "5px" }} ref={videoRef} controls autoPlay={false}>
          <source src={videoPreview} type="video/mp4" />
        </video>
      </div>}
    </div>
  );
};

export default AboutProfileTeacherCard;
