import { CopyOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, message, Popover, Row } from "antd";
import { UserRoles } from "common/enums";
import AppButton from "components/libs/button";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import s from "./password.module.css";

type Props = {
    visible: boolean,
    onClose: () => void,
    userData?: any,
}

const SendUpdatePasswordRequestDrawer: React.FC<Props> = ({ visible, onClose, userData }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    //!HOOKS
    const { t } = useTranslation();
    const auth = useSelector((state) => state.auth);
    const [formInfos] = Form.useForm();
    const { user } = useSelector((state) => state.auth);

    //!STATES
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isParent = auth.user?.roles?.includes(UserRoles.PARENT);
    const isOrganism = auth.user?.roles?.includes(UserRoles.ORGANISME);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    const closeDrawer = async () => {
        onClose();
    }

    const [currentEmail, setCurrentEmail] = useState('');
    const [error, setError] = useState<any>(null);
    const [successMessage, setSuccessMessage] = useState<string>("");

    const handlePasswordRequest = async () => {

        const userEmail = user?.email;

        if (isParent) {
            if (userEmail === currentEmail) {
                sendPasswordRequest();
            }
            else {
                setError("Cette addresse email est différente de la tienne. Entre ton adresse email!");
            }
        }
        else if (isOrganism) {
            if (userEmail === currentEmail) {
                sendPasswordRequest();
            }
            else {
                setError("Cette addresse email est différente de la tienne. Entre ton adresse email!");
            }
        }
        else {
            sendPasswordRequest();
        }

    };

    const sendPasswordRequest = async () => {
        setIsLoading(true);
        try {
            const auth = getAuth();
            await sendPasswordResetEmail(auth, currentEmail);
            setSuccessMessage('E-mail de réinitialisation du mot de passe envoyé avec succès. Merci de consulter vos emails.');
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }

    const [visibleSocialMedia, setVisibleSocialMedia] = useState(false);
    const hide = () => setVisibleSocialMedia(false);
    const handleVisibleChange = () => setVisibleSocialMedia(!visibleSocialMedia);

    const handleCopy = () => {
        const textToCopy = `"Email: ${userData?.email}, Mot de passe: ${(userData?.password !== "undefined" && userData?.password !== "") ? userData?.password : "123456"}"`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            message.success('Accès copiés');
        }).catch(() => {
            message.error('Erreur lors de la copie!');
        });
    };

    return (
        <div>
            <Drawer
                title={<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                    <div style={{ textTransform: "uppercase", fontSize: "1.2em", margin: "0px auto" }}>{(isParent || isOrganism) ? "Accès élève" : "Mot de passe oublié"}</div>
                </div>}
                placement="right"
                closable={true}
                onClose={closeDrawer}
                visible={visible}
                width={isMobile ? "100%" : "70%"}
                headerStyle={{ display: "flex", justifyContent: "space-between" }}
                bodyStyle={{ background: "#FFF", padding: "0px" }}
            >
                <div style={{ alignItems: "flex-start", margin: "40px auto" }}>
                    <Form
                        validateTrigger={["onFinish"]}
                        validateMessages={validateMessages}
                        name="registerParent"
                        autoComplete="off"
                        form={formInfos}
                        onFinish={handlePasswordRequest}
                    >
                        <div style={{ width: "55%", margin: "0px auto" }}>

                            {(isParent || isOrganism) && <div style={{ marginLeft: "10%", marginRight: "10%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                <div style={{ fontSize: "1.2em", fontWeight: "400", marginTop: "0px" }}>Username : {userData?.email}</div>
                                <div style={{ fontSize: "1.2em", fontWeight: "400", marginTop: "20px" }}>Mot de passe : {(userData?.password !== "undefined" && userData?.password !== "") ? userData?.password : "123456"}</div>
                            </div>}

                            {(isParent || isOrganism) && <div style={{ margin: "30px auto", width: "220px" }}>
                                <Popover
                                    content={
                                        <>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div>
                                                    {" "}
                                                    <WhatsappShareButton title={"Accès élève"} url={`"Email: ${userData?.email}, Mot de passe: ${(userData?.password !== "undefined" && userData?.password !== "") ? userData?.password : "123456"}"`}>
                                                        <WhatsappIcon size={32} round={true} />
                                                    </WhatsappShareButton>
                                                </div>

                                                <div>
                                                    {" "}
                                                    <Button
                                                        type="primary"
                                                        icon={<CopyOutlined />}
                                                        onClick={handleCopy}
                                                        style={{ borderRadius: "50%", marginLeft: "10px" }}
                                                    >
                                                    </Button>
                                                </div>
                                            </div>
                                            <div style={{ width: "100%", textAlign: "center" }}>
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        hide();
                                                    }}
                                                    href="/"
                                                >
                                                    Fermer
                                                </a>
                                            </div>
                                        </>
                                    }
                                    trigger="click"
                                    visible={visibleSocialMedia}
                                    onVisibleChange={handleVisibleChange}
                                >
                                    {" "}
                                    <AppButton className={s.shareButton}>partager</AppButton>

                                </Popover>
                            </div>
                            }

                            {(!isParent && !isOrganism) && <Form.Item
                                name="email"
                                rules={[{ required: true }]}
                                style={{ marginBottom: "20px" }}
                            >
                                <Input
                                    type="email"
                                    className={s.field}
                                    placeholder={"Entre ton adresse email"}
                                    value={currentEmail}
                                    onChange={(e) => setCurrentEmail(e.target.value)}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>}

                        </div>

                        {(!isParent && !isOrganism) && <Row justify="center" style={{ marginTop: "30px" }}>
                            <Form.Item>
                                <AppButton loading={isLoading} htmlTypeSubmit={true}><a className={s.nextButton}>Valider</a></AppButton>
                            </Form.Item>
                        </Row>}
                    </Form>
                    {error && <div style={{ color: 'red', margin: "0px auto", textAlign: "center" }}>{error}</div>}
                    {successMessage && <div style={{ color: 'green', margin: "0px auto", textAlign: "center" }}>{successMessage}</div>}
                </div>

            </Drawer>
        </div>
    );

};

export default SendUpdatePasswordRequestDrawer;