import {
  PlusOutlined
} from "@ant-design/icons";
import {
  Badge,
  Card,
  Form,
  message,
  Row,
  Select,
  Skeleton,
  Spin,
  Steps,
  Typography
} from "antd";
import * as ADS_SERVICES from "api/services/advertissements/advertissements.services";
import * as appRoutes from "config/routes.config";
import useTeacherAds from "hooks/useTeacherAds.hook";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  displayDrawer,
  displayDrawerUpdate,
  setDataUpdate,
} from "store/adventissements";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import CardAds from "components/libs/cardAds";
import H2 from "components/libs/subtitle";
import s from "./assets/adds.module.css";


// import Euro from "../recentAnnouncements/assets/images/euro.svg";
import { findLevelsById } from "api/services/levels/levels.services";
import { useGetAdQuery } from "store/services/teacher/ads.services";
import { formatDates } from "utils/formats";

const { Paragraph, Text } = Typography;
const { Option } = Select;
const { Step } = Steps;

export enum AdSteps {
  first = 0,
  second = 1,
  third = 2,
}

const today = new Date();

interface FirstStepData {
  title?: string;
  levelId?: string;
  topicId?: string;
  price?: string;
  description?: string;
  language?: any;
}
interface SecondStepData {
  duration?: number | any;
  date?: moment.Moment | any;
  repeat?: boolean;
  niches?: any;
}

const TeacherAds = () => {
  //!HOOKS
  const { t } = useTranslation();

  const { drawerVisible } = useSelector((state) => state.drawerAds);
  const auth = useSelector((state) => state.auth);
  const { data: adsStore, isLoading } = useGetAdQuery(auth.user?._id);
  const { refetch } = useGetAdQuery(auth.user?._id);
  const [adds] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const topics = useSelector((state) => state.topics);
  const levels = useSelector((state) => state.levels);
  const { data, loading } = useTeacherAds(auth.user?._id || "");
  const [form] = Form.useForm();

  //!STATE
  const [addStep, setAddStep] = useState(0);
  const [firstStepData, setFirstStepData] = useState<FirstStepData>({});
  const [secondStepData, setSecondStepData] = useState<SecondStepData>({});
  const [, setThirdStepData] = useState<FirstStepData | SecondStepData>({});
  const [createAdsLoading, setCreateAdsLoading] = useState(false);
  const [showAddAdModal, setshowAddAdModal] = useState(false);
  const [childrenDrawerVisble, setChildrenDrawerVisible] =
    useState<boolean>(false);
  const [add, setAdd] = useState<any>([]);
  const [widthDraxer, setWidthDrawer] = useState<number>(75);
  const [tempTopic, setTempTopic] = useState<any>();
  const [tempNameTopic, setTempNameTopic] = useState<any>([]);
  const [tempNameLevel, setTempNameLevel] = useState<any>(null);

  //!FUNCTIONS
  const openAddAdModal = () => setshowAddAdModal(true);
  const closeAddAdModal = () => setshowAddAdModal(false);
  const goToTeacherAds = () => navigate(appRoutes.TEACHER_ADS);

  const handleShowChildrenDrawer = () =>
    setChildrenDrawerVisible(!childrenDrawerVisble);

  const fetchAds = useCallback(async () => {
    setCreateAdsLoading(true);
    const response = await ADS_SERVICES.getAds(auth.user?._id);
    try {
      if (response?.statusCode === StatusCode.OK) {
        setAdd(response.data);
      }
    } catch (error) {
    } finally {
      setCreateAdsLoading(false);
    }
  }, []);

  const submitFirstStep = (values: FirstStepData) => {
    setFirstStepData(values);
    setAddStep(AdSteps.second);
  };

  const submitSecondStep = (values: SecondStepData) => {
    values.niches = values?.niches
      ?.map((niche: any) => {
        return {
          ...niche,
          date: niche?.date.format("L"),
          time: niche?.time.map((item: any) => {
            return item.format("LT");
          }),
        };
      })
      .reduce((acc: any, cur: any) => {
        const currentDateIndex = acc.findIndex(
          (item: any) => item.date === cur.date
        );

        if (currentDateIndex === -1) {
          return [
            ...acc,
            {
              date: cur.date,
              timeRanges: [
                {
                  range: cur.time,
                  repeat: !!cur?.repeat,
                },
              ],
            },
          ];
        }

        if (currentDateIndex !== -1) {
          return [
            ...acc.slice(0, currentDateIndex),
            {
              date: cur.date,
              timeRanges: [
                ...acc[currentDateIndex].timeRanges,
                {
                  range: cur.time,
                  repeat: !!cur?.repeat,
                },
              ],
            },
            ...acc.slice(currentDateIndex + 1),
          ];
        }
        return acc;
      }, []);

    setSecondStepData(values);
    setAddStep(AdSteps.third);
  };

  const submitThirdStep = useCallback(async () => {
    try {
      setCreateAdsLoading(true);
      const data: FirstStepData | SecondStepData = {
        ...firstStepData,
        ...secondStepData,
      };
      setThirdStepData(data);
      const response = await ADS_SERVICES.createAd(data);

      setAdd((prev: any) => [...prev, response?.data]);
      message.success("créé avec succès !");
      adds.resetFields();
      refetch();
    } catch (error) {
      message.error("Une erreur inconu a été survenue");
    } finally {
      setCreateAdsLoading(false);
    }
  }, [firstStepData, secondStepData, adds]);
  //!DATE RENDER
  function dateCellRender(value: any) {
    // const listData = getListData(value);
    const date = value.format("DD/MM/YYYY");

    if (loading) return <Spin />;

    if (data) {
      const ad = data.find((ad: any) =>
        ad?.niches?.some((x: any) => x.date === date)
      );
      if (ad) {
        return ad.niches.map((niche: any) => {
          return (
            <div key={niche.date}>
              <Badge status="success" /> {niche.date}
            </div>
          );
        });
      }
      return null;
    }
    return null;
  }

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      try {
        const response = await TOPICS_SERVICES.findAllQueries(levelId);

        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
        }

        const res = await findLevelsById(levelId);
        if (res?.statusCode === StatusCode.OK) {
          setTempNameLevel(res?.data);
        }
      } catch (error) {
        // message.error("Une erreur non connue s'est produite.");
      }
    },
    [tempTopic, tempNameLevel]
  );

  const onFetchAd = async (id: string) => {
    try {
      const response = await ADS_SERVICES.findAd(id);
      if (response?.statusCode === StatusCode.OK) {
        dispatch(setDataUpdate(response?.data));
        dispatch(displayDrawerUpdate(true));
      }
      return true;
    } catch (error) {
    }
  };

  const getTopic = useCallback(async (idTopic: string) => {
    try {
      const response = await TOPICS_SERVICES.findTopicById(idTopic);
      if (response.statusCode === StatusCode.OK) {
        setTempNameTopic([...tempNameTopic, response.data]);
      }
    } catch (error) {
      // message.error("Une erreur non connue s'est produite.");
    }
  }, []);

  //!RENDER

  return (
    <>
      {/* <Breadcrumbs /> */}
      <div style={{ margin: "2em 0" }}>
        <div className={s.header}>
          <div className={s.left}>
            <H2 className={s.subtitle}>Mes annonces les plus récentes</H2>
          </div>

          <div className={s.center} />

          <div className={s.right}>
            <AppButton
              icon={<PlusOutlined />}
              //  onClick={openAddAdModal}
              onClick={() => dispatch(displayDrawer(true))}
            >
              Créer une annonce
            </AppButton>
          </div>
        </div>
      </div>

      <div className={s.main}>
        <Card
          bordered={false}
          headStyle={{ borderBottom: "unset" }}
          bodyStyle={{ margin: 0, padding: 0 }}
        >
          {isLoading && (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}
          {!isLoading && (
            <>
              {adsStore?.data?.map((ad: any, index: number) => (
                <div key={index}>
                  <CardAds
                    id={ad?._id || ""}
                    title={ad?.title}
                    description={ad?.description || ""}
                    date={formatDates(ad?.updatedAt) || ""}
                    dates={ad?.niches?.map((niche: any) => niche) || []}
                    price={ad?.price || 0}
                    index={index + 1}
                    status={ad?.status || ""}
                    topic={ad}
                    level={ad}
                    language={ad}
                  />
                </div>
              ))}
            </>
          )}

          {!isLoading && adsStore?.data?.length <= 0 && (
            <>
              <Row justify="center">
                <AppButton onClick={openAddAdModal} icon={<PlusOutlined />}>
                  Créer une annonce{" "}
                </AppButton>
              </Row>
            </>
          )}
        </Card>
      </div>
      {/* <DrawerAds fetchAds={fetchAds} /> */}
    </>
  );
};

// type AdCardProps = {
//   id: string;
//   date: string;
//   dates: string[];
//   description: string;
//   topic: string;
//   price: number;
//   index: number;
//   status: string;
// };

export default TeacherAds;
