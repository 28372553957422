import { SearchOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Avatar, Badge, Col, Row, Spin } from "antd";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";

//
import { useDispatch, useSelector } from "react-redux";
import {
  setConversationStore,
  setCurrentChat,
  setIdReceiver,
  setIdSender,
  setSelectUserChat,
  setTalkId,
  setToggleStartChat,
} from "store/AppChat/appChat";
import { useGetAllRoomsQuery } from "store/services/chat";

//!STYLE
import SearchUser from "../searchEngine";
import s from "./style/conversation.module.css";

//!ASSETS
import BadgeInstaProf from "assets/images/app/badge.svg";
import TeacherIcon from "assets/images/teacher/teacher.svg";
import AppResult from "components/appResults";
import moment from "moment";

type Props = {
  setConversation: React.Dispatch<any>;
  conversations: any;
  isLoading: boolean;
  isError: boolean;
};

const Conversation: FC<Props> = ({
  setConversation,
  conversations,
  isLoading,
  isError,
}) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { refetch } = useGetAllRoomsQuery(user?._id);
  const { socket, idSender, idReceiver, chatReceiverId, currentChat, selectUserChat } =
    useSelector((state) => state.appChat);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [userInSearch, setUserInSearch] = useState<any>({});
  const refFirstConversation = useRef<HTMLDivElement>(null);

  ///const [isSeen, setIsSeen] = useState<boolean>(true);

  const findConversations = useCallback(async (values: any) => {
    if (values) {
      socket?.connect();

      const findRoomBody = {
        actor1: values?.receiverId?._id || values?.receiverIdC?._id,
        actor2: values?.senderId?._id,
        flag: values?.receiverIdC?._id ? "GROUP_FLAG" : "SINGLE_FLAG",
        instaprof: values?.instaProf ? "find" : "",
        type: values?.type ? "instaprof" : "",
      };

      await socket.emit("startChat", isSearch ? values : findRoomBody);
      await socket.on("openRoom", (res: any) => {
        if (res.status === "OK") {
          socket.emit("joinUsers", res?.data.talkId);

          const getConversationBody = {
            senderId: user?._id,
            talkId: res?.data?.talkId,
          };
          dispatch(setTalkId(res?.data?.talkId));
          socket.emit("loadPreviousMessages", getConversationBody);
          socket.on("previousMessagesLoaded", (msgResponse: any) => {
            setConversation(msgResponse.data);
          });
        }
      });
    }
  }, []);

  // Function to update "seen" status
  const markAsSeen = (conversationId: string) => {
    const updatedConversations = conversations?.data?.map((conversation: any) => {
      if (conversation?._id === conversationId) {
        return { ...conversation, seen: true };
      }
      ///return conversation;
      refetch();
    });
    ///setConversation(updatedConversations);
  };

  useEffect(() => {
    conversations?.data?.forEach((el: any) => {
      if (
        chatReceiverId != el?.receiverId?._id ||
        chatReceiverId != el?.senderId?._id
      ) {
        refetch();
      }
    });
  }, [conversations, chatReceiverId]);

  useEffect(() => {
    if (selectUserChat) {
      setTimeout(() => {
        setUserInSearch({ _id: chatReceiverId });
        setTimeout(() => {
          refFirstConversation.current?.click();
          dispatch(setSelectUserChat(false));
        }, 200);
      }, 100);
    }
  }, [chatReceiverId, selectUserChat]);

  return (
    <div className={s.conversation}>
      <div className={s.search}>
        <Row align="middle">
          <Col span={22}>
            <SearchUser
              findConversations={findConversations}
              getAllRooms={refetch}
              setIsSearch={setIsSearch}
              refFirstConversation={refFirstConversation}
              setUserInSearch={setUserInSearch}
            />
          </Col>
          <Col span={2}>
            <SearchOutlined style={{ fontSize: "15px" }} />
          </Col>
        </Row>
      </div>

      <div className={s.content__conversation}>
        {isLoading ? (
          <React.Fragment>
            <Row justify="center" style={{ minHeight: "40vh" }} align="middle">
              <Spin />
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {conversations?.data.length <= 0 ? (
              <AppResult
                status="info"
                title={
                  <h4 style={{ lineHeight: "27px" }}>
                    Liste de discussion vide
                  </h4>
                }
                subTitle="Tu peux choisir un contact pour commencer la discussion"
              />
            ) : (
              <ul>
                {conversations?.data?.map(
                  (conversation: any, index: number) => (
                    <li
                      key={index}
                      className={`${s.list} ${currentChat?._id === conversation?._id && `${s.active}`
                        }`}
                    >
                      <div
                        ref={
                          userInSearch?._id === conversation?.receiverId?._id ||
                            userInSearch?._id === conversation?.senderId?._id
                            ? refFirstConversation
                            : null
                        }
                        className={s.conversation__list}
                        onClick={() => {
                          findConversations(conversation);
                          dispatch(setConversationStore(conversation));
                          dispatch(
                            setIdReceiver(conversation?.receiverId?._id)
                          );
                          dispatch(setIdSender(conversation?.senderId?._id));
                          dispatch(setCurrentChat(conversation));
                          dispatch(setToggleStartChat(true));
                          markAsSeen(conversation?._id);
                        }}
                      >
                        <div className={s.avatar}>
                          <Avatar
                            size={34}
                            src={
                              conversation?.receiverIdC ? (
                                <UsergroupAddOutlined
                                  style={{
                                    color: "var(--first-color)",
                                    fontSize: "26px",
                                  }}
                                />
                              ) : user?._id ===
                                conversation?.receiverId?._id ? (
                                conversation?.senderId?.profile || TeacherIcon
                              ) : (
                                conversation?.receiverId?.profile || TeacherIcon
                              )
                            }
                          />
                        </div>
                        <div className={s.username}>
                          <div className={s.name__user}>
                            <Row justify="space-between">
                              <Col>
                                {conversation?.receiverIdC ? (
                                  <span style={{ height: "25px", width: "auto", padding: "5px", background: "#8AB0B1", color: "#FFF" }}>
                                    {/* Classe de{" "} */}
                                    {`${conversation?.receiverIdC?.title || ""
                                      }`}
                                  </span>
                                ) : (
                                  <span>
                                    {conversation?.receiverId?._id ===
                                      user?._id ? (
                                      <React.Fragment>
                                        {conversation?.senderId?.firstname}{" "}
                                        {/* {conversation?.senderId?.lastname} */}
                                        {conversation?.instaProf && (
                                          <img
                                            src={BadgeInstaProf}
                                            width={45}
                                            alt=""
                                          />
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {conversation?.receiverId?.firstname}{" "}
                                        {/* {conversation?.receiverId?.lastname} */}
                                        {conversation?.instaProf && (
                                          <img
                                            src={BadgeInstaProf}
                                            width={45}
                                            alt=""
                                          />
                                        )}
                                      </React.Fragment>
                                    )}
                                  </span>
                                )}
                              </Col>

                              {conversation?.lastMessage && conversation?.seen === false && (
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  {conversation?.lastReceiverId === user?._id && <div className={s.unreadCount}>1</div>}
                                  <Badge color="lime" />
                                </div>
                              )}
                              {!conversation?.lastMessage && (
                                <Col>
                                  <Badge status="warning" />
                                </Col>
                              )}
                            </Row>
                          </div>
                          <div className={s.apercu__msg}>
                            <Row justify="space-between" align="middle">
                              <Col>
                                {" "}
                                {conversation?.seen === false ? <span style={{ color: "#868686" }}>
                                  {conversation?.lastMessage?.substring(0, 20)}{" "}
                                </span> : <span>
                                  {conversation?.lastMessage?.substring(0, 20)}{" "}
                                </span>}
                              </Col>
                              <Col>
                                <span style={{ fontSize: "8px" }}>
                                  {moment(conversation?.updatedAt).fromNow().replace(/^\w/, (c) => c.toUpperCase())}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Conversation;
