import { useState } from "react";

//!COMPONENTS
import ChatSpace from "./components/chat";
import Conversation from "./components/conversation";
import Message from "./components/message";

//!REDUX
import { useDispatch, useSelector } from "react-redux";
import { toggleAppChat } from "store/AppChat/appChat";

//!ASSETS
import ArrowBack from "../../assets/images/app/arrow_back.svg";

//!STYLE
import { Drawer } from "antd";
import { useGetAllRoomsQuery } from "store/services/chat";
import s from "./assets/appChat.module.css";

const AppChat = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { openAppChat, currentChat, startChat } = useSelector((state) => state.appChat);
  const {
    data: conversations,
    isLoading,
    isError,
    refetch,
  } = useGetAllRoomsQuery(user?._id);

  //!STATES
  const [conversation, setConversation] = useState<any[]>([]);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Drawer
      className="drawer"
      onClose={() => dispatch(toggleAppChat(false))}
      visible={openAppChat}
      width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "100%" : isMediumScreen ? "100%" : isNormalScreen ? "80%" : "75%"}
      title={
        <div className={s.drawerTitle}>
          Messagerie
        </div>
      }
      headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
      closeIcon={
        <img
          src={ArrowBack}
          height={isMobile ? 10 : 18}
          style={{}}
        ></img>
      }
      drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "30px", borderBottomLeftRadius: isMobile ? "0px" : "30px", boxShadow: "-3px 3px 34px #39393929" }}
    >
      <div className={s.app__container}>
        <div className={s.row}>
          <div className={s.conversation}>
            <Conversation
              setConversation={setConversation}
              conversations={conversations}
              isLoading={isLoading}
              isError={isError}
            />
          </div>

          <div className={`${s.chat} ${currentChat && s.active}`}>
            <div className={s.chat__area}>
              <ChatSpace conversation={conversation} isTyping={isTyping} isBlocked={isBlocked} setIsBlocked={setIsBlocked} />
            </div>
            {startChat && <div className={s.chat__area__footer}>
              <Message
                conversation={conversation}
                setConversation={setConversation}
                setIsTyping={setIsTyping}
                isBlocked={isBlocked}
              />
            </div>}
          </div>
        </div>
        <div className={s.conditionsBox}>En utilisant notre chat vous acceptez nos <a href="/assets/files/mentions_legales.pdf" target="_blank" rel="noopener noreferrer">mentions légales</a> et notre <a href="/assets/files/cgu.pdf" target="_blank" rel="noopener noreferrer">politique de confidentialité</a>.</div>
      </div>
    </Drawer>
  );
};

export default AppChat;
