import { CheckCircleOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Input, Menu, message, Modal, Popconfirm, Skeleton, Spin, Tag, Typography } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { calculateTimeDifference, FLAGS, isToday, StatusCode } from "common/enums";
import AppResult from "components/appResults";
import Matiers from "components/matiers";
import ZoomMeeting from "components/zoom";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { setIsReservationsUpdated } from "store/app/appSlice";
import { setChatReceiverId, setSelectUserChat, toggleAppChat } from "store/AppChat/appChat";
import { openProfileDetailsDrawer, setProfileDetailsID, setProfileDetailsType } from "store/auth/authSlice";
import { setIsDrawerInstaProf } from "store/chat/roomSlice";
import { useFindRoomMutation } from "store/services/chat";
import { useGetHistoriqueQuery } from "store/services/teacher/classes.services";
import MoreOptions from "../../../assets/images/app/chat_options.svg";
import ClockIcon from "../../../assets/images/app/clock_icon.svg";
import EllipseTeacherProfileFour from "../../../assets/images/app/ellipse_teacher_profile4.png";
import EllipseTeacherProfileFive from "../../../assets/images/app/ellipse_teacher_profile5.png";
import CancelIcon from "../../../assets/images/app/i_icon.svg";
import MeetIcon from "../../../assets/images/app/meet_now_icon.svg";
import s from "./assets/calendar.module.css";

interface CoursesRoomProps {
  course?: object | any;
  onViewMeeting?: (meetingId: string) => void;
}

const CoursesRoom: FC<CoursesRoomProps> = ({ course, onViewMeeting }) => {

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  const [findRoom, { isSuccess }] = useFindRoomMutation();

  const handleDisplayMessagerie = () => {
    dispatch(setIsDrawerInstaProf(false));
    setIsLoadingData(true);
    const finalData = {
      actor1: course?.profId?._id,
      actor2: course?.studId?._id,
      flag: FLAGS.SINGLE_FLAG,
      type: "",
      instaprof: "",
    };
    findRoom(finalData);
    dispatch(setChatReceiverId(course?.studId?._id));
  };

  const handleMenuClick = (e: any) => {
    /* if (e.key === "1") {
      setDropdownVisible(false);
      handleMeetingClick();
    } else  */if (e.key === "1") {
      setPopconfirmVisible(true);
      setDropdownVisible(false);
    }
    else if (e.key === "2") {
      setDropdownVisible(false);
      handleDisplayMessagerie();
    }
  };

  const handleStatMeeting = () => {
    setDropdownVisible(false);
    handleMeetingClick();
  };

  const handleConfirm = () => {
    setPopconfirmVisible(false);
    showModal();
  };

  const handleCancel = () => {
    setPopconfirmVisible(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelMessage, setCancelMessage] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  const handleScheduleMeeting = () => {
    handleDisplayMessagerie();
  };

  /* const openUrlInNewTab = () => {
    window.open(course?.profLink, '_blank');
  }; */

  const handleMeetingClick = () => {
    if (!course?.profLink) {
      message.error("Lien de réunion non trouvé pour ce cours");
      return;
    }

    // Extract meeting ID from Zoom link - handle multiple URL formats
    let meetingId;
    try {
      const url = new URL(course.profLink);
      // Handle different Zoom URL formats
      if (url.pathname.includes('/j/')) {
        meetingId = url.pathname.split('/j/')[1]?.split('?')[0];
      } else if (url.pathname.includes('/s/')) {
        meetingId = url.pathname.split('/s/')[1]?.split('?')[0];
      } else {
        // Try to find any number sequence that could be a meeting ID
        meetingId = url.pathname.match(/\d{10,11}/)?.[0];
      }

      // Remove any non-numeric characters
      meetingId = meetingId?.replace(/\D/g, '');

      if (!meetingId || meetingId.length < 10) { // Zoom meeting IDs are typically 10-11 digits
        throw new Error('Invalid meeting ID format');
      }
    } catch (error) {
      message.error("ID de réunion invalide");
      return;
    }
    onViewMeeting?.(meetingId);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {/* <Menu.Item key="1">Rejoindre le meet</Menu.Item> */}
      <Menu.Item key="1">Annuler le meet</Menu.Item>
      <Menu.Item key="2">Replanifier le meet</Menu.Item>
    </Menu>
  );

  const cancelMeeting = async () => {
    setIsLoading(true);
    try {
      if (cancelMessage !== "") {
        const response = await AUTH_SERVICES.cancelMeeting(course?._id, cancelMessage);
        if (response?.statusCode === StatusCode.CREATED) {
          message.success({
            content: "Meet annulé ",
          });
          setTimeout(() => {
            dispatch(setIsReservationsUpdated(true));
          }, 2000);
          setIsLoading(false);
          setIsModalOpen(false);
        }
        else {
          message.warning({
            content: "Ooops, erreur lors de l'annulation",
          });
          setIsLoading(false);
        }
      }
      else {
        message.warning({
          content: "Ooops, entre le message de l'annulation",
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
      setIsLoading(false);
    }
  }

  const handleProfileDetails = () => {
    dispatch(setProfileDetailsID(course?.studId?.uid));
    dispatch(setProfileDetailsType("student"));
    dispatch(openProfileDetailsDrawer());
  }

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
      setIsLoadingData(false);
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
  }, [isSuccess]);

  return (
    <>
      <div className={`${course?.cancelMessage !== "" ? s.classeCardDeleted : s.classeCard}`} style={{ cursor: "pointer" }}>
        <div className={s.cardClassBox}>
          <div className={s.avatarNameBox} onClick={handleProfileDetails}>
            <Avatar.Group
              maxCount={3}
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
              }}
              style={{ marginRight: isMobile ? "0px" : "5px" }}
            >
              <Avatar
                size={45}
                style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
                src={course?.studId?.profile}
              />
              {course?.typeCreneaux !== "individuel" && <Avatar
                size={45}
                style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029", position: "relative", left: "-25px" }}
                src={course?.studId?.profile}
              />}
              {course?.typeCreneaux !== "individuel" && <Avatar
                size={45}
                style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029", position: "relative", left: "-50px" }}
                src={course?.studId?.profile}
              />}
            </Avatar.Group>
            <div style={{ position: "relative", top: isMobile ? "5px" : "0px", left: isMobile ? "0px" : !isMobile && course?.typeCreneaux !== "individuel" ? "-40px" : "15px", fontSize: isMobile ? "1em" : "13px", textTransform: "capitalize" }}>{course?.typeCreneaux !== "individuel" ? "Cours collectif" : course?.studId?.firstname + " " + course?.studId?.lastname}</div>
          </div>
          {isMobile && <Matiers
            cours={course?.topic?.title.slice(0, 4) || "null"}
            level={course?.level?.title || "null"}
          />}
          <div className={s.detailsBox}>
            <div className={s.timeBox}>
              {!isMobile && <img src={ClockIcon} height={15} style={{ marginRight: "10px" }}></img>}
              <div className={s.times}>
                <span className={s.time}>{isMobile && <img src={ClockIcon} height={10} style={{ marginRight: "5px" }}></img>} De {course?.range[0]} à {course?.range[course?.range?.length - 1]}</span>
                <span className={s.timeDescription}>Séance de “{calculateTimeDifference(course?.range[0], course?.range[course?.range.length - 1])}”</span>
              </div>
            </div>
            {!isMobile && <Matiers
              cours={course?.topic?.title.slice(0, 4) || "null"}
              level={course?.level?.title || "null"}
            />}
            <div className={s.amountBox}>
              Séance réservée pour
              <div className={s.priceBox}>{course?.byCredits === "oui" ? course?.creditedValue + " Crédit" : course?.price + " €"}</div>
            </div>
          </div>

          {false ? (
            <Tag
              icon={<CheckCircleOutlined />}
              color="success"
            >
              success
            </Tag>
          ) : (
            isLoading || isLoadingData ? <div>
              <Spin />
            </div> : <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              {course?.cancelMessage === "" && <Button onClick={handleStatMeeting} className={s.meetButton}>
                {!isMobile && "Rejoindre le meet"} <img src={MeetIcon} height={isMobile ? 14 : 18} style={{ marginLeft: isMobile ? "0px" : "5px" }}></img>
              </Button>}
              {!isMobile && course?.cancelMessage === "" && <Popconfirm
                title={`Veux-tu annuler ce meet?`}
                visible={popconfirmVisible}
                onVisibleChange={(visible) => {
                  if (!isLoading) {
                    setPopconfirmVisible(visible);
                  }
                }}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                okText="Oui"
                cancelText="Non"
              >
                <Button type="primary" />
              </Popconfirm>}
              {course?.cancelMessage === "" && <Dropdown overlay={menu} visible={dropdownVisible} onVisibleChange={setDropdownVisible} overlayStyle={{ border: "none" }}>
                <Button onClick={() => setDropdownVisible(true)} className={s.moreOptionsButton}>
                  <img src={MoreOptions} height={isMobile ? 14 : 18}></img>
                </Button>
              </Dropdown>}
              {course?.cancelMessage !== "" && <div className={s.cancelBox}>
                <div className={s.horizontalLineDeleted}></div>
                <span>
                  <img src={CancelIcon} height={isMobile ? 10 : 18} style={{ marginRight: "5px", marginLeft: isMobile ? "0px" : "20px" }}></img>
                  Meet annulé
                </span>
              </div>}
            </div>
          )}
        </div>
      </div>

      {isMobile && course?.cancelMessage === "" && <Popconfirm
        title={`Veux-tu annuler ce meet?`}
        visible={popconfirmVisible}
        onVisibleChange={(visible) => {
          if (!isLoading) {
            setPopconfirmVisible(visible);
          }
        }}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        okText="Oui"
        cancelText="Non"
      >
        <Button type="primary" />
      </Popconfirm>}

      <Modal
        title={
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
            <Avatar.Group
              maxCount={3}
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
              }}
              style={{ marginRight: "5px" }}
            >
              <Avatar
                size={45}
                style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
                src={auth?.user?.profile}
              />
            </Avatar.Group>
            Annulation de meet
          </div>
        }
        visible={isModalOpen}
        onCancel={handleCancelModal}
        footer={null}
      >
        <div style={{ marginBottom: "16px", textTransform: "capitalize" }}>
          <strong>
            {new Intl.DateTimeFormat("fr-FR", {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric",
            }).format(new Date(course?.day))}
          </strong>
        </div>
        <div style={{ marginBottom: "8px" }}>
          <span>Message pour {course?.studId?.firstname}</span>
        </div>
        <Input.TextArea
          rows={3}
          placeholder="J'annule le meet parce que..."
          value={cancelMessage}
          onChange={(e) => setCancelMessage(e.target.value)}
          style={{ borderRadius: "10px" }}
          required={true}
        />
        <div
          style={{
            marginTop: "24px",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: isMobile ? "center" : "space-between",
          }}
        >
          <Button className={s.scheduleButton} loading={isLoadingData} onClick={handleScheduleMeeting}>
            Reprogrammer plutôt
          </Button>
          <Button className={s.cancelButton} loading={isLoading || isLoadingData} onClick={cancelMeeting}>
            Confirmer l'annulation
          </Button>
        </div>
      </Modal>
    </>
  );
};

const { Text } = Typography;

const CalendarOfReservationTeacher = () => {

  const { user } = useSelector((state) => state.auth);
  const { isReservationsUpdated } = useSelector((state) => state.app);
  const { data, isLoading, isError, refetch } = useGetHistoriqueQuery(
    user?._id
  );
  const [courses, setCourses] = useState<any>([]);

  const [isZoomMeetingVisible, setIsZoomMeetingVisible] = useState(false);
  const [currentMeetingId, setCurrentMeetingId] = useState<string>('');

  useEffect(() => {
    refetch();
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  const handleViewMeeting = (meetingId: string) => {
    if (!meetingId || meetingId.trim() === '') {
      console.error("ID de réunion invalide");
      return;
    }
    setCurrentMeetingId(meetingId);
    setIsZoomMeetingVisible(true);
  };

  useEffect(() => {
    if (data?.data) {
      const sortedCourses = [...data?.data].sort(
        (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      const groupedByDate = sortedCourses.reduce((acc: any, course: any) => {
        const date = new Date(course.startDate).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(course);
        return acc;
      }, {});

      setCourses(groupedByDate);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [isReservationsUpdated]);

  return (
    <>
      {!isMobile && <img src={EllipseTeacherProfileFour} height={106} style={{ position: "absolute", top: "25%", left: "0px", zIndex: "-1" }}></img>}
      {!isMobile && <img src={EllipseTeacherProfileFive} height={54} style={{ position: "absolute", top: "35%", left: "94%", zIndex: "-1" }}></img>}
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <div className={s.calendar}>
          {data?.data?.length > 0 ? (
            courses && Object.keys(courses)?.map((date) => {
              return (
                <>
                  <div key={date} style={{ marginTop: "10px" }}>
                    <div className={s.dateBox}>
                      {new Intl.DateTimeFormat("fr-FR", {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }).format(new Date(date))}
                      {isToday(date) && <div className={s.todayBox}>Aujourd’hui</div>}
                      <div className={s.horizontalLine}></div>
                    </div>
                    {!isMobile && courses[date].map((course: any) => (
                      <div key={course.id}>
                        <CoursesRoom course={course} onViewMeeting={handleViewMeeting} />
                      </div>
                    ))}

                    {isMobile && <div className={s.cardDisplay} /* className={`${s.cardDisplay} ${courses[date].length === 1 ? s.singleCourse : ""}`} */>
                      {courses[date].map((course: any) => (
                        <div key={course.id}>
                          <CoursesRoom course={course} onViewMeeting={handleViewMeeting} />
                        </div>
                      ))}
                    </div>}
                  </div>
                </>
              );
            })
          ) : (
            <div className={s.emptyClass}>
              <AppResult status={"info"} title={"Aucune réservation"}></AppResult>
            </div>
          )}
        </div>
      )}
      {isZoomMeetingVisible && (
        <ZoomMeeting
          meetingNumber={currentMeetingId}
          userName={user?.firstname + " " + user?.lastname}
          sdkKey={process.env.REACT_APP_ZOOM_SDK_KEY ?? ""}
          sdkSecret={process.env.REACT_APP_ZOOM_SDK_SECRET ?? ""}
          onClose={() => {
            setIsZoomMeetingVisible(false);
          }}
        />
      )}
    </>
  );
};

export default CalendarOfReservationTeacher;
