import {
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
  LikeOutlined,
} from "@ant-design/icons";
import { Avatar, Comment, Drawer, Skeleton, Tooltip } from "antd";
import socket from "config/api.socket";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useCommentDisLikeMutation,
  useCommentLikeMutation,
  useGetCommentsMutation,
  useGetPostsQuery,
  useUndoCommentDisLikeMutation,
  useUndoCommentLikeMutation,
} from "store/services/teacher/activities.services";
import AddComment from "./addComment";

interface Props {
  action: "get" | "post";
  open: any;
  postId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Comments: React.FC<Props> = ({
  open,
  postId,
  setOpen,
  action: commentAction,
}) => {
  const [getComments, { data, isLoading }] = useGetCommentsMutation();
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [action, setAction] = useState<string | null>(null);
  const { user } = useSelector((state) => state.auth);
  const { refetch } = useGetPostsQuery(user?._id);

  const [commentLike, { isSuccess }] = useCommentLikeMutation();
  const [commentDisLike] = useCommentDisLikeMutation();

  const [undoCommentLike] = useUndoCommentLikeMutation();
  const [undoCommentDisLike] = useUndoCommentDisLikeMutation();

  const like = () => {
    setLikes(1);
    setDislikes(0);
    setAction("liked");
  };

  const dislike = () => {
    setLikes(0);
    setDislikes(1);
    setAction("disliked");
  };

  useEffect(() => {
    getComments(postId);

    if (socket) {
      socket.on("connect", () => {
        console.log("Socket connected!");
      });

      socket.on("newPostComment", (resData: any) => {
        refetch();
      });
    }

    if (data && data.data.results.length > 0) { }

    return () => {
      socket?.off("newPost");
    };
  }, [open ? open : null]);

  const actions = [
    <Tooltip key="comment-basic-like" title="Like">
      <span onClick={like}>
        {action === "liked" ? <LikeFilled /> : <LikeOutlined />}
        <span className="comment-action">{likes}</span>
      </span>
    </Tooltip>,
    <Tooltip key="comment-basic-dislike" title="Dislike">
      <span onClick={dislike}>
        {action === "disliked" ? <DislikeFilled /> : <DislikeOutlined />}
        <span className="comment-action">{dislikes}</span>
      </span>
    </Tooltip>,
    // <span key="comment-basic-reply-to">Reply to</span>,
  ];

  return (
    <Drawer
      title={false}
      placement="right"
      onClose={() => setOpen(false)}
      visible={open}
    >
      <>
        {isLoading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : data && data.data.results.length > 0 ? (
          <>
            <AddComment postId={postId} />

            {data.data.results.map((comment: any, index: number) => (
              <Comment
                style={{ marginLeft: "1.5em" }}
                key={comment.id}
                actions={
                  [
                    <Tooltip key="comment-basic-like" title="Like">
                      <span onClick={async () => {
                        const body = {
                          commentId: comment?._id,
                          userId: user?._id,
                        };
                        if (comment?.liked) {
                          await undoCommentLike(body);
                        } else {
                          await commentLike(body);
                        }
                        getComments(postId);
                      }}>
                        {comment.liked ? <LikeFilled /> : <LikeOutlined />}
                        <span className="comment-action">{comment?.likesCount}</span>
                      </span>
                    </Tooltip>,
                    <Tooltip key="comment-basic-dislike" title="Dislike">
                      <span onClick={async () => {
                        const body = {
                          commentId: comment?._id,
                          userId: user?._id,
                        };
                        if (comment?.disliked) {
                          await undoCommentDisLike(body);
                        } else {
                          await commentDisLike(body);
                        }
                        getComments(postId);
                      }}>
                        {comment.disliked ? <DislikeFilled /> : <DislikeOutlined />}
                        <span className="comment-action">{comment?.dislikesCount}</span>
                      </span>
                    </Tooltip>,
                  ]
                }
                author={<a>{comment.userId.firstname}</a>}
                avatar={
                  <Avatar
                    src={comment.userId.profile}
                    alt={comment.userId.firstname}
                  />
                }
                content={<p>{comment.comment}</p>}
                datetime={
                  <Tooltip title={moment(comment.updatedAt).fromNow()}>
                    <span>{moment(comment.updatedAt).fromNow()}</span>
                  </Tooltip>
                }
              />
            ))}
          </>
        ) : (
          <div>
            {/* <AppResult status="info" title="Aucun commentaire" /> */}
            <AddComment postId={postId} />
          </div>
        )}
      </>
    </Drawer>
  );
};

export default Comments;
