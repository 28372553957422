import { SyncOutlined } from "@ant-design/icons";
import { Drawer, Image, Skeleton } from "antd";
import { findTopicByIdStudent } from "api/services/topics/topics.services";
import * as appRoutes from "config/routes.config";
import useChapters from "hooks/useChapters";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

//!REDUX
import { useDispatch, useSelector } from "react-redux";
import { onSetIdCours } from "store/courses/coursesSlice";

//!COMPONENTS
import { TypeMatiers } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import Grid from "components/libs/grid";
import H2 from "components/libs/subtitle";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import CardChapter from "../components/cardChapitre";

//!IMAGES

//ICONS
import bars from '../../../assets/images/app/bars_icon.png';
import ANGLAIS from "../../../assets/images/app/iconMatiers/Anglais.png";
import FRANCAIS from "../../../assets/images/app/iconMatiers/Francais.png";
import HISTOIREGEO from "../../../assets/images/app/iconMatiers/Histoire-geo.png";
import MATH from "../../../assets/images/app/iconMatiers/Mathematiques.png";
import PHILO from "../../../assets/images/app/iconMatiers/Philosophie.png";
import PHYSIQUE from "../../../assets/images/app/iconMatiers/Physique-chimie.png";
import SVT from "../../../assets/images/app/iconMatiers/SVT.png";

//!STYLES
import AppResult from "components/appResults";
import s from "./assets/chapitre.module.css";

import {
  checkIfSpecial,
  fetchDocs,
  fetchSommary,
  getAvancementByCourse,
} from "api/services/quizz/quizz.services";
import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import DataAccordionDrawer from "../components/docsDrawer/annalesDrawer";

const Chapitre: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<"id">();
  const { loading, data } = useChapters(params.id!);
  const [topic, setTopic] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const { user } = useSelector((state) => state.auth);
  const [progress, setProgress] = useState<any>([]);
  const [annalesData, setAnnalesData] = useState<any>([]);
  const [sommaryData, setSommaryData] = useState<any>();

  const [isSpecialLevel, setSpecialLevel] = useState<boolean>(true);

  const [showSommaryModal, setShowSommaryModal] = useState(false);
  const closeSommaryModal = () => setShowSommaryModal(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const fetchTopicById = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const response = await findTopicByIdStudent(id);
      if (response) {
        setTopic(response.data);
        localStorage.setItem("currentTopic", JSON.stringify(response.data));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  const getProgression = useCallback(
    async (id: string | any, courseId: string | any) => {
      if (user) {
        const response = await getAvancementByCourse(id, courseId);
        if (response?.statusCode === StatusCode.OK) {
          setProgress(response?.data);
        }
      }
    },
    [user]
  );

  const checkSpecial = useCallback(
    async (id: string | any) => {
      if (user) {
        const response = await checkIfSpecial(id);
        if (response?.statusCode === StatusCode.OK) {
          const isSpecial = response?.data['special'];
          if (isSpecial) {
            setSpecialLevel(true);
            getDocs(id);
          }
          else {
            setSpecialLevel(false);
          }
        }
      }
    },
    [user]
  );

  const getDocs = useCallback(
    async (id: string | any) => {
      if (user) {
        const response = await fetchDocs(id);
        if (response?.statusCode === StatusCode.OK) {
          setAnnalesData(response?.data);
        }
      }
    },
    [user]
  );

  const getSommary = useCallback(
    async (id: string | any) => {
      if (user) {
        const response = await fetchSommary(id);
        if (response?.statusCode === StatusCode.OK) {
          setSommaryData(response?.data['content']);
        }
      }
    },
    [user]
  );

  useEffect(() => {
    dispatch(onSetIdCours(params.id!));
    fetchTopicById(params.id!);
    getProgression(user?._id, params.id!);
    checkSpecial(params.id!);
    getSommary(params.id!);
  }, []);

  useEffect(() => {
    localStorage.setItem("idCours", params.id!);
  }, []);

  return (
    <React.Fragment>
      {isMobile && user?.accountManaged !== "" && <div style={{ height: "70px", width: "100%" }}></div>}
      <Breadcrumbs isMycontent={true} />

      <div className={s.chapitre}>
        <AppScreen>
          <div className={s.__content__level__matier}>
            <div className={s.left} />

            <div className={s.content__left}>
              <div className={s.__matier__matier__left}>
                {isLoading ? (
                  <Skeleton.Image style={{ marginRight: "0.5em" }} />
                ) : (
                  <Image
                    src={
                      topic?.description == TypeMatiers.mathematique
                        ? MATH
                        : topic?.description == TypeMatiers.SVT
                          ? SVT
                          : topic?.description == TypeMatiers.PhysiqueChimie
                            ? PHYSIQUE
                            : topic?.description == TypeMatiers.Philosophie
                              ? PHILO
                              : topic?.description == TypeMatiers.HistoireGeo
                                ? HISTOIREGEO
                                : topic?.description == TypeMatiers.Francais
                                  ? FRANCAIS
                                  : topic?.description == TypeMatiers.Anglais
                                    ? ANGLAIS
                                    : ""
                    }
                    preview={false}
                  />
                )}
              </div>
              <div className={s.__matier__right}>
                <div className={s.__matier__top}>
                  {isLoading ? (
                    <Skeleton.Input active size="small" />
                  ) : (
                    <span>{topic?.level?.title}</span>
                  )}
                </div>
                <div className={s.__matier__bottom}>
                  {isLoading ? (
                    <Skeleton.Input active size="small" />
                  ) : (
                    <span>{topic?.title}</span>
                  )}
                </div>
              </div>
            </div>

            <div
              className={s.__back__level}
              onClick={() => navigate(appRoutes.STUDENT_MY_CONTENT)}
            >
              <span>
                <SyncOutlined /> CHOISIR UNE AUTRE MATIÈRE{" "}
              </span>
            </div>
          </div>
          {/* <H1 className={s.__title}>Mon contenu</H1> */}
          {/* <TabsStudent idChapter={params.id} /> */}

          <div className={s.chapitre__content}>
            <div className={s.chapterDocsBox}>
              <div className={s.docsBox}>
                <H2 className={s.subtitle}>Chapitres</H2>
                {isSpecialLevel && <div className={isMobile ? '' : s.docsButton}>
                  <DataAccordionDrawer data={annalesData} />
                </div>}
              </div>
              {isSpecialLevel && <AppButton onClick={() => setShowSommaryModal(true)}> <img src={bars} width={15} style={{ marginRight: "10px" }} />Consulter le Programme</AppButton>}
            </div>
            <div className={s.card__content}>
              {!loading && (
                <Grid>
                  {data?.map((chapter: any) => (
                    <Link
                      to={`${appRoutes.STUDENT_FIND_COURSE}/${chapter?._id}`}
                      className={s.__links}
                      onClick={() =>
                        localStorage.setItem("chapterId", chapter?._id)
                      }
                    >
                      <CardChapter chapter={chapter} progress={progress} />
                    </Link>
                  ))}
                </Grid>
              )}

              {loading && (
                <React.Fragment>
                  <Skeleton active />
                  <Skeleton active />
                </React.Fragment>
              )}
            </div>

            {!loading && data.length <= 0 && (
              <AppResult
                status="info"
                title="Aucun contenu disponible pour le moment!"
              />
            )}
          </div>
          <Drawer
            width={isMobile ? "100%" : "40vw"}
            closable={isMobile ? true : false}
            visible={showSommaryModal}
            onClose={closeSommaryModal}
            bodyStyle={{ paddingTop: isMobile ? "0px" : "20px", overflow: "scroll" }}
          >
            <div dangerouslySetInnerHTML={{ __html: sommaryData }} />
          </Drawer>
        </AppScreen>
      </div>
    </React.Fragment>
  );
};

export default Chapitre;
