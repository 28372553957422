import dayjs from "dayjs";
import DOMPurify from 'dompurify';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import { FC, useRef, useState } from "react";

//!COMPONENTS

//!ANTD

//!STYLES
import s from "./assets/footerFooter.module.css";

//!IMAGES
import Container from "components/container";
import AlbertAI from "../../../../assets/images/albert/alerrt_ai.svg";
import MessageIcon from "../../../../assets/images/app/ai_conversation_icon.svg";
import CopyIcon from "../../../../assets/images/app/ai_copy_icon.svg";
import RegenerateIcon from "../../../../assets/images/app/ai_regenerate_icon.svg";
import AISendIcon from "../../../../assets/images/app/ai_send_icon.svg";
import AttachFileIcon from "../../../../assets/images/app/attach_file_icon.svg";
import MenuBars from "../../../../assets/images/app/bars_green_icon.svg";
import DeleteIcon from "../../../../assets/images/app/delete_red_icon.svg";
import EditIcon from "../../../../assets/images/app/edit_article.svg";
import ExitIcon from "../../../../assets/images/app/exit_icon.svg";
import MoreOptions from "../../../../assets/images/app/more_options.svg";
import AlbertTyping from "../../../../assets/images/app/typing.gif";
/* import SaveIcon from "../../../../assets/images/app/save_icon.svg"; */
import albert_head from "./assets/images/albert_head.svg";
import icon_facebook from "./assets/images/icon_facebook.svg";
import icon_instagram from "./assets/images/icon_instagram.svg";
import icon_linkedin from "./assets/images/icon_linkedin.svg";
import icon_youtube from "./assets/images/icon_youtube.svg";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, List, message, Popconfirm, Popover, Spin, Tooltip } from "antd";
import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import axios from "axios";
import AITopicIcon from "components/aiTopicIcon/topicIcon";
import { useEffect } from "react";
import Latex from "react-latex-next";
import { useDispatch, useSelector } from "react-redux";
import StudentCoursQuiz from "screens/student/coursQuizBot";
import StudentExercices from "screens/student/studentExosBot";
import { setIsBotCalled } from "store/app/appSlice";
import sStyle from "../../../../screens/student/cours/assets/cours.module.css";
import st from "./assets/chat.module.css";

type Conversation = {
  _id: string;
  title: string;
  createdAt: string;
};

// Add this helper function to safely render HTML with LaTeX
const renderMathContent = (content: string) => {
  try {
    // Replace LaTeX expressions with rendered KaTeX
    const processedContent = content.replace(/\\\((.*?)\\\)/g, (match, latex) => {
      try {
        return katex.renderToString(latex, {
          displayMode: false,
          throwOnError: false,
          output: 'html'
        });
      } catch (e) {
        return match;
      }
    });

    // Sanitize the final HTML
    return {
      __html: DOMPurify.sanitize(processedContent, {
        ADD_TAGS: ['span', 'math', 'semantics', 'mrow', 'mi', 'mo', 'mn'],
        ADD_ATTR: ['class', 'style'],
        ALLOWED_TAGS: ['h1', 'h2', 'p', 'ul', 'li', 'span', 'math', 'semantics', 'mrow', 'mi', 'mo', 'mn'],
        ALLOWED_ATTR: ['class', 'style']
      })
    };
  } catch (e) {
    return { __html: content };
  }
};

// Update the CSS for better math rendering
const mathStyles = {
  fontSize: '14px',
  lineHeight: '1.6',
  '& .katex': {
    fontSize: '1.1em',
    fontFamily: 'KaTeX_Math',
  },
  '& .katex-display': {
    margin: '1em 0',
  }
};

const FooterFooter: FC = () => {

  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1051);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 1051);
  };
  window.addEventListener('resize', handleResize);

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [drawerVisible, setDrawerVisible] = useState<any>(false);
  const [drawerCoursVisible, setDrawerCoursVisible] = useState<any>(false);
  const [drawerQuizzVisible, setDrawerQuizzVisible] = useState<any>(false);
  const [quizzId, setQuizzId] = useState<any>(null);
  const [drawerExerciceVisible, setDrawerExerciceVisible] = useState<any>(false);
  const [exerciceId, setExerciceId] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [coursFetched, setCoursFetched] = useState<any>(null);
  const [messages, setMessages] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>('');
  const [conversations, setConversations] = useState<any>([]);
  const [currentConversationIndex, setCurrentConversationIndex] = useState<any>(null);
  const [showSuggestedQuestions, setShowSuggestedQuestions] = useState<any>(true);
  const [suggestedQuestions, setSuggestedQuestions] = useState<any>([]);
  const [suggestedQuestionsClicked, setSuggestedQuestionsClicked] = useState<any>(false);
  const [showLoaderNewConversation, setShowLoaderNewConversation] = useState<any>(false);

  // Ref for scrolling to bottom
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const conversationsEndRef = useRef<HTMLDivElement>(null);

  const [topics, setTopics] = useState<any>([]); // State to store fetched topics
  const [selectedTopic, setSelectedTopic] = useState<any>('Auto'); // State to track selected topic
  const [textInput, setTextInput] = useState('');
  const { isBotCalled } = useSelector((state) => state.app);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageIconClick = () => {
    fileInputRef.current?.click();
  };

  const handleTextInputChange = (e: any) => {
    // Only allow numeric input
    const value = e.target.value.trim();
    setTextInput(value);
  };

  /// Chat AI
  const [visibleMenu, setVisibleMenu] = useState<boolean>(true);
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null);
  const [visibleEditIndex, setVisibleEditIndex] = useState<number | null>(null);
  const [visibleDeleteIndex, setVisibleDeleteIndex] = useState<number | null>(null);
  const [userConversations, setUserConversations] = useState<any>([]);
  const [groupedData, setGroupedData] = useState<{
    today: Conversation[];
    last7Days: Conversation[];
  }>({
    today: [],
    last7Days: [],
  });
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  /* const [visibleShareIndex, setVisibleShareIndex] = useState<number | null>(null); */

  const groupConversations = () => {
    const today = dayjs();
    const sevenDaysAgo = today.subtract(7, "day");

    const grouped: {
      today: Conversation[];
      last7Days: Conversation[];
    } = {
      today: [],
      last7Days: [],
    };

    userConversations.forEach((conversation: any, index: number) => {
      const createdAt = dayjs(conversation.createdAt);
      const conversationWithIndex = { ...conversation, originalIndex: index };

      if (createdAt.isSame(today, "day")) {
        grouped.today.push(conversationWithIndex);
      } else if (createdAt.isAfter(sevenDaysAgo)) {
        grouped.last7Days.push(conversationWithIndex);
      }
    });

    setGroupedData(grouped);
  };

  const togglePopover = (index: number) => {
    setVisibleIndex(visibleIndex === index ? null : index);
  };

  const showPopconfirmEdit = () => {
    setVisibleEditIndex(visibleIndex);
    setVisibleIndex(null);
  };

  const showPopconfirmDelete = () => {
    setVisibleDeleteIndex(visibleIndex);
    setVisibleIndex(null);
  };

  /* const showPopconfirmShare = () => {
    setVisibleShareIndex(visibleShareIndex === visibleIndex ? null : visibleIndex);
    setVisibleIndex(null);
  }; */

  const handleCancelEdit = () => {
    setVisibleEditIndex(null);
  };

  const handleCancelDelete = () => {
    setVisibleDeleteIndex(null);
  };

  /* const handleCancelShare = () => {
    setVisibleShareIndex(null);
  }; */

  const content = (
    <div>
      <div onClick={showPopconfirmDelete} style={{ fontSize: "15px", color: "#F56666", cursor: "pointer" }}><img src={DeleteIcon} height={15}></img> Supprimer</div>
      <div style={{ height: "5px", width: "100%", margin: "0px auto" }}></div>
      {/* <div onClick={showPopconfirmShare} style={{ fontSize: "15px", color: "#707070", cursor: "pointer" }}><img src={ShareIcon} height={15}></img> Partager</div>
      <div style={{ height: "5px", width: "100%", margin: "0px auto" }}></div> */}
      <div onClick={showPopconfirmEdit} style={{ fontSize: "15px", color: "#707070", cursor: "pointer" }}><img src={EditIcon} height={15}></img> Renommer</div>
    </div>
  );
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    setTimeout(() => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        if (visibleDeleteIndex || visibleEditIndex) {
          console.log("Action selected!");
        } else {
          setVisibleIndex(null);
        }
      }
    }, 200);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visibleDeleteIndex, visibleEditIndex]);
  /// Chat AI

  useEffect(() => {
    // Fetch topics from API on component mount
    fetchTopics();
  }, []);

  useEffect(() => {
    if (isBotCalled) {
      setDrawerVisible(true);
    }
  }, [isBotCalled]);

  const fetchTopics = async () => {
    try {
      let matieres: any = {
        _id: 'auto',
        description: 'Auto',
      }
      const response = await axios.get(`${API_RESSOURCES}/courses?levelId=${user.levelId}`);
      const newArray = [matieres].concat(response.data['data'])
      setTopics(newArray);
    } catch (error) {
      // Handle error if needed
    }
  };

  const handleTopicClick = (topicId: any) => {
    setSelectedTopic(topicId); // Update selected topic state on button click
  };

  useEffect(() => {
    if (isLoggedIn && user.roles.includes("student")) {
      fetchConversations()
    }
  }, []);

  useEffect(() => {
    setInputValue('');
    handleSendMessage("");
  }, [suggestedQuestionsClicked]);

  useEffect(() => {
    scrollToBottom();
  }, [currentConversationIndex]);

  useEffect(() => {
    scrollToBottom();
  }, [conversations]);

  useEffect(() => {
    groupConversations();
  }, [userConversations]);

  const handleIconClick = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
    dispatch(setIsBotCalled(false));
  };

  const handleShowCoursClick = async (chapterId: any) => {
    const responseChapter = await axios.get(
      `${API_RESSOURCES}/chapters/${chapterId}`
    );
    if (responseChapter?.data) {
      setCoursFetched(responseChapter?.data['data'])
    }
    setDrawerCoursVisible(true);
  };

  const handleShowCoursCloseDrawer = () => {
    setDrawerCoursVisible(false);
  };

  const handleShowQuizzClick = async (quizzId: any) => {
    setQuizzId(quizzId)
    setDrawerQuizzVisible(true);
  };

  const handleShowQuizzCloseDrawer = () => {
    setDrawerQuizzVisible(false);
    setQuizzId(null)
  };

  const handleShowExerciceClick = (exerciceId: any) => {
    setExerciceId(exerciceId)
    setDrawerExerciceVisible(true);
  };

  const handleShowExerciceCloseDrawer = () => {
    setDrawerExerciceVisible(false);
    setExerciceId(null)
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const getMessages = async (indexConv: any): Promise<any> => {
    try {
      const idConv = indexConv;
      let response = await axios.get(
        `${API_RESSOURCES}/openai/ask/user/convresastions/getOne/${idConv}`
      );

      if (response?.data) {
        if (response?.data['data'].length !== 0) {
          if (response?.data['data'].length === 2) {
            return []; // Return an empty array if length is 2
          }
          if (response?.data['data'].length > 2) {
            return response?.data['data'].slice(2); // Return sliced array if length > 2
          }
          return response.data['data']; // Return original data if other conditions don't apply
        } else {
          // Handle case where data is empty
          message.warning({
            content: "getMessages: Data is empty.",
          });
          return []; // Return empty array if data is empty
        }
      } else {
        // Handle case where response.data is undefined or null
        message.warning({
          content: "getMessages: An error occurred! Please try again later.",
        });
        return []; // Return empty array in case of error
      }
    } catch (error) {
      // Handle any errors that occur during the axios call
      message.warning({
        content: "getMessages: An error occurred! Please try again later.",
      });
      return []; // Return empty array in case of error
    }
  };


  const fetchConversations = () => {
    (async () => {
      try {
        const response = await axios.get(
          `${API_RESSOURCES}/openai/ask/user/convresastions/getAll/${user._id}`
        );

        if (response?.data) {
          setUserConversations(response?.data['data']);
          if (response?.data['data'].length !== 0) {
            let apiConvs: any = []
            for (let index = 0; index < response?.data['data'].length; index++) {
              let conv = {
                messages: [],
                title: response?.data['data'][index].title,
                id: response?.data['data'][index]._id
              };
              if (index === response?.data['data'].length - 1) {
                const messages = await getMessages(response?.data['data'][index]._id)
                conv.messages = messages
                if (messages.length === 0) {
                  handle3Questions(true);
                  setShowSuggestedQuestions(true);
                }
              }
              apiConvs.push(conv)
            }

            setConversations(apiConvs);
            setCurrentConversationIndex(apiConvs.length - 1);
          } else {
            const idConv: any = await handle3Questions(false);
            const newConversation = {
              messages: [],
              title: 'Nouvelle conversation',
              id: idConv
            };
            setConversations([...conversations, newConversation]);
            setCurrentConversationIndex(conversations.length);
            setShowSuggestedQuestions(true);
          }
        }
        else {
          message.warning({
            content: "fetch conversations: An error occurred! Please try again later.",
          });
        }
      } catch (error) {
        message.warning({
          content: "fetch conversations 2: An error occurred! Please try again later.",
        });
      }
    })();
  }

  const formatTextWithLineBreaks = (text: any) => {
    return text.replace(/(\.)(?=\s|$)/g, '.$1<br />');
  };

  const deleteConversation = (idDelete: any) => {
    (async () => {
      try {
        const response = await axios.delete(
          `${API_RESSOURCES}/openai/ask/convresastions/${idDelete}`
        );

        if (response?.data) {
          //delete the exact same conversation from local
          let updatedConversations = [...conversations];
          let newConvs: any = []
          for (let index = 0; index < updatedConversations.length; index++) {
            if (updatedConversations[index].id !== idDelete) {
              newConvs.push(updatedConversations[index])
            }
          }

          // Filter out the conversation with the specified _id
          const updatedUserConversations = userConversations.filter(
            (conversation: any) => conversation._id !== idDelete
          );

          if (newConvs.length !== 0) {
            setCurrentConversationIndex(newConvs.length - 1);
            setConversations(newConvs);
            setUserConversations(updatedUserConversations);
          } else {
            const idConv: any = await handle3Questions(false);
            const newConversation = {
              messages: [],
              title: 'Nouvelle conversation',
              id: idConv
            };
            const newUserConversation: Conversation = {
              _id: idConv,
              title: "Nouvelle conversation",
              createdAt: dayjs().toISOString(),
            };
            setUserConversations(newUserConversation);
            setConversations([...newConvs, newConversation]);
            setCurrentConversationIndex(conversations.length - 1);
            setShowSuggestedQuestions(true);
            setSuggestedQuestionsClicked(false);
          }

          message.success({
            content: "delete conversation: success",
          });
        }
        else {
          message.warning({
            content: "delete conversation: An error occurred! Please try again later.",
          });
        }
      } catch (error) {
        message.warning({
          content: "delete conversation 2: An error occurred! Please try again later.",
        });
      }
      finally {
        setVisibleDeleteIndex(null);
      }
    })();
  }

  const updateConversation = (idUpdate: any, text: any) => {
    (async () => {
      try {
        //trim the text first
        const textTrim = text?.substring(0, 30) + '...'
        const response = await axios.patch(
          `${API_RESSOURCES}/openai/ask/convresastions/${idUpdate}`, {
          text: textTrim
        }
        );

        if (response?.data) {
          //update the exact same conversation from local
          let updatedConversations = [...conversations];
          for (let index = 0; index < updatedConversations.length; index++) {
            if (updatedConversations[index].id === idUpdate) {
              updatedConversations[index].title = textTrim
            }
          }
          setConversations(updatedConversations);

          message.success({
            content: "update conversation: success",
          });
        }
        else {
          message.warning({
            content: "update conversation: An error occurred! Please try again later.",
          });
        }
      } catch (error) {
        message.warning({
          content: "update conversation 2: An error occurred! Please try again later.",
        });
      }
      finally {
        setVisibleEditIndex(null);
      }
    })();
  }

  const fetchConversation = (index: any) => {
    (async () => {
      try {
        const idConv = conversations[index].id;
        let messages: any = []
        if (idConv !== '') {
          messages = await getMessages(idConv);
        }

        // Update messages for the selected conversation
        let updatedConversations = [...conversations];
        updatedConversations[index].messages = messages;
        setConversations(updatedConversations);
        setCurrentConversationIndex(index); // Ensure the correct index is set
      } catch (error) {
        // Handle error
        message.warning({
          content: "An error occurred while fetching the conversation.",
        });
      }
    })();
  };


  const handle3Questions = async (exists: any): Promise<any> => {
    try {
      const response = await axios.post(
        `${API_RESSOURCES}/openai/ask/initial/getQuestions`,
        {
          id: user._id,
          exists: exists
        }
      );

      if (response?.data) {
        setSuggestedQuestions(response?.data['data']['suggestedQuestionsRender']);
        return response?.data['data']['idConv']; // Return idConv here
      } else {
        message.warning({
          content: "An error occurred! Please try again later.",
        });
        return ''; // Return empty string or handle error case
      }
    } catch (error) {
      message.warning({
        content: "An error occurred! Please try again later.",
      });
      return ''; // Return empty string or handle error case
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        // Only accept image files
        if (file.type.startsWith('image/')) {
          setSelectedFile(file);
          // Create preview URL safely
          const url = URL.createObjectURL(file);
          setPreviewUrl(url);
        } else {
          message.error('Please select an image file');
        }
      } catch (error) {
        message.error('Error processing the image');
        // Clear the selection
        setSelectedFile(null);
        setPreviewUrl(null);
      }
    }

    // Clear the input value to allow selecting the same file again
    if (event.target) {
      event.target.value = '';
    }
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
  };

  const handleSendMessage = (question?: string) => {
    if ((inputValue.trim() !== '' || selectedFile || question?.trim() !== '') && !isWaitingResponse) {
      setShowLoaderNewConversation(true);
      setIsWaitingResponse(true); // Disable send button

      const newMessage = {
        text: question?.trim() !== '' ? question : inputValue,
        sender: 'user',
        image: previewUrl
      };

      let updatedConversations = [...conversations];
      if (updatedConversations[currentConversationIndex]?.messages.length === 0) {
        updatedConversations[currentConversationIndex].title = question?.trim() !== '' ? question?.substring(0, 30) + '...' : inputValue?.substring(0, 30) + '...'
      }
      updatedConversations[currentConversationIndex]?.messages.push(newMessage);
      setConversations(updatedConversations);
      setInputValue('');

      let accumulatedText = '';
      let botMessage = {
        text: '',
        sender: 'bot',
        _id: '',
        chapterId: '',
        chapterContent: {},
        quizzId: '',
        exerciceId: '',
      };

      (async () => {
        try {
          const formData = new FormData();
          formData.append('id', updatedConversations[currentConversationIndex].id);
          formData.append('question', question?.trim() !== '' ? question : inputValue);
          formData.append('special', selectedTopic);
          formData.append('idUser', user._id);
          formData.append('regenerate', question?.trim() !== '' ? "true" : "false");

          if (selectedFile) {
            formData.append('file', selectedFile);
          }

          const response = await fetch(`${API_RESSOURCES}/openai/ask`, {
            method: 'POST',
            body: formData
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const reader = response.body?.getReader();
          if (!reader) throw new Error('No reader available');

          // Add bot message to conversation immediately
          let currentConversations = [...conversations];
          currentConversations[currentConversationIndex]?.messages.push(botMessage);
          setConversations(currentConversations);

          setShowLoaderNewConversation(false);

          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              if (selectedFile) {
                fetchConversation(currentConversationIndex);
              }
              setIsWaitingResponse(false); // Re-enable send button
              break;
            }

            const chunk = new TextDecoder().decode(value);
            const lines = chunk.split('\n');

            for (const line of lines) {
              if (line.trim() === '') continue;

              try {
                if (line.startsWith('event: chunk')) {
                  continue;
                }

                if (line.startsWith('data:')) {
                  const jsonData = JSON.parse(line.slice(5));

                  if (jsonData.error) {
                    throw new Error(jsonData.error);
                  }

                  if (jsonData.token) {
                    accumulatedText += jsonData.token;

                    let currentConversations = [...conversations];
                    const lastMessageIndex = currentConversations[currentConversationIndex]?.messages.length - 1;
                    currentConversations[currentConversationIndex].messages[lastMessageIndex].text = accumulatedText;
                    setConversations(currentConversations);
                  } else if (jsonData.statusCode === 201) {
                    const finalData = jsonData.data;

                    let finalConversations = [...conversations];
                    const lastIndex = finalConversations[currentConversationIndex]?.messages.length - 1;
                    finalConversations[currentConversationIndex].messages[lastIndex] = {
                      ...finalConversations[currentConversationIndex]?.messages[lastIndex],
                      _id: finalData.idMessage,
                      chapterId: finalData.chapterId,
                      quizzId: finalData.quizzId,
                      exerciceId: finalData.exerciceId,
                    };

                    if (finalData.chapterId) {
                      try {
                        const responseChapter = await axios.get(
                          `${API_RESSOURCES}/chapters/${finalData.chapterId}`
                        );
                        if (responseChapter?.data?.data?.contents?.length > 0) {
                          finalConversations[currentConversationIndex].messages[lastIndex].chapterContent =
                            responseChapter.data.data.contents[0];
                          setConversations(finalConversations);
                        }
                      } catch (error) {
                      }
                    } else {
                      setConversations(finalConversations);
                    }
                  }
                }
              } catch (parseError) {
                message.error('Une erreur est survenue lors du traitement de la réponse');

                // Remove the bot message if there's an error
                let errorConversations = [...conversations];
                errorConversations[currentConversationIndex]?.messages.pop();
                setConversations(errorConversations);
                break;
              }
            }
          }
        } catch (error) {
          message.error('Une erreur est survenue lors de la communication avec le serveur');

          // Remove the bot message if there's an error
          let errorConversations = [...conversations];
          errorConversations[currentConversationIndex]?.messages.pop();
          setConversations(errorConversations);
          setIsWaitingResponse(false); // Re-enable send button on error
        } finally {
          clearSelectedFile();
          setShowLoaderNewConversation(false);
        }
      })();

      setSuggestedQuestionsClicked(false);
    }
  };

  const regenerateMessage = (question: string) => {
    // Reuse the same streaming logic as handleSendMessage
    if (question.trim() !== '') {
      handleSendMessage(question);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToBottomConversations = () => {
    if (conversationsEndRef.current) {
      conversationsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNewConversation = async () => {
    try {
      setShowLoaderNewConversation(true);

      const idConv: any = await handle3Questions(false); // Await handle3Questions call

      const newConversation = {
        messages: [],
        title: 'Nouvelle conversation',
        id: idConv
      };

      const newUserConversation: Conversation = {
        _id: idConv,
        title: "Nouvelle conversation",
        createdAt: dayjs().toISOString(),
      };
      setUserConversations((prev: any) => [...prev, newUserConversation]);

      setConversations([...conversations, newConversation]);
      setCurrentConversationIndex(conversations.length);
      setInputValue('');
      setShowSuggestedQuestions(true);
      setSuggestedQuestionsClicked(false);

      setShowLoaderNewConversation(false);

      scrollToBottomConversations()
    } catch (error) {
      // Handle error if handle3Questions fails
      message.warning({
        content: "An error occurred! Please try again later.",
      });
      setShowLoaderNewConversation(false);
    }
  };


  const handleQuestionSelection = (question: any) => {
    setInputValue(question);
    setShowSuggestedQuestions(false);
    setSuggestedQuestionsClicked(true)
  };

  const renderSuggestedQuestions = () => {
    if (showSuggestedQuestions && conversations[currentConversationIndex]?.messages.length === 0) {
      return (
        <div style={{ padding: '10px', overflowY: 'auto', position: 'sticky', marginBottom: '60px' }}>
          <strong>Suggested Questions:</strong>
          <ul style={{ listStyleType: 'none', paddingInlineStart: '0', marginTop: '10px' }}>
            {suggestedQuestions.map((question: any, index: any) => (
              <li
                key={index}
                style={{
                  cursor: 'pointer',
                  marginBottom: '8px',
                  padding: '10px',
                  borderRadius: '5px',
                  backgroundColor: '#f0f0f0',
                  transition: 'background-color 0.3s ease',
                }}
                onClick={() => handleQuestionSelection(question)}
                onMouseEnter={(e: any) => { e.target.style.backgroundColor = '#e6e6e6'; }}
                onMouseLeave={(e: any) => { e.target.style.backgroundColor = '#f0f0f0'; }}
              >
                {question}
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };


  const renderMessages = () => {
    if (currentConversationIndex !== null && conversations.length > 0) {
      return conversations[currentConversationIndex]?.messages.map((message: any, index: any) => (
        <List.Item key={index} style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: message.sender === 'user' ? 'flex-end' : 'flex-start',
          width: '100%'
        }}>
          <div style={{
            maxWidth: '70%',
            borderRadius: '10px',
            padding: '16px',
            backgroundColor: message.sender === 'user' ? '#e6f7ff' : '#f0f0f0',
          }}>
            {message.sender === 'user' ? (
              <div>{message.text}</div>
            ) : (
              <div
                className={`${st.mathContent}`}
                dangerouslySetInnerHTML={renderMathContent(message.text)}
                style={{
                  fontSize: '14px',
                  lineHeight: '1.6',
                }}
              />
            )}
            {message.image && (
              <div style={{ marginTop: '8px' }}>
                <img
                  src={message.image}
                  alt="Attached"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '200px',
                    borderRadius: '5px'
                  }}
                />
              </div>
            )}
          </div>
        </List.Item>
      ));
    }
    return null;
  };

  const renderConversations = () => (
    conversations.map((conversation: any, index: any) => (
      <List.Item
        key={index}
        onClick={() => setCurrentConversationIndex(index)}
        style={{ cursor: 'pointer' }}
      >
        Conversation {index + 1}
      </List.Item>
    ))
  );

  const handleCopy = async (text: string) => {
    try {
      const plainText = text.replace(/<[^>]*>/g, "");
      await navigator.clipboard.writeText(plainText);
      message.success("Texte copié dans le clipboard!");
    } catch (err) {
      message.error("Erreur survenue lors de la copie du text.");
    }
  };

  const handleRegenerate = async (index: number) => {
    const question = conversations[currentConversationIndex].messages[index]?.text;
    regenerateMessage(question);
  }

  const handleShare = async (text: string) => {

  }

  return (
    <div className={s.footerFooter}>
      {isMobile ? <div className={s.footerBox}>
        <div className={s.footerBoxTop}>
          <div className={s.footerBoxTopMain}>
            <div className={s.logoContainer}>
              {!isMobile && <div className={s.logoBox}>
                <img src={albert_head} style={{ height: "85px" }}></img>
              </div>}
              <a className={s.logoTitle}>MON AMI<br />ALBERT</a>
            </div>
            <p className={s.footerMessage}>La réussite et l’excellence académiques<br /> à portée de tous!</p>
            <p className={s.followUsTitle}>Suivez-nous</p>
            <div className={s.socialBox}>
              <img src={icon_facebook} style={{ height: "21px", marginRight: "30px" }}></img>
              <img src={icon_instagram} style={{ height: "21px", marginRight: "30px" }}></img>
              <img src={icon_linkedin} style={{ height: "21px", marginRight: "30px" }}></img>
              <img src={icon_youtube} style={{ height: "21px" }}></img>
            </div>
          </div>
          <div className={s.footerBoxTopMain}>
            <p className={s.menuTitle}>Explorer</p>
            {isMobile ? <div className={s.menuBox}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: "40px" }}>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Qui Suis-je ?</a>
                <a href="/parent" style={{ color: "rgb(204, 204, 204)" }}>Parent</a>
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: "40px" }}>
                <a href="/teacher" style={{ color: "rgb(204, 204, 204)" }}>Enseignant</a>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Méthodologie</a>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Contact</a>
                <a href="/faq" style={{ color: "rgb(204, 204, 204)" }}>Aide</a>
              </div>
            </div> : <div className={s.menuBox}>
              <a href="#" style={{ color: "rgb(204, 204, 204)" }}>QUI SUIS-JE ?</a>
              <a href="/parent" style={{ color: "rgb(204, 204, 204)" }}>PARENT</a>
              <a href="/teacher" style={{ color: "rgb(204, 204, 204)" }}>ENSEIGNANT</a>
              <a href="#" style={{ color: "rgb(204, 204, 204)" }}>MÉTHODOLOGIE</a>
              <a href="#" style={{ color: "rgb(204, 204, 204)" }}>CONTACT</a>
              <a href="/faq" style={{ color: "rgb(204, 204, 204)" }}>AIDE</a>
            </div>}
          </div>
          <div className={s.footerBoxTopMain}>
            <p className={s.menuTitle}>Newsletter</p>
            <p className={s.footerMessage}>Inscrivez-vous à notre newsletter<br />pour découvrir des astuces pédagogiques,<br />accéder à des ressources exclusives,<br />suivre nos consignes et rester<br />informé des dernières nouveautés.</p>
            <div className={s.formBox}>
              <form className={s.newslettersForm}>
                <input className={s.newslettersFormEmail} type="email" id="newsletterEmail" name="newsletterEmail" placeholder="Entrez votre email ici" required></input>
                <button type="submit" className={s.newslettersButton}>Je m’inscris</button>
              </form>
            </div>
          </div>
        </div>
        <div className={s.footerBoxBottom}>
          <div className={s.footerSeparator}></div>
          <div className={s.footerBottomBox}>
            <a className={s.footerCopyrights}>Ⓒ Mon ami Albert 2024  </a>
            <div className={s.copyrightsSeparator}></div>
            <a className={s.legalMention}> Mentions légales</a>
          </div>
        </div>
      </div> : <Container>
        <div className={s.footerBox}>
          <div className={s.footerBoxTop}>
            <div className={s.footerBoxTopMain}>
              <div className={s.logoContainer}>
                {!isMobile && <div className={s.logoBox}>
                  <img src={albert_head} style={{ height: "85px" }}></img>
                </div>}
                <a className={s.logoTitle}>MON AMI<br />ALBERT</a>
              </div>
              <p className={s.footerMessage}>La réussite et l’excellence académiques<br /> à portée de tous!</p>
              <p className={s.followUsTitle}>Suivez-nous</p>
              <div className={s.socialBox}>
                <img src={icon_facebook} style={{ height: "21px", marginRight: "30px" }}></img>
                <img src={icon_instagram} style={{ height: "21px", marginRight: "30px" }}></img>
                <img src={icon_linkedin} style={{ height: "21px", marginRight: "30px" }}></img>
                <img src={icon_youtube} style={{ height: "21px" }}></img>
              </div>
            </div>
            <div className={s.footerBoxTopMain}>
              <p className={s.menuTitle}>Explorer</p>
              {isMobile ? <div className={s.menuBox}>
                <div style={{ display: "flex", flexDirection: "column", marginRight: "40px" }}>
                  <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Qui Suis-je ?</a>
                  <a href="/parent" style={{ color: "rgb(204, 204, 204)" }}>Parent</a>
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginRight: "40px" }}>
                  <a href="/teacher" style={{ color: "rgb(204, 204, 204)" }}>Enseignant</a>
                  <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Méthodologie</a>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Contact</a>
                  <a href="/faq" style={{ color: "rgb(204, 204, 204)" }}>Aide</a>
                </div>
              </div> : <div className={s.menuBox}>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>QUI SUIS-JE ?</a>
                <a href="/parent" style={{ color: "rgb(204, 204, 204)" }}>PARENT</a>
                <a href="/teacher" style={{ color: "rgb(204, 204, 204)" }}>ENSEIGNANT</a>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>MÉTHODOLOGIE</a>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>CONTACT</a>
                <a href="/faq" style={{ color: "rgb(204, 204, 204)" }}>AIDE</a>
              </div>}
            </div>
            <div className={s.footerBoxTopMain}>
              <p className={s.menuTitle}>Newsletter</p>
              <p className={s.footerMessage}>Inscrivez-vous à notre newsletter<br />pour découvrir des astuces pédagogiques,<br />accéder à des ressources exclusives,<br />suivre nos consignes et rester<br />informé des dernières nouveautés.</p>
              <div className={s.formBox}>
                <form className={s.newslettersForm}>
                  <input className={s.newslettersFormEmail} type="email" id="newsletterEmail" name="newsletterEmail" placeholder="Entrez votre email ici" required></input>
                  <button type="submit" className={s.newslettersButton}>Je m’inscris</button>
                </form>
              </div>
            </div>
          </div>
          <div className={s.footerBoxBottom}>
            <div className={s.footerSeparator}></div>
            <div className={s.footerBottomBox}>
              <a className={s.footerCopyrights}>Ⓒ Mon ami Albert 2024  </a>
              <div className={s.copyrightsSeparator}></div>
              <a className={s.legalMention}> Mentions légales</a>
            </div>
          </div>
        </div>
      </Container>}

      {!isMobile && <div>
        {user?.roles?.includes("student")/*  || user?.roles?.includes("teacher") */ ? (
          <Tooltip title="Chat avec l'IA Albert">
            <Button
              type="link"
              shape="circle"
              style={{ position: 'fixed', bottom: 20, right: 60, background: "#FFF", border: "2px solid #8AB0B1", display: "flex", alignItems: "center", justifyContent: "center", height: "60px", width: "60px" }}
              onClick={handleIconClick}
            >
              <img src={AlbertAI} height={45}></img>
            </Button>
          </Tooltip>
        ) : (
          <></>
        )}

        <Drawer
          /* title="Chat with Assistant" */
          placement="right"
          width="95%"
          closable={false}
          onClose={handleCloseDrawer}
          visible={drawerVisible}
          headerStyle={{ backgroundColor: "#FAFAFA" }}
          drawerStyle={{ backgroundColor: "#FAFAFA" }}
        >
          <div style={{ height: '100%', display: 'flex', justifyContent: "space-between" }}>
            {/* Left Box */}
            <div style={{ width: visibleMenu ? '20%' : "10%", padding: '10px' }}>
              {/* Top Buttons */}
              <div style={{ marginLeft: visibleMenu ? '0%' : "30%" }}>
                <Button className={st.menuButton} onClick={() => setVisibleMenu(!visibleMenu)}>
                  <img src={MenuBars} height={20}></img>
                </Button>
              </div>
              {visibleMenu && <Button
                className={st.newConversationButton}
                icon={<PlusOutlined />}
                onClick={handleNewConversation}
              >
                New Conversation
              </Button>}
              {!visibleMenu && <Button
                type="primary"
                shape="circle"
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "40px", width: "40px", marginLeft: visibleMenu ? '0%' : "25%" }}
                icon={<PlusOutlined />}
                onClick={handleNewConversation}
              >
              </Button>}

              {visibleMenu && <div className={st.lineSeparator}></div>}

              {/* Chat History */}
              <div style={{ height: '50vh', overflowY: 'auto' }}>
                {/* {visibleMenu && <List
                  dataSource={conversations}
                  renderItem={(item: any, index: any) => (
                    <>
                      <div key={index} style={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', marginTop: '10px', fontSize: '16px', paddingTop: '4px',
                        paddingBottom: '7px',
                        paddingRight: '16px',
                        paddingLeft: '0px',
                      }}
                        onMouseEnter={(e: any) => { e.target.style.color = '#7FB1B2'; }}
                        onMouseLeave={(e: any) => { e.target.style.color = '#707070'; }}
                      >
                        <div
                          onClick={() => { setShowSuggestedQuestions(true); setCurrentConversationIndex(index); fetchConversation(index); }}
                          style={{ cursor: 'pointer', width: "90%", color: index === currentConversationIndex ? '#7FB1B2' : '#707070', display: "flex", alignItems: "center", justifyContent: "flex-start" }}
                        >
                          <img src={MessageIcon} height={15} style={{ marginRight: "10px", filter: index === currentConversationIndex ? "invert(42%) sepia(23%) saturate(300%) hue-rotate(140deg) brightness(90%) contrast(85%)" : "invert(37%) sepia(4%) saturate(0%) hue-rotate(200deg) brightness(87%) contrast(89%)" }}></img>
                          {item ? item.title : 'Nouvelle conversation'}
                        </div>

                        <Popconfirm
                          title={
                            <Input
                              placeholder="Entre un titre"
                              value={textInput}
                              onChange={handleTextInputChange}
                            />
                          }
                          visible={visibleEditIndex === index}
                          onConfirm={() => updateConversation(item.id, textInput?.substring(0, 30) + '...')}
                          onCancel={handleCancelEdit}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <Button style={{ visibility: "hidden" }}></Button>
                        </Popconfirm>
                        <Popconfirm
                          title="Veux-tu supprimer cette conversation ?"
                          visible={visibleDeleteIndex === index}
                          onConfirm={() => deleteConversation(item.id)}
                          onCancel={handleCancelDelete}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <Button style={{ visibility: "hidden" }}></Button>
                        </Popconfirm>
                        <Popover
                          key={index}
                          visible={visibleIndex === index}
                          placement="bottom"
                          content={content}
                          trigger="click"
                          style={{ height: "70px !important", borderRadius: "8px", boxShadow: "4px 8px 16px #8B8B8B38", border: "0.5px solid #BFBCBC" }}
                          className={s.customPopover}
                          overlayClassName={s.customPopover}
                        >
                          <div ref={popoverRef} style={{ height: "20px", width: "25px" }} onClick={(e: any) => { togglePopover(index); }}><img src={MoreOptions} height={4} ></img></div>
                        </Popover>
                      </div>
                      <Popconfirm
                        title="Veux-tu partager cette conversation ?"
                        visible={visibleShareIndex === index}
                        onConfirm={() => deleteConversation(item.id)}
                        onCancel={handleCancelShare}
                        okText="Oui"
                        cancelText="Non"
                      >
                        <Button style={{ display: "none" }}></Button>
                      </Popconfirm>
                    </>
                  )}
                />} */}
                {visibleMenu && Object.entries(groupedData).map(([group, items], groupIndex) => (
                  <List
                    key={group}
                    header={<div style={{ fontWeight: "500", fontSize: "12px", color: "#707070" }}>{group === "today" ? "Aujourd’hui" : "7 jours précédents"}</div>}
                    dataSource={items}
                    renderItem={(item: any, index: any) => {
                      const globalIndex = groupIndex * 1000 + index;
                      return (
                        <div
                          key={globalIndex}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "10px",
                            fontSize: "16px",
                            paddingTop: '4px',
                            paddingBottom: '7px',
                            paddingRight: '16px',
                            paddingLeft: '5px',
                            border: item.originalIndex === currentConversationIndex ? "1px solid #7FB1B2" : "none",
                            borderRadius: "5px"
                          }}
                          onMouseEnter={(e: any) => { e.target.style.color = '#7FB1B2'; }}
                          onMouseLeave={(e: any) => { e.target.style.color = '#707070'; }}
                        >
                          <div
                            onClick={() => { setShowSuggestedQuestions(true); setCurrentConversationIndex(item.originalIndex); fetchConversation(item.originalIndex); }}
                            style={{ cursor: 'pointer', width: "90%", color: item.originalIndex === currentConversationIndex ? '#7FB1B2' : '#707070', display: "flex", alignItems: "center", justifyContent: "flex-start" }}
                          >
                            <img src={MessageIcon} height={15} style={{ marginRight: "10px", filter: item.originalIndex === currentConversationIndex ? "invert(42%) sepia(23%) saturate(300%) hue-rotate(140deg) brightness(90%) contrast(85%)" : "invert(37%) sepia(4%) saturate(0%) hue-rotate(200deg) brightness(87%) contrast(89%)" }}></img>
                            {item ? item.title : 'Nouvelle conversation'}
                          </div>
                          <Popconfirm
                            title={
                              <Input
                                placeholder="Entre un titre"
                                value={textInput}
                                onChange={handleTextInputChange}
                              />
                            }
                            visible={visibleEditIndex === globalIndex}
                            onConfirm={() => updateConversation(item.id, textInput?.substring(0, 30) + '...')}
                            onCancel={handleCancelEdit}
                            okText="Oui"
                            cancelText="Non"
                          >
                            <Button style={{ visibility: "hidden" }}></Button>
                          </Popconfirm>
                          <Popconfirm
                            title="Veux-tu supprimer cette conversation ?"
                            visible={visibleDeleteIndex === globalIndex}
                            onConfirm={() => deleteConversation(item._id)}
                            onCancel={handleCancelDelete}
                            okText="Oui"
                            cancelText="Non"
                          >
                            <Button style={{ visibility: "hidden" }}></Button>
                          </Popconfirm>
                          <Popover
                            key={index}
                            visible={visibleIndex === globalIndex}
                            placement="bottom"
                            content={content}
                            trigger="click"
                            style={{ height: "70px !important", borderRadius: "8px", boxShadow: "4px 8px 16px #8B8B8B38", border: "0.5px solid #BFBCBC" }}
                            className={s.customPopover}
                            overlayClassName={s.customPopover}
                          >
                            <div ref={popoverRef} style={{ height: "20px", width: "25px" }} onClick={(e: any) => { togglePopover(globalIndex); }}><img src={MoreOptions} height={4} ></img></div>
                          </Popover>
                        </div>);
                    }}
                  />
                ))}
                <div ref={conversationsEndRef} />
              </div>

              {visibleMenu && <div className={st.lineSeparator}></div>}

              {/* Save & Exit buttons */}
              {/* {visibleMenu && <Button className={st.actionButton}>
                <img src={SaveIcon} height={15} style={{ marginRight: "10px" }}></img>
                Enregistrement
              </Button>} */}
              {visibleMenu && <Button className={st.actionButton} onClick={handleCloseDrawer}>
                <img src={ExitIcon} height={15} style={{ marginRight: "10px" }}></img>
                Sortie
              </Button>}
              {!visibleMenu && <Button
                type="primary"
                shape="circle"
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "40px", width: "40px", marginLeft: visibleMenu ? '0%' : "25%" }}
                onClick={handleCloseDrawer}
              >
                <img src={ExitIcon} height={15} style={{ filter: "invert(1) brightness(2)", marginRight: "0px" }}></img>
              </Button>}

              {/* User Avatar */}
              <div className={visibleMenu ? st.userAvatarBox : st.userAvatarBoxMenuClosed} style={{ marginLeft: visibleMenu ? '0%' : "15%" }}>
                <img src={user?.profile} height={50} style={{ borderRadius: "50%", marginRight: visibleMenu ? "10px" : "0px", border: "1px solid #707070" }}></img>
                {visibleMenu && <div className={st.userNameBox}>
                  {user?.firstname + " " + user?.lastname}
                  <span>{user?.roles.includes("student") ? "Étudiant" : "Professeur"}</span>
                </div>}
              </div>
            </div>

            {/* Right Box */}
            <div className={visibleMenu ? st.chatBox : st.chatBoxMenuClosed}>
              {/* Chat Box */}
              {conversations[currentConversationIndex]?.messages.length !== 0 && <div className={previewUrl ? st.messagesBoxImagePreview : st.messagesBox}>
                <List
                  dataSource={currentConversationIndex !== null ? conversations[currentConversationIndex].messages : []}
                  renderItem={(item: any, index: any) => (
                    <List.Item key={index} style={{ display: 'flex', justifyContent: item.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                      <div style={{ display: 'flex', flexDirection: "column" }}>
                        {item.sender !== 'user' && <div className={st.albertAvatar}>
                          <img src={AlbertAI} height={40}></img>
                        </div>}
                        {item.sender === 'user' && <div className={st.userAvatar}>
                          <img src={user?.profile} height={40}></img>
                        </div>}
                        <div style={{ minWidth: "30vw", width: '85%', padding: '20px', backgroundColor: item.sender === 'user' ? '#FAFAFA' : '#FAFAFA', border: "1px solid #EAEAEA", borderRadius: item.sender === 'user' ? "24px 24px 5px 24px" : "4px 24px 24px 24px", marginLeft: item.sender === 'user' ? "0px" : "45px", marginRight: item.sender === 'user' ? "50px" : "0px" }}>
                          {/* Display text if exists */}
                          {item.text && (
                            <div
                              dangerouslySetInnerHTML={{ __html: item.text }}
                            />
                          )}

                          {/* Display image if fileUrl exists */}
                          {item.fileUrl && (
                            <div style={{ marginTop: item.text ? '10px' : '0' }}>
                              <img
                                src={item.fileUrl}
                                alt="Uploaded content"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '300px',
                                  borderRadius: '5px',
                                  objectFit: 'contain'
                                }}
                              />
                            </div>
                          )}

                          {/* Rest of the conditional renders for chapterId, quizzId, etc. */}
                          {(item?.chapterId && item?.chapterId !== '') && (
                            <>
                              <div><br /></div>

                              <div style={{ marginBottom: '15px' }} >Voici des suggestions de nos cours, suite à notre discussion :</div>

                              <div key={item._id} className={sStyle.__body}>
                                <div className={sStyle.__big__title__content}>
                                  <h3 className={sStyle.__big__title}>
                                    {item?.chapterContent?.title}
                                  </h3>
                                </div>

                                <div className={sStyle.appercue__continute__content}>
                                  {item?.chapterContent?.subtitles?.slice(0, 3).map((x: any) => (
                                    <div key={x._id}>
                                      <h4>{x?.title}</h4>
                                      {x?.subtitles?.slice(0, 3).map((itemX: any) => (
                                        <>
                                          <div key={itemX._id} className={sStyle.section__content}>
                                            <h4>{itemX?.title}</h4>
                                          </div>
                                          <Latex>
                                            {itemX?.content
                                              ? itemX?.content.replace("amp;", "").replace("<br />", "")
                                              : ""}
                                          </Latex>
                                        </>
                                      ))}
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div style={{ marginTop: '40px', marginBottom: '10px', display: 'flex', justifyContent: 'space-around' }} >
                                <Button
                                  key={'chapterShowMore'}
                                  style={{
                                    fontSize: '14px',
                                    backgroundColor: '#FFF',
                                    color: '#4BB980',
                                    border: '1px solid #4BB980',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    paddingLeft: '118px',
                                    paddingRight: '118px',
                                    boxShadow: "0px 6px 11px #B9B9B939"
                                  }}
                                  onMouseEnter={(e: any) => { e.target.style.color = '#FFF'; e.target.style.backgroundColor = '#4BB980'; }}
                                  onMouseLeave={(e: any) => { e.target.style.backgroundColor = '#FFF'; }}
                                  onClick={() => handleShowCoursClick(item?.chapterId)}
                                >
                                  {'Consulter le cours'}
                                </Button>
                              </div>
                            </>
                          )}

                          {(item?.quizzId && item?.quizzId !== '') && (
                            <>
                              <div style={{ marginTop: '5px', marginBottom: '5px', display: 'flex', justifyContent: 'space-around' }} >
                                <Button
                                  key={'quizzShowMore'}
                                  style={{
                                    fontSize: '14px',
                                    backgroundColor: '#FFF',
                                    color: '#6A989A',
                                    border: '1px solid #8AB0B1',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    paddingLeft: '130px',
                                    paddingRight: '130px',
                                    boxShadow: "0px 6px 11px #B9B9B939"
                                  }}
                                  onMouseEnter={(e: any) => { e.target.style.color = '#FFF'; e.target.style.backgroundColor = '#6A989A'; }}
                                  onMouseLeave={(e: any) => { e.target.style.backgroundColor = '#FFF'; }}
                                  onClick={() => handleShowQuizzClick(item?.quizzId)}
                                >
                                  {'Faire des quiz'}
                                </Button>
                              </div>
                            </>
                          )}

                          {(item?.exerciceId && item?.exerciceId !== '') && (
                            <>
                              <div style={{ marginTop: '5px', marginBottom: '5px', display: 'flex', justifyContent: 'space-around' }} >
                                <Button
                                  key={'exerciceShowMore'}
                                  style={{
                                    fontSize: '14px',
                                    backgroundColor: '#FFF',
                                    color: '#EAA72D',
                                    border: '1px solid #EAA72D',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    paddingLeft: '118px',
                                    paddingRight: '120px',
                                    boxShadow: "0px 6px 11px #B9B9B939"
                                  }}
                                  onMouseEnter={(e: any) => { e.target.style.color = '#FFF'; e.target.style.backgroundColor = '#EAA72D'; }}
                                  onMouseLeave={(e: any) => { e.target.style.backgroundColor = '#FFF'; }}
                                  onClick={() => handleShowExerciceClick(item?.chapterId)}
                                >
                                  {'Faire des exercice'}
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                        {item.sender !== 'user' && <div className={item.sender === 'user' ? st.messageButtonRow : st.messageButtonRowAI}>
                          <div className={st.messageActionButton} onClick={() => handleCopy(item.text)}>
                            <img src={CopyIcon} height={10} style={{ marginRight: "10px" }}></img>
                            Copier
                          </div>
                          <div className={st.messageActionButton} onClick={() => handleRegenerate(item.sender === 'user' ? index : index - 1)}>
                            <img src={RegenerateIcon} height={10} style={{ marginRight: "10px" }}></img>
                            Régénérer la réponse
                          </div>
                          {/*  <div className={st.messageActionButton}>
                            <img src={ShareIcon} height={10} style={{ marginRight: "10px" }}></img>
                            Partager
                          </div> */}
                        </div>}
                      </div>
                    </List.Item>
                  )}
                />
                {showLoaderNewConversation && <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div style={{ display: 'flex', flexDirection: "column" }}>
                    {<div className={st.albertAvatar}>
                      <img src={AlbertAI} height={40}></img>
                    </div>}
                    <img src={AlbertTyping} height={70}></img>
                  </div>
                </div>}
                <div ref={messagesEndRef} />
              </div>}

              {/* Welcome & Topics Box */}
              {conversations[currentConversationIndex]?.messages.length === 0 && <div className={st.emptyChat}>
                <div className={st.emptyChatAlbert}>
                  <img src={AlbertAI} height={100}></img>
                  <div className={st.emptyChatAlbertMessage}>
                    Bienvenue !
                    <div className={st.messageSubtitle}>
                      Je suis ton assistant. Comment puis-je t'aider ?
                    </div>
                  </div>
                </div>

                <div className={st.topicsBox}>
                  {topics.map((topic: any) => (
                    <div key={topic._id} className={st.topicButton} onClick={() => handleTopicClick(topic.description)} style={{ backgroundColor: selectedTopic === topic.description ? '#1890ff' : '#FFF', color: selectedTopic === topic.description ? '#FFF' : '#393E3E' }}>
                      <AITopicIcon topic={topic.description} />
                      {topic.description}
                    </div>
                  ))}
                </div>
              </div>}

              {/*  Input Textarea */}
              <div className={visibleMenu ? st.inputBox : st.inputBoxClosedMenu}>
                <div className={visibleMenu ? st.inputTextarea : st.inputTextareaClosedMenu} style={{ height: previewUrl ? "200px !important" : "100px !important" }}>
                  {previewUrl && (
                    <div style={{
                      position: 'relative',
                      display: 'inline-block',
                      margin: '8px'
                    }}>
                      <img
                        src={previewUrl}
                        alt="Preview"
                        style={{
                          maxHeight: '100px',
                          maxWidth: '100px',
                          objectFit: 'contain',
                          borderRadius: "5px",
                        }}
                      />
                      <Button
                        type="text"
                        onClick={clearSelectedFile}
                        shape="circle"
                        style={{
                          height: "25px",
                          width: "25px",
                          position: 'absolute',
                          top: -8,
                          right: -8,
                          padding: 4,
                          minWidth: 'auto',
                          borderRadius: '50%',
                          backgroundColor: 'white',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <img src={DeleteIcon} height={18} alt="Remove" />
                      </Button>
                    </div>
                  )}
                  <Input.TextArea
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Écris ta requête ici…"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    style={{ paddingRight: "50px" }}
                    bordered={false}
                  />
                  <div className={st.inputActions}>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleFileSelect}
                      style={{ display: 'none' }}
                    />
                    <Button
                      type="text"
                      onClick={handleImageIconClick}
                      style={{ border: 'none', padding: 0, background: 'none' }}
                    >
                      <img src={AttachFileIcon} height={25} style={{ marginLeft: "10px" }} alt="Upload image" />
                    </Button>
                    <img
                      src={AISendIcon}
                      onClick={() => handleSendMessage("")}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "40px",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                        opacity: isWaitingResponse ? 0.5 : 1, // Add opacity for visual feedback
                        cursor: isWaitingResponse ? 'not-allowed' : 'pointer' // Change cursor
                      }}
                      height={40}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Loader component New Conversation*/}
          {showLoaderNewConversation && (
            <div style={{ position: 'absolute', top: '50%', left: '60%', transform: 'translate(-50%, -50%)' }}>
              <Spin size="large" />
            </div>
          )}
        </Drawer>

        <Drawer
          title="Contenu du cours"
          placement="right"
          width="70%"
          onClose={handleShowCoursCloseDrawer}
          visible={drawerCoursVisible}
        >
          <div className={sStyle.appercue__continute__bot}>
            {!loading && <h3>Cours : {coursFetched?.title}</h3>}

            {!loading &&
              coursFetched?.contents?.map((field: any, i: number) => (
                <div key={i} className={sStyle.__body}>
                  <div className={sStyle.__big__title__content}>
                    <h3 className={sStyle.__big__title}>1 - {field?.title}</h3>
                  </div>

                  <div className={sStyle.appercue__continute__content}>
                    {field?.subtitles?.map((x: any) => (
                      <>
                        <h4>{x?.title}</h4>
                        {x?.subtitles?.map((item: any) => (
                          <>
                            <div className={sStyle.section__content}>
                              <h4>{item?.title}</h4>
                            </div>

                            <Latex>{item?.content ? item?.content : ""}</Latex>
                          </>
                        ))}
                      </>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </Drawer>

        <Drawer
          title="Contenu du quiz"
          placement="right"
          width="70%"
          onClose={handleShowQuizzCloseDrawer}
          visible={drawerQuizzVisible}
        >
          <StudentCoursQuiz key={quizzId} id={quizzId} handleShowQuizzCloseDrawer={handleShowQuizzCloseDrawer} />
        </Drawer>

        <Drawer
          title="Contenu des exercices"
          placement="right"
          width="70%"
          onClose={handleShowExerciceCloseDrawer}
          visible={drawerExerciceVisible}
        >
          <StudentExercices key={exerciceId} id={exerciceId} />
        </Drawer>

      </div>}

    </div>
  );
};

export default FooterFooter;
