export const AUTH_STUDENT = `${process.env.REACT_APP_API_URL}/users/students`;
export const AUTH_TEACHER = `${process.env.REACT_APP_API_URL}/users/teachers`;
export const AUTH_STUDENT_BY_UID = `${process.env.REACT_APP_API_URL}/users/students/uid`;
export const AUTH_USER = `${process.env.REACT_APP_API_URL_RESSOURCES}/users`;
export const RESSOURCES_BO = `${process.env.REACT_APP_API_URL_RESSOURCES}/Retraits`;
export const AUTH_PARENT_ORGANISATION = `${process.env.REACT_APP_API_URL}/users/students/superStudent`;
export const AUTH_USER_OTP = `${process.env.REACT_APP_API_URL}/users/students/all/otp/check`;
export const AUTH_TEACHER_DATA = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/reserve/search/by/teacher`;
export const AUTH_INVITE_STUDENT = `${process.env.REACT_APP_API_URL_RESSOURCES}/assistance/superStudent/invite/student/send`;
export const AUTH_REGISTER_STUDENT = `${process.env.REACT_APP_API_URL_RESSOURCES}/assistance/superStudent/invite/student/send`;
export const AUTH_GET_STUDENT = `${process.env.REACT_APP_API_URL_RESSOURCES}/quizz/superStudent/search`;
export const AUTH_GET_STUDENT_STATS = `${process.env.REACT_APP_API_URL_RESSOURCES}/quizz/superStudent/search/subStudents/stats`;
export const AUTH_GET_STUDENT_PAYMENTS = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/historique/paiements/find`;
export const AUTH_GET_PARENT_ORGANISM_TOP_UP = `${process.env.REACT_APP_API_URL_USER_DATA}/payments/superStudent`;
export const AUTH_GET_PARENT_ORGANISM_PAYMENTS = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/historique/paiements/find`;
export const AUTH_SUGGEST_TEACHER = `${process.env.REACT_APP_API_URL_RESSOURCES}/users/superStudent/prof/affect`;
export const AUTH_GET_SUGGESTED_TEACHER = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/reserve/search`;
export const AUTH_NOT_SUGGEST_TEACHER = `${process.env.REACT_APP_API_URL_RESSOURCES}/users/superStudent/prof/desaffect`;
export const AUTH_ASK_BUDGET = `${process.env.REACT_APP_API_URL_RESSOURCES}/retraits/student/budget/demande`;
export const AUTH_GET_PARENT_ORGANISM_STUDENTS = `${process.env.REACT_APP_API_URL_RESSOURCES}/users/superStudent/registred/students`;
export const AUTH_SEND_BUDGET = `${process.env.REACT_APP_API_URL_RESSOURCES}/retraits/student/budget/demande`;
export const AUTH_SEND_ACCOUNT_TOP_UP = `${process.env.REACT_APP_API_URL_RESSOURCES}/users/superStudent/coins/affect/one`;
export const AUTH_SEND_EQUAL_TOP_UP = `${process.env.REACT_APP_API_URL_RESSOURCES}/users/superStudent/coins/affect`;
export const AUTH_DISSOCIATE_ACCOUNT = `${process.env.REACT_APP_API_URL_RESSOURCES}/users/superStudent/registred/students/disassociate`;
export const AUTH_GET_STUDENT_HISTORY_DATA = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/parent/students/find/one`;
export const AUTH_GET_TEACHER_STUDENT_HISTORY_DATA = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/prof/students/find/one`;
export const AUTH_GET_TEACHER_STUDENTS = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/prof/students/find`;
export const AUTH_RATE_STUDENTS = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/prof/students/session/one/rate`;
export const AUTH_GET_STUDENTS_CREDENTIALS = `${process.env.REACT_APP_API_URL_RESSOURCES}/assistance/superStudent/invite/student/send`;
export const AUTH_GET_USER_EMAIL = `${process.env.REACT_APP_API_URL_RESSOURCES}/assistance/superStudent/invite/student/send`;
export const AUTH_CANCEL_MEETING = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/users/reservations/instance/session/annuler`;
export const AUTH_SCHEDULE_MEETING = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/users/reservations/instance/session/replanifier`;
export const AUTH_DISSOCIATE_STUDENT = `${process.env.REACT_APP_API_URL_RESSOURCES}/users/superStudent/student/desaffect`;
