import { Button } from "antd";
import * as appRoutes from "config/routes.config";
import { FC, useEffect, useState } from "react";
import s from "./assets/tabs.module.css";

//!IMAGES
import closeIcon from "assets/images/app/delete.svg";
import Container from "components/container";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setFullscreen } from "store/app/appSlice";
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";
import Fullscreen from "../../../../assets/images/app/fullscreen.svg";
import Cours from "../../../../assets/images/teacher/cours.svg";
import Exo from "../../../../assets/images/teacher/exo.svg";
import Quiz from "../../../../assets/images/teacher/quiz.svg";

interface TabsStudent {
  idChapter?: string;
  level?: string;
  textBack?: string;
  linkTo?: string;
  isPageStudentQuiz?: boolean;
  onClick?: () => void;
  isFullscreen?: boolean;
  onRequest: () => void;
  onExit: () => void;
}

const TeacherHeaderstabs: FC<TabsStudent> = ({
  idChapter,
  level,
  textBack,
  isPageStudentQuiz,
  onClick,
  isFullscreen,
  onRequest,
  onExit,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams<"id">();
  const [tempIdCours, setTempIdCours] = useState<any>("");
  const [tempIdChapter, setTempIdChapter] = useState<any>("");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  useEffect(() => {
    let coursId = localStorage.getItem("idCours");
    let chapterId = localStorage.getItem("chapterId");
    setTempIdCours(coursId);
    setTempIdChapter(chapterId);
  }, [tempIdCours, tempIdChapter]);

  return (
    <Container>
      <div className={s.__content__tabs} onClick={onClick}>
        {!isMobile && <div className={!isFullscreen ? s.header : s.fullHeader}>
          {isFullscreen && <Link
            to={
              isPageStudentQuiz ? "#" : `${appRoutes.GETCHAPTERS}/${tempIdCours}`
            }
          >
            <Button className={s.newBackButton}>
              <img src={ArrowBack} height={20} style={{ marginRight: "10px" }} />
              {textBack ? textBack : "Retour aux chapitres"}
            </Button>
          </Link>}

          {!isFullscreen && location.pathname === `${appRoutes.GETCHAPTER}/${params.id!}` ?
            <Button
              onClick={() => {
                onRequest();
                dispatch(setFullscreen(true));
              }}
              className={s.newButton}
            >
              <img src={Fullscreen} height={15} style={{ marginRight: "10px" }} />
              <span> Mode plein écran</span>
            </Button> : <div style={{ width: "15%" }}></div>
          }

          <div className={s.tabButtons}>
            <Link to={`${appRoutes.GETCHAPTER}/${tempIdChapter}`}>
              <div className={`${s.tab} ${location.pathname === `${appRoutes.GETCHAPTER}/${params.id}` && s.tabActive}`}>
                <img height={45} src={Cours} ></img> Cours
              </div>
            </Link>

            <Link to={`${appRoutes.FINDQUIZZFORCHAPTER}/${params.id}`}>
              <div className={`${s.tab} ${location.pathname === `${appRoutes.FINDQUIZZFORCHAPTER}/${params.id}` && s.tabActive || location.pathname === `/student/search/quizz/${params.id}` && s.tabActive || location.pathname === `/student/quizz/${params.id}` && s.tabActive}`}>
                <img height={45} src={Quiz} ></img> Quiz
              </div>
            </Link>

            <Link to={`${appRoutes.FINDEXOFORCHAPTER}/${tempIdChapter}`}>
              <div className={`${s.tab} ${location.pathname === `${appRoutes.FINDEXOFORCHAPTER}/${params.id}` && s.tabActive || location.pathname === `${appRoutes.FINDEXOFORCHAPTER}/${params.id}` && s.tabActive}`}>
                <img height={30} src={Exo} style={{ marginRight: "10px" }}></img> Exercices
              </div>
            </Link>
          </div>

          {!isFullscreen && <Link
            to={
              isPageStudentQuiz ? "#" : `${appRoutes.GETCHAPTERS}/${tempIdCours}`
            }
          >
            <Button className={s.newBackButton}>
              <img src={ArrowBack} height={20} style={{ marginRight: "10px" }} />
              {textBack ? textBack : "Retour aux chapitres"}
            </Button>
          </Link>}
        </div>}

        {isMobile && <div className={isFullscreen ? s.mobileMenuFullscreen : s.mobileMenu}>
          {location.pathname === `${appRoutes.GETCHAPTER}/${params.id!}` && <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            {!isFullscreen && location.pathname === `${appRoutes.GETCHAPTER}/${params.id!}` && <Button
              onClick={() => {
                onRequest();
                dispatch(setFullscreen(true));
              }}
              className={s.newButton}
            >
              <img src={Fullscreen} height={20} style={{ marginRight: "10px" }} />
              Mode plein écran
            </Button>}
            {isFullscreen &&
              <Button
                onClick={() => {
                  onExit();
                  dispatch(setFullscreen(false));
                }}
                className={s.newButton}
              >
                <img src={closeIcon} height={20} style={{ marginRight: "10px" }} />
                Fermer
              </Button>}
            {isFullscreen && !isMobile && <Link
              to={
                isPageStudentQuiz ? "#" : `${appRoutes.GETCHAPTERS}/${tempIdCours}`
              }
            >
              <Button className={s.newBackButton}>
                <img src={ArrowBack} height={20} style={{ marginRight: "10px" }} />
                {textBack ? textBack : "Retour aux chapitres"}
              </Button>
            </Link>}
          </div>}
          <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
            <Link to={`${appRoutes.GETCHAPTER}/${tempIdChapter}`}>
              <div className={`${s.tab} ${location.pathname === `${appRoutes.GETCHAPTER}/${params.id}` && s.tabActive}`}>
                <img height={25} src={Cours} ></img> Cours
              </div>
            </Link>

            <Link to={`${appRoutes.FINDQUIZZFORCHAPTER}/${params.id}`}>
              <div className={`${s.tab} ${location.pathname === `${appRoutes.FINDQUIZZFORCHAPTER}/${params.id}` && s.tabActive || location.pathname === `/student/search/quizz/${params.id}` && s.tabActive || location.pathname === `/student/quizz/${params.id}` && s.tabActive}`}>
                <img height={25} src={Quiz} ></img> Quiz
              </div>
            </Link>

            <Link to={`${appRoutes.FINDEXOFORCHAPTER}/${tempIdChapter}`}>
              <div className={`${s.tab} ${location.pathname === `${appRoutes.FINDEXOFORCHAPTER}/${params.id}` && s.tabActive || location.pathname === `${appRoutes.FINDEXOFORCHAPTER}/${params.id}` && s.tabActive}`}>
                <img height={15} src={Exo} style={{ marginRight: "10px" }}></img> Exercices
              </div>
            </Link>
          </div>
        </div>}
      </div>
    </Container>
  );
};

export default TeacherHeaderstabs;
