import * as AUTH_SERVICES from "api/services/auth/auth.services";
import Breadcrumbs from "components/breadcrumbs";
import React, { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, batch, useDispatch, useSelector } from "react-redux";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!STYLES

//IMAGES
import { Button, Drawer, Form, Image, Input, Modal, Skeleton, Tabs, message } from "antd";
import { useParams } from "react-router-dom";

import Wallet from "assets/images/app/header/wallet.svg";
import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import ParentOrganismPaymentTable from "components/paiementHistoryParentOrganism";
import { SponsorDrawerPaiement } from "components/paiementSponsor";
import StudentsManagedTable from "components/studentsManaged";
import ParentOrganismTopUpTable from "components/topUpHistoryParentOrganism";
import { updateUser } from "store/auth/authSlice";
import s from "./style.module.css";

const { TabPane } = Tabs;

const Wallets = () => {

    const dispatch = useDispatch();
    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const { user } = useSelector((state) => state.auth);
    const params = useParams<"id">();
    const [currentTab, handleTabChange] = useState<string>("1");
    const switchTab = async (tabIndex: string) => {
        handleTabChange(tabIndex);
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const [showDispatch, setShowDispatch] = useState<boolean>(false);

    const [budgetDrawerVisibility, setBudgetDrawerVisibility] = useState<boolean>(false);
    const closeBudgetDrawer = async () => {
        setBudgetDrawerVisibility(false);
    }

    const [walletDrawerVisibility, setWalletDrawerVisibility] = useState<boolean>(false);
    const closeWalletDrawer = async () => {
        setWalletDrawerVisibility(false);
    }

    const [page, setPage] = useState<any>("1");
    const [loading, setLoading] = useState<boolean>(true);
    const [topUpHistoryData, setTopUpHistoryData] = useState<any>([]);
    const [paiementHistoryData, setPaiementHistoryData] = useState<any>([]);
    const [studentsData, setStudentsData] = useState<any>([]);
    const [userData, setUserData] = useState<any>();

    const getTopUpHistory = async () => {
        try {
            const history = await AUTH_SERVICES.getParentOrganismTopUpHistory(user?._id, page);
            if (history?.data) {
                setTopUpHistoryData(history['data']['results']);
            } else {
                throw new Error("Error");
            }
        } catch (error) { }
        finally {
            getPaiementHistory();
        }
    }

    const getPaiementHistory = async () => {
        try {
            const history = await AUTH_SERVICES.getParentOrganismPaiementHistory(user?._id, page);
            if (history?.data) {
                setPaiementHistoryData(history['data']['results']);
            } else {
                throw new Error("Error");
            }
        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    const getParentStudents = async () => {
        try {
            const students = await AUTH_SERVICES.getParentStudents(user?._id, page);
            if (students?.data) {
                setStudentsData(students['data']['results']);
                setShowDispatch(students['data']['showButton'])
            } else {
                throw new Error("Error");
            }
        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    const [formInfos] = Form.useForm();
    const [visibleAddModal, setVisibleAddModal] = useState(false);
    const [isMessageLoading, setMessageLoading] = useState<boolean>(false);
    const [idStudent, setIdStudent] = useState<string>('');

    const handleStudentId = (studentId: string) => {
        // Implement your save logic here
        setIdStudent(studentId);
        setVisibleAddModal(true);
    };

    const handleCancel = () => {
        setVisibleAddModal(false);
    };

    const sendTopUp = async (values: any) => {
        setMessageLoading(true);
        try {
            const response = await AUTH_SERVICES.sendAccountTopUp(user?._id, idStudent, values.montant);
            if (response?.statusCode === StatusCode.CREATED) {
                message.success({
                    content: "Compte rechargé ",
                });
                setMessageLoading(false);
                setVisibleAddModal(false);
            }
            else {
                message.warning({
                    content: "Ooops, Une erreur est survenue",
                });
                setMessageLoading(false);
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        } finally {
            updateUserData();
            getParentStudents();
        }
    }

    const updateUserData = useCallback(async () => {
        try {
            const finalUser = await AUTH_SERVICES.getUserByUid(user.uid);
            if (finalUser?.data) {
                batch(() => {
                    dispatch(updateUser(finalUser.data));
                });
            }
        } catch (error) { }
    }, []);

    const sendEqualTopUp = async () => {
        setMessageLoading(true);
        try {
            const response = await AUTH_SERVICES.sendEqualTopUp(user?._id);
            if (response?.statusCode === StatusCode.CREATED) {
                message.success({
                    content: "Comptes rechargé ",
                });
                setMessageLoading(false);
                setVisibleAddModal(false);
            }
            else {
                message.warning({
                    content: "Ooops, Une erreur est survenue",
                });
                setMessageLoading(false);
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        } finally {
            getParentStudents();
        }
    }

    useEffect(() => {
        getTopUpHistory();
        getParentStudents();
    }, []);

    return (
        <React.Fragment>
            <Breadcrumbs isWallet />
            <AppScreen>
                <div style={{ display: "flex", flexDirection: "column", margin: "0px", padding: "0px", position: "relative", top: "-30px" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", height: "100px" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", fontSize: isMobile ? "0.8em" : "1.7em", fontWeight: "bold" }}>
                            <Image
                                preview={false}
                                src={Wallet}
                                width={isMobile ? 30 : 50}
                            />
                            Solde : {user?.coins} €
                        </div>
                        <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", justifyContent: "space-between" }}>
                            <Button onClick={() => { setWalletDrawerVisibility(true) }} style={{ borderRadius: "10px", color: "white", backgroundColor: "#80B1B2", marginRight: "20px", fontSize: isMobile ? "0.8em" : "14px" }}>
                                {"Recharger mon compte"}
                            </Button>
                            <Button onClick={() => { setBudgetDrawerVisibility(true) }} style={{ borderRadius: "10px", color: "white", backgroundColor: "#80B1B2", marginTop: isMobile ? "10px" : "0px", fontSize: isMobile ? "0.8em" : "14px" }}>
                                {"Gérer l’attribution de mon budget"}
                            </Button>
                        </div>
                    </div>
                    {loading ? <Skeleton active /> : <div style={{ background: "#fafafa", borderRadius: "15px", padding: "1%", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%" }}>
                        <Tabs activeKey={currentTab} onChange={(key) => switchTab(key)} centered>
                            {<TabPane tab="Historique des paiements" key="1">
                                <ParentOrganismTopUpTable payments={paiementHistoryData} />
                            </TabPane>}
                            {<TabPane tab="Historique des recharges pour élèves" key="2">
                                <ParentOrganismPaymentTable payments={topUpHistoryData} />
                            </TabPane>}
                        </Tabs>
                    </div>}
                </div>

                <Drawer
                    placement="right"
                    closable={true}
                    onClose={closeBudgetDrawer}
                    visible={budgetDrawerVisibility}
                    width={isMobile ? "100%" : "70%"}
                    headerStyle={{ display: "flex", justifyContent: "space-between" }}
                    bodyStyle={{ background: "#FFF", padding: "0px" }}
                    title={<div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.2em" }}>Gérer l'attribution de budget</div>}
                >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", margin: "40px auto", paddingLeft: "20px", paddingRight: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", fontSize: "1.7em", fontWeight: "bold" }}>
                            <Image
                                preview={false}
                                src={Wallet}
                                width={50}
                            />
                            Solde : {user?.coins} €
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                            {/* Action rapide */}
                            {showDispatch && <Button onClick={() => sendEqualTopUp()} style={{ borderRadius: "10px", color: "white", backgroundColor: "#80B1B2", marginRight: "20px" }}>
                                {"Séparer équitablement"}
                            </Button>}
                        </div>
                    </div>
                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                        <StudentsManagedTable data={studentsData} onEdit={handleStudentId} />
                    </div>
                </Drawer>
                <Drawer
                    placement="right"
                    closable={true}
                    onClose={closeWalletDrawer}
                    visible={walletDrawerVisibility}
                    width={isMobile ? "100%" : "40%"}
                    headerStyle={{ display: "flex", justifyContent: "space-between" }}
                    bodyStyle={{ background: "#FFF", padding: "0px" }}
                >
                    <div style={{ alignItems: "flex-start", margin: "40px auto" }}>
                        <SponsorDrawerPaiement currentUser={user} type="parent/organism" />
                    </div>
                </Drawer>
                <Modal
                    title={<div style={{ fontFamily: "CartoonFont", fontSize: "1.8em", display: "flex", alignItems: "center", justifyContent: "center" }}>Ajouter du solde</div>}
                    visible={visibleAddModal}
                    onCancel={handleCancel}
                    footer={null}
                    closable={true}
                >
                    <p style={{ fontFamily: "CartoonFont", fontSize: "1.8em" }}>Recharge le compte de ton etudiant</p>
                    <Form
                        validateTrigger={["onFinish"]}
                        name="registerParent"
                        autoComplete="off"
                        form={formInfos}
                        onFinish={sendTopUp}
                    >

                        <Form.Item
                            name="montant"
                            rules={[{ required: true }]}
                            style={{ marginBottom: "20px" }}
                        >
                            <Input
                                type="number"
                                className={s.field}
                                placeholder={"Budget à recharger €"}
                                maxLength={30}
                            />
                        </Form.Item>

                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Form.Item>
                                <AppButton loading={isMessageLoading} htmlTypeSubmit={true}>Recharger</AppButton>
                            </Form.Item>
                        </div>
                    </Form>
                </Modal>
            </AppScreen>
        </React.Fragment>
    );

}

export default Wallets;