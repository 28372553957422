import { FC, useEffect, useState } from "react";

//!STYLES
import s from "./assets/style.module.css";

//!COMPONENTS
import { Tabs } from "antd";
import { useSelector } from "react-redux";
import AboutProfileTeacherCard from "../aboutCard";
import AvisProfileTeacher from "../avis";
import ContributionsProfileTeacherCard from "../contributions";
import PublicationsProfileTeacherCard from "../publications";

//!IMAGES
import AppResult from "components/appResults";
import AboutIcon from "../../../../../assets/images/app/about_icon.svg";
import ContribuionsIcon from "../../../../../assets/images/app/contributions_icon.svg";
import EllipseTeacherProfileFour from "../../../../../assets/images/app/ellipse_teacher_profile4.png";
import EllipseTeacherProfileFive from "../../../../../assets/images/app/ellipse_teacher_profile5.png";
import PublicationsIcon from "../../../../../assets/images/app/publications_icon.svg";
import RateIcon from "../../../../../assets/images/app/rate_icon.svg";

type Props = {
  teacher: any;
};

const { TabPane } = Tabs;

const AboutProfileTeacher: FC<Props> = ({ teacher }) => {

  const { seeAvis } = useSelector((state) => state.auth);
  const [top, setTop] = useState<number>(10);
  const [currentTab, setCurrentTab] = useState<any>("1");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  useEffect(() => {
    if (seeAvis) {
      setCurrentTab("4");
    }
  }, [seeAvis]);

  return (
    <>
      {!isMobile && <img src={EllipseTeacherProfileFour} height={106} style={{ position: "absolute", top: "65%", left: "0px", zIndex: "-1" }}></img>}
      {!isMobile && <img src={EllipseTeacherProfileFive} height={54} style={{ position: "absolute", top: "70%", left: "94%", zIndex: "-1" }}></img>}
      <div className={s.about}>
        <Tabs
          tabBarStyle={{ borderRadius: "0px", overflow: "hidden", border: "0px solid transparent !important", backgroundColor: "transparent !important" }}
          activeKey={currentTab}
          onChange={(key) => setCurrentTab(key)}
          type="line"
        >
          <TabPane
            tab={
              <div className={currentTab == "1" ? s.tabTitleBoxActive : s.tabTitleBox}>
                <img src={AboutIcon} height={18} style={{ marginRight: "10px" }}></img> À Propos
              </div>
            }
            key="1"
          >
            <div className={s.informationBox}>
              <AboutProfileTeacherCard teacher={teacher?.prof} />
            </div>
          </TabPane>
          <TabPane
            tab={
              <div className={currentTab == "2" ? s.tabTitleBoxActive : s.tabTitleBox}>
                <img src={PublicationsIcon} height={18} style={{ marginRight: "10px" }}></img> Publications
              </div>
            }
            key="2"
          >
            <div className={s.informationBox}>
              {teacher?.publications !== "" ? <PublicationsProfileTeacherCard publications={teacher?.publications} /> : <AppResult status={"info"} title="Aucune publication" />}
            </div>
          </TabPane>
          <TabPane
            tab={
              <div className={currentTab == "3" ? s.tabTitleBoxActive : s.tabTitleBox}>
                <img src={ContribuionsIcon} height={18} style={{ marginRight: "10px" }}></img> Contributions
              </div>
            }
            key="3"
          >
            <div className={s.informationBox}>
              <ContributionsProfileTeacherCard publications={teacher?.publications} />
            </div>
          </TabPane>
          <TabPane
            tab={
              <div className={currentTab == "4" ? s.tabTitleBoxActive : s.tabTitleBox}>
                <img src={RateIcon} height={18} style={{ marginRight: "10px" }}></img> Avis élèves
              </div>
            }
            key="4"
          >
            <div className={s.informationBox}>
              <AvisProfileTeacher avis={teacher} />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default AboutProfileTeacher;
