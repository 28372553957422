import { Button, Typography } from "antd";
import Container from "components/container";
import React, { FC, useState } from "react";

import s from "./assets/calendar.module.css";

//!IMAGES

import Breadcrumbs from "components/breadcrumbs";
import HelpPopover from "components/help";
import CalendarPicture from "../../../assets/images/app/calendar_icon.svg";
import CalendarOfReservationTeacher from "./c";
import DrawerHistorique from "./components";

const { Title, Text } = Typography;

const AppCalendar: FC = () => {

  const [openDrawerHistorique, setOpenDrawerHistorique] = useState<boolean>(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  //!RETURN
  return (
    <React.Fragment>
      <Breadcrumbs isCalendar={true} />
      <div style={{ marginTop: "1em" }}>
        <Container>
          <div className={s.headerBox}>
            <div className={s.titleBox}>
              <div className={s.titlePictureBox}>
                <img src={CalendarPicture} height={isMobile ? 20 : 35}></img>
              </div>
              Planning des cours
            </div>
            <div className={s.buttonBox}>
              <Button className={s.newButton} onClick={() => setOpenDrawerHistorique(true)}>
                Mon historique
              </Button>
              <div style={{ marginLeft: "10px", marginTop: "5px" }}>
                <HelpPopover id={"planning_des_cours_mon_historique"} />
              </div>
            </div>
          </div>

          <div className={s.calendrier}>
            <div className={s.calendar__content}>
              <CalendarOfReservationTeacher />
            </div>
          </div>
        </Container>
      </div>

      <DrawerHistorique
        open={openDrawerHistorique}
        setOpen={setOpenDrawerHistorique}
      />
    </React.Fragment>
  );
};

export default AppCalendar;
