import { Result } from "antd";
import React, { FC } from "react";

//!ASSETS
import { ReactComponent as AlbertNeutre } from "../../assets/images/albert/albert_empty_conversation.svg";
import { ReactComponent as AlbertEmptyData, ReactComponent as AlbertSad } from "../../assets/images/albert/albert_empty_data.svg";
import { ReactComponent as AlbertInstaProf } from "../../assets/images/albert/albert_insta_prof.svg";
import { ReactComponent as AlbertVeryHappy } from "../../assets/images/albert/albert_search_teacher.svg";

type Props = {
  status: "404" | "success" | "info" | "new" | "instaProf";
  title: string | React.ReactNode;
  subTitle?: string;
  extra?: React.ReactNode;
  isMobile?: any,
};

const AppResult: FC<Props> = ({ status, title, subTitle, extra, isMobile }) => {

  const handleCheckStatus = (status: string) => {
    switch (status) {
      case "success":
        return <AlbertVeryHappy style={{ height: isMobile ? "150px" : "250px" }} />;
      case "info":
        return <AlbertEmptyData style={{ height: isMobile ? "150px" : "250px" }} />;
      case "404":
        return <AlbertSad style={{ height: isMobile ? "150px" : "250px" }} />;
      case "new":
        return <AlbertNeutre style={{ height: isMobile ? "150px" : "250px" }} />;
      case "instaProf":
        return <AlbertInstaProf style={{ height: isMobile ? "150px" : "250px" }} />;
      default:
        return null;
    }
  };
  return (
    <Result
      title={title}
      icon={handleCheckStatus(status)}
      subTitle={subTitle}
      extra={extra}
      style={{ fontSize: "0.8em" }}
    />
  );
};

export default AppResult;
