import { CHOICE, StatusCode } from "common/enums";
// import { getToken } from "api";
import { getToken } from "api";
import axios from "axios";
import { ApiResponse } from "common/interfaces";
import {
  API_ANNALES_CHECK,
  API_ANNALES_FETCH,
  API_BASE_URL,
  API_BASE_URL_DRAFT,
  API_SOMMAIRE_FETCH,
  getAvancementByCoursePath,
  getAvancementPath,
  getFiltredQuizzes,
  getQuizOfCours,
  quizzResponses,
  TEACHER_FILTER_QUIZZ,
  updateQuiz,
} from "./quizz.api.routes";

///Check If Special
export const checkIfSpecial = async (
  idCourse: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(`${API_ANNALES_CHECK}/${idCourse}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

///Fetch Special
export const fetchDocs = async (
  idCourse: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(`${API_ANNALES_FETCH}/${idCourse}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

///Fetch Sommary
export const fetchSommary = async (
  idCourse: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(`${API_SOMMAIRE_FETCH}/${idCourse}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findAll = async (): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();

    if (!token) throw new Error("No token");
    const response = await axios.get(API_BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findAllQuizPublished = async (): Promise<
  ApiResponse | undefined
> => {
  try {
    const token = await getToken();

    if (!token) throw new Error("No token");
    const response = await axios.get(`${API_BASE_URL}?status=PUBLISHED`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const create = async (
  isDraft: any,
  createQuizzDto: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (createQuizzDto?.questions.length === 0) {
      return {
        statusCode: 400,
        data: {},
        message: "Vous devez au moins insérer une question",
        status: StatusCode.NO_CONTENT.toString(),
      };
    }

    for (let j = 0; j < createQuizzDto?.questions.length; j++) {
      if (createQuizzDto?.questions[0]?.propositions?.length === 0) {
        return {
          statusCode: 400,
          data: {},
          message: "Vous devez au moins insérer quatre (4) propositions",
          status: StatusCode.NO_CONTENT.toString(),
        };
      }
    }

    let i;
    let j;

    for (j = 0; j < createQuizzDto?.questions.length; j++) {
      let found = 0;
      if (found) break;
      for (
        i = 0;
        i < createQuizzDto?.questions[j]?.propositions?.length;
        i += 1
      ) {
        if (createQuizzDto?.questions[j]?.propositions[i]?.isCorrect === true) {
          found += 1;
          break;
        }
      }
      if (found === 0) {
        return {
          statusCode: 400,
          data: {},
          message: "Vous devez choisir la ou les bonne(s) réponses",
          status: StatusCode.NO_CONTENT.toString(),
        };
      }
    }

    const response = await axios.post(
      `${isDraft ? API_BASE_URL_DRAFT : API_BASE_URL}`,
      createQuizzDto,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateQuizFunction = async (
  flag: string,
  id: string,
  updateQuizzDto: any
): Promise<ApiResponse | undefined> => {
  try {
    if (updateQuizzDto?.questions.length === 0) {
      return {
        statusCode: 400,
        data: {},
        message: "Vous devez au moins insérer une question",
        status: StatusCode.NO_CONTENT.toString(),
      };
    }

    for (let j = 0; j < updateQuizzDto?.questions.length; j++) {
      if (updateQuizzDto?.questions[0]?.propositions?.length === 0) {
        return {
          statusCode: 400,
          data: {},
          message: "Vous devez au moins insérer quatre (4) propositions",
          status: StatusCode.NO_CONTENT.toString(),
        };
      }
    }

    let i;
    let j;

    for (j = 0; j < updateQuizzDto?.questions.length; j++) {
      let found = 0;
      if (found) break;
      for (
        i = 0;
        i < updateQuizzDto?.questions[j]?.propositions?.length;
        i += 1
      ) {
        if (updateQuizzDto?.questions[j]?.propositions[i]?.isCorrect === true) {
          found += 1;
          break;
        }
      }
      if (found === 0) {
        return {
          statusCode: 400,
          data: {},
          message: "Vous devez choisir la ou les bonne(s) réponses",
          status: StatusCode.NO_CONTENT.toString(),
        };
      }
    }

    const response = await axios.patch(
      `${API_BASE_URL}/${id}/${flag === CHOICE.SAVE_PERMANENTLY ? "submit" : "newUpdateQuizz"
      }`,
      {
        ...updateQuizzDto,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const findOne = async (id: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const findOneTeacher = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}/withInswers`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const findOneChapters = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}/chapters`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findOneProposition = async (
  id: string,
  propositionId: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/${id}/propositions/${propositionId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProposition = async (
  id: string,
  propositionId: string,
  updatePropositionDto: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();

    const response = await axios.patch(
      `${API_BASE_URL}/${id}/propositions/${propositionId}`,
      updatePropositionDto,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchQuizzesByfilter = async (
  title: string | undefined,
  quizzLevel: string | undefined,
  duration: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("No token");
    const response = await axios.get(
      getFiltredQuizzes(title, quizzLevel, duration),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const onfetchQuizzesByfilter = async (
  title: string | undefined,
  quizzLevel: string | undefined,
  duration: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("No token");
    const response = await axios.get(
      getFiltredQuizzes(title, quizzLevel, duration),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findQuizOfCours = async (idQuiz: string) => {
  try {
    const response = await axios.get(getQuizOfCours(idQuiz));
    if (response) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const postQuizResponses = async (
  idQuiz: string,
  questionId: string,
  values: any
) => {
  try {
    const response = await axios.post(
      quizzResponses(idQuiz, questionId),
      values
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const teacherFilterQuizz = async (values: any) => {
  try {
    const token = await getToken();
    const response = await axios.get(`${TEACHER_FILTER_QUIZZ(values)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const teacherUpdateQuizz = async (idQuiz: any, values: any) => {
  const token = await getToken();
  try {
    const response = await axios.patch(updateQuiz(idQuiz), values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteQuizz = async (
  id: any
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// progression
export const getAvancement = async (
  id: string,
  level: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(getAvancementPath(id, level), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
// progression
export const getAvancementByCourse = async (
  id: string,
  idCourse: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(getAvancementByCoursePath(id, idCourse), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const checkAvancement = async (
  idUser: string,
  level: string,
  course: string,
  quizz: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(
      API_BASE_URL + "/user/checkAvancement",
      { idUser: idUser, level: level, course: course, quizz: quizz },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
  }
};
export const setAvancement = async (
  idUser: string,
  level: string,
  course: string,
  quizz: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(
      API_BASE_URL + "/setAvancement",
      { idUser: idUser, level: level, course: course, quizz: quizz },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
