import {
    Button,
    Checkbox,
    Form,
    Input,
    message,
    Row
} from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { Dispatch, FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateUser } from "store/auth/authSlice";
import * as TEACHER_SERVICES from "../../../api/services/teacher/teacher.services";

//!STYLES
import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import { useDispatch, useSelector } from "react-redux";
import st from "./assets/register.module.css";

import { uploadToCloudinary } from "api/services/teacher/teacher.services";
import docsPicture from '../../../assets/images/app/docs.svg';
import badgeCert from '../../../assets/images/app/validation.svg';

type Props = {
    stepTwoData: any;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

const StepThree: FC<Props> = ({ stepTwoData, setCurrent }) => {
    //!HOOKS
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [formInfos] = Form.useForm();

    //!STATES
    const [isDivVisible, setIsDivVisible] = useState(true);
    const [diplomaCount, setDiplomaCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [diplomaList, setDiplomaList] = useState<any>([]);

    const [dataList, setDataList] = useState<any>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const [updateIndex, setUpdateIndex] = useState<number>(0);

    const [stepThreeData, setStepThreeData] = useState<any>({});
    var userDiplomas = [{}];
    var [userDiplomaUrl, setDiplomaUrl] = useState<any>([]);

    const [fileExtensions, setFileExtensions] = useState<string[]>([]);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1050);
    };
    window.addEventListener('resize', handleResize);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    //!FUNCTIONS
    const diplomaFormVisibility = () => {
        setIsDivVisible(!isDivVisible);
    }

    ///Drag & Drop Functions
    ///Certification Div
    const [divs, setDivs] = useState([{ index: diplomaCount }]);

    ///Add New Diploma
    const handleAddDiv = (index: number) => {
        const newDivs = [...divs, { index: index }];
        setDivs(newDivs);
    };

    const handleAddPreviousDiv = (index: number) => {
        const newDivs = [...divs, { index: index }];
        setDivs(newDivs);

        var element: any = {};
        setDiplomaCount((diplomaCount) => diplomaCount + 1);

        for (let i = 0; i < userDiplomas.length; i++) {
            element = userDiplomas[i];

            const universityKey = `university${i}`;
            const nameKey = `diplomaName${i}`;
            const typeKey = `diplomaType${i}`;
            const specialityKey = `speciality${i}`;
            const startingDateKey = `startedYear${i}`;
            const endingDateKey = `endedYear${i}`;
            const diplomaKey = `diplomaUrl${i}`;

            formInfos.setFieldsValue({
                [universityKey]: element.university,
                [nameKey]: element.diplome,
                [typeKey]: element.diplomeType,
                [specialityKey]: element.speciality,
                [startingDateKey]: element.dateDebut,
                [endingDateKey]: element.dateFin,
                [diplomaKey]: element.diplomeUrl,
            });

            userDiplomaUrl.push(element.diplomeUrl);

            const extension = getFileExtension(element.diplomeUrl);
            if (extension) {
                setFileExtensions((prev) => [...prev, extension]);
            }
        }
        setDiplomaCount(userDiplomas.length);
    };

    const setDivPreviousElement = async () => {
        var element: any = {};
        element = userDiplomas[0];

        formInfos.setFieldsValue({
            university0: element.university,
            diplomaName0: element.diplome,
            diplomaType0: element.diplomeType,
            speciality0: element.speciality,
            startedYear0: element.dateDebut,
            endedYear0: element.dateFin,
            diplomaUrl0: element.diplomeUrl,
        });

        userDiplomaUrl.push(element.diplomeUrl);
        setDiplomaCount(userDiplomas.length);

        const extension = getFileExtension(element.diplomeUrl);
        if (extension) {
            setFileExtensions((prev) => [...prev, extension]);
        }
    }

    const addNewDiploma = (index: number) => {
        setDiplomaCount((certCount) => certCount + 1);
        handleAddDiv(index);
    }

    const handleRemoveDiplomaDiv = (index: number) => {
        const newDivs = [...divs];
        newDivs.splice(index, 1);
        setDivs(newDivs);
        setDiplomaCount((diplomaCount) => diplomaCount - 1);
    };

    const setDiplomaInputValue = (url: string) => {
        const diplomaKey = `diplomaUrl${updateIndex}`;
        const newArray = [...userDiplomaUrl];

        formInfos.setFieldsValue({
            [diplomaKey]: url,
        });

        newArray.splice(updateIndex, 0, url);
        setDiplomaUrl(newArray);
        setIsLoading(false);
    }

    ///Trigger Form
    const moveToStepFour = async (values: any) => {
        if (isDivVisible) {
            for (let i = 0; i < diplomaCount; i++) {
                const diplomaUrlKey = `diplomaUrl${i}`;
                if (values[diplomaUrlKey]) {
                    setIsLoading(true);

                    var diploma = {};
                    const diplomas = [];

                    for (let i = 0; i < diplomaCount; i++) {

                        const universityKey = `university${i}`;
                        const nameKey = `diplomaName${i}`;
                        const typeKey = `diplomaType${i}`;
                        const specialityKey = `speciality${i}`;
                        const startingDateKey = `startedYear${i}`;
                        const endingDateKey = `endedYear${i}`;
                        const diplomaKey = `diplomaUrl${i}`;

                        diploma = {
                            "university": values[universityKey],
                            "diplome": values[nameKey],
                            "diplomeType": values[typeKey],
                            "speciality": values[specialityKey],
                            "dateDebut": values[startingDateKey],
                            "dateFin": values[endingDateKey],
                            "diplomeUrl": values[diplomaKey],
                        }
                        diplomas.push(diploma);

                    }
                    updateUserInfos(diplomas);

                }
                else {

                }
            }
        }
        else {
            updateUserInfos([]);
        }

    }

    ///Update Teacher Certifications
    const updateUserInfos = async (diplomas: any) => {
        setStepThreeData(user);
        const finalData = {
            diplomes: diplomas,
            step: user && user?.step === "completed" ? "completed" : "diploma",
        };

        try {
            const response = await AUTH_SERVICES.updateTeacher(user?._id, {
                ...finalData,
            });
            if (response?.statusCode === StatusCode.OK) {
                dispatch(
                    updateUser({
                        ...user,
                        diplomes: diplomas,
                        step: user && user?.step === "completed" ? "completed" : "diploma"
                    })
                );
                message.success({
                    content: "Modification effectuée ",
                });
            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        } finally {
            setIsLoading(false);
            setCurrent(4);
        }
    };

    //Drag & Drop Functions
    function handleFile(files: any) {
        const file = files[0];
        const maxSize = 5 * 1024 * 1024;
        const reader = new FileReader();
        const allowedTypes = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];

        if (file && file.size <= maxSize && allowedTypes.includes(file.type)) {
            const fileExtension = file.name.split('.').pop();
            if (fileExtension) {
                setFileExtensions((prev) => [...prev, fileExtension]);
            }

            reader.onload = () => {
                uploadDiplomaPicture(file);
            };
            reader.readAsDataURL(file);
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
    }

    ///Upload Diploma
    const uploadDiplomaPicture = async (file: any) => {
        try {
            const response = await uploadToCloudinary(file, "formation");
            if (response?.statusCode === StatusCode.CREATED) {
                setDiplomaInputValue(response?.data);
            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (let i = 0; i < error?.response?.data?.message?.length; i++) {
                content = error?.response?.data?.message[i] + ", " + content;
            }
            message.error({
                content: content,
            });
        }
    }

    // triggers when file is selected with click
    const handleChange = (e: any) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = (index: number) => {
        if (inputRef.current != null) {
            inputRef.current.click();
            setUpdateIndex(index);
        }
    };

    const getStaticLists = useCallback(
        async (type: string, dataList: any, state: Dispatch<any>) => {
            const response = await TEACHER_SERVICES.getDataList(type);

            if (response?.statusCode === StatusCode.OK) {
                state(response?.data);
            }
        },
        [dataList]
    );

    function getFileExtension(url: string): string | null {
        const parts = url.split('?')[0];
        const extension = parts.substring(parts.lastIndexOf('.') + 1);
        return extension || null;
    }

    //!EFFECTS
    useEffect(() => {
        getStaticLists('dates', dataList, setDataList);
        getStaticLists('diplomeType', diplomaList, setDiplomaList);

        if (user) {
            userDiplomas = user?.diplomes;
            if (userDiplomas.length !== 0) {
                if (userDiplomas.length === 1) {
                    setDivPreviousElement();
                }
                else {
                    for (let i = 1; i < userDiplomas.length; i++) {
                        handleAddPreviousDiv(i);
                    }
                }
            }
            else {
                setDiplomaCount((diplomaCount) => diplomaCount + 1);
            }
        }
        else {
            setDiplomaCount((diplomaCount) => diplomaCount + 1);
        }
    }, [user]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={{ height: "auto", width: "100%", margin: "0px auto", }}>
            <Form
                validateTrigger={["onFinish"]}
                name="registerTeacher"
                autoComplete="off"
                form={formInfos}
                validateMessages={validateMessages}
                initialValues={{ noFormation: false }}
                onFinish={moveToStepFour}
            >
                <div className={st.certificateDiplomaFormBox}>
                    <div className={st.termesToAccept} style={{ margin: "30px auto" }}>
                        <Form.Item
                            name="noFormation"
                            valuePropName="checked"
                            style={{ marginBottom: "20px" }}
                        >
                            <Checkbox
                                style={{ color: "#636363" }}
                                className={st.policy}
                                onClick={diplomaFormVisibility}
                            >
                                {" "}
                                Je n’ai pas de diplôme de l’enseignement supérieur{" "}
                            </Checkbox>
                        </Form.Item>
                    </div>

                    {isDivVisible &&
                        divs.map((div, index) => (
                            <div key={div.index} className={st.formContainer}>
                                {index !== 0 && <div style={{ height: "1px", width: "100%", border: "1px solid #333", borderStyle: "dashed", marginTop: "20px", marginBottom: "20px" }}></div>}
                                <Form.Item
                                    name={`university${index}`}
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "20px" }}
                                >
                                    <Input
                                        className={st.field}
                                        placeholder="Université"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={`diplomaName${index}`}
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "20px" }}
                                >
                                    <Input
                                        className={st.field}
                                        placeholder="Diplôme"
                                    />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "20px" }}
                                    name={`diplomaType${index}`}
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        className={st.field}
                                        placeholder="Type de diplôme"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={`speciality${index}`}
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "20px" }}
                                >
                                    <Input
                                        className={st.field}
                                        placeholder="Spécialité"
                                    />
                                </Form.Item>

                                <div className={st.inputRow}>
                                    <div className={st.inputRowChild}>
                                        <Form.Item
                                            style={{ marginBottom: "20px" }}
                                            name={`startedYear${index}`}
                                            rules={[{ required: true }]}
                                        >
                                            <select
                                                style={{ padding: "7px", fontSize: isMobile ? "0.8em" : "16px" }}
                                                className={st.selectInput}
                                            >
                                                <option value="">Années d'études</option>
                                                {dataList.map((item: any, i: any) => (<option key={i} value={item}>{item}</option>))}
                                            </select>
                                        </Form.Item>
                                    </div>
                                    <div className={st.inputRowChild}>
                                        <Form.Item
                                            style={{ marginBottom: "20px" }}
                                            name={`endedYear${index}`}
                                            rules={[{ required: true }]}
                                        >
                                            <select
                                                style={{ padding: "7px", fontSize: isMobile ? "0.8em" : "16px" }}
                                                className={st.selectInput}
                                            >
                                                <option value="">Années d'études</option>
                                                {dataList.map((item: any, i: any) => (<option key={i} value={item}>{item}</option>))}
                                            </select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className={st.docBox}>
                                    <div className={st.docBoxImage}>
                                        <img
                                            height={20}
                                            width={20}
                                            src={badgeCert}
                                            alt="Badge"
                                        />
                                        <a className={st.docBoxTitle}>{userDiplomaUrl.length !== 0 && userDiplomaUrl[index] !== undefined ? "Diplôme ajouté" : "Télécharge ton diplôme ou ton CV ici pour booster la crédibilité de ton profil! "}</a>
                                    </div>
                                    <a className={st.docBoxMessage}>Notre équipe l'examinera afin de pouvoir ajouter un badge à ton profil. Une fois examinés et validés, tes fichiers (ou documents) personnels seront supprimés.</a>

                                    <div className={st.uploadDocBox}>
                                        <Form.Item
                                            style={{ width: "100%", visibility: "visible", marginLeft: isMobile ? "0px" : "35px" }}
                                            name={`diplomaUrl${index}`}
                                            rules={[{ required: true, message: 'Veuillez ajouter le scan du diplôme' }]}
                                        >
                                            <Input readOnly={true} style={{ height: "0px", visibility: "collapse", marginLeft: isMobile ? "0px" : "35px" }} />
                                        </Form.Item>
                                    </div>

                                    <div className={st.addDocPictureButton}>
                                        <div className={st.uploadFileButton}>
                                            <AppButton loading={isLoading} onClick={() => onButtonClick(index)} className={st.newMoveButton}>{userDiplomaUrl.length !== 0 && userDiplomaUrl[index] !== undefined ? "Modifier" : "Importer"}</AppButton>
                                            <input style={{ visibility: "hidden", width: "1px" }} name={`diploma${index}`} value={''} ref={inputRef} type="file" accept=".jpg,.jpeg,.png,.doc,.docx,.pdf" id={st.inputFileUpload} multiple={false} onChange={handleChange} />
                                        </div>

                                        {userDiplomaUrl[index] && <div className={st.previewCertificateDiploma}>
                                            {fileExtensions[index] === "jpg" || fileExtensions[index] === "jpeg" || fileExtensions[index] === "png" ? <img
                                                height={120}
                                                width={120}
                                                src={userDiplomaUrl[index]}
                                                alt="Docs"
                                            /> : <img
                                                height={80}
                                                width={80}
                                                src={docsPicture}
                                                alt="Docs"
                                            />}
                                        </div>}
                                    </div>

                                    <p className={st.docBoxMessageFormat}>Format JPG, PNG, DOC, DOCX, PDF Taille maximale 20 Mo</p>

                                </div>
                                {index !== 0 && <Row style={{ justifyContent: "end" }}><button onClick={() => handleRemoveDiplomaDiv(index)} className={st.removeButton}>Retirer</button></Row>}
                            </div>
                        ))
                    }

                    {isDivVisible && <div className={st.newDocumentbuttonBox}><Button className={st.newActionButton} onClick={() => addNewDiploma(diplomaCount + 1)} >Ajouter un autre diplôme</Button></div>}
                </div>
                <div className={st.rowButtons}>
                    <Button onClick={() => setCurrent((n) => n - 1)} className={st.newMoveButton}>Retour</Button>
                    <Form.Item style={{ margin: 0, padding: 0 }}>
                        <Button loading={isLoading} htmlType="submit" className={st.newMoveButton}>Suivant</Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default StepThree;
