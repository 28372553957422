/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";

// !!ANTD
import { Button, Drawer, Input, message, Popconfirm } from "antd";

// !!IMAGES
import ArrowBack from "../../assets/images/app/arrow_back.svg";
import EllipseWalletTwo from "../../assets/images/app/ellipse_wallet_2.svg";
import EllipseWalletThree from "../../assets/images/app/ellipse_wallet_3.svg";
import EllipseWalletFour from "../../assets/images/app/ellipse_wallet_4.svg";
import EllipseWalletFive from "../../assets/images/app/ellipse_wallet_5.svg";

// !!REDUX
import { useDispatch, useSelector } from "react-redux";

// !!STYLES
import style from "./style.module.css";
// !ICONS
import questionMark from "./question_mark.svg";
// !STORE
import { closePaiementDrawer } from "store/chat/mainSlice";

// !COMPONENTS
import { setShowDrawerPaiementOneMoreTimeHandlerToFalse } from "store/auth/authSlice";
import {
  getConvertedAmountFunction,
  getStripeUrlFunction,
} from "store/paiement/paiementSlice";


// !CONSTANTS
const SENTENCE_LABEL = "Tu as ";

interface DrawerPaiement {
  drawerPaiement?: boolean;
}

// !MEDIA_QUERIES
const smallDevices = window.matchMedia("only screen and (max-width: 992px)");
const mediumDevices = window.matchMedia("only screen and (min-width: 992px)");

export const DrawerPaiementComponent: React.FC<DrawerPaiement> = ({ drawerPaiement }) => {
  // !HOOKS
  const [width, setwidth] = useState<string>();
  const { user } = useSelector((state) => state.auth);
  const { stripeInfos } = useSelector((state) => state.paiement);
  const dispatch = useDispatch();
  const { paiementDrawerOpen } = useSelector((state) => state.main);
  const [montant, setMontant] = useState(user?.coins);

  const visibleHandler = () => {
    if (user?.roles.includes("student")) dispatch(closePaiementDrawer());
  };

  const s_videoSizeController = (x: any) => {
    if (x.matches) {
      setwidth("100%");
    }
  };

  const m_videoSizeController = (x: any) => {
    if (x.matches) {
      setwidth("35%");
    }
  };

  const getConvertedAmount = (amount: String) => {
    if (amount !== "" && amount) {
      dispatch(
        getConvertedAmountFunction({
          amount,
        })
      );
    } else {
      dispatch(getConvertedAmountFunction(0));
    }
  };

  const getStripeUrl = (
    userId: string,
    amount: number,
    success: string,
    cancel: string
  ) => {
    if (amount === 0)
      message.success({
        content: "Veuillez ne pas rentrer un montant égale à 0",
      });
    else {
      dispatch(
        getStripeUrlFunction({
          userId,
          amount,
          success,
          cancel,
        })
      );
    }
  };

  // !USE_EFFECT
  useEffect(() => {
    s_videoSizeController(smallDevices);
    smallDevices.addListener(s_videoSizeController);
    m_videoSizeController(mediumDevices);
    mediumDevices.addListener(m_videoSizeController);
  }, []);

  useEffect(() => {
    getConvertedAmount("0");
    setTimeout(() => {
      dispatch(setShowDrawerPaiementOneMoreTimeHandlerToFalse());
    }, 10000);
  }, []);

  useEffect(() => {
    if (stripeInfos.statusCode === 201) {
      window.open(stripeInfos.data.url);
      location.reload();
    }
  }, [stripeInfos]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);


  return (
    <Drawer
      className="drawer"
      placement="right"
      width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "55%" : isMediumScreen ? "50%" : isNormalScreen ? "45%" : "40%"}
      onClose={visibleHandler}
      visible={paiementDrawerOpen || drawerPaiement}
      closable={true}
      title={<div style={{ fontSize: isMobile ? "0.8em" : "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>Portefeuille</div>}
      headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
      closeIcon={
        <img
          src={ArrowBack}
          height={isMobile ? 10 : 18}
          style={{}}
        ></img>
      }
      drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "50px", borderBottomLeftRadius: isMobile ? "0px" : "50px", boxShadow: "-3px 3px 34px #39393929" }}
    >
      <div className={style.mainBox}>
        <div className={style.soldeBox}>
          <div className={style.soldeBoxTitle}>Ton solde actuel</div>
          <div className={style.amountBox}>
            <span style={{ color: "#4BB980", fontSize: "12px" }}>Solde du compte</span>
            <span style={{ color: "#4BB980", fontSize: "16px" }}>{user?.coins} (€)</span>
          </div>
        </div>
        {!isMobile && <img src={EllipseWalletFive} height={68} style={{ position: "absolute", top: "16%", right: "30%", zIndex: "0" }}></img>}

        <div className={style.detailsBox}>
          <div className={style.titleBox}>
            <span className={style.detailsBoxTitle}>Recharger ton portefeuille</span>
            <span className={style.detailsBoxMessage}>Pense à recharger ton portefeuille<br />pour réserver des cours particuliers.</span>
          </div>

          <Input
            className={style.field}
            type="number"
            placeholder="Merci de saisir le montant"
            min="0"
            onChange={(e) => {
              if (e.target.value === "") {
                setMontant(0);
              } else {
                setMontant(parseInt(e.target.value));
              }
            }}
          />

          <div className={style.buttonBox}>
            <Popconfirm
              placement="top"
              title={"Voulez-vous continuer ?"}
              onConfirm={() =>
                getStripeUrl(
                  user?._id || "",
                  montant,
                  "https://monamialbert.com/achat/success",
                  "https://monamialbert.com/achat/echec"
                )
              }
              okText="Oui"
              cancelText="Non"
              icon={
                <img
                  src={questionMark}
                  alt=""
                  style={{ float: "left", width: "15px" }}
                />
              }
            >
              <Button className={style.newButton}>Recharger</Button>
            </Popconfirm>
          </div>
        </div>
        {!isMobile && <img src={EllipseWalletTwo} height={70} style={{ position: "absolute", top: "52.5%", left: "40px", zIndex: "0" }}></img>}
        {!isMobile && <img src={EllipseWalletThree} height={45} style={{ position: "absolute", top: "30%", left: "30px", zIndex: "0" }}></img>}
        {!isMobile && <img src={EllipseWalletFour} height={50} style={{ position: "absolute", top: "45%", right: "30%", zIndex: "0" }}></img>}
      </div>
    </Drawer>
  );
};
