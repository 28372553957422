import { LogoutOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Card,
  Col,
  Drawer,
  Image,
  Modal,
  Popconfirm,
  Row,
  Steps,
  message,
} from "antd";
import instaBadges from "assets/images/app/InstaProf_Icon.png";
import Calendar from "assets/images/app/header/calendar.svg";
import logo from "assets/images/app/header/logo.svg";
import instaBadgesRed from "assets/images/students/instaProfBadgeFalse.svg";
import { UserRoles } from "common/enums";
import Container from "components/container";
import MAA from "components/maa";
import Notification from "components/notifications/teacher";
import * as appRoutes from "config/routes.config";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LottieAnimation from "screens/teacher/components/LottieAnimation";
import MenuModal from "screens/teacher/components/menu";
import { toggleAppChat } from "store/AppChat/appChat";
import { setCorrectingLevel } from "store/app/appSlice";
import {
  closePorteFeuilleDrawer__,
  closeProfileDrawer,
  logout,
  openPorteFeuilleDrawer__,
  openProfileDrawer,
} from "store/auth/authSlice";
import { openChatDrawer } from "store/chat/mainSlice";
import { clearRoom } from "store/chat/roomSlice";
import { clearRooms } from "store/chat/roomsSlice";
import {
  ACTIVE_DRAWER_ALERT_INSTAPROF,
  ACTIVE_MODE_INSTAPROF,
  INIT_MODAL,
} from "store/notification";
import {
  useAcceptNotificationOfInstaProfMutation,
  useDeleteNotifByUserMutation,
  useGetNotifByUserQuery,
  useViewNotifByUserMutation,
} from "store/services/teacher/notification.services";
import CalendarIcon from "../../assets/images/app/calendar_teacher_icon.svg";
import NavUser from "./NavUser";
import ActivitiesIcon from "./assets/images/ads.svg";
import Classes from "./assets/images/classes.svg";
import Coeur from "./assets/images/coeur.svg";
import Dashboard from "./assets/images/dashboard.svg";
import InfoIcon from "./assets/images/info.svg";
import Maa from "./assets/images/maa.svg";
import MenuIcon from "./assets/images/menu.svg";
import Msg from "./assets/images/msg.svg";
import PointIcon from "./assets/images/points.svg";
import AnimationData from "./assets/images/warning_animation.json";
import style from "./assets/user.header.module.css";
import WhenNotConnected from "./header";

const { Step } = Steps;

const User: FC = () => {
  //!HOOKS
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { isFullscreen } = useSelector((state) => state.app);

  //!RETURN
  return (
    <div>
      {isLoggedIn && !isFullscreen && <WhenConnected />}
      {!isLoggedIn && <WhenNotConnected />}
    </div>
  );
};

/* type MenuProps = {
  isFullscreen?: boolean, 
}  */

const WhenConnected: FC = () => {

  //!HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, currentStudentInstaProf } = useSelector((state) => state.auth);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1151);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1151);
  };
  window.addEventListener('resize', handleResize);

  const { currentChat } = useSelector((state) => state.appChat);

  const {
    data: dataNotif,
    isLoading,
    refetch,
  } = useGetNotifByUserQuery(user._id);
  const { socket } = useSelector((state) => state.appChat);

  const [
    deleteNotifByUser,
    { data: deleteData, isLoading: isLoadingDelete, isSuccess },
  ] = useDeleteNotifByUserMutation();
  const [
    viewNotifByUser,
    { data: viewData, isLoading: isLoadingView, isSuccess: isSuccessView },
  ] = useViewNotifByUserMutation();
  const [
    acceptNotificationOfInstaProf,
    { data: acceptNotif, isSuccess: acceptNotifIsSucces },
  ] = useAcceptNotificationOfInstaProfMutation();

  //!STATE
  const [visible, setVisible] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [moneyDrawer, setMoneyDrawerOpen] = useState(false);
  const [notif, setMotif] = useState<any>([]);
  const [LoadingNotif, setLoadingNotif] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [allDataInstaProf, setAllDataInstaProf] = useState<any>();

  const { isCorrectingLevel } = useSelector((state) => state.app);

  //!FUNCTIONS
  const openPorteFeuilleDrawer = () => dispatch(openPorteFeuilleDrawer__());
  const openDrawer = () => dispatch(openProfileDrawer());
  const closeVideoModal = () => setShowVideoModal(false);
  const closeMoneyDrawer = () => setMoneyDrawerOpen(false);

  //!NOTIFICATIONS
  const text = "voulez-vous supprimer la notification ?";
  const confirm: any = async (id: string) => {
    if (id) {
      await deleteNotifByUser(id);
    }
    if (isSuccess) {
      message.success("Supprimer avec succès");
    } else {
      message.error("Une erreur inconnue a été survenue");
    }
  };

  let filterNotifForMsg = dataNotif?.data?.filter(
    (data: any) => data?.type === "messagerie" && data?.seen === false
  );

  let filterNotifForInstaProf = dataNotif?.data?.filter(
    (data: any) => data?.type === "insta_prof" && data?.seen === false
  );

  const resetCount = async () => {
    let result =
      dataNotif?.data?.filter((element: any) => {
        return element?.seen === false;
      }) || [];

    await result?.forEach((element: any) => {
      if (element?._id) {
        viewNotifByUser(element?._id);
      }
    });
  };

  const TEACHER = [
    {
      id: 1,
      name: "Accueil",
      icon: ActivitiesIcon,
      link: "/teacher/activites",
      size: 40,
    },
    {
      id: 0,
      name: "dashboard",
      icon: Dashboard,
      link: "/teacher/dashboard",
      size: 30,
    },
    {
      id: 3,
      name: "Calendrier",
      icon: CalendarIcon,
      link: "/teacher/calendrier",
      size: 20,
    },
    {
      id: 2,
      name: "Mes élèves",
      icon: Classes,
      link: appRoutes.TEACHER_STUDENTS,
      size: 40,
    },
    {
      id: 3,
      name: "Réservations",
      icon: Calendar,
      link: "/teacher/calendar",
      size: 40,
    },
  ];

  const onAcceptNotifInstaProf = (id: string) => {
    acceptNotificationOfInstaProf(id);
    // onViewNotif(id);
    // setModalAlertInstaProf(false);
  };

  const showModalInfoInstaProf = () => {
    dispatch(ACTIVE_MODE_INSTAPROF(true));
    dispatch(INIT_MODAL());
  };

  const openMessagerieDrawer = () => {
    dispatch(openChatDrawer());
    dispatch(closeProfileDrawer());
    dispatch(closePorteFeuilleDrawer__());
    setVisible(false);
  };

  const resultPercent = user?.points > 10000 ? 100 : user?.points / 100;

  const closeDrawer = () => {
    dispatch(setCorrectingLevel(false));
    dispatch(closeProfileDrawer());
  };

  const disconnect = async () => {
    await socket.emit("unsubscribe", user?._id);
    socket?.disconnect();
    closeDrawer();
    dispatch(clearRoom());
    dispatch(clearRooms());
    dispatch(logout());
    localStorage.clear();
  };

  const setOpenCorrection = () => {
    dispatch(setCorrectingLevel(true));
    openDrawer();
  }

  const setOpenProfile = () => {
    dispatch(setCorrectingLevel(false));
    openDrawer();
  }

  return (
    <Fragment>
      <header className={style.headerTeacher}>
        {!user?.isActive && user?.step === "completed" && <div className={style.topBar}>
          <div className={style.lottieBox}>
            <LottieAnimation animationData={AnimationData} />
          </div>
          {isMobile ? <div>
            <a style={{ fontSize: "12px", fontWeight: "CartoonFont", color: "white" }}>Ton compte est en cours de validation,</a><br />
            <a style={{ fontSize: "12px", fontWeight: "CartoonFont", color: "white" }}>l’accès aux fonctionnalités est limité</a>
          </div> : <div>Ton compte est en cours de validation, l’accès aux fonctionnalités est limité</div>}
        </div>}
        {user?.step === "completed" && user?.nivMat.length === 0 && <div className={style.topBar}>
          <div className={style.lottieBox}>
            <LottieAnimation animationData={AnimationData} />
          </div>
          {isMobile ? <div>
            <a style={{ fontSize: "12px", fontWeight: "CartoonFont", color: "white" }}>Attention, tu ne peux pas ajouter un contenu,</a><br />
            <a style={{ fontSize: "12px", fontWeight: "CartoonFont", color: "white" }}>ou une classe sans renseigner les niveaux enseignés | <span onClick={setOpenCorrection} style={{ color: "#fff", fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}>Corriger</span></a>
          </div> : <div>Attention, tu ne peux pas ajouter un contenu, ou une classe sans renseigner les niveaux enseignés | <span onClick={setOpenCorrection} style={{ color: "#fff", fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}>Corriger</span></div>}
        </div>}
        <Container>
          <div className={style.menuTeacher}>
            {/* <div className={style.logo}>
              <Link to={UserRoles.TEACHER ? user?.step === "completed" ? "/" : appRoutes.COMPLETE_PROFILE : "/"}>
                <Image preview={false} src={logo} width={135} />
              </Link>
            </div> */}
            <Link to={UserRoles.TEACHER ? user?.step === "completed" ? "/" : appRoutes.COMPLETE_PROFILE : "/"}>
              <div className={style.logoContainer}>
                {/* <a className={style.logoTitleHeader}>MON AMI<br />ALBERT</a> */}
                <img src={logo} height={50} alt="logo" ></img>
              </div>
            </Link>

            {/* {isMobile && !user?.isActive && user?.step !== "completed" && <div className={style.__isMobile}>
              <Link to={UserRoles.TEACHER ? user?.step === "completed" ? "/" : appRoutes.COMPLETE_PROFILE : "/"}>
                <Image preview={false} src={logo} width={80} />
              </Link>
            </div>} */}

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              {isMobile && user?.isActive && user?.step === "completed" && <div className={style.notifIcon}>
                <Notification
                  dataNotif={dataNotif}
                  isLoading={isLoading}
                  refetch={refetch}
                />
              </div>}

              {/* {user?.step === "completed" && <div
                className={style.header__menu}
                onClick={() => setVisible(!visible)}
              >
                <img src={MenuIcon} alt="" width={26} />
              </div>} */}
            </div>

            {!user?.isActive && user?.step !== "completed" && <div className={style.__logout}>
              <Popconfirm
                placement="left"
                title={"Tu confirmes la déconnexion ?"}
                onConfirm={disconnect}
                okText="Oui"
                cancelText="Non"
              >
                <LogoutOutlined
                  style={{
                    color: "#FA0000",
                    fontSize: "1.8em",
                  }}
                />
              </Popconfirm>
            </div>}

            {!isMobile && <nav className={style.nav__bar}>
              {user?.step === "completed" && <ul className={`${style.nav__item}`}>
                {TEACHER.map((item, i) => (
                  <li key={i} className={style.__isMobile}>
                    <ul>
                      <li className={style.navbarItemBox}>
                        <Link to={user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : item?.link}>
                          <img
                            src={item.icon}
                            height={item.size}
                          />
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to={user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : item?.link}>{item.name}</Link>
                      </li>
                    </ul>
                  </li>
                ))}

                <li className={`${style.user} ${style.__prof}`}>
                  <Row align="middle">
                    <div className={style.user__info}>
                      <span
                        style={{
                          display: "block",
                          paddingLeft: "6px",
                        }}
                        onClick={setOpenProfile}
                      >
                        {user?.firstname?.split(" ")[0]}
                      </span>
                      <span
                        style={{
                          paddingLeft: "5px",
                          textTransform: "uppercase",
                          fontWeight: "500",
                        }}
                        onClick={setOpenProfile}
                      >
                        Mon profil
                      </span>
                    </div>

                    <div
                      className={style.avatar}
                      style={{
                        position: "relative",
                        display: "block",
                        left: "7px",
                        paddingLeft: "7px",
                      }}
                    >
                      <Avatar
                        style={{ backgroundColor: "#80B1B2" }}
                        size={30}
                        src={
                          <img
                            // fallback={`http://gravatar.com/avatar${user?.uid}?d=identicon`}
                            // preview={false}
                            onClick={setOpenProfile}
                            style={{
                              objectFit: "cover",
                            }}
                            src={
                              user?.profile
                                ? user.profile
                                : `https://gravatar.com/avatar/${user?._id}?d=identicon`
                            }
                          />
                        }
                      >
                        {""}
                      </Avatar>
                    </div>

                    <div className={style.avatar}>
                      <Avatar
                        style={{ backgroundColor: "#80B1B2" }}
                        size={30}
                        src={<Image preview={false} src={Maa} />}
                      ></Avatar>
                    </div>

                    <div
                      className={style.__user__right}
                      onClick={() => setOpen(!open)}
                    >
                      <small className={style.userPointSmall}>
                        {user?.points}{" "}
                        <img
                          src={PointIcon}
                          alt=""
                          width={20}
                          style={{
                            position: "relative",
                            top: "-1px",
                          }}
                        />
                      </small>
                      <Row
                        align="middle"
                        justify="space-between"
                        style={{ marginBottom: "3px" }}
                      >
                        <Col span={3}>
                          <div className={style.cercle}>
                            <img src={Coeur} width={13} />
                          </div>
                        </Col>
                        <Col span={19}>
                          <div className={style.__bar}>
                            <div
                              className={style.progress}
                              style={{
                                width: `${resultPercent}%`,
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <span className={style.__frend}>
                        {user?.points > 0 &&
                          user?.points <= 1000 &&
                          "Tu es mon ami"}

                        {user?.points > 1000 &&
                          user?.points <= 5000 &&
                          "Tu es mon grand ami"}

                        {user?.points > 5000 &&
                          user?.points <= 10000 &&
                          "Tu es mon vieile ami"}

                        {user?.points > 10000 && "Tu es mon meilleur ami"}

                        <img
                          src={InfoIcon}
                          alt=""
                          width={11}
                          style={{
                            position: "relative",
                            top: "-1px",
                            paddingLeft: "2px",
                          }}
                        />
                      </span>
                    </div>
                  </Row>
                </li>

                {user?.instaProf === true && user?.isActive && user?.step === "completed" && !isMobile ? (
                  <Badge
                    className="site-badge-count-109"
                    count={
                      filterNotifForInstaProf?.length > 0
                        ? filterNotifForInstaProf?.length
                        : 0
                    }
                    style={{ backgroundColor: "#0BE39B" }}
                  >
                    <li
                      onClick={() => {
                        dispatch(ACTIVE_DRAWER_ALERT_INSTAPROF());
                        // setCheckIfDrawerAllReqIsVisible(true);
                      }}
                    >
                      <Image preview={false} src={instaBadges} width={37} />
                    </li>
                  </Badge>
                ) : user?.isActive && user?.step === "completed" ? (
                  <li onClick={() => showModalInfoInstaProf()} className={style.__isMobile}>
                    <Image preview={false} src={instaBadgesRed} width={35} />
                  </li>
                ) : ('')}

                <Badge size="small" count={filterNotifForMsg?.length}>
                  <li
                    onClick={() => {
                      // openMessagerieDrawer();
                      dispatch(toggleAppChat(true));
                      resetCount();
                    }}
                    className={style.__isMobile}
                  >
                    <Image preview={false} src={Msg} width={25} />
                  </li>
                </Badge>

                {!isMobile && user?.isActive && user?.step === "completed" && <li className={style.__isNotif}>
                  <Notification
                    dataNotif={dataNotif}
                    isLoading={isLoading}
                    refetch={refetch}
                  />
                </li>}

                <li className={style.__isMobile} onClick={() => setVisible(true)}>
                  <Image preview={false} src={MenuIcon} width={24} />
                </li>
              </ul>}
            </nav>}
          </div>
        </Container>
      </header>

      <Drawer
        width={isMobile ? "100%" : "40vw"}
        title="teacher Money"
        placement="right"
        onClose={closeMoneyDrawer}
        visible={moneyDrawer}
      >
        <Card>
          <Steps direction="vertical" size="small" current={1}>
            <Step title="Faisons connaissance " description="0 point" />

            <Step title="Mon ami" description="100 points" />
            <Step title="Grand ami" description="500 points" />
            <Step title="Vieil ami " description="1000 points" />
            <Step title="Mon meilleur ami" description="5000 points" />
          </Steps>
        </Card>
      </Drawer>

      <Modal
        destroyOnClose
        footer={null}
        width={isMobile ? "100%" : "40vw"}
        closable={false}
        visible={showVideoModal}
        onCancel={closeVideoModal}
        bodyStyle={{ padding: 0, background: "#000", overflow: "hidden" }}
      >
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/S6jCd2hSVKA?autoplay=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Modal>

      <MenuModal
        visible={visible}
        setVisible={setVisible}
        openDrawer={openDrawer}
        openPorteFeuilleDrawer__={openPorteFeuilleDrawer}
      />

      <MAA open={open} setOpen={setOpen} />

      {!currentChat && user?.step === "completed" && (
        <NavUser
          openMessagerieDrawer={openMessagerieDrawer}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </Fragment>
  );
};

export default User;
