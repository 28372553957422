import * as appRoutes from "config/routes.config";
import "katex/dist/katex.min.css";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconDropDown } from "../../../../assets/images/app/dropDownIcon.svg";
import Star from "../../../../assets/images/app/start.svg";
import backgroundImage from "../../../../assets/images/teacher/card.svg";

//!STYLES
import {
  Button,
  Dropdown,
  Menu,
  message,
  Space,
  Tooltip
} from "antd";
import { deleteQuizz } from "api/services/quizz/quizz.services";
import { StatusCode, UserRoles } from "common/enums";
import AppDrawer from "components/appDrawer";
import HelpPopover from "components/help";
import QuizMenu from "components/libs/readyToUse/apercueQuiz/quiz/QuizMenu";
import H2 from "components/libs/subtitle";
import Matiers from "components/matiers";
import { Link } from "react-router-dom";
import { updateItemsQuizzes } from "store/quizz/quizzSlice";
import { formatDates } from "utils/formats";
import AvatarGroup from "../avatarGroup";
import s from "./assets/cardQuizz.module.css";

interface CardCourses {
  quizz?: any;
  className?: string;
}

const CardQuizz: FC<CardCourses> = ({ quizz, className }) => {
  const { user } = useSelector((state) => state.auth);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [currentQuizz, setCurrentQuizz] = useState();
  const dipsatch = useDispatch();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  // !FUNCTIONS
  const deleteQuizzFunction = async (idQuizz: string) => {
    try {
      const response = await deleteQuizz(idQuizz);

      if (response?.statusCode === StatusCode.OK) {
        message.success("Suppression a été bien effectuée");
      } else {
        message.warning("Suppression non effectuée");
      }
    } catch (e: any) {
      message.error("Une erreur a été survenue");
    }
  };
  const isTeacher = user?.roles?.includes(UserRoles.TEACHER);

  const menu = (
    <React.Fragment>
      <Menu className={s.__down}>
        {quizz?.status === "DRAFT" && (
          <React.Fragment>
            {quizz?.teacherId === user?._id ? (
              <React.Fragment>
                {!isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                    key={quizz._id}
                  >
                    Modifier mon quiz
                  </Link>
                </Menu.Item>}

                <Menu.Item>
                  <Link
                    to={"#"}
                    onClick={() => {
                      setCurrentQuizz(quizz?.questions);
                      setDrawer(!drawer);
                    }}
                  >
                    Vue d'ensemble
                  </Link>
                </Menu.Item>

                {!isMobile && <Menu.Item>
                  <Link
                    to={"#"}
                    onClick={() => {
                      dipsatch(updateItemsQuizzes(quizz?._id));
                      deleteQuizzFunction(quizz?._id);
                    }}
                  >
                    Supprimer
                  </Link>
                </Menu.Item>}
                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                      key={quizz._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </React.Fragment>
            ) : (
              <>
                <Menu.Item>
                  <Link to={"#"} onClick={() => setDrawer(!drawer)}>
                    Vue d'ensemble
                  </Link>
                </Menu.Item>

                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                      key={quizz._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </>
            )}
          </React.Fragment>
        )}

        {quizz?.status === "PENDING" && (
          <React.Fragment>
            <Menu.Item>
              <Link
                to={"#"}
                onClick={() => {
                  setCurrentQuizz(quizz?.questions);
                  setDrawer(!drawer);
                }}
              >
                Vue d'ensemble
              </Link>
            </Menu.Item>

            {user.roles.includes("admin") && (
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                  key={quizz._id}
                >
                  Modifier
                </Link>
              </Menu.Item>
            )}
          </React.Fragment>
        )}

        {quizz?.status === "REJECTED" && (
          <React.Fragment>
            <Menu.Item>
              <Link
                to={"#"}
                onClick={() => {
                  setCurrentQuizz(quizz?.questions);
                  setDrawer(!drawer);
                }}
              >
                Vue d'ensemble
              </Link>
            </Menu.Item>

            {user.roles.includes("admin") && (
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                  key={quizz._id}
                >
                  Modifier
                </Link>
              </Menu.Item>
            )}
          </React.Fragment>
        )}

        {quizz?.status === "PUBLISHED" && (
          <React.Fragment>
            {quizz?.teacherId === user?._id ? (
              <>
                <Menu.Item>
                  <Tooltip
                    placement="bottom"
                    title={" Vue d'ensemble mon quiz"}
                  >
                    <Link
                      to={"#"}
                      onClick={() => {
                        setDrawer(!drawer);
                        setCurrentQuizz(quizz?.questions);
                      }}
                    >
                      Vue d'ensemble mon quiz
                    </Link>
                  </Tooltip>
                </Menu.Item>
                {!isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}/edit/propositions`}
                  >
                    Ameliorer mon quiz
                  </Link>
                </Menu.Item>}
                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                      key={quizz._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}

                {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}/duplicate`}
                  >
                    Dupliquer
                  </Link>
                </Menu.Item>}
              </>
            ) : (
              <>
                <Menu.Item>
                  <Link
                    to={"#"}
                    onClick={() => {
                      setDrawer(!drawer);
                      setCurrentQuizz(quizz?.questions);
                    }}
                  >
                    Vue d'ensemble
                  </Link>
                </Menu.Item>
                {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}/edit/propositions`}
                  >
                    Ameliorer
                  </Link>
                </Menu.Item>}
                {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}/duplicate`}
                  >
                    Dupliquer
                  </Link>
                </Menu.Item>}
                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                      key={quizz._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Menu>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }} className={s.card}>
        <div
          style={{
            background: `
              ${quizz?.status === "PUBLISHED"
                ? "#60CD95"
                : quizz?.status === "DRAFT"
                  ? "#064205"
                  : quizz?.status === "PENDING"
                    ? "#FFC8BA"
                    : quizz?.status === "PROPOSITION"
                      ? "#FFDCAB"
                      : ""
              }`,
          }}
          className={s.ribbon}
        >
          <span style={{
            color: `
              ${quizz?.status === "PUBLISHED"
                ? "#FFF"
                : quizz?.status === "DRAFT"
                  ? "#064205"
                  : quizz?.status === "PENDING"
                    ? "#F56666"
                    : quizz?.status === "PROPOSITION"
                      ? "#9A6111"
                      : ""
              }`,
          }}>
            {quizz?.status === "DRAFT" && "Brouillon"}
            {quizz?.status === "PENDING" && "En cours de validation"}
            {quizz?.status === "PUBLISHED" && "Publié"}
            {quizz?.status === "PROPOSITION" && "Proposition"}
          </span>
        </div>

        <div className={s.userGroup}>
          <AvatarGroup user={quizz?.teacher} />
        </div>
        <div className={s.publisherName}>{quizz?.teacher?.firstname + " " + quizz?.teacher?.lastname}</div>
        <div className={s.rateBox}>{quizz?.teacher?.rate || 0} <img src={Star} height={12} style={{ marginLeft: "5px", marginRight: "5px" }}></img> <span>({quizz?.teacher?.rateNum || 0} avis)</span></div>

        <Matiers
          cours={quizz?.course?.title.slice(0, 4) || "null"}
          level={quizz?.course?.level?.title || "null"}
          style={{ marginTop: isMobile ? "0px" : "15px", marginBottom: isMobile ? "5px" : "10px" }}
        />

        <Tooltip placement="top" title={
          <>
            {"Titre : " + quizz?.title}
            <br />
            {"Chapitre : " + quizz?.chapterId?.title}
          </>
        }>
          {!isMobile && <div className={s.courseTitle}>{quizz?.chapterId?.title}</div>}
          {isMobile && <div className={s.courseTitle}>{quizz?.chapterId?.title.length > 20 ? quizz?.chapterId?.title.substring(0, 20) + "..." : quizz?.chapterId?.title}</div>}
        </Tooltip>

        {/* <div className={s.chapter}>
          <img src={CoursICons} width={20} style={{ position: "relative", top: "-1px" }} />
          {quizz?.chapterId?.title}
        </div> */}
        <div className={s.__date}>
          <span>Update : {formatDates(quizz?.updatedAt)}</span>
        </div>

        {isTeacher && <div className={s.buttonsRow}>
          <Dropdown
            className={`${s.dropdown}`}
            overlay={menu}
            placement="bottomCenter"
          >
            <Button>
              <Space>
                Contribuer
                <IconDropDown
                  style={{
                    position: "relative",
                    left: isMobile ? "5px" : "15px",
                  }}
                />
              </Space>
            </Button>
          </Dropdown>
          <div style={{ position: "relative", left: "10px", top: "2px" }}><HelpPopover id={"mon_contenu_contribuer"} /></div>
        </div>}
      </div>


      <AppDrawer
        title={
          <Tooltip title={quizz?.title} placement="bottom">
            <H2>{quizz?.title?.substring(0, 60)}</H2>
          </Tooltip>
        }
        width={"70%"}
        onClose={() => setDrawer(!drawer)}
        visible={drawer}
      >
        <QuizMenu data={currentQuizz}></QuizMenu>
      </AppDrawer>
    </React.Fragment>
  );
};

export default CardQuizz;
