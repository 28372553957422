import * as appRoutes from "config/routes.config";
import { Link, useLocation } from "react-router-dom";

//!IMAGES
import Cours from "assets/images/teacher/cours.svg";
import Exo from "assets/images/teacher/exo.svg";
import Quiz from "assets/images/teacher/quiz.svg";

//!STYLE
import { useState } from "react";
import s from "./style.module.css";

const HeaderTabContent = () => {

  //!HOOKS
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  return (
    <div className={s.tabs}>
      <Link to={appRoutes.TEACHER_COURSES}>
        <div className={`${s.tab} ${location.pathname === appRoutes.TEACHER_COURSES && s.tabActive}`}>
          <img height={isMobile ? 30 : 45} src={Cours} ></img> Cours
        </div>
      </Link>
      <Link to={appRoutes.TEACHER_QUIZZ}>
        <div className={`${s.tab} ${location.pathname === appRoutes.TEACHER_QUIZZ && s.tabActive}`}>
          <img height={isMobile ? 30 : 45} src={Quiz} ></img> Quiz
        </div>
      </Link>
      <Link to={appRoutes.TEACHER_EXERCICES}>
        <div className={`${s.tab} ${location.pathname === appRoutes.TEACHER_EXERCICES && s.tabActive}`}>
          <img height={isMobile ? 18 : 25} src={Exo} style={{ marginRight: "10px" }}></img> Exercices
        </div>
      </Link>
    </div>
  );
};

export default HeaderTabContent;
