import { getToken } from "api";
import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { GET_SUGGESTED_USERS } from "../chat.routes";
import { API_BASE_URL } from "./user.api.routes";

export const findOne = async (
  search: string,
  roles: string[]
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.post(`${API_BASE_URL}`, {
      roles: roles,
      search: search,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSuggestedUser = async (
  id: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${GET_SUGGESTED_USERS}`, { id }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
