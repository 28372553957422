import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LeftOutlined
} from "@ant-design/icons";
import { Button, Card, Switch, Table } from "antd";
import Container from "components/container";
import * as appRoutes from "config/routes.config";
import useQuizz from "hooks/useQuizz.hook";
import { FC } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const EditQuizz: FC = () => {
  //!HOOKS
  const navigate = useNavigate();
  const { id } = useParams<"id">();
  const { data, loading } = useQuizz(id!);

  //!DUMMY DATA
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text?: string) => <span>{!!text ? text : "-"}</span>,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Créé le",
      key: "createdAt",
      dataIndex: "createdAt",
    },
    {
      title: "Correct",
      key: "isCorrect",
      dataIndex: "isCorrect",
      render: (isCorrect?: boolean) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={isCorrect}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (quizz: any) => (
        <Link
          to={`${appRoutes.TEACHER_QUIZZ}/${id}/edit/propositions/${quizz?._id}`}
        >
          <Button type="primary" ghost icon={<EditOutlined />} />
        </Link>
      ),
    },
  ];

  //!FUNCTIONS
  const goToQuizz = () => navigate(appRoutes.TEACHER_QUIZZ);

  return (
    <div>
      <Container>
        <Card
          loading={!!loading}
          title={data?.title}
          extra={
            <Button
              onClick={goToQuizz}
              icon={<LeftOutlined />}
              type="primary"
              ghost
            >
              Retourner au quiz
            </Button>
          }
        >
          <Table
            loading={!!loading}
            columns={columns}
            dataSource={data.propositions}
          />
        </Card>
      </Container>
    </div>
  );
};

export default EditQuizz;
