import { CopyOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, Space, Spin, Tooltip, Upload, message } from "antd";
import axios from "axios";
import { badRequest } from "messages";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
//!STYLES
import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import s from "./styles/style.module.css";

const UploadCloudinary = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string>("");

  const onFinish = async (image: any) => {
    setLoading(true);

    const formData = new FormData();

    formData.append("upload", image);
    formData.append("ckCsrfToken", "4RNIEZCrq0jA624PUf6UIE0N3nX3KIOuVb9rSK2v");

    try {
      const response = await axios.post(`${API_RESSOURCES}/chapters/ressources/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data) {
        setImage(response.data.data); // Extract the data property
      }
    } catch (error) {
      message.error(badRequest.error);
    } finally {
      setLoading(false);
    }
  };

  const props: UploadProps = {
    name: "file",
    maxCount: 1,
    customRequest: async ({ file, onSuccess, onError }) => {
      await onFinish(file);
    },
  };

  const successMessageCopy = () => {
    message.success("Copier avec succès");
    setImage("");
  };

  return (
    <div
      style={{
        marginLeft: "1em",
      }}
    >
      <>
        {loading ? (
          <Spin />
        ) : (
          <>
            {image ? (
              <Space>
                <div className={s.__copy}>
                  <CopyToClipboard text={image}>
                    <div className={s.__ck__math__content}>
                      <span>{image}</span>
                    </div>
                  </CopyToClipboard>
                </div>

                <div className={s.__round__copy}>
                  <CopyToClipboard text={image}>
                    <Tooltip placement="top" title={"Copier le text"}>
                      <CopyOutlined
                        onClick={successMessageCopy}
                        style={{
                          margin: "auto",
                          cursor: "copy",
                          color: "var(--first-color)",
                          fontSize: "15px",
                        }}
                      />
                    </Tooltip>
                  </CopyToClipboard>
                </div>
              </Space>
            ) : (
              <Upload {...props} accept="image/*">
                <Button icon={<UploadOutlined />}>Uploade l'image</Button>
              </Upload>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default UploadCloudinary;
