import { Drawer, Image, Modal } from "antd";
import * as appRoutes from "config/routes.config";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//!STYLES
import s from "./assets/menu.module.css";

//!IMAGES
import CouponIcon from "../../../../assets/images/app/coupon_icon.svg";
import Organisme from "../../../../assets/images/app/home/organisation.svg";
import Teacher from "./assets/images/TeacherSvg.svg";
import Ads from "./assets/images/ads.svg";
import Aide from "./assets/images/aide.svg";
import Calendrier from "./assets/images/calendar.svg";
import Classes from "./assets/images/classes.svg";
import Contact from "./assets/images/contacte-nous.svg";
import Content from "./assets/images/contenu.svg";
import Message from "./assets/images/msg.svg";
import Porfeille from "./assets/images/portfeille.svg";
import FindTeacher from "./assets/images/prof.svg";
import Wallet from "./assets/images/wallet.svg";

//STUDENT
import Student from "./assets/images/student.svg";
import SearchProf from "./assets/images/teacherForstudent.svg";

import Close from "assets/images/app/close.svg";
import { UserRoles } from "common/enums";
import { toggleAppChat } from "store/AppChat/appChat";
import { setCouponDrawer } from "store/auth/authSlice";

const smallDevices = window.matchMedia("only screen and (max-width: 500px)");
const mediumDevices = window.matchMedia("only screen and (min-width: 500px)");

type Props = {
  visible: boolean;
  setVisible: any;
  openDrawer: any;
  openMessagerieDrawer?: any;
  openMessagerieDrawerStudent?: any;
  openPorteFeuilleDrawer__?: any;
  openPaiementDrawer?: any;
};

const MenuModal: FC<Props> = ({
  visible,
  setVisible,
  openDrawer,
  openMessagerieDrawerStudent,
  openPorteFeuilleDrawer__,
  openPaiementDrawer,
}) => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState<boolean>();
  const { user } = useSelector((state) => state.auth);
  const isStudent = user?.roles?.includes(UserRoles.STUDENT);
  const isTeacher = user?.roles?.includes(UserRoles.TEACHER);
  const isParent = user?.roles?.includes(UserRoles.PARENT);
  const isOrganism = user?.roles?.includes(UserRoles.ORGANISME);
  const isAdmin = user?.roles?.includes(UserRoles.ADMIN);

  const openMessagerieDrawer = () => dispatch(toggleAppChat(true));
  const openCouponsDrawer = () => dispatch(setCouponDrawer(true));

  const s_videoSizeController = (x: any) => {
    if (x.matches) {
      setIsMobile(true);
    }
  };

  const m_videoSizeController = (x: any) => {
    if (x.matches) {
      setIsMobile(false);
    }
  };

  // !USE_EFFECT
  useEffect(() => {
    s_videoSizeController(smallDevices);
    smallDevices.addListener(s_videoSizeController);
    m_videoSizeController(mediumDevices);
    mediumDevices.addListener(m_videoSizeController);
  }, []);

  const Data: {
    id: number;
    name: string;
    icon: any;
    link: string;
    openDrawer?: any;
    openMessagerieDrawer?: any;
    openPorteFeuilleDrawer__?: any;
    iconSize?: number;
  }[] = [
      {
        id: 1,
        name: "parametres",
        icon: Teacher,
        link: "#",
        openDrawer: openDrawer,
      },
      {
        id: 2,
        name: "Portefeuille",
        icon: Porfeille,
        link: "#",
        openDrawer: openPorteFeuilleDrawer__,
      },
      {
        id: 3,
        name: "messagerie",
        icon: Message,
        link: "#",
        openDrawer: openMessagerieDrawer,
      },
      {
        id: 7,
        name: "Mon contenu",
        icon: Content,
        link: /* user?.isActive ? "/teacher/courses" :  */appRoutes.COURSESBYLEVELS,
      },
      {
        id: 8,
        name: "Accueil",
        icon: Ads,
        link: user?.step === "completed" ? "/teacher/activites" : appRoutes.COMPLETE_PROFILE,
      },
      // {
      //   id: 8,
      //   name: "Comment ça marche ?",
      //   icon: Interrogation,
      //   link: "#",
      // },
      {
        id: 4,
        name: "Réservations",
        icon: Calendrier,
        link: user?.step === "completed" ? "/teacher/calendar" : appRoutes.COMPLETE_PROFILE,
      },

      {
        id: 9,
        name: "Mes classes",
        icon: Classes,
        link: user?.isActive ? "/teacher/rooms" : appRoutes.COMPLETE_PROFILE,
      },
      {
        id: 5,
        name: "Aide",
        icon: Aide,
        link: appRoutes.FAQ,
      },
      {
        id: 6,
        name: isAdmin ? "Gestion organisme" : "Contacte-nous",
        icon: isAdmin ? Organisme : Contact,
        link: isAdmin ? appRoutes.GESTION_ORGANISME : appRoutes.TEACHER_CONTACT,
      },
      {
        id: 10,
        name: "Mes Coupons",
        icon: CouponIcon,
        link: "#",
        openDrawer: openCouponsDrawer,
        iconSize: 30,
      },
    ];

  const MenuStudent: {
    id: number;
    name: string;
    icon: any;
    link: string;
    openDrawer?: any;
    openPaiementDrawer?: any;
  }[] = [
      {
        id: 1,
        name: "parametres",
        icon: Student,
        link: "/",
        openDrawer: openDrawer,
      },
      {
        id: 2,
        name: "Moyen de paiement",
        icon: Porfeille,
        link: "#",
        openDrawer: openPaiementDrawer,
      },
      {
        id: 3,
        name: "messagerie",
        icon: Message,
        link: "#",
        openDrawer: () => dispatch(toggleAppChat(true)),
      },
      {
        id: 4,
        name: "calendrier",
        icon: Calendrier,
        link: appRoutes.STUDENT_CALENDAR,
      },
      // {
      //   id: 5,
      //   name: "Aide",
      //   icon: Aide,
      //   link: appRoutes.FAQ,
      // },
      {
        id: 5,
        name: "Accueil",
        icon: Ads,
        link: "/teacher/activites",
      },

      {
        id: 6,
        name: "Mon contenu",
        icon: Content,
        link: appRoutes.STUDENT_MY_CONTENT,
      },

      {
        id: 7,
        name: "Trouver un prof",
        icon: SearchProf,
        link: appRoutes.STUDENT_SEARCH_BEST_TEACHER,
      },
      {
        id: 8,
        name: "Mes classes",
        icon: Classes,
        link: appRoutes.STUDENT_MY_CLASS,
      },
      {
        id: 9,
        name: "Contacte-nous",
        icon: Contact,
        link: appRoutes.TEACHER_CONTACT,
      },
    ];

  const MENU_STUDENT_MOBILE = [
    "mon contenu",
    "parametres",
    "calendrier",
    "mes classes",
    "aide",
    "moyen de paiement",
  ];

  const MenuParentOrganism: {
    id: number;
    name: string;
    icon: any;
    link: string;
    openDrawer?: any;
    openPaiementDrawer?: any;
  }[] = [
      {
        id: 1,
        name: "Mes élèves",
        icon: Student,
        link: isParent ? appRoutes.PARENT_DASHBOARD : appRoutes.ORGANISME_DASHBOARD,
      },
      {
        id: 2,
        name: "Annuaire des profs",
        icon: FindTeacher,
        link: isParent ? appRoutes.FIND_STUDENT_TEACHER : appRoutes.ORGANISM_FIND_STUDENT_TEACHER,
      },
      {
        id: 3,
        name: "Messagerie",
        icon: Message,
        link: "#",
      },
      {
        id: 4,
        name: "Portefeuille",
        icon: Wallet,
        link: isParent ? appRoutes.WALLETS : appRoutes.ORGANISM_WALLETS,
      },
      {
        id: 5,
        name: "Contacte-nous",
        icon: Contact,
        link: appRoutes.TEACHER_CONTACT,
      },
      {
        id: 5,
        name: "Aide",
        icon: Aide,
        link: appRoutes.FAQ,
      },
    ];

  return (
    <>
      {isMobile ? (
        <Drawer
          closeIcon={<Image preview={false} src={Close} width={25} />}
          visible={visible}
          height={"100%"}
          onClose={() => setVisible(false)}
          placement="bottom"
        >
          <div className={s.container}>
            <div className={s.row}>
              {isTeacher &&
                Data.map((item: any) => (
                  item.id === 10 && isAdmin ? <></> : <Link
                    to={item?.link}
                    key={item.id}
                    onClick={() => setVisible(false)}
                  >
                    <div className={s.card} onClick={item?.openDrawer}>
                      <div className={s.icon}>
                        <img
                          height={item.id === 10 ? 20 : 55}
                          src={item.icon}
                          alt="logo"
                        ></img>
                      </div>
                      <span>{item.name}</span>
                    </div>
                  </Link>
                ))}
              {isParent &&
                MenuParentOrganism.map((item) => (
                  <Link
                    to={item?.link}
                    key={item.id}
                    onClick={() => setVisible(false)}
                  >
                    <div className={s.card} onClick={item?.openDrawer}>
                      <div className={s.icon}>
                        <Image
                          preview={false}
                          width={55}
                          src={item.icon}
                          alt="logo"
                        />
                      </div>
                      <span>{item.name}</span>
                    </div>
                  </Link>
                ))}
              {isOrganism &&
                MenuParentOrganism.map((item) => (
                  <Link
                    to={item?.link}
                    key={item.id}
                    onClick={() => setVisible(false)}
                  >
                    <div className={s.card} onClick={item?.openDrawer}>
                      <div className={s.icon}>
                        <Image
                          preview={false}
                          width={55}
                          src={item.icon}
                          alt="logo"
                        />
                      </div>
                      <span>{item.name}</span>
                    </div>
                  </Link>
                ))}
              {isStudent &&
                MenuStudent.map((item, index) => {
                  if (isMobile) {
                    if (!MENU_STUDENT_MOBILE.includes(item.name.toLowerCase()))
                      return <></>;
                    return (
                      <Link
                        style={{ order: item.name === "notifs" ? "1" : "3" }}
                        to={item?.link}
                        key={item.id}
                        onClick={() => setVisible(false)}
                      >
                        <div className={s.card} onClick={item?.openDrawer}>
                          <div className={s.icon}>
                            <Image
                              preview={false}
                              width={80}
                              src={item.icon}
                              alt="logo"
                            />
                          </div>
                          <span>{item.name}</span>
                        </div>
                      </Link>
                    );
                  }
                  return (
                    <Link
                      to={item?.link}
                      key={item.id}
                      onClick={() => setVisible(false)}
                    >
                      <div className={s.card} onClick={item?.openDrawer}>
                        <div className={s.icon}>
                          <Image
                            preview={false}
                            width={80}
                            src={item.icon}
                            alt="logo"
                          />
                        </div>

                        <span>{item.name}</span>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </Drawer>
      ) : (
        <Modal
          title={null}
          closable={false}
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={800}
          footer={null}
          className={s.menu__modal}
        >
          <div className={s.container}>
            <div className={s.row}>
              {!isStudent &&
                Data.map((item) => (
                  item.id === 10 && isAdmin ? <></> : <Link
                    to={item?.link}
                    key={item.id}
                    onClick={() => setVisible(false)}
                  >
                    <div className={s.card} onClick={item?.openDrawer}>
                      <div className={s.icon}>
                        <img
                          height={item.id === 10 ? 20 : 55}
                          src={item.icon}
                          alt="logo"
                        />
                      </div>
                      <span>{item.name}</span>
                    </div>
                  </Link>
                ))}
              {isStudent &&
                MenuStudent.map((item) => {
                  if (isMobile) {
                    if (MENU_STUDENT_MOBILE.includes(item.name)) return <></>;
                    return (
                      <Link
                        to={item?.link}
                        key={item.id}
                        onClick={() => setVisible(false)}
                      >
                        <div className={s.card} onClick={item?.openDrawer}>
                          <div className={s.icon}>
                            <Image
                              preview={false}
                              width={55}
                              src={item.icon}
                              alt="logo"
                            />
                          </div>
                          <span>{item.name}</span>
                        </div>
                      </Link>
                    );
                  }
                  return (
                    <Link
                      to={item?.link}
                      key={item.id}
                      onClick={() => setVisible(false)}
                    >
                      <div className={s.card} onClick={item?.openDrawer}>
                        <div className={s.icon}>
                          <Image
                            preview={false}
                            width={55}
                            src={item.icon}
                            alt="logo"
                          />
                        </div>

                        <span>{item.name}</span>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
          <div className={s.close} onClick={() => setVisible(false)}>
            <Image preview={false} src={Close} width={20} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default MenuModal;
