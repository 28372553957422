import {
  Affix,
  Button,
  Form,
  Input,
  Modal,
  Select,
  Skeleton,
  Steps
} from "antd";
import { StatusCode } from "common/enums";
import { useWindowSize } from "hooks/useWindowSize.hook";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchTeacherMutation } from "store/services/student/ads.services";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import Grid from "components/libs/grid";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import CardAdsTeacher from "./components";
import DrawerSearchInstaProf from "./drawerSearchInstaProf";

//!IMAGES
import AlbertSearch from "../../../assets/images/albert/albert_search_teacher.svg";
import SearchArrow from "../../../assets/images/app/arrow_search.svg";
import InstaIcon from "../../../assets/images/app/insta_icon.svg";
import SearchIcon from "../../../assets/images/app/search.svg";
import TeacherIcon from "../../../assets/images/teacher/teacher.svg";

import EllipseOne from "../../../assets/images/app/ellipse_teacher_profile1.png";
import EllipseTwo from "../../../assets/images/app/ellipse_teacher_profile2.png";
import EllipseThree from "../../../assets/images/app/ellipse_teacher_profile3.png";

//!STYLES
import AppResult from "components/appResults";
import { useLocation } from "react-router-dom";
import { setUserConnect } from "store/auth/authSlice";
import { useGetAllLevelsQuery } from "store/services/app/levels.services";
import Pagination from "../../../components/pagination/index";
import TeacherCalendarForStudent from "../components/calendarTeacher";
import s from "./assets/searchTeacher.module.css";

const { Option } = Select;
const { Step } = Steps;
const { Search } = Input;

const SearchTeacher: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const topics = useSelector((state) => state.topics);
  const { data: levelsData } = useGetAllLevelsQuery("");
  const size = useWindowSize();
  const [searchTeacher, { data: teacher, isLoading, isError, isSuccess }] =
    useSearchTeacherMutation();
  const location = useLocation();
  // State to hold the input value
  const [inputValue, setInputValue] = useState('');
  const [formInfos] = Form.useForm();

  //!STATE
  const [openDrawerInstaProf, setOpenDrawerInstaProf] =
    useState<boolean>(false);
  const [tempTopic, setTempTopic] = useState<any>();
  const refSubmiteBtn = useRef<any>(null);
  const [top, setTop] = useState(10);
  const [mobileTop, setMobileTop] = useState(-50);
  const [showDrawerCreProf, setShowDrawerCreProf] = useState<boolean>(false);
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [isBtnInstaProf, setIsBtnInstaProf] = useState<boolean>(false);
  const [idTeacher, setIdTeacher] = useState<any>(null);
  const [currentPage, setcurrentPage] = useState<number>(1);
  const searchParams = new URLSearchParams(location.search);
  const inputParams = searchParams.get('s');

  const [selectedValue, setSelectedValue] = useState<string>('');

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleSelectChange = (value: string) => {
    if (value !== undefined) {
      const [minValue, maxValue] = value.split('-');
      const minField = 'minTarif';
      const maxField = 'maxTarif';
      if (maxValue === 'plus') {
        formInfos.setFieldsValue({
          [minField]: parseInt(minValue)
        });
        formInfos.setFieldsValue({
          ['maxTarif']: undefined
        });
      } else {
        formInfos.setFieldsValue({
          [minField]: parseInt(minValue),
          [maxField]: parseInt(maxValue)
        });
      }

      formInfos.setFieldsValue({
        ['tarif']: undefined
      });
      onSubmit();
    }
  };

  const onShowDrawerCalendarProf = () => {
    if (isLoggedIn) {
      setShowDrawerCreProf(true);
    } else {
      dispatch(setUserConnect(true));
    }
  };

  const showDrawerSearchInstaProf = () => {
    if (isLoggedIn) {
      setOpenDrawerInstaProf(!openDrawerInstaProf);
    } else {
      dispatch(setUserConnect(true));
    }
  };

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      const response = await TOPICS_SERVICES.findAll(levelId);
      if (response?.statusCode === StatusCode.OK) {
        setTempTopic(response?.data);
      }
    },
    [tempTopic]
  );

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const onSubmit = () => {
    refSubmiteBtn?.current?.click();
  };

  const handleSubmit = (e: any) => {
    handleInputChange(e);
    onSubmit();
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const [page, setPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(1);
  const [suggestedTeachers, setSuggestedTeachers] = useState<string[]>([]);

  const handleInstaProf = () => {
    showDrawerSearchInstaProf();
  }

  const handleLevelSelect = (value: string) => {
    OnSelectLevel(value);
    formInfos.setFieldsValue({
      ['level']: value
    });
    localStorage.setItem("level", value);
  }

  const handleTopicSelect = (value: string) => {
    formInfos.setFieldsValue({
      ['matiere']: value
    });
    localStorage.setItem("matiere", value);
  }

  const startSearching = () => {
    onSubmit();
    handleClose();
  }

  useEffect(() => {
    if (teacher?.data?.totalPages !== undefined) {
      setTotalPages(teacher?.data?.totalPages);
    }
  }, [teacher]);

  useEffect(() => {
    if (size.width > 700) {
      setDisplayFilter(true);
    }
  }, [displayFilter, size.width]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const s = searchParams.get('s');

    if (s) {
      setInputValue(s);
      onSubmit();
    }
    else {
      if (isLoggedIn) {
        searchTeacher({ page: currentPage, userId: user?._id });
      }
      else {
        searchTeacher({ page: currentPage });
      }
    }
  }, [currentPage, location.search, inputValue]);

  // Show the modal when the page loads
  useEffect(() => {
    const level = localStorage.getItem("level");
    const matiere = localStorage.getItem("matiere");
    if (level) {
      formInfos.setFieldsValue({
        ['level']: level
      });
      OnSelectLevel(level);
      if (matiere) {
        formInfos.setFieldsValue({
          ['matiere']: matiere
        });
        onSubmit();
      }
      else {
        onSubmit();
      }
    }
    else {
      setIsModalVisible(true);
    }
  }, []);

  const levels = levelsData?.data;

  const [showDiv, setShowDiv] = useState(false);

  const handleScroll = () => {
    const scrollHeight = window.scrollY;
    if (scrollHeight >= 100) {
      setShowDiv(true);
    } else {
      setShowDiv(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      {isMobile && user?.accountManaged !== "" && <div style={{ height: "70px", width: "100%" }}></div>}
      <Breadcrumbs isSearchProf={true} />
      <AppScreen className={s.__searchTeacher}>

        <div className={s.headerBox}>
          <div className={s.titleBox}>
            <div className={s.titlePictureBox}>
              <img src={TeacherIcon} height={isMobile ? 18 : 45}></img>
            </div>
            Trouve le prof parfait pour toi!
          </div>
          <Affix offsetTop={isMobile ? mobileTop : top}>
            <div className={s.buttonBox}>
              <Button className={s.newInstaButton} onClick={showDrawerSearchInstaProf}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                  <img src={InstaIcon} height={isMobile ? 15 : 30} style={{ marginRight: "5px" }}></img>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontSize: isMobile ? "0.75em" : "16px", color: "#FFF", fontFamily: "Rubik", fontWeight: "500", lineHeight: isMobile ? "7px" : "12px" }}>
                    InstaProf
                    <span style={{ fontSize: isMobile ? "0.55em" : "10px", color: "#FFF", fontFamily: "Poppins", fontWeight: "400" }}>Trouver un prof now</span>
                  </div>
                </div>
              </Button>
            </div>
          </Affix>
        </div>

        <Form
          layout="inline"
          name="searchTeacher"
          autoComplete="off"
          form={formInfos}
          initialValues={{ search: inputParams }}
          onFinish={searchTeacher}
        >
          <Button
            htmlType="submit"
            ref={refSubmiteBtn}
            style={{ position: "absolute", left: "-100%" }}
          >
            Go
          </Button>
          <div className={s.searchBox}>
            <div className={s.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: isMobile ? "0.75em" : "14px" }}>Choisir ton niveau</span>
              <Form.Item name="level">
                <Select
                  placeholder={<span style={{ color: "#000", opacity: "0.8", fontSize: isMobile ? "0.75em" : "12px" }}>Niveau</span>}
                  allowClear
                  bordered={false}
                  style={{ width: isMobile ? "75vw" : "205px", position: "relative", left: "-12px", top: isMobile ? "-12px" : "0px", fontSize: isMobile ? "0.8em" : "12px" }}
                  onChange={(value: string) => {
                    OnSelectLevel(value);
                    onSubmit();
                  }}
                >
                  {levels?.Primaire?.map((level: any) => (
                    <Option key={level._id} value={level._id}>
                      {level.title}
                    </Option>
                  ))}
                  {levels?.Collège?.map((level: any) => (
                    <Option key={level._id} value={level._id}>
                      {level.title}
                    </Option>
                  ))}
                  {levels?.Lycée?.map((level: any) => (
                    <Option key={level._id} value={level._id}>
                      {level.title}
                    </Option>
                  ))}
                  {levels?.Prépa?.map((level: any) => (
                    <Option key={level._id} value={level._id}>
                      {level.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className={s.separation}></div>

            <div className={s.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: isMobile ? "0.75em" : "14px" }}>Choisir ta matière</span>
              <Form.Item name="matiere">
                <Select
                  placeholder={<span style={{ color: "#000", opacity: "0.8", fontSize: isMobile ? "0.75em" : "12px" }}>Matière</span>}
                  allowClear
                  bordered={false}
                  style={{ width: isMobile ? "75vw" : "205px", position: "relative", left: "-12px", top: isMobile ? "-12px" : "0px", fontSize: isMobile ? "0.8em" : "12px" }}
                  onChange={() => onSubmit()}
                >
                  {tempTopic?.map((topic: any) => (
                    <Option key={topic._id} value={topic._id}>
                      {topic.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className={s.separation}></div>

            <div className={s.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: isMobile ? "0.75em" : "14px" }}>Choisis ton tarif</span>
              <Form.Item name="tarif">
                <Select
                  placeholder={<span style={{ color: "#000", opacity: "0.8", fontSize: isMobile ? "0.75em" : "12px" }}>Tarifs</span>}
                  allowClear
                  bordered={false}
                  style={{ width: isMobile ? "75vw" : "205px", position: "relative", left: "-12px", top: isMobile ? "-12px" : "0px", fontSize: isMobile ? "0.8em" : "12px" }}
                  onChange={handleSelectChange}
                >
                  <Option value={"1-15"}>1 - 15 €</Option>
                  <Option value={"15-30"}>15 - 30 €</Option>
                  <Option value={"30-50"}>30 - 50 €</Option>
                  <Option value={"50-100"}>50 - 100 €</Option>
                  <Option value={"100-plus"}>100 et plus</Option>
                </Select>
              </Form.Item>
            </div>

            <div className={s.inputBox}>
              <div className={s.filedSearch}>
                <Form.Item name="search">
                  <Input
                    className={s.searchInput}
                    placeholder="Chercher un prof"
                    bordered={false}
                    style={{ textAlign: "left", paddingTop: isMobile ? "3px" : "10px", paddingLeft: isMobile ? "10px" : "30px", paddingRight: isMobile ? "10px" : "30px" }}
                    onChange={() => onSubmit()}
                  />
                </Form.Item>
              </div>
              {isMobile && <div style={{ width: "10%" }}>
                <Form.Item>
                  <Button
                    ref={refSubmiteBtn}
                    shape="circle"
                    htmlType="submit"
                    style={{ background: "#8AB0B1", height: "30px", width: "30px" }}
                  >
                    <img src={SearchIcon} height={10} style={{ filter: 'brightness(0) invert(1)' }}></img>
                  </Button>
                </Form.Item>
              </div>}
            </div>
            {!isMobile && <Form.Item>
              <Button
                ref={refSubmiteBtn}
                shape="circle"
                htmlType="submit"
                style={{ background: "#8AB0B1", height: "45px", width: "45px" }}
              >
                <img src={SearchIcon} height={15} style={{ filter: 'brightness(0) invert(1)' }}></img>
              </Button>
            </Form.Item>}
            <Form.Item name="page" initialValue={1} hidden></Form.Item>
            {isLoggedIn && <Form.Item name="userId" initialValue={user?._id} hidden></Form.Item>}
          </div>
        </Form>

        <div className={s.main}>
          {isLoading && (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}

          {!isLoading && (
            <Grid className={s.lineBox}>
              {teacher?.data?.result?.map((ad: any, index: number) => (
                <div key={ad?._id} onClick={() => setIdTeacher(ad)}>
                  <CardAdsTeacher
                    handleDisplayTeacher={() => null}
                    resetDrawerInstaProf={() => null}
                    handleOpenDrwerProfileTeacher={() => null}
                    // filterTeacherAds={filterTeacherAds}
                    onShowDrawerCalendarProf={onShowDrawerCalendarProf}
                    ad={ad}
                    isBtnInstaProf={isBtnInstaProf}
                    setIsBtnInstaProf={setIsBtnInstaProf}
                    showDrawerSearchInstaProf={showDrawerSearchInstaProf}
                    suggestedTeachers={ad?.recommended}
                  />
                </div>
              ))}
            </Grid>
          )}

          {!isLoading && teacher?.data?.result.length <= 0 && (
            <AppResult status="info" title="Aucun résultat " />
          )}
        </div>
      </AppScreen>

      {!isLoading && teacher?.data?.result.length > 0 && (
        <Pagination
          setcurrentPage={setcurrentPage}
          totalPages={teacher?.data?.totalPages}
          currentPage={currentPage}
        />
      )}

      <DrawerSearchInstaProf
        openDrawerInstaProf={openDrawerInstaProf}
        showDrawerSearchInstaProf={showDrawerSearchInstaProf}
        isBtnInstaProf={isBtnInstaProf}
        setIsBtnInstaProf={setIsBtnInstaProf}
        dataOfTeacher={isBtnInstaProf && idTeacher}
      />

      <TeacherCalendarForStudent
        open={showDrawerCreProf}
        setOpen={setShowDrawerCreProf}
        currentTeacher={idTeacher}
      />

      {showDiv && (
        <div className={s.instaprof__mobile} onClick={handleInstaProf}>
          <span style={{ fontSize: "0.75em" }}>Trouve ton prof now !</span>{" "}
          <Button className={s.buttonInsta}>InstaProf</Button>
        </div>
      )}

      <Modal
        className="searchTeacher"
        centered
        visible={isModalVisible}
        closable={true}
        footer={null}
        onCancel={handleClose}
      >

        <div className={s.searchModalContentBox}>
          {!isMobile && <img src={EllipseOne} style={{ position: "absolute", right: "30px", top: "20px", zIndex: "-1" }}></img>}
          {!isMobile && <img src={EllipseTwo} height={40} style={{ position: "absolute", right: "50px", bottom: "50px", zIndex: "-1" }}></img>}
          {!isMobile && <img src={EllipseThree} height={150} style={{ position: "absolute", left: "30px", bottom: "20px", zIndex: "-1" }}></img>}
          {isMobile && <span className={s.searchContentTitle}>Dis nous ton besoin<br />Je te trouve les meilleures profs</span>}
          <img src={AlbertSearch} className={s.modalImage}></img>
          <div className={s.searchContentBox}>
            {!isMobile && <span className={s.searchContentTitle}>Dis nous ton besoin<br />Je te trouve les meilleures profs</span>}
            {!isMobile && <img src={SearchArrow} height={75} className={s.searchArrow}></img>}
            <span className={s.modalSubtitle}>Je cherche un prof pour la matière au niveau :</span>
            <div className={s.modalForm}>
              <div className={s.modalSelect}>
                <Select
                  placeholder={<span style={{ color: "#B2B2B2", fontSize: isMobile ? "0.75em" : "12px" }}>Choisis un niveau</span>}
                  allowClear
                  bordered={false}
                  style={{ width: isMobile ? "90%" : "170px" }}
                  onChange={handleLevelSelect}
                >
                  {levels?.Primaire?.map((level: any) => (
                    <Option key={level._id} value={level._id}>
                      {level.title}
                    </Option>
                  ))}
                  {levels?.Collège?.map((level: any) => (
                    <Option key={level._id} value={level._id}>
                      {level.title}
                    </Option>
                  ))}
                  {levels?.Lycée?.map((level: any) => (
                    <Option key={level._id} value={level._id}>
                      {level.title}
                    </Option>
                  ))}
                  {levels?.Prépa?.map((level: any) => (
                    <Option key={level._id} value={level._id}>
                      {level.title}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className={s.modalSelect}>
                <Select
                  placeholder={<span style={{ color: "#B2B2B2", fontSize: isMobile ? "0.75em" : "12px" }}>Choisis une matière</span>}
                  allowClear
                  bordered={false}
                  style={{ width: isMobile ? "90%" : "170px" }}
                  onChange={handleTopicSelect}
                >
                  {tempTopic?.map((topic: any) => (
                    <Option key={topic._id} value={topic._id}>
                      {topic.title}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <Button className={s.searchButton} onClick={startSearching}>Lancer</Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default SearchTeacher;
