import { SyncOutlined } from "@ant-design/icons";
import { Col, Image, Row, Skeleton } from "antd";
import { findTopicByIdStudent } from "api/services/topics/topics.services";
import * as appRoutes from "config/routes.config";
import useChapters from "hooks/useChapters";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

//!REDUX
import { useDispatch, useSelector } from "react-redux";
import { onSetIdCours } from "store/courses/coursesSlice";

//!COMPONENTS
import Grid from "components/libs/grid";
import H2 from "components/libs/subtitle";
import CardChapter from "screens/student/components/cardChapitre";
import AppScreen from "screens/teacher/ScreenWithSidebar";

import { TypeMatiers, UserRoles } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";

//!IMAGES

//ICONS
import ANGLAIS from "../../../assets/images/app/iconMatiers/Anglais.png";
import FRANCAIS from "../../../assets/images/app/iconMatiers/Francais.png";
import HISTOIREGEO from "../../../assets/images/app/iconMatiers/Histoire-geo.png";
import MATH from "../../../assets/images/app/iconMatiers/Mathematiques.png";
import PHILO from "../../../assets/images/app/iconMatiers/Philosophie.png";
import PHYSIQUE from "../../../assets/images/app/iconMatiers/Physique-chimie.png";
import SVT from "../../../assets/images/app/iconMatiers/SVT.png";

//!STYLES
import AppResult from "components/appResults";
import AppButton from "components/libs/button";
import s from "./assets/chapitre.module.css";

import {
  getAvancementByCourse,
} from "api/services/quizz/quizz.services";
import { StatusCode } from "common/enums";

const GetAllChapters: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<"id">();
  const { loading, data } = useChapters(params.id!);
  const [topic, setTopic] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const { user } = useSelector((state) => state.auth);
  const isTeacher = user?.roles?.includes(UserRoles.TEACHER);
  const [progress, setProgress] = useState<any>([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const fetchTopicById = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const response = await findTopicByIdStudent(id);
      if (response) {
        setTopic(response.data);
        localStorage.setItem("currentTopic", JSON.stringify(response.data));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  const getProgression = useCallback(
    async (id: string | any, courseId: string | any) => {
      if (user) {
        const response = await getAvancementByCourse(id, courseId);
        if (response?.statusCode === StatusCode.OK) {
          setProgress(response?.data);
        }
      }
    },
    [user]
  );

  useEffect(() => {
    dispatch(onSetIdCours(params.id!));
    fetchTopicById(params.id!);
    getProgression(user?._id, params.id!);
  }, [fetchTopicById]);

  useEffect(() => {
    localStorage.setItem("idCours", params.id!);
  }, []);

  return (
    <React.Fragment>
      <Breadcrumbs isMycontent={true} />
      <div className={s.chapitre}>
        <AppScreen>
          <div className={s.__content__level__matier}>
            <div className={s.content__left}>
              <div className={s.__matier__matier__left}>
                <Image
                  src={
                    topic?.description == TypeMatiers.mathematique
                      ? MATH
                      : topic?.description == TypeMatiers.SVT
                        ? SVT
                        : topic?.description == TypeMatiers.PhysiqueChimie
                          ? PHYSIQUE
                          : topic?.description == TypeMatiers.Philosophie
                            ? PHILO
                            : topic?.description == TypeMatiers.HistoireGeo
                              ? HISTOIREGEO
                              : topic?.description == TypeMatiers.Francais
                                ? FRANCAIS
                                : topic?.description == TypeMatiers.Anglais
                                  ? ANGLAIS
                                  : ""
                  }
                  preview={false}
                  width={80}
                />
              </div>
              <div className={s.__matier__right}>
                <div className={s.__matier__top}>
                  <span>{topic?.level?.title}</span>
                </div>
                <div className={s.__matier__bottom}>
                  <span>{topic?.title}</span>
                </div>
              </div>
            </div>
            <div className={s.__back__level}>
              <Link to={appRoutes.COURSESBYLEVELS}>
                <span>
                  <SyncOutlined /> CHOISIR UNE AUTRE MATIÈRE{" "}
                </span>
              </Link>
            </div>
          </div>
          {/* <H1 className={s.__title}>Mon contenu</H1> */}
          {/* <TabsStudent idChapter={params.id} /> */}

          <div className={s.chapitre__content}>
            <Row justify="space-between">
              <Col>
                <H2 className={s.subtitle}>Chapitres</H2>
              </Col>

              {isTeacher && user?.isActive && user?.step === "completed" && !isMobile && <Col>
                <Link to={appRoutes.TEACHER_CREATE__COURS}>
                  <AppButton>Ajouter un chapitre</AppButton>
                </Link>
              </Col>}
            </Row>

            <div className={s.card__content}>
              {!loading && (
                <Grid>
                  {data?.map((chapter: any) => (
                    <div className="square">
                      <Link
                        to={`${appRoutes.GETCHAPTER}/${chapter?._id}`}
                        className={`${s.__links}`}
                        onClick={() =>
                          localStorage.setItem("chapterId", chapter?._id)
                        }
                      >
                        <CardChapter chapter={chapter} progress={progress} />
                      </Link>
                    </div>
                  ))}
                </Grid>
              )}
            </div>

            {loading && (
              <React.Fragment>
                <Skeleton active />
                <Skeleton active />
              </React.Fragment>
            )}

            {!loading && data.length <= 0 && (
              <AppResult
                status="info"
                title="Aucun contenu disponible pour le moment!"
              />
            )}
          </div>
        </AppScreen>
      </div>
    </React.Fragment>
  );
};

export default GetAllChapters;
