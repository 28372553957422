import {
  LeftOutlined,
  MinusCircleOutlined
} from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Steps,
  TimePicker,
  Typography
} from "antd";
import { Language, StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import React, { useCallback, useState } from "react";

//!SERVICES
import * as ADS_SERVICES from "api/services/advertissements/advertissements.services";
import * as TOPICS_SERVICES from "../../../../api/services/topics/topics.services";

import { findLevelsById } from "api/services/levels/levels.services";

import { findTopicById } from "../../../../api/services/topics/topics.services";

import { useDispatch, useSelector } from "react-redux";
import { displayDrawerUpdate } from "store/adventissements";
import { useGetAdQuery } from "store/services/teacher/ads.services";

//!STYLE
import MatiersAds from "screens/teacher/components/matiersAds";
import { disabledDate } from "utils/disabledDate";
import s from "../assets/adds.module.css";

const { Paragraph, Text } = Typography;
const { Option } = Select;
const { Step } = Steps;

export enum AdSteps {
  first = 0,
  second = 1,
  third = 2,
}

interface FirstStepData {
  title?: string;
  levelId?: string;
  topicId?: string;
  price?: string;
  description?: string;
  language?: any;
}
interface SecondStepData {
  duration?: number | any;
  date?: moment.Moment | any;
  repeat?: boolean;
  niches?: any;
}

const DrawerUpdate = () => {
  //!HOOK
  const { dataAd } = useSelector((state) => state.drawerAds);
  const auth = useSelector((state) => state.auth);
  const topics = useSelector((state) => state.topics);
  const levels = useSelector((state) => state.levels);
  const { drawerUpdate } = useSelector((state) => state.drawerAds);
  const dispatch = useDispatch();
  const { data: adsStore, isLoading } = useGetAdQuery(auth.user?._id);
  const { refetch } = useGetAdQuery(auth.user?._id);
  const [form] = Form.useForm();

  //!STATE
  const [addStep, setAddStep] = useState(0);
  const [firstStepData, setFirstStepData] = useState<FirstStepData>({});
  // const [dataAd, setDataAd] = useState<any>([]);
  const [isValideted, setIsValideted] = useState<boolean>(false);
  const [tempForm, setTempForm] = useState<any>({});
  const [isUpdate, setIsUpdate] = useState<any>(true);
  const [tempNameTopic, setTempNameTopic] = useState<any>(null);
  const [tempNameLevel, setTempNameLevel] = useState<any>(null);
  const [tempTopic, setTempTopic] = useState<any>();
  const [secondStepData, setSecondStepData] = useState<SecondStepData>({});

  //GLOBALS VARIABLES
  let tempUpdateLet: any = {};

  //!FUNCTION
  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      try {
        const response = await TOPICS_SERVICES.findAllQueries(levelId);

        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
        }

        const res = await findLevelsById(levelId);
        if (res?.statusCode === StatusCode.OK) {
          setTempNameLevel(res?.data);
        }
      } catch (error) {
        // message.error("Une erreur non connue s'est produite.");
      }
    },
    [tempTopic, tempNameLevel]
  );

  const getTopic = useCallback(
    async (idTopic: string) => {
      try {
        const response = await findTopicById(idTopic);
        if (response.statusCode === StatusCode.OK) {
          setTempNameTopic(response?.data);
        }
      } catch (error) {
        // message.error("Une erreur non connue s'est produite.");
      }
    },
    [tempNameTopic]
  );

  const onUpdateOneForm = useCallback(
    async (values) => {
      try {
        if (dataAd?._id && isValideted) {
          const response = await ADS_SERVICES.udpdateAd(dataAd?._id, values);
          if (response?.statusCode === StatusCode.OK) {
            refetch();
            message.success("Modification effectuée ");
            setIsValideted(false);
            dispatch(displayDrawerUpdate(false));
          }
        }
      } catch (error) { }
    },
    [dataAd, tempForm, isValideted, dataAd]
  );

  const handleTempForm = useCallback(
    (_?: any, allFields?: any) => {
      allFields
        .filter((field: any) => {
          return field.touched === true;
        })
        .map((c: any) => {
          const key = c?.name[0];
          let value = c?.value;

          if (key === "price") {
            value = parseInt(c?.value);
          }
          tempUpdateLet[key] = value;
        });

      setTempForm({ ...tempUpdateLet });
      onUpdateOneForm(tempUpdateLet);
    },
    [tempForm, tempUpdateLet, isValideted]
  );

  return (
    <Drawer
      title={false}
      placement="right"
      footer={null}
      width={"75%"}
      onClose={() => dispatch(displayDrawerUpdate(false))}
      visible={drawerUpdate}
      closable={false}
      className={s.__drawer}
    // onCancel={() => dispatch(displayDrawerUpdate(false))}
    >
      {addStep === AdSteps.first && <div className={s.__teacher__MAA} />}

      {addStep === AdSteps.second && (
        <div className={s.__teacher__MAA__step_two} />
      )}

      <div
        className={s.__back}
        onClick={() => dispatch(displayDrawerUpdate(true))}
      />
      <div className={s.__drawer__header}>
        <h3>Ajouter une annonce</h3>
      </div>
      <div>
        <div className={s.drawer__form__content}>
          <Form form={form}>
            <Form.Item>
              <Steps current={addStep}>
                <Step />
                <Step />
                <Step />
              </Steps>
            </Form.Item>
          </Form>
        </div>
        {/* firstStepData */}
        <Form
          form={form}
          onFinish={isUpdate && onUpdateOneForm}
          onFieldsChange={handleTempForm}
          initialValues={
            dataAd
              ? {
                title: dataAd?.title,
                levelId: dataAd?.level?.map((l: any) => l?._id),
                topicId: dataAd?.topic?.map((t: any) => t?._id),
                language: dataAd?.language?.map((l: any) => l),
                description: dataAd?.description,
                price: dataAd?.price,
              }
              : firstStepData
          }
          name="control-hooks"
        >
          {addStep === AdSteps.first && (
            <>
              <div className={s.__form}>
                <Form.Item name="title">
                  <Input
                    className={s.__input_price}
                    style={{ height: "42px" }}
                    placeholder="Title"
                  />
                </Form.Item>

                <Form.Item
                  name="teacherId"
                  initialValue={auth.user?._id}
                  hidden
                ></Form.Item>

                <Form.Item
                  name="firstname"
                  initialValue={auth.user?.firstname}
                  hidden
                ></Form.Item>

                <Form.Item
                  name="lastname"
                  initialValue={auth.user?.lastname}
                  hidden
                ></Form.Item>

                <Form.Item name="levelId" rules={[{ required: true }]}>
                  <Select
                    placeholder="Niveau"
                    allowClear
                    loading={levels.loading}
                    mode="multiple"
                    className={`${s.select__input}`}
                    onChange={(value: string) => OnSelectLevel(value)}
                    disabled={isUpdate}
                  >
                    {levels.items.map((level) => (
                      <Option key={level._id} value={level._id}>
                        {level.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Row justify="space-between">
                  <Col>
                    <Form.Item rules={[{ required: true }]} name="topicId">
                      <Select
                        placeholder="Matière"
                        allowClear
                        loading={topics.loading}
                        mode="multiple"
                        className={`${s.select__input}`}
                        onChange={(IdTopic: string) => getTopic(IdTopic)}
                        disabled={isUpdate}
                      >
                        {tempTopic?.map((topic: any) => (
                          <Option key={topic._id} value={topic._id}>
                            {topic.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item name="language" rules={[{ required: true }]}>
                      <Select
                        placeholder="Langues"
                        allowClear
                        mode="multiple"
                        loading={topics.loading}
                        className={`${s.select__input}`}
                      >
                        <Option value={Language.ENGLISH}>Anglais</Option>
                        <Option value={Language.FRENCH}>Français</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="description">
                  <Input.TextArea
                    rows={5}
                    className={s.__text__area}
                    placeholder="Ton annonce ( détails du cours , méthodologie utilisé .. )"
                  />
                </Form.Item>

                <Row>
                  <Col span={4}>
                    <Paragraph style={{ position: "relative", top: "10px" }}>
                      Tarifs horaire{" "}
                    </Paragraph>
                  </Col>
                  <Col
                    span={20}
                    style={{
                      position: "relative",
                    }}
                  >
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Ce champs est requis",
                        },
                        {
                          pattern: /^(?:\d*)$/,
                          message:
                            "La valeur doit contenir uniquement un nombre",
                        },
                        // {
                        //   max: 50,
                        //   message:
                        //     "Value should be less than 50 character",
                        // },
                      ]}
                      name="price"
                    >
                      <Input
                        className={s.__input_price}
                        size="large"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <div className={s.__euro__icon}></div>
                  </Col>
                </Row>
              </div>
              {/* onFill */}
              {/* <span ref={onFillRef} hidden onClick={onFill} /> */}

              {isUpdate ? (
                <Row justify="center">
                  <AppButton
                    className={s.button__submit}
                    onClick={() => setIsValideted(true)}
                    htmlTypeSubmit={true}
                  // onClick={() => setAddStep((curr: number) => curr + 1)}
                  >
                    Mettre à jours
                  </AppButton>
                </Row>
              ) : (
                <Row justify="center">
                  <AppButton className={s.button__submit} htmlTypeSubmit={true}>
                    Suivant
                  </AppButton>
                </Row>
              )}
            </>
          )}
        </Form>

        {/* secondStepData */}
        <Form
          // onFinish={submitSecondStep}
          onFieldsChange={handleTempForm}
          initialValues={
            dataAd
              ? {
                niches: dataAd?.niches?.map((niche: any) => niche?.date),
              }
              : secondStepData
          }
        >
          {addStep === AdSteps.second && (
            <>
              <div className={s.__final__step_form}>
                <p
                  className={s.__available}
                  style={{
                    textAlign: "center",
                    color: "var(--first-color)",
                  }}
                >
                  Je suis disponible le :{" "}
                </p>
                <Form.List name="niches">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Row gutter={20} key={key}>
                          <Col span={5}>
                            <Paragraph
                              style={{
                                position: "relative",
                                top: "10px",
                              }}
                            >
                              Planning du cours
                            </Paragraph>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              name={[name, "date"]}
                              rules={[
                                {
                                  required: isUpdate ? false : true,
                                  message: "Ce champ est requis",
                                },
                              ]}
                            >
                              <DatePicker
                                disabledDate={disabledDate}
                                // disabled={isDisabled}
                                placeholder={
                                  isUpdate && dataAd?.niches[index]?.date
                                }
                                style={{
                                  width: "100%",
                                  height: "45px",
                                  border: "1px solid var(--text-color)",
                                  borderRadius: "15px",
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={9}>
                            <Form.Item
                              {...restField}
                              noStyle
                              name={[name, `time`]}
                              rules={[
                                {
                                  required: isUpdate ? false : true,
                                  message: "Ce champ est requis",
                                },
                              ]}
                            >
                              <TimePicker.RangePicker
                                format={"HH:mm"}
                                placeholder={
                                  isUpdate &&
                                  dataAd?.niches[index]?.timeRanges[0]?.range
                                }
                                style={{
                                  width: "100%",
                                  height: "45px",
                                  border: "1px solid var(--text-color)",
                                  borderRadius: "15px",
                                }}
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col span={2}>
                                    <Form.Item
                                      {...restField}
                                      noStyle
                                      name={[name, `repeat`]}
                                      valuePropName="checked"
                                    >
                                      <Checkbox />
                                    </Form.Item>
                                  </Col> */}
                          <Col span={2}>
                            <MinusCircleOutlined
                              style={{
                                position: "relative",
                                top: "10px",
                              }}
                              onClick={() => remove(name)}
                            />
                          </Col>
                        </Row>
                      ))}
                      <Row justify="center">
                        <Form.Item>
                          <AppButton
                            className={s.__btn_creneau}
                            onClick={() => {
                              add();
                            }}
                          // icon={
                          //   <PLUS
                          //     style={{
                          //       position: "relative",
                          //       top: "20px",
                          //       left: "-5px",
                          //     }}
                          //   />
                          // }
                          >
                            Sélectionner un créneau
                          </AppButton>
                        </Form.Item>
                      </Row>

                      <Row
                        justify="center"
                        style={{
                          position: "relative",
                          top: "6em",
                        }}
                      >
                        <Space>
                          <AppButton
                            onClick={() => setAddStep(AdSteps.first)}
                            className={s.button__submit}
                            htmlTypeSubmit={true}
                          >
                            Précédent
                          </AppButton>
                          {isUpdate ? (
                            <Row justify="center">
                              <AppButton
                                className={s.button__submit}
                                // htmlTypeSubmit={true}
                                onClick={() =>
                                  setAddStep((curr: number) => curr + 1)
                                }
                              >
                                Mettre à jours
                              </AppButton>
                            </Row>
                          ) : (
                            <>
                              {fields.length >= 1 && (
                                <AppButton
                                  className={s.button__submit}
                                  htmlTypeSubmit={true}
                                >
                                  Suivant
                                </AppButton>
                              )}
                            </>
                          )}
                        </Space>
                      </Row>
                    </>
                  )}
                </Form.List>
              </div>
            </>
          )}
        </Form>
        <Form
          form={form}
          onFinish={isUpdate && onUpdateOneForm}
          onFieldsChange={handleTempForm}
        >
          {addStep === AdSteps.third && (
            <>
              <div className={s.__final__step_form}>
                <Form.Item>
                  <Row>
                    <Col span={4}>
                      <h4>Matière :</h4>{" "}
                    </Col>
                    <Col span={20}>
                      {" "}
                      <MatiersAds
                        className={s.__matiers}
                        cours={tempNameTopic?.topic?.map((c: any) => (
                          <span>
                            {c?.title}
                            <span>, </span>
                          </span>
                        ))}
                        level={tempNameLevel?.level?.map((l: any) => (
                          <span>
                            {l?.title} <span>, </span>
                          </span>
                        ))}
                        lang={firstStepData?.language?.map((c: any) => (
                          <span>
                            {c}
                            <span>, </span>
                          </span>
                        ))}
                      />
                      {/* <Matiers
                              cours={tempNameTopic?.title}
                              level={tempNameLevel?.title}
                              className={s.__matiers}
                            />{" "} */}
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Row>
                    <Col span={4}>
                      <h4>Titre :</h4>{" "}
                    </Col>
                    <Col span={20}>
                      {firstStepData?.title || tempForm?.title || dataAd.title}
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Row>
                    <Col span={4}>
                      <h4>Annonce :</h4>{" "}
                    </Col>
                    <Col span={20}>
                      {tempForm?.description ||
                        firstStepData?.description ||
                        dataAd?.description}
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Row>
                    <Col span={4}>
                      <h4>Langue :</h4>{" "}
                    </Col>
                    <Col span={20} style={{ textTransform: "uppercase" }}>
                      <span>
                        {firstStepData?.language
                          ? firstStepData?.language[0]
                          : "" || dataAd?.language
                            ? dataAd?.language[0]
                            : "" || (tempForm?.language && tempForm?.language[0])}
                      </span>
                      {
                        (firstStepData?.language?.length > 1 && "-",
                          dataAd?.language?.length > 1 && "-",
                          tempForm?.language?.length > 1 && "-")
                      }{" "}
                      <span>
                        {firstStepData?.language
                          ? firstStepData?.language[1]
                          : "" || tempForm?.language
                            ? tempForm?.language[1]
                            : "" || (dataAd?.language && dataAd?.language[1])}
                      </span>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Row>
                    <Col span={4}>
                      <h4>Planning :</h4>{" "}
                    </Col>
                    <Col>
                      {secondStepData?.niches ||
                        // tempForm?.niches ||
                        dataAd?.niches?.map((d: any) => (
                          <div>
                            {d?.date}
                            {" - de "}
                            <span>
                              {d?.timeRanges?.map((timeRange: any) => (
                                <React.Fragment>
                                  {timeRange?.range[0]} à {timeRange?.range[1]}
                                </React.Fragment>
                              ))}
                            </span>
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Row>
                    <Col span={4}>
                      <h4>Tarif :</h4>
                    </Col>
                    <Col span={20}>
                      {firstStepData?.price || tempForm?.price || dataAd?.price}{" "}
                      Euro{" "}
                    </Col>
                  </Row>
                </Form.Item>
              </div>

              <Row justify="center">
                <Col>
                  <Space>
                    <AppButton
                      icon={<LeftOutlined />}
                      className={s.button__submit}
                      onClick={() => setAddStep(AdSteps.second)}
                    >
                      Précédent
                    </AppButton>

                    <AppButton
                      // loading={createAdsLoading}
                      className={s.button__submit}
                      htmlTypeSubmit={true}
                      onClick={() => setIsValideted(true)}
                    >
                      valider
                    </AppButton>
                  </Space>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  );
};

export default DrawerUpdate;
