import {
  MinusCircleOutlined,
  PlusOutlined,
  ScanOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Spin,
  Steps,
  message,
} from "antd";
import { fetchCoursForTopic } from "api/services/courses/courses.services";
import * as QUIZZ_API from "api/services/quizz/quizz.services";
import { PlayLevel, StatusCode } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import AppSelectGroup from "components/libs/selectGroup";
import H2 from "components/libs/subtitle";
import H1 from "components/libs/title";
import MathScan from "components/maths";
import * as appRoutes from "config/routes.config";
import useQuizzTeacher from "hooks/useQuizzTeacher.hook";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { findAllQuizz } from "store/quizz/quizzSlice";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";
import { ReactComponent as Back } from "../../../assets/images/app/back.svg";
import AppScreen from "../ScreenWithSidebar";
import s from "../createQuiz/assets/createQuiz.module.css";
import style from "../createQuiz/quiz/assets/quiz.module.css";

const { Step } = Steps;

const QuizzDuplicate = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Option } = Select;
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams<"id">();
  const { data, loading } = useQuizzTeacher(id!);
  //
  const { currentQuizz, currentChapter } = useSelector((state) => state.quizz);
  const { flag, isDraft } = useSelector((s) => s.quizz);
  const { items } = useSelector((state) => state.topics);
  const allCourses = useSelector((state) => state.courses);
  const levels = useSelector((state) => state.levels);
  const topics = useSelector((state) => state.topics);

  //!STATE
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [tempTopic, setTempTopic] = useState<any>(items);
  const [isAddingQuizz, setIsAddingQuizz] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStateDraft, setIsStateDraft] = useState<boolean>(false);
  const [isStatePublished, setIsStatePublished] = useState<boolean>(false);
  const [course, setCourse] = useState<any>(null);
  const [currentCourses, setCurrentCourses] = useState<any>(undefined);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isloadingTopic, setIsloadingTopic] = useState<boolean>(false);

  //!FUNCTION
  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      const response = await TOPICS_SERVICES.findAll(levelId);

      if (response?.statusCode === StatusCode.OK) {
        setTempTopic(response?.data);
      }
    },
    [tempTopic]
  );

  const onDeleteFields = (values: any) => {
    return values?.map(
      (question: any) => (
        delete question?.__v,
        delete question?._id,
        delete question?.createdAt,
        delete question?.updatedAt,
        delete question?.position,
        delete question?.status,
        delete question?.quizz,
        question?.propositions?.map(
          (proposition: any) => (
            delete proposition?.__v,
            delete proposition?._id,
            delete proposition?.createdAt,
            delete proposition?.updatedAt,
            delete proposition?.position,
            delete proposition?.quizz,
            delete proposition?.status,
            delete proposition?.question
          )
        )
      )
    );
  };

  const createQuizz = useCallback(
    async (values: any) => {
      if (currentStep === 1) {
        return setCurrentStep(2);
      }

      const finalData = {
        quizz: {
          title:
            values.quizz.title === undefined ? data.title : values.quizz.title,
          duration:
            values.duration === undefined ? data.duration : values.duration,
          courseId:
            values.courseId === undefined ? data?.course?._id : values.courseId,
          chapterId:
            values.chapterId === undefined
              ? data?.chapterId?._id
              : values.chapterId,
          quizzLevel:
            values.quizzLevel === undefined
              ? data?.quizzLevel
              : values.quizzLevel,
          teacherId: user?._id,
        },
        questions: onDeleteFields(values?.questions),
      };

      try {
        //!REQUEST
        setIsLoading(true);
        if (finalData) {
          let response;
          response = await QUIZZ_API.create(isStateDraft, finalData);
          if (response?.statusCode === StatusCode.CREATED) {
            dispatch(findAllQuizz());
            navigate(appRoutes.TEACHER_QUIZZ);
          } else {
            message.warning(response?.message);
          }
        }
      } catch (error) {
        message.error("Une erreur inconue a été survenue");
      } finally {
        setIsAddingQuizz(false);
        setIsLoading(false);
      }
    },
    [isLoading, isStateDraft, data]
  );

  const OnSelectCours = useCallback(
    async (courseId: any) => {
      setIsLoading(true);
      try {
        if (courseId) {
          const response = await fetchCoursForTopic(courseId);
          if (response?.statusCode === StatusCode.OK) {
            setCourse(response?.data);
            setIsDisabled(false);
          }
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [course, isDisabled]
  );

  return (
    <React.Fragment>
      <Breadcrumbs />
      {!loading ? (
        <Form
          onFinish={createQuizz}
          name="dynamic_form_item"
          initialValues={{
            courseId: data?.course?.title,
            chapterId: data?.chapterId?.title,
            quizzLevel: data?.quizzLevel,
            title: data?.title,
            levelId: data?.course?.level?._id,
            duration: data?.duration,
            quizz: {
              title: data.title,
            },
            questions: data.questions,
          }}
        >
          {currentStep === 1 && (
            <AppScreen className={s.cours}>
              <H1>{data?.title}</H1>
              <div className={s.teacher__maa}></div>
              <div className={s.form__container}>
                <Form.Item>
                  <Steps current={currentStep}>
                    <Step />
                    <Step />
                  </Steps>
                </Form.Item>

                <div className={`${s.select}`}>
                  <Form.Item
                    name="levelId"
                    style={{ marginBottom: "var(--mb-2)" }}
                  >
                    <AppSelectGroup
                      placeholder="Choisir un niveau"
                      initValue={data?.course?.level?._id}
                      onChange={(value: string) => OnSelectLevel(value)}
                    />
                  </Form.Item>
                </div>

                <div className={`${s.select}`}>
                  <Form.Item
                    style={{ marginBottom: "var(--mb-2)" }}
                    name="courseId"
                    rules={[
                      {
                        required: true,
                        message: "Ce champ est requis",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Choisir une matière"
                      allowClear
                      loading={isloadingTopic}
                      bordered={false}
                      onChange={(courseId: string) => {
                        OnSelectCours(courseId);
                      }}
                    >
                      {tempTopic?.map((topic: any) => (
                        <Option key={topic._id} value={topic._id}>
                          {topic.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className={`${s.select}`}>
                  <Form.Item
                    style={{ marginBottom: "var(--mb-2)" }}
                    name="chapterId"
                    rules={[
                      {
                        required: true,
                        message: "Ce champ est requis",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Choisir un cours"
                      allowClear
                      showSearch
                      bordered={false}
                      loading={isDisabled}
                      disabled={isDisabled}
                      optionFilterProp="children"
                      filterOption={(input: string, option: any) =>
                        (option?.label.toLowerCase() ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={course?.map((course: any) => {
                        return {
                          label: course.title,
                          value: course._id,
                        };
                      })}
                      onChange={(_, value: string) => setCurrentCourses(value)}
                    />
                  </Form.Item>
                </div>

                <div className={`${s.select}`}>
                  <Form.Item
                    style={{ marginBottom: "var(--mb-2)" }}
                    name="quizzLevel"
                    rules={[
                      {
                        required: true,
                        message: "Ce champ est requis",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Niveau du quiz"
                      bordered={false}
                      allowClear
                    >
                      <Option value={PlayLevel.EASY}> {PlayLevel.EASY} </Option>
                      <Option value={PlayLevel.MEDIUM}>
                        {" "}
                        {PlayLevel.MEDIUM}{" "}
                      </Option>
                      <Option value={PlayLevel.HARD}> {PlayLevel.HARD} </Option>
                    </Select>
                  </Form.Item>
                </div>

                <Row justify="end">
                  <div className={`${s.select} ${s.temps}`}>
                    <Form.Item
                      name="duration"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est requis",
                        },
                      ]}
                    >
                      <Select placeholder="Durée" bordered={false} allowClear>
                        <Option value={15}>15 min</Option>
                        <Option value={30}>30 min</Option>
                        <Option value={45}>45 min</Option>
                        <Option value={60}>1h</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Row>

                <Row justify="center">
                  <AppButton htmlTypeSubmit={true}>Suivant</AppButton>
                </Row>
              </div>
            </AppScreen>
          )}
          <>
            {currentStep === 2 && (
              <AppScreen className={style.cours}>
                <>
                  <div className={style.header}>
                    <div className={style.left}>
                      <H2 className={style.subtitle}>Quiz {data?.title}</H2>
                    </div>
                    <div className={style.center} />
                    <div className={style.right}>
                      <AppButton
                        icon={
                          <Back
                            style={{
                              position: "relative",
                              top: "4px",
                              right: "5px",
                              width: "15px",
                            }}
                          />
                        }
                        onClick={() => {
                          navigate(appRoutes.TEACHER_QUIZZ);
                        }}
                        className={style.button}
                      >
                        Retour
                      </AppButton>
                    </div>
                  </div>
                  <div className={style.main}>
                    <div className={style.content}>
                      <div className={style.content__header}>
                        <div className={style.header__left}>
                          <span>Niveau : {data?.quizzLevel}</span>
                        </div>
                      </div>

                      <div className={style.content__main}>
                        <div>
                          <div>
                            <Form.Item
                              noStyle
                              name="teacherId"
                              hidden
                              initialValue={user?._id}
                            ></Form.Item>

                            <Row justify="space-between">
                              <Col span={4}>
                                <span>Titre du quiz</span>
                              </Col>
                              <Col span={18}>
                                <div className={style.field}>
                                  <Form.Item name={["quizz", "title"]}>
                                    <Input
                                      placeholder="Titre du quiz"
                                      bordered={false}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <Row justify="space-between">
                            <Col span={4}>
                              <span>Description du quiz</span>
                            </Col>
                            <Col span={18}>
                              <Form.List name="questions">
                                {(fields, { add, remove }, { errors }) => (
                                  <div>
                                    {fields.map((field, index) => (
                                      <Form.Item
                                        required={false}
                                        key={field.key}
                                      >
                                        <>
                                          <Row justify="space-between">
                                            <Col span={20}>
                                              <div className={style.field}>
                                                <Form.Item
                                                  name={[field.name, "title"]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Ce champ est requis",
                                                    },
                                                  ]}
                                                >
                                                  <Input
                                                    bordered={false}
                                                    placeholder="Question"
                                                  />
                                                </Form.Item>
                                              </div>
                                            </Col>

                                            <Col span={4}>
                                              {fields.length > 1 ? (
                                                <MinusCircleOutlined
                                                  style={{
                                                    position: "relative",
                                                    left: "80px",
                                                    top: "13px",
                                                    color: "#FF0100",
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  className={`dynamic-delete-button`}
                                                  onClick={() =>
                                                    remove(field.name)
                                                  }
                                                />
                                              ) : null}
                                            </Col>
                                          </Row>

                                          <Form.List
                                            name={[field.name, "propositions"]}
                                            rules={[
                                              {
                                                validator: async (_, names) => {
                                                  if (
                                                    !names ||
                                                    names.length < 2
                                                  ) {
                                                    return Promise.reject(
                                                      new Error(
                                                        "Ajouter au moins 2 propositions"
                                                      )
                                                    );
                                                  }
                                                },
                                              },
                                            ]}
                                          >
                                            {(
                                              fields,
                                              { add, remove },
                                              { errors }
                                            ) => (
                                              <>
                                                {fields.map(
                                                  (subfield, index) => (
                                                    <div
                                                      className={
                                                        style.__field__content
                                                      }
                                                    >
                                                      <Form.Item
                                                        required={false}
                                                        key={subfield.key}
                                                        style={{
                                                          margin: 0,
                                                          padding: 0,
                                                        }}
                                                      >
                                                        <Row
                                                          gutter={20}
                                                          justify="space-between"
                                                          align="middle"
                                                        >
                                                          <Col span={20}>
                                                            <div
                                                              className={
                                                                style.field
                                                              }
                                                            >
                                                              <Row justify="space-between">
                                                                <Col
                                                                  span={"20"}
                                                                >
                                                                  <Form.Item
                                                                    name={[
                                                                      subfield.name,
                                                                      "title",
                                                                    ]}
                                                                    rules={[
                                                                      {
                                                                        required:
                                                                          true,
                                                                        message:
                                                                          "Ce champ est requis",
                                                                      },
                                                                    ]}
                                                                  >
                                                                    <Input
                                                                      bordered={
                                                                        false
                                                                      }
                                                                      placeholder="Titre de la proposition"
                                                                    />
                                                                  </Form.Item>
                                                                </Col>

                                                                <Col span={1}>
                                                                  <Popover
                                                                    placement="top"
                                                                    title={
                                                                      false
                                                                    }
                                                                    content={
                                                                      <MathScan />
                                                                    }
                                                                    trigger="click"
                                                                  >
                                                                    <ScanOutlined
                                                                      style={{
                                                                        position:
                                                                          "relative",
                                                                        top: "5px",
                                                                      }}
                                                                    />
                                                                  </Popover>
                                                                </Col>
                                                              </Row>
                                                            </div>
                                                          </Col>

                                                          <Col span={2}>
                                                            <Row
                                                              align="middle"
                                                              gutter={15}
                                                              style={{
                                                                marginBottom:
                                                                  "1.5em",
                                                              }}
                                                            >
                                                              <Col>
                                                                <Form.Item
                                                                  name={[
                                                                    subfield.name,
                                                                    "isCorrect",
                                                                  ]}
                                                                  valuePropName="checked"
                                                                  noStyle
                                                                >
                                                                  <Checkbox
                                                                    defaultChecked={
                                                                      false
                                                                    }
                                                                  />
                                                                </Form.Item>
                                                              </Col>

                                                              <Col>
                                                                <div
                                                                  className={`dynamic-delete-button `}
                                                                >
                                                                  <MinusCircleOutlined
                                                                    className={`dynamic-delete-button`}
                                                                    onClick={() =>
                                                                      remove(
                                                                        subfield.name
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                        </Row>
                                                      </Form.Item>
                                                    </div>
                                                  )
                                                )}
                                                <Form.Item>
                                                  <Col span={24}>
                                                    <Button
                                                      block
                                                      className={
                                                        style.__add__input
                                                      }
                                                      onClick={() => add()}
                                                      icon={<PlusOutlined />}
                                                    >
                                                      Ajouter une proposition
                                                    </Button>
                                                  </Col>
                                                  <Col></Col>

                                                  <Form.ErrorList
                                                    errors={errors}
                                                  />
                                                </Form.Item>
                                              </>
                                            )}
                                          </Form.List>
                                        </>
                                      </Form.Item>
                                    ))}
                                    <Form.Item>
                                      <Col span={24}>
                                        <Button
                                          block
                                          className={style.__add__input}
                                          onClick={() => {
                                            add();
                                          }}
                                          icon={<PlusOutlined />}
                                        >
                                          Ajouter une question
                                        </Button>
                                      </Col>
                                      <Col></Col>

                                      <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                  </div>
                                )}
                              </Form.List>
                            </Col>
                          </Row>

                          <Row
                            justify="center"
                            style={{ position: "relative", gap: "25px" }}
                          >
                            <AppButton
                              className={style.__submit__validation}
                              htmlTypeSubmit={true}
                              loading={isLoading}
                              onClick={() => setIsStateDraft(true)}
                            >
                              enregistrer
                            </AppButton>

                            <AppButton
                              className={style.__submit__validation}
                              htmlTypeSubmit={true}
                              loading={isLoading}
                              onClick={() => setIsStateDraft(false)}
                            >
                              Soumettre pour validation
                            </AppButton>
                          </Row>
                          {/* <AppButton
                                htmlTypeSubmit={true}
                                className={s.save}
                              >
                                Soumettre pour validation
                              </AppButton> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </AppScreen>
            )}
          </>
        </Form>
      ) : (
        <Row justify="center">
          <Spin />
        </Row>
      )}
    </React.Fragment>
  );
};

export default QuizzDuplicate;
