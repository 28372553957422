import { RedoOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Steps,
  Tooltip
} from "antd";
import * as appRoutes from "config/routes.config";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

//!IMAGES
import ExerciseIcon from "../../../assets/images/app/Exercices.svg";
import SearchIcon from "../../../assets/images/app/search.svg";

//!STYLES
import AppScreen from "../ScreenWithSidebar";
import styles from "./assets/exercices.module.css";

import { StatusCode, TypeExercices, UserRoles } from "common/enums";

//!COMPONENTS
import { fetchCoursForTopic } from "api/services/courses/courses.services";
import AppResult from "components/appResults";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import AppSelectGroup from "components/libs/selectGroup";
import Pagination from "components/pagination";
import {
  Icourses,
  useGetAllExercicesMutation,
} from "store/services/teacher/courses";
import CardExercice from "../components/cardExo";
import HeaderTabContent from "../components/headerTabContent";

const { Option } = Select;
const { Step } = Steps;
const { Search } = Input;

const Exercises: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();

  const [
    getAllExercices,
    { data, isLoading: isLoadingExo, isSuccess, isError },
  ] = useGetAllExercicesMutation();
  const [form] = Form.useForm();

  //!STATE
  const { user } = useSelector((state) => state.auth);
  const isTeacher = user?.roles?.includes(UserRoles.TEACHER);
  const [courses, setCourses] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tempTopic, setTempTopic] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [course, setCourse] = useState<any>(null);
  const submit = useRef<any>(null);
  const [currentPage, setcurrentPage] = useState<number>(1);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const [title, setTitle] = useState<string>("Exercices");

  const filterExercices: any = useCallback(async (values: any) => {
    await getAllExercices(values);
  }, []);

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      const response = await TOPICS_SERVICES.findAll(levelId);
      if (response?.statusCode === StatusCode.OK) {
        setTempTopic(response?.data);
      }
    },
    [tempTopic]
  );

  const OnSelectCours = useCallback(
    async (courseId: any) => {
      setIsLoading(true);
      try {
        if (courseId) {
          const response = await fetchCoursForTopic(courseId);
          if (response?.statusCode === StatusCode.OK) {
            setCourse(response?.data);
            setIsDisabled(false);
            submit.current.click();
          }
        } else {
          submit.current.click();
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [course, isDisabled]
  );

  const onReset = () => {
    form.resetFields();
    getAllExercices({
      title: "",
      levelId: "",
      topicId: "",
      status: "",
      self: false,
    } as Icourses);
  };

  useEffect(() => {
    getAllExercices({
      title: "",
      levelId: "",
      topicId: "",
      status: "",
      self: false,
      page: currentPage,
      duration: "",
      quizzLevel: ""
    });
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <React.Fragment>
      <Breadcrumbs isMycontent={true} />
      <AppScreen>
        <div className={styles.headerBox}>
          <div className={styles.titleBox}>
            <div className={styles.titlePictureBox}>
              <img src={ExerciseIcon} height={isMobile ? 15 : 35}></img>
            </div>
            {title}
          </div>
          <div className={styles.buttonBox}>
            {isTeacher && user?.isActive && user?.step === "completed" && !isMobile && <Link to={appRoutes.TEACHER_CREATE__EXO}>
              <Button className={styles.newButton}>
                Créer un exercice
              </Button>
            </Link>}
          </div>
        </div>

        <HeaderTabContent />

        <Form
          layout="inline"
          name="control-hooks"
          autoComplete="off"
          onFinish={filterExercices}
          form={form}
        >
          <div className={styles.searchBox}>
            <div className={styles.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: "14px" }}>Choisir ton niveau</span>
              <Form.Item name="levelId">
                <AppSelectGroup
                  placeholder="Niveau"
                  style={{ width: "150px", position: "relative", left: "-12px" }}
                  onChange={(value: string) => (
                    OnSelectLevel(value), submit.current.click()
                  )}
                />
              </Form.Item>
            </div>

            <div className={styles.separation}></div>

            <div className={styles.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: "14px" }}>Choisir ta matière</span>
              <Form.Item name="topicId">
                <Select
                  placeholder={<span style={{ color: "#000", opacity: "0.8" }}>Matière</span>}
                  allowClear
                  bordered={false}
                  style={{ width: "150px", position: "relative", left: "-12px" }}
                  onChange={(courseId: string) => OnSelectCours(courseId)}
                >
                  {tempTopic?.map((topic: any) => (
                    <Option key={topic._id} value={topic._id}>
                      {topic.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className={styles.separation}></div>

            <div className={styles.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: "14px" }}>Choisir le chapitre</span>
              <Form.Item name="chapterId">
                <Select
                  placeholder={<span style={{ color: "#000", opacity: "0.8" }}>Chapitre</span>}
                  allowClear
                  loading={isLoading}
                  disabled={isDisabled}
                  bordered={false}
                  style={{ width: "150px", position: "relative", left: "-12px" }}
                  onChange={() => submit.current.click()}
                >
                  {course?.map((course: any) => (
                    <Option key={course._id} value={course._id}>
                      {course.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className={styles.separation}></div>

            <div className={styles.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: "14px" }}>Choisir le type</span>
              <Form.Item name="chapterId">
                <Select
                  placeholder={<span style={{ color: "#000", opacity: "0.8" }}>Type</span>}
                  allowClear
                  loading={isLoading}
                  disabled={isDisabled}
                  onChange={() => submit.current.click()}
                  bordered={false}
                  style={{ width: "150px", position: "relative", left: "-12px" }}
                >
                  <Option value={TypeExercices.ApplicationsDeCours}>
                    {TypeExercices.ApplicationsDeCours}
                  </Option>
                  <Option value={TypeExercices.Classiques}>
                    {TypeExercices.Classiques}
                  </Option>
                  <Option value={TypeExercices.Annales}>
                    {TypeExercices.Annales}
                  </Option>
                  <Option value={TypeExercices.Entraînement}>
                    {TypeExercices.Entraînement}
                  </Option>
                </Select>
              </Form.Item>
            </div>

            <div className={styles.inputBox}>
              <div className={styles.filedSearch}>
                <Form.Item name="title">
                  <Input
                    className={styles.searchField}
                    placeholder="Chercher ici"
                    bordered={false}
                    style={{ textAlign: "left", paddingTop: isMobile ? "2px" : "10px", paddingLeft: "30px", paddingRight: "30px" }}
                  />
                </Form.Item>
              </div>
            </div>

            <div style={{ width: isMobile ? "10%" : "auto" }}>
              <Form.Item>
                <Button
                  ref={submit}
                  shape="circle"
                  htmlType="submit"
                  style={{ background: "#8AB0B1", height: isMobile ? "35px" : "45px", width: isMobile ? "35px" : "45px", marginLeft: isMobile ? "5px" : "0px" }}
                >
                  <img src={SearchIcon} height={15} style={{ filter: 'brightness(0) invert(1)' }}></img>
                </Button>
              </Form.Item>
            </div>

            {!isMobile && <Tooltip placement="left" title={"Réinitialiser tous les filtres"}>
              <Button
                onClick={onReset}
                shape="circle"
                htmlType="button"
                style={{ height: "45px", width: "45px" }}
              >
                <RedoOutlined />
              </Button>
            </Tooltip>}
          </div>
          <div className={`${title === "Exercices" ? styles.myCoursesBox : styles.myCoursesBoxActive}`}>
            <Form.Item name="self" valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  if (isChecked) {
                    setTitle("Mes exercices");
                  } else {
                    setTitle("Exercices");
                  }
                  submit.current.click();
                }}
                style={{ color: title === "Exercices" ? "#FFF" : "#707070", fontSize: isMobile ? "0.7em" : "14px", width: isMobile ? "5px" : "auto" }}
              >
                {!isMobile && "Mes exercices"}
              </Checkbox>
            </Form.Item>
            {isMobile && "Mes exercices"}
          </div>
        </Form>

        <div style={{ marginBottom: "80px", marginTop: "40px" }}>
          {isLoadingExo && (
            <div>
              <Skeleton active />
              <Skeleton active />
            </div>
          )}
          {!isLoadingExo && data?.data?.result.length > 0 && (
            <div className={styles.exerciseBox}>
              {data?.data?.result.map((exercise: any) => (
                <React.Fragment key={exercise._id}>
                  <CardExercice exercise={exercise} />
                </React.Fragment>
              ))}
            </div>
          )}
          {!isLoadingExo && data?.data?.result.length < 1 && (
            <>
              <Row justify="center" style={{ marginBottom: "15px" }}>
                <Link to={appRoutes.TEACHER_CREATE__EXO}>
                  <AppButton className={styles.addExerciseButton}>Créer un exercice</AppButton>
                </Link>
              </Row>
              <AppResult
                status="info"
                title="Aucun exercice disponible"
              />
            </>
          )}
        </div>
      </AppScreen>
      {!isLoading && data?.data?.result.length > 0 && <Pagination
        totalPages={data?.data?.count}
        setcurrentPage={setcurrentPage}
        currentPage={currentPage}
      />}
    </React.Fragment>
  );
};

export default Exercises;
