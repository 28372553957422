import "katex/dist/katex.min.css";
import { FC } from "react";
import Latex from "react-latex-next";

//!STYLE
import redClose from "../../../../../assets/images/app/closeDeleteIcon.svg";
import s from "./assets/cardResult.module.css";

interface Props {
  res: any;
  resWithAnswer: any;
}
const CardResult: FC<Props> = ({ res, resWithAnswer }) => {
  return (
    <div className={s.body}>
      <h4 className={s.question}>
        <Latex>
          {res?.question?.title
            ? res?.question?.title
              ?.replaceAll("amp;", "")
              ?.replaceAll("<br />", "")
            : ""}
        </Latex>
      </h4>
      <div
        className={`${s.cardResult} ${res?.answer?.isCorrect ? `${s.isCorrect}` : `${s.isNoCorrect}`
          }`}
      >
        <span>
          {!res?.answer?.isCorrect && <img src={redClose} height={25}></img>}
          <Latex>
            {res?.answer?.title
              ? res?.answer?.title
                ?.replaceAll("amp;", "")
                ?.replaceAll("<br />", "")
              : ""}
          </Latex>
        </span>
      </div>
      {!res?.answer?.isCorrect && <div className={s.cardResponse}>
        La réponse correcte est : <Latex>
          {resWithAnswer?.title
            ? resWithAnswer?.title
              ?.replaceAll("amp;", "")
              ?.replaceAll("<br />", "")
            : ""}
        </Latex>
      </div>}
    </div>
  );
};

export default CardResult;
