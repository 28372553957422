import {
    Button,
    Form,
    Input,
    message,
    Select,
    Skeleton
} from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as TEACHER_SERVICES from "../../../api/services/teacher/teacher.services";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

//!IMAGES
import questionPicture from '../../../assets/images/app/question.png';
import TeacherPicture from "../../../assets/images/teacher/teacher.svg";

//!STYLES
import {
    removeDuplicates,
    StatusCode,
} from "common/enums";
import MAA from "components/maa";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "store/auth/authSlice";
import stl from "./assets/register.module.css";
import st from "./assets/stepTwo.module.css";

const { Option } = Select;

type Props = {
    stepSixData: any;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

const StepSeven: FC<Props> = ({ stepSixData, setCurrent }) => {
    //!HOOKS
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [form] = Form.useForm();

    //!STATES
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tempTopic, setTempTopic] = useState<any>([]);

    const refAddCreneau = useRef<any>();
    const refAddMatiere = useRef<any>();
    const [userNivMat, setUserNivMat] = useState(
        user?.nivMat
            ? user.nivMat
            : [{}]
    );

    const [open, setOpen] = useState<boolean>(false);

    const [profilePreview, setProfilePreview] = useState('');

    const [levels, setLevels] = useState<any>([]);
    const [loadingLevels, setLoadingLevels] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<{ [key: string]: any[] }>({});

    const [courses, setCourses] = useState<any>([]);
    const [loadingCourses, setLoadingCourses] = useState<boolean>(false);
    const [selectedCourse, setSelectedCourse] = useState<any>([]);

    const [prices, setPrices] = useState<any>([]);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    //!STATES JOFREY
    const [stepSevenData, setStepSevenData] = useState<any>({});

    //!FUNCTIONS JOFREY
    const moveToStepEight = async (values: any) => {

        setIsLoading(true);

        try {
            const response = await TEACHER_SERVICES.addLevelsCourses(user?._id, selectedData, selectedCourse, prices);
            if (response?.statusCode === StatusCode.CREATED) {
                updateUserData();
                message.success({
                    content: "Modification effectuée ",
                });
            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        }
    }

    const updateUserData = async () => {
        try {
            const finalUser = await AUTH_SERVICES.getUserByUid(user.uid);
            if (finalUser?.data) {
                dispatch(updateUser(finalUser.data));
                setIsLoading(false);
                setCurrent(8);
            } else {
                throw new Error("L'utilisateur n'existe pas");
            }
        } catch (error) { }
    }

    const handleGroupClick = (group: string) => {
        setSelectedData((prev: any) => {
            // Toggle selection: If group is already selected, remove it; otherwise, add it
            if (prev[group]) {
                const { [group]: _, ...rest } = prev;
                return rest;
            }
            return { ...prev, [group]: levels[group] };
        });
    };

    const handleCourseClick = (course: string) => {
        setSelectedCourse((prev: string[]) => {
            if (prev.includes(course)) {
                // Remove the course if it already exists
                return prev.filter((item) => item !== course);
            } else {
                // Add the course if it does not exist
                return [...prev, course];
            }
        });
    };

    const handlePrices = (level: string, value: string | number) => {
        setPrices((prevPrices: any[]) => {
            const updatedPrices = [...prevPrices];
            const existingIndex = updatedPrices.findIndex((p) => p.level === level);
            if (existingIndex !== -1) {
                // Update the existing price
                updatedPrices[existingIndex].price = value;
            } else {
                // Add a new entry
                updatedPrices.push({ levelGroup: level, price: value });
            }
            return updatedPrices;
        });
    };

    const fetchLevels = async () => {
        try {
            setLoadingLevels(true);
            const response = await TOPICS_SERVICES.fetchLevels();
            if (response) {
                const order = ["Primaire", "Collège", "Lycée", "Prépa"];
                const orderedData = Object.fromEntries(
                    order
                        .filter((key) => key in response?.data)
                        .map((key) => [key, response?.data[key]])
                );
                setLevels(orderedData);
            }
        } catch (error) { }
        finally {
            setLoadingLevels(false);
        }
    }

    const fetchCourses = async () => {
        try {
            setLoadingCourses(true);
            const response = await TOPICS_SERVICES.fetchCourses();
            if (response) {
                setCourses(response?.data);
            }
        } catch (error) { }
        finally {
            setLoadingCourses(false);
        }
    }

    //!EFFECTS
    useEffect(() => {
        if (user) {
            setProfilePreview(user?.profile);
        }
    }, [user]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchLevels();
    }, [user]);

    useEffect(() => {
        if (user?.groupLevels.length !== 0) {
            const uniqueData = removeDuplicates(user?.groupLevels);
            uniqueData.forEach((groupData: any) => {
                handleGroupClick(groupData?.levelGroup);
            });
        }
    }, [user, levels]);

    useEffect(() => {
        if (user?.matieres.length !== 0) {
            user?.matieres.forEach((course: any) => {
                handleCourseClick(course);
            });
            setDataLoaded(true);
        }
    }, [levels, courses]);

    useEffect(() => {
        if (dataLoaded && user?.groupLevels.length !== 0 && user?.matieres.length !== 0) {
            user?.groupLevels.forEach((groupData: any) => {
                handlePrices(groupData?.levelGroup, groupData?.price);
            });
        }
    }, [dataLoaded]);

    useEffect(() => {
        if (dataLoaded && user?.groupLevels.length !== 0 && user?.matieres.length !== 0 && prices) {
            prices.forEach((data: any) => {
                form.setFieldsValue({
                    [`price${data.levelGroup}`]: data.price || 0,
                });
            });
        }
    }, [user, dataLoaded, prices, form, selectedCourse]);

    useEffect(() => {
        if (Object.keys(selectedData).length === 0) {
            setCourses([]);
        }
        else {
            fetchCourses();
        }
    }, [selectedData]);

    return (
        <section>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", textAlign: "center" }}>
                <div className={st.avatarBox}>
                    {profilePreview !== "" ? <img src={profilePreview} alt="Uploaded Preview" height={140} style={{ borderRadius: "50%" }}></img> : <img src={TeacherPicture} height={140}></img>}
                </div>
                <p className={stl.pageTitle}>Définissez vos tarifs</p>
                <p className={st.descriptionTitle}>Indiquez vos tarifs pour permettre à vos élèves de choisir facilement<br />les options qui leur conviennent.</p>

                <div className={stl.explanationBox} onClick={() => setOpen(!open)}>
                    <img src={questionPicture} alt="Question Mark" height={25} className={stl.questionMark} />
                    <a className={stl.explanationBoxTitle}> Explication du système du points</a>
                </div>

                <div className={stl.levelsBox}>
                    <span>Je donne des cours en : </span>
                    {loadingLevels ? <Skeleton></Skeleton> : <div className={stl.levelsButtons}>
                        {Object.keys(levels).map((group) => (
                            <button
                                key={group}
                                onClick={() => handleGroupClick(group)}
                                className={`${selectedData[group] ? stl.levelButtonActive : stl.levelButton}`}
                            >
                                {group}
                            </button>
                        ))}
                    </div>}
                </div>

                {courses.length !== 0 && <div className={stl.levelsBox}>
                    <span>J’enseigne les matières : </span>
                    {loadingCourses ? <Skeleton></Skeleton> : <div className={stl.topicButtons}>
                        {courses.map((course: string) => (
                            <button
                                key={course}
                                onClick={() => handleCourseClick(course)}
                                className={`${selectedCourse.includes(course) ? stl.topicButtonActive : stl.topicButton}`}
                            >
                                {course}
                            </button>
                        ))}
                    </div>}
                </div>}

                {Object.keys(selectedData).length !== 0 && selectedCourse.length !== 0 && <div className={stl.pricesBox}>
                    <span>Mes tarifs horaires sont : </span>
                    <div className={stl.priceBox}>
                        {Object.keys(selectedData).map((level) => {
                            return (<div className={stl.price}>
                                <div className={stl.priceLevel}>{level}</div>
                                <Form form={form}>
                                    <Form.Item
                                        name={`price${level}`}
                                        rules={[{ required: true }]}
                                        style={{ marginLeft: "20px" }}
                                    >
                                        <Input
                                            className={stl.field}
                                            placeholder="30"
                                            suffix={"€/Heure"}
                                            type="number"
                                            min={0}
                                            onBlur={(e) => handlePrices(level, e.target.value)}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>);
                        })}
                    </div>
                </div>}

                <div className={stl.rowButtons}>
                    <Button onClick={() => setCurrent((n) => n - 1)} className={stl.newMoveButton}>Retour</Button>
                    <Form.Item style={{ margin: 0, padding: 0 }}>
                        <Button loading={isLoading} htmlType="submit" className={stl.newMoveButton} onClick={moveToStepEight}>Suivant</Button>
                    </Form.Item>
                </div>
            </div>
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                <MAA open={open} setOpen={setOpen} />
            </div>
        </section>
    );
};

export default StepSeven;
