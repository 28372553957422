import { getToken } from "api";
import axios from "axios";
import { ApiResponse } from "common/interfaces";
import {
  API_BASE_URL,
  API_GET_CHAPTERS,
  API_GET_NEW_PROPOSITIONS,
  API_GET_OLD_PROPOSITIONS,
  API_GET_SCHOOLS,
  FIND_ALL,
  TEACHER_FILTER_COURSES,
  findCoursForTopic,
} from "./courses.api.routes";
import { CreateCourse } from "./courses.interface";

export const findAll = async (
  self: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(FIND_ALL(self), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findOne = async (id: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findOneChapters = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}/chapters`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCourse = async (
  course: CreateCourse
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(API_BASE_URL, course, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const teacherFilterCourses = async (
  // title?: string,
  // levelId?: string,
  // course?: string,
  // status?: string
  values: any
) => {
  try {
    const token = await getToken();
    const response = await axios.get(`${TEACHER_FILTER_COURSES(values)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCoursForTopic = async (idTopic: string) => {
  const token = await getToken();
  try {
    const response = await axios.get(`${findCoursForTopic(idTopic)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSchools = async (country: string, system: string) => {
  const token = await getToken();
  try {
    const response = await axios.get(`${API_GET_SCHOOLS}/liste?country=${country}&system=${system}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPropositionOld = async (id: string) => {
  const token = await getToken();
  try {
    const response = await axios.get(`${API_GET_OLD_PROPOSITIONS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPropositionNew = async (id: string) => {
  const token = await getToken();
  try {
    const response = await axios.get(`${API_GET_NEW_PROPOSITIONS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchChapter = async (id: string) => {
  const token = await getToken();
  try {
    const response = await axios.get(`${API_GET_CHAPTERS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
