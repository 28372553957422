import { PlusOutlined } from "@ant-design/icons";
import { Button, Skeleton, Typography } from "antd";
import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

//!STYLES
import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import axios, { AxiosResponse } from "axios";
import AppResult from "components/appResults";
import HelpPopover from "components/help";
import useWebhook from "hooks/useWebHook";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { displayDrawer, displayDrawerAdsSpecific } from "store/adventissements";
import { setIsCalendarUpdated, setUserHavingCalendar } from "store/app/appSlice";
import DrawerAdsCreneauSpecific from "../components/availabilitySpecific/drawer";
import CardCreneau from "./card";
import TeacherCalendarDetails from "./drawerDetails";
import s from "./style.module.css";

//!IMAGES
import { isToday, StatusCode } from "common/enums";
import CalendarPicture from "../../../assets/images/app/calendar_teacher_icon.svg";

interface CustomResponse {
  statusCode: number;
  message: string;
  data: any;
}

type Props = {
  currentTeacher: any;
};

interface Niche {
  date: string;
  level: {
    _id: string;
    title: string;
    levelGroup: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  topic: {
    _id: string;
    title: string;
    level: string;
    status: string;
    position: number;
    language: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    description: string;
    price: string;
  };
  timeRanges: string[];
  idCreneau: string;
  reservations: any[];
  status: any[];
  grouplevels: any[];
  id: number;
  day: string;
}

interface Obj {
  day: string;
  niches: Niche[];
}

const { Text } = Typography;

export const handleRemoveDoublonNiveau = (res: any[]) => {
  return res?.filter(
    (arr: any, index: number, self: any[]) =>
      index ===
      self?.findIndex(
        (t: any) =>
          t?.levelGroup === arr?.levelGroup &&
          t?.levelGroup?._id === arr?.levelGroup?._id
      )
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  className: s.slider,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
  ],
};

const settingsMobile = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: s.slider,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const MainTeacherCalendar: FC<Props> = ({ currentTeacher }) => {

  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [sendWebhook] = useWebhook();
  const [creneau, setCreneau] = useState<any>([]);
  const [index, setIndex] = useState<number>(0);
  const [allData, setAllData] = useState<any>([]);
  const [allSuccessRes, setAllSuccessRes] = useState<any>([]);
  const [allCreneaux, setAllCreneaux] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAllData, setIsAllData] = useState<boolean>(true);
  const [drawerRes, setDrawerRes] = useState<boolean>(false);
  const dateRef = useRef<(HTMLDivElement | null)[]>([]);
  const [confirmReservations, setConfirmRes] = useState<any[]>([]);

  const { isCalendarUpdated } = useSelector((state) => state.app);

  const generateNiches = useCallback(
    (data: any) => {
      const niches = [];
      if (Array.isArray(data?.niches) && data?.niches?.length > 0) {
        const topics = Array.isArray(data?.niches[0]?.topic)
          ? data?.niches[0]?.topic
          : [];
        const levels = Array.isArray(data?.niches[0]?.level)
          ? data?.niches[0]?.level
          : [];
        const topicsLength = topics?.length;
        const levelsLength = levels?.length;

        for (let i = 0; i < topicsLength; i++) {
          for (let j = 0; j < levelsLength; j++) {
            const filteredTopics = data?.niches?.map((n: any) =>
              Array.isArray(n.topic) && Array.isArray(n?.level)
                ? n?.topic.filter(
                  (t: any) =>
                    t?.level === n?.level[j]._id && t?.position === i + 1
                )
                : []
            );

            const foundTopic = filteredTopics?.find((n: any) => n?.length > 0);

            if (foundTopic) {
              const niche = {
                date: data.niches[0].date,
                level: data.niches[0].level[j],
                topic: foundTopic[j],
                timeRanges: data.niches[0].timeRanges[j],
                idCreneau: data.niches[0].idCreneau,
                reservations:
                  data.niches[0] && data.niches[0].reservations
                    ? data.niches[0].reservations[j] || []
                    : [],
                status: data.niches[0].status[j] || [],
                annonce: data.annonce,
                id: j + 1,
                creneauTypes: data.niches[0].creneauTypes[j] || [],
                grouplevels: data.niches[0].grouplevels[j] || "",
                day: data.day,
              };
              niches.push(niche);
            }
          }
        }
      }
      const output = { day: data.day, niches: niches };
      setCreneau(output);
      return output;
    },
    [creneau]
  );

  const fetchCalender = useCallback(
    async (idTeacher: string) => {
      setLoading(true);
      if (user?._id) {
        try {
          const response: AxiosResponse<CustomResponse> = await axios.get(`${API_RESSOURCES}/advertisements/fetch/ReelCalender/prof/instance/${idTeacher}`);

          if (response.status === StatusCode.OK) {
            dispatch(setUserHavingCalendar(true));
          }
          else {
            dispatch(setUserHavingCalendar(false));
          }

          if (response.data?.message === "calender not found") {
            setAllData([]);
            setAllCreneaux([]);
          }
          else {
            setAllData(response.data?.data);
          }

          if (allCreneaux?.length <= 0) {
            const result = response.data?.data
              .filter((data: any) => data?.niches?.length > 0)
              .map((niche: any) => generateNiches(niche));

            setAllCreneaux(result);
          }
        } catch (error) {
          setAllData([]);
          setAllCreneaux([]);
        } finally {
          setLoading(false);
          dispatch(setIsCalendarUpdated(false));
        }
      }
    },
    [user?._id]
  );

  useEffect(() => {
    fetchCalender(user?._id);
  }, [currentTeacher]);

  useEffect(() => {
    fetchCalender(user?._id);
  }, [isCalendarUpdated]);

  useEffect(() => {
    const filterReservations = (allCreneaux: any[]): any[] => {
      const reservations: any[] = allCreneaux.filter((creneau: any) => {
        return creneau.niches.some((niche: any) => {
          return niche.reservations && niche.reservations._id;
        });
      });

      const successReservations: any[] = reservations.reduce(
        (acc: any[], creneau: any) => {
          const niche = creneau.niches[0];
          if (niche.reservations) {
            acc.push({
              ...niche.reservations,
              topic: niche.topic,
            });
          }
          return acc;
        },
        []
      );

      return successReservations;
    };

    const myReservations: any[] = filterReservations(allCreneaux);

    setAllSuccessRes((res: any) => [...res, myReservations]);
  }, [allCreneaux]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className={s.headerBox}>
        <div className={s.titleBox}>
          <div className={s.titlePictureBox}>
            <img src={CalendarPicture} height={isMobile ? 10 : 25}></img>
          </div>
          Mon calendrier
          <div style={{ marginLeft: "10px", marginTop: "5px" }}>
            <HelpPopover id={"dashboard_mon_calendrier"} />
          </div>
          {/* <div style={{ marginLeft: "10px", marginTop: "5px" }}>
                <HelpPopover id={"calendrier_ajouter_un_creneau"} />
              </div> */}
          {/* <div style={{ marginLeft: "10px", marginTop: "5px" }}>
                <HelpPopover id={"calendrier_gestion_et_ajout_disponibilité"} />
              </div> */}
        </div>
        <div className={s.buttonBox}>
          <Button icon={<PlusOutlined />} className={s.addButton} onClick={() => dispatch(displayDrawerAdsSpecific(true))}>
            Créneau unique ponctuel
          </Button>
          <Button className={s.gestionButton} onClick={() => dispatch(displayDrawer(true))}>
            Créneau récurent
          </Button>
        </div>
      </div>

      {allData?.length > 0 && <div className={s.weekBox}>
        <div style={{ width: "5%" }}>
          <Button
            onClick={() => {
              setConfirmRes([]);
              setIsAllData(true);
            }}
            style={{ height: isMobile ? "25px" : "35px", backgroundColor: isAllData ? "var(--first-color)" : "transparent", color: isAllData ? "#fff" : "#333", borderRadius: isMobile ? "12px" : "16px", fontSize: isMobile ? "0.8em" : "14px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            Afficher tout
          </Button>
        </div>
        <div style={{ width: isMobile ? "50%" : "83%", marginRight: isMobile ? "25px" : "20px" }}>
          {!isMobile && <Slider {...settings} arrows={true}>
            {allData?.map((item: any, index: number) => (
              <div
                key={index}
                ref={(el) => (dateRef.current[index] = el)}
                onClick={() => {
                  generateNiches(item);
                  setConfirmRes([]);
                  setIndex(index);
                  setIsAllData(false);
                }}
                className={`${s.date} ${creneau?.day === item?.day && s.active}`}
              >
                <span>{moment(item?.day).format('dddd D MMMM YYYY')}</span>
              </div>
            ))}
          </Slider>}
          {isMobile && <Slider {...settingsMobile} arrows={true}>
            {allData?.map((item: any, index: number) => (
              <div
                key={index}
                ref={(el) => (dateRef.current[index] = el)}
                onClick={() => {
                  generateNiches(item);
                  setConfirmRes([]);
                  setIndex(index);
                  setIsAllData(false);
                }}
                className={`${s.date} ${creneau?.day === item?.day && s.active}`}
              >
                <span>{moment(item?.day).format('dddd D MMMM YYYY')}</span>
              </div>
            ))}
          </Slider>}
        </div>
      </div>}

      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Fragment>
          {allData?.length > 0 ? (
            <div className={s.calendarBox}>
              {isAllData ? (
                allCreneaux?.map((cren: any, index: number) => {
                  return (
                    cren?.niches[0]?.idCreneau !== undefined && <div key={index} style={{ marginBottom: "20px" }}>
                      <div className={s.dateBox}>
                        {new Intl.DateTimeFormat("fr-FR", {
                          weekday: "long",
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        }).format(new Date(cren?.day))}
                        {isToday(cren?.day) && <div className={s.todayBox}>Aujourd’hui</div>}
                        <div className={s.horizontalLine}></div>
                      </div>

                      {isMobile && <div className={s.cardTime}>
                        {cren?.niches?.map((c: any, index: number) => {
                          return (
                            <CardCreneau
                              isAllReservation={false}
                              key={index}
                              cren={c}
                              index={index}
                              currentTeacher={currentTeacher}
                              confirmReservations={confirmReservations}
                              setConfirmRes={setConfirmRes}
                            />
                          );
                        })}
                      </div>}
                      {!isMobile && cren?.niches?.map((c: any, index: number) => {
                        return (
                          <CardCreneau
                            isAllReservation={false}
                            key={index}
                            cren={c}
                            index={index}
                            currentTeacher={currentTeacher}
                            confirmReservations={confirmReservations}
                            setConfirmRes={setConfirmRes}
                          />
                        );
                      })}
                    </div>
                  )
                })
              ) : (
                <div>
                  <div className={s.dateBox}>
                    {new Intl.DateTimeFormat("fr-FR", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }).format(new Date(creneau?.day))}
                    {isToday(creneau?.day) && <div className={s.todayBox}>Aujourd’hui</div>}
                    <div className={s.horizontalLine}></div>
                  </div>

                  {creneau?.niches?.length <= 0 ? (
                    <AppResult
                      status="info"
                      title={`Tu n'as aucune disponibilité pour le ${moment(
                        creneau?.day
                      ).format("LL")}`}
                    />
                  ) : (
                    <Fragment>
                      {isMobile && <div className={s.cardTime}>
                        {creneau?.niches?.map((cren: any, index: number) => {
                          return (
                            <CardCreneau
                              key={index}
                              cren={cren}
                              index={index}
                              isAllReservation={false}
                              currentTeacher={currentTeacher}
                              confirmReservations={confirmReservations}
                              setConfirmRes={setConfirmRes}
                            />
                          );
                        })}
                      </div>}
                      {!isMobile && creneau?.niches?.map((cren: any, index: number) => {
                        return (
                          <CardCreneau
                            key={index}
                            cren={cren}
                            index={index}
                            isAllReservation={false}
                            currentTeacher={currentTeacher}
                            confirmReservations={confirmReservations}
                            setConfirmRes={setConfirmRes}
                          />
                        );
                      })}
                    </Fragment>
                  )}
                </div>
              )}
            </div>
          ) : (
            <AppResult
              status={"info"}
              title="Tu n'as aucune disponibilité"
              isMobile={isMobile ? true : false}
            />
          )}
        </Fragment>
      )}

      <TeacherCalendarDetails
        currentTeacher={currentTeacher}
        drawerRes={drawerRes}
        allSuccessRes={allSuccessRes}
        setDrawerRes={setDrawerRes}
      />
      <DrawerAdsCreneauSpecific />
    </div>
  );
};

export default MainTeacherCalendar;
