import { Button, Drawer, Form, Input, message, Select, Skeleton, Spin } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { StatusCode } from "common/enums";
import AppResult from "components/appResults";
import AppButton from "components/libs/button";
import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import IconTopic from "screens/student/searchTeacher/components/iconTopic";
import { handleClearNiveau, handleClearTopic } from "utils/formats";
import * as TEACHER_SERVICES from "../../../api/services/teacher/teacher.services";
import BackArrow from "../../../assets/images/app/arrow_back_circle.svg";
import Wallet from "../../../assets/images/app/header/wallet.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/app/search.svg";
import starCoins from "../../../assets/images/app/star-coins.png";
import Student from "../../../assets/images/students/student.svg";
import Teacher from "../../../assets/images/teacher/teacher.svg";
import AppScreen from "../ScreenWithSidebar";
import s from "./style.module.css";

type Props = {
    organimseId: string;
    organimseName: string;
    organismeCredit: any;
    students: any;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    setOrganismeCredit: React.Dispatch<React.SetStateAction<number>>;
    setStudents: React.Dispatch<React.SetStateAction<Array<any>>>;
}

const { Option } = Select;

const CreditOrganisme: FC<Props> = ({ organimseId, organimseName, organismeCredit, students, setCurrent, setOrganismeCredit, setStudents }) => {

    const [organismeCredits, setOrganismeCredits] = useState<any>(organismeCredit);
    const [selectedStudent, setSelectedStudent] = useState<any>(null);
    const [studentCredit, setStudentCredit] = useState<any>(0);
    const [currentPage, setcurrentPage] = useState<any>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [teachersList, setTeachersList] = useState<any>([]);
    const [attributedTeachersList, setAttributedTeachersList] = useState<any>([]);
    const { user } = useSelector((state) => state.auth);
    const refSubmiteBtn = useRef<any>(null);
    const [searchValue, setSearchValue] = useState("");

    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isAttributionDrawerVisible, setIsAttributionDrawerVisible] = useState(false);
    const [selectedTeacher, setSelectedTeacher] = useState<any>(null);
    const [price, setPrice] = useState<any>();
    const [cost, setCost] = useState<any>();
    const [creditAllowed, setCreditAllowed] = useState<any>();
    const [teacherLevels, setTeacherLevels] = useState<any>();
    const [selectedLevel, setSelectedLevel] = useState<any>();
    const [tempTopic, setTempTopic] = useState<any>();
    const [isloadingTopic, setIsloadingTopic] = useState<boolean>(false);
    const [showTeacherPrice, setShowTeacherPrice] = useState<boolean>(false);
    const [teacherDataLoaded, setTeacherDataLoaded] = useState<boolean>(false);
    const [selectedTopic, setSelectedTopic] = useState<any>();
    const [teacherPrice, setTeacherPrice] = useState<any>();

    const handleStudentClick = (student: any) => {
        setSelectedStudent(student);
        getTeachers();

    };

    const getTeachers = async () => {
        try {
            setIsLoading(true);
            setTeachersList([]);
            const res = await AUTH_SERVICES.getAllTeachers(currentPage);
            if (res?.data) {
                setTeachersList(res.data.result);
            } else {
                throw new Error("Error");
            }
        } catch (error) { }
        finally {
            setIsLoading(false);
        }
    }

    const onSubmit = () => {
        refSubmiteBtn?.current?.click();
    };

    const handleTeacherClick = (teacher: any) => {
        const selectedItem = teachersList.find((item: any) => (item?.teacher?.firstname === teacher?.profObject?.firstname && item?.teacher?.lastname === teacher?.profObject?.lastname));
        setSelectedTeacher(selectedItem);
        handleStudentAttribution(selectedItem?.teacher?._id);
        setIsAttributionDrawerVisible(false);
    };

    // Open drawer with selected teacher details
    const handleButtonClick = (teacher: any) => {
        setSelectedTeacher(teacher);
        handleStudentAttribution(teacher?.teacher?._id);
        handleTeacherLevels(teacher);
    };

    // Close the drawer
    const handleCloseDrawer = () => {
        setIsDrawerVisible(false);
        setSelectedTeacher(null);
        setSelectedLevel(null);
        setSelectedTopic(null);
        setShowTeacherPrice(false);
        setPrice(null);
        setCost(null);
    };

    // Close the drawer
    const handleCloseAttributionDrawer = () => {
        setIsAttributionDrawerVisible(false);
    };

    const handleSearchChange = (e: any) => {
        setSearchValue(e.target.value);
        if (e.target.value.length > 0) {
            const filterTeacher = teachersList.filter((item: any) =>
                item?.teacher.firstname.toLowerCase().includes(e.target.value.toLowerCase()) ||
                item?.teacher.lastname.toLowerCase().includes(e.target.value.toLowerCase()) ||
                item?.teacher.email.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setTeachersList(filterTeacher);
        }
        else {
            getTeachers();
        }
    };

    const handleConfirmation = async () => {
        try {
            setLoading(true);
            let newCredit;

            if (typeof creditAllowed === 'number') {
                newCredit = price - creditAllowed;
            } else {
                newCredit = price;
            }

            const response = await TEACHER_SERVICES.setStudentCredit(organimseId, selectedTeacher?.teacher?._id, selectedStudent?.key, newCredit, cost, selectedTopic, selectedLevel);
            if (response?.statusCode === StatusCode.CREATED) {
                setOrganismeCredits(response?.data?.organismeCredits);
                setOrganismeCredit(response?.data?.organismeCredits);
                setStudentCredit(response?.data?.studentCredits);
                updateStudentCredit(selectedStudent?.key, response?.data?.studentCredits);
                message.success({
                    content: "Crédit attribué avec succès.",
                });
                setSelectedLevel(null);
                setSelectedTopic(null);
                setShowTeacherPrice(false);
                setPrice(null);
                setCost(null);
                if (creditAllowed === 0) {
                    await AUTH_SERVICES.suggestTeacher(organimseId, selectedTeacher?.teacher?._id);
                }
            } else {
                message.warning({
                    content: "Erreur d'attribution de crédit à ce prof, merci de réessayer.",
                });
            }
        } catch (error) {
            message.warning({
                content: "Erreur d'attribution de crédit à ce prof, merci de réessayer.",
            });
        }
        finally {
            setLoading(false);
            setIsDrawerVisible(false);
        }
    }

    const updateStudentCredit = (studentKey: any, newCredit: any) => {
        setStudents((prevStudents: any) =>
            prevStudents.map((student: any) =>
                student.key === studentKey
                    ? { ...student, credits: newCredit }
                    : student
            )
        );
    };

    const handleStudentAttributions = async () => {
        try {
            const response = await TEACHER_SERVICES.getStudentsAttibutions("", selectedStudent?.key);
            if (response?.statusCode === StatusCode.OK) {
                setAttributedTeachersList(response?.data);
            } else {
                throw new Error("Error");
            }
        } catch (error) { }
        finally {
            setIsAttributionDrawerVisible(true);
        }
    }

    const handleStudentAttribution = async (teacherId: string) => {
        try {
            const response = await TEACHER_SERVICES.getStudentsAttibutions(teacherId, selectedStudent?.key);
            if (response?.statusCode === StatusCode.OK) {
                const credit = response?.data[0]?.creditsForStudent;
                if (typeof credit === 'number') {
                    setCreditAllowed(credit);
                } else {
                    setCreditAllowed(0);
                }
            } else {
                throw new Error("Error");
            }
        } catch (error) { }
        finally {
            setIsDrawerVisible(true);
        }
    }

    const handleTeacherLevels = (teacher: any) => {
        const levels = teacher?.teacher?.nivMat.map((item: any) => ({
            id: item.niveau._id,
            title: item.niveau.title,
        }));
        setTeacherLevels(levels);
        setTeacherDataLoaded(true);
    }

    // Handle selection of niveau
    const handleNiveauChange = (niveauId: any) => {
        const selectedData = selectedTeacher?.teacher?.nivMat.find((item: any) => item.niveau._id === niveauId);
        if (selectedData) {
            const topics = selectedData.matieres.map((matiereItem: any) => ({
                id: matiereItem.matiere._id,
                title: matiereItem.matiere.title,
            }));
            setTempTopic(topics);
        } else {
            setTempTopic([]);
        }
        const levelData = selectedTeacher?.teacher?.groupLevels.find((item: any) => item.levelGroup === selectedData?.niveau?.levelGroup);
        setTeacherPrice(levelData ? levelData.price : 0);
    };

    useEffect(() => {
        setSelectedStudent(students[0]);
    }, [students]);

    useEffect(() => {
        setStudentCredit(selectedStudent?.credits);
    }, [selectedStudent]);

    useEffect(() => {
        getTeachers();
    }, [user]);

    useEffect(() => {
        if (tempTopic) {
            setIsloadingTopic(false);
        }
    }, [tempTopic]);

    useEffect(() => {
        if (creditAllowed !== undefined) {
            setPrice(creditAllowed);
        }
        else if (creditAllowed !== null) {
            setPrice(creditAllowed);
        }
        else {
            setPrice(0);
        }
    }, [creditAllowed]);

    useEffect(() => {
        if (selectedTopic) {
            setShowTeacherPrice(true);
        }
        else {
            setShowTeacherPrice(false);
        }
    }, [selectedTopic]);

    return (
        <React.Fragment>
            {/* <Breadcrumbs isBack={false} /> */}
            <AppScreen>
                <div style={{ display: "flex", flexDirection: "column", marginBottom: "0px" }}>
                    <div style={{ display: "flex", flexDirection: "row", background: "#FAFAFA", alignItems: "center", justifyContent: "space-between", height: "120px", paddingLeft: "2%", paddingRight: "2%", borderRadius: "12px" }}>
                        <div style={{ fontSize: "16px", color: "#000" }}><img src={BackArrow} height={20} style={{ marginRight: "20px" }} onClick={() => setCurrent(1)}></img> {organimseName}</div>
                        <div style={{ fontSize: "16px", color: "#000" }}><img src={Wallet} height={40}></img> {organismeCredits} CRÉDITS</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "100px", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "column", background: "#FAFAFA", width: "25%", height: "250px", marginTop: "40px", borderRadius: "12px", alignItems: "center", justifyContent: "flex-start" }}>
                            {students.map((student: any, key: any) => (
                                <div onClick={() => handleStudentClick(student)} className={selectedStudent?.key === student.key ? s.studentSelectedCard : s.studentCard}>
                                    <div style={{ height: "55px", width: "55px", borderRadius: "50%", background: "#FFF", alignItems: "center", justifyContent: "center", margin: "20px" }}><img src={Student} height={50}></img></div>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
                                        <span style={{ color: selectedStudent?.key === student.key ? "#FFF" : "#000" }}>{student.firstname + " " + student.lastname}</span>
                                        <span style={{ backgroundColor: "#D5D5D5", padding: '4px 8px', borderRadius: '12px' }}>
                                            {student.level}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "40px", width: "75%", alignItems: "flex-end" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderRadius: "12px", background: "#FAFAFA", width: "98%", height: "70px" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "10px" }}>
                                    <div style={{ height: "45px", width: "45px", borderRadius: "50%", background: "#FFF", alignItems: "center", justifyContent: "center", marginRight: "20px" }}><img src={Student} height={40}></img></div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                        <span style={{ color: "#000" }}>{selectedStudent?.firstname + " " + selectedStudent?.lastname}</span>
                                        <span style={{ backgroundColor: "#D5D5D5", padding: '4px 8px', borderRadius: '12px', marginLeft: "10px" }}>
                                            {selectedStudent?.level}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginRight: "20px" }}>
                                    <AppButton onClick={handleStudentAttributions} style={{ margin: "0px", padding: "0px" }}>Liste des attributions</AppButton>
                                    <div style={{ fontSize: "14px", color: "#000", marginLeft: "20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}><img src={Wallet} height={40}></img> <span style={{ marginRight: "5px", marginLeft: "10px" }}>{studentCredit}</span> CRÉDITS</div>
                                </div>
                            </div>

                            <div>
                                <Form
                                    layout="inline"
                                    name="searchTeacher"
                                    autoComplete="off"
                                    initialValues={{ search: searchValue }}
                                    onFinish={getTeachers}
                                >
                                    <div className={s.filedSearch}>
                                        <Form.Item name="search">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                <Input
                                                    type="text"
                                                    value={searchValue}
                                                    onChange={handleSearchChange}
                                                    style={{ border: "0px", marginLeft: "5px" }}
                                                />
                                                <button
                                                    style={{ border: "unset", background: "transparent" }}
                                                >
                                                    <SearchIcon
                                                        style={{
                                                            position: "relative",
                                                            width: "30px",
                                                            top: "3px",
                                                        }}
                                                    />
                                                </button>
                                            </div>
                                        </Form.Item>
                                        <Button
                                            htmlType="submit"
                                            ref={refSubmiteBtn}
                                            style={{ display: "none" }}
                                        >
                                            Go
                                        </Button>
                                    </div>
                                </Form>
                            </div>

                            <div style={{ height: "640px", width: "98%", marginTop: "20px", display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', overflowY: "scroll" }}>
                                {isLoading ? (
                                    <>
                                        <Skeleton active />
                                        <Skeleton active />
                                        <Skeleton active />
                                        <Skeleton active />
                                        <Skeleton active />
                                        <Skeleton active />
                                    </>
                                ) : teachersList.map((item: any, index: any) => (
                                    <div className={s.card} key={index}>
                                        <div style={{ height: "75px", width: "75px", background: "#FFF", borderRadius: "50%", alignItems: "center", justifyContent: "center" }}><img src={Teacher} height={70}></img></div>
                                        <div style={{ fontSize: "14px", color: "#000", textTransform: "uppercase", marginTop: "5px" }}>{item?.teacher?.lastname + " " + item?.teacher.firstname}</div>
                                        <div style={{ fontSize: "12px", color: "#000", marginTop: "0px" }}>{item?.teacher?.nivMat[0]?.matieres[0]?.cost}€/heure</div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            {handleClearTopic(item?.topic)?.map((topic: any) => (
                                                <div style={{ display: "flex", flexDirection: "row", marginTop: "20px", textTransform: "uppercase" }}>
                                                    <IconTopic data={topic} />
                                                    {topic?.title?.substring(0, 4)}
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", textTransform: "uppercase", fontSize: "14px" }}>
                                            {handleClearNiveau(item?.teacher?.nivMat)?.map((niv: any) => {
                                                return (
                                                    (niv?.niveau?.levelGroup?.includes("Primaire") && (
                                                        <>
                                                            <span>Primaire,{" "}</span>
                                                        </>
                                                    )) ||
                                                    (niv?.niveau?.levelGroup?.includes("Collège") && (
                                                        <>
                                                            <span style={{ marginLeft: "5px" }}>Collège,{" "}</span>
                                                        </>
                                                    )) ||
                                                    (niv?.niveau?.levelGroup?.includes("Lycée") && (
                                                        <>
                                                            <span style={{ marginLeft: "5px" }}>Lycée,{" "}</span>
                                                        </>
                                                    )) ||
                                                    (niv?.niveau?.levelGroup?.includes("Prépa") && (
                                                        <>
                                                            <span style={{ marginLeft: "5px" }}>Prépa</span>
                                                        </>
                                                    ))
                                                );
                                            })}
                                            {item?.prof?.nivMat?.length <= 0 && (
                                                <>
                                                    <span>Non renseigné</span>
                                                </>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", textTransform: "uppercase", fontSize: "10px" }}>
                                            {item?.prof?.langues?.includes("fr") && (
                                                <>
                                                    <span style={{ marginRight: "3px" }}>Français</span>
                                                </>
                                            )}
                                            {item?.prof?.langues?.includes("en") && (
                                                <>
                                                    <span> - Anglais</span>
                                                </>
                                            )}
                                        </div>
                                        <div style={{ paddingLeft: "15%", paddingRight: "15%", marginTop: "20px" }}>
                                            <AppButton onClick={() => handleButtonClick(item)} style={{ margin: "0px", padding: "0px" }}>Gestion des crédits</AppButton>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <Drawer
                        title={`Attribution de crédits - ${selectedTeacher?.teacher?.firstname} ${selectedTeacher?.teacher?.lastname}`}
                        placement="right"
                        onClose={handleCloseDrawer}
                        visible={isDrawerVisible}
                        width={"30%"}
                        style={{ zIndex: "2" }}
                    >
                        <div style={{ marginLeft: "20%", marginRight: "20%", }}>
                            <div className={s.card}>
                                <div style={{ height: "75px", width: "75px", background: "#FFF", borderRadius: "50%", alignItems: "center", justifyContent: "center" }}><img src={Teacher} height={70}></img></div>
                                <div style={{ fontSize: "14px", color: "#000", textTransform: "uppercase", marginTop: "5px" }}>{selectedTeacher?.teacher?.lastname + " " + selectedTeacher?.teacher.firstname}</div>
                                <div style={{ fontSize: "12px", color: "#000", marginTop: "0px" }}>{selectedTeacher?.teacher?.nivMat[0]?.matieres[0]?.cost}€/heure</div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {handleClearTopic(selectedTeacher?.topic)?.map((topic: any) => (
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "20px", textTransform: "uppercase" }}>
                                            <IconTopic data={topic} />
                                            {topic?.title?.substring(0, 4)}
                                        </div>
                                    ))}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", textTransform: "uppercase", fontSize: "14px" }}>
                                    {handleClearNiveau(selectedTeacher?.teacher?.nivMat)?.map((niv: any) => {
                                        return (
                                            (niv?.niveau?.levelGroup?.includes("Primaire") && (
                                                <>
                                                    <span>Primaire,{" "}</span>
                                                </>
                                            )) ||
                                            (niv?.niveau?.levelGroup?.includes("Collège") && (
                                                <>
                                                    <span style={{ marginLeft: "5px" }}>Collège,{" "}</span>
                                                </>
                                            )) ||
                                            (niv?.niveau?.levelGroup?.includes("Lycée") && (
                                                <>
                                                    <span style={{ marginLeft: "5px" }}>Lycée,{" "}</span>
                                                </>
                                            )) ||
                                            (niv?.niveau?.levelGroup?.includes("Prépa") && (
                                                <>
                                                    <span style={{ marginLeft: "5px" }}>Prépa</span>
                                                </>
                                            ))
                                        );
                                    })}
                                    {selectedTeacher?.prof?.nivMat?.length <= 0 && (
                                        <>
                                            <span>Non renseigné</span>
                                        </>
                                    )}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", textTransform: "uppercase", fontSize: "10px" }}>
                                    {selectedTeacher?.prof?.langues?.includes("fr") && (
                                        <>
                                            <span style={{ marginRight: "3px" }}>Français</span>
                                        </>
                                    )}
                                    {selectedTeacher?.prof?.langues?.includes("en") && (
                                        <>
                                            <span> - Anglais</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: '20px', marginLeft: "20%", marginRight: "20%", display: "flex", flexDirection: "column" }}>

                            {selectedTeacher && teacherDataLoaded && <p><strong>Sélectionner le niveau :</strong></p>}
                            {selectedTeacher && teacherDataLoaded && <div style={{ borderRadius: "8px", border: "1px solid #707070" }}>
                                <Select
                                    placeholder={<span style={{ color: "#000", opacity: "0.8" }}>Niveau</span>}
                                    bordered={false}
                                    onChange={(value) => {
                                        setSelectedLevel(value);
                                        setIsloadingTopic(true);
                                        handleNiveauChange(value);
                                    }}
                                    style={{ width: "100%" }}
                                >
                                    {teacherLevels.map((level: any) => (
                                        <Option key={level?.id} value={level?.id}>
                                            {level?.title}
                                        </Option>
                                    ))}
                                </Select>
                            </div>}

                            {selectedTeacher && teacherDataLoaded && <p style={{ marginTop: "10px" }}><strong>Sélectionner la matière :</strong></p>}
                            {selectedTeacher && teacherDataLoaded && <div style={{ borderRadius: "8px", border: "1px solid #707070" }}>
                                <Select
                                    placeholder={<span style={{ color: "#000", opacity: "0.8" }}>Matière</span>}
                                    allowClear
                                    loading={isloadingTopic}
                                    bordered={false}
                                    onChange={(value) => {
                                        setSelectedTopic(value);
                                    }}
                                    style={{ width: "100%" }}
                                >
                                    {tempTopic?.map((topic: any) => (
                                        <Option key={topic.id} value={topic.id}>
                                            {topic.title}
                                        </Option>
                                    ))}
                                </Select>
                            </div>}

                            {showTeacherPrice && <p style={{ marginTop: "10px" }}><strong>Tarif horaire du prof :</strong></p>}
                            {showTeacherPrice && <Input
                                type="number"
                                value={teacherPrice}
                                className={s.placeholderStyle}
                                style={{ borderRadius: "8px", border: "1px solid #707070" }}
                                readOnly={true}
                            />}

                            <p style={{ marginTop: "10px" }}><strong>Crédit à attribuer :</strong></p>
                            <Input
                                type="number"
                                min={1}
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                placeholder="Entre le nombre de crédit"
                                className={s.placeholderStyle}
                                style={{ borderRadius: "8px", border: "1px solid #707070" }}
                            />

                            <p style={{ marginTop: "10px" }}><strong>Coût du crédit :</strong></p>
                            <Input
                                type="number"
                                min={1}
                                value={cost}
                                onChange={(e) => setCost(e.target.value)}
                                placeholder="Entre le coût du crédit"
                                className={s.placeholderStyle}
                                style={{ borderRadius: "8px", border: "1px solid #707070" }}
                                suffix="€"
                            />
                            {loading ? <Spin style={{ marginTop: "20px" }} /> : <Button onClick={handleConfirmation} style={{ marginTop: '20px', borderRadius: "20px", background: "#80b1b2", color: "#FFF", width: "50%", margin: "20px auto" }}>
                                Confirmer
                            </Button>}
                        </div>
                    </Drawer>

                    <Drawer
                        title={`Attribution de crédits de ${selectedStudent?.firstname + " " + selectedStudent?.lastname}`}
                        placement="right"
                        onClose={handleCloseAttributionDrawer}
                        visible={isAttributionDrawerVisible}
                        width={"70%"}
                    >
                        {attributedTeachersList.length === 0 ? <AppResult status={"info"} title={"Cet élève n'a aucune attribution."} /> : <div style={{ marginTop: "20px", marginLeft: "10%", marginRight: "10%", display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', overflowY: "scroll" }}>
                            {attributedTeachersList.map((item: any, index: any) => (
                                <div className={s.card} key={index}>
                                    <div style={{ height: "35px", width: "65%", background: "#a9e0e4", marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1em", color: "white", borderRadius: "8px", fontWeight: "400", padding: "4px" }}>
                                        <div style={{ background: "#669e9d", borderRadius: "5px", height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: "10px", paddingRight: "10px" }}><div><img src={starCoins} height={15}></img> Crédit </div> <div style={{ fontSize: "1.2em" }}>{item?.creditsForStudent}<span style={{ fontSize: "0.8em", position: "relative", bottom: "5px" }}> CDT</span></div></div>
                                    </div>
                                    <div style={{ height: "75px", width: "75px", background: "#FFF", borderRadius: "50%", alignItems: "center", justifyContent: "center" }}><img src={Teacher} height={70}></img></div>
                                    <div style={{ fontSize: "14px", color: "#000", textTransform: "uppercase", marginTop: "5px" }}>{item?.profObject?.lastname + " " + item?.profObject.firstname}</div>
                                    <div style={{ fontSize: "12px", color: "#000", marginTop: "0px" }}>{item?.profObject?.price}€/heure</div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {handleClearTopic(item?.profObject?.matieres)?.map((topic: any) => (
                                            <div style={{ display: "flex", flexDirection: "row", marginTop: "20px", textTransform: "uppercase" }}>
                                                <IconTopic data={topic} />
                                                {topic?.title?.substring(0, 4)}
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", textTransform: "uppercase", fontSize: "14px" }}>
                                        {handleClearNiveau(item?.profObject?.niveau)?.map((niv: any) => {
                                            return (
                                                (niv?.levelGroup?.includes("Primaire") && (
                                                    <>
                                                        <span>Primaire,{" "}</span>
                                                    </>
                                                )) ||
                                                (niv?.levelGroup?.includes("Collège") && (
                                                    <>
                                                        <span style={{ marginLeft: "5px" }}>Collège,{" "}</span>
                                                    </>
                                                )) ||
                                                (niv?.levelGroup?.includes("Lycée") && (
                                                    <>
                                                        <span style={{ marginLeft: "5px" }}>Lycée,{" "}</span>
                                                    </>
                                                )) ||
                                                (niv?.levelGroup?.includes("Prépa") && (
                                                    <>
                                                        <span style={{ marginLeft: "5px" }}>Prépa</span>
                                                    </>
                                                ))
                                            );
                                        })}
                                        {item?.profObject?.niveau?.length <= 0 && (
                                            <>
                                                <span>Non renseigné</span>
                                            </>
                                        )}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", textTransform: "uppercase", fontSize: "10px" }}>
                                        {item?.profObject?.langues?.includes("fr") && (
                                            <>
                                                <span style={{ marginRight: "3px" }}>Français</span>
                                            </>
                                        )}
                                        {item?.profObject?.langues?.includes("en") && (
                                            <>
                                                <span> - Anglais</span>
                                            </>
                                        )}
                                    </div>
                                    {/* <div style={{ padding: "1.5% 5% 1.5% 5%", marginTop: "20px", background: "#FFF", border: "1px solid #80b1b2", borderRadius: "12px", color: "#000" }}>
                                        Crédit attribué : {item?.creditsForStudent}
                                    </div> */}
                                    <div style={{ paddingLeft: "15%", paddingRight: "15%", marginTop: "5px" }}>
                                        <AppButton onClick={() => handleTeacherClick(item)} style={{ margin: "0px", padding: "0px" }}>Gestion des crédits</AppButton>
                                    </div>
                                </div>
                            ))}
                        </div>}
                    </Drawer>
                </div>
            </AppScreen>
        </React.Fragment>
    );
}

export default CreditOrganisme;