import { FC, useState } from "react";

//IMAGES
import HISTOIREGEO from "assets/images/app/iconMatiers/icon_histoire_geo.png";
import { default as ANGLAIS, default as FRANCAIS } from "assets/images/app/iconMatiers/icon_langues.png";
import MATH from "assets/images/app/iconMatiers/icon_math.png";
import Auto from "assets/images/app/iconMatiers/icon_others.png";
import PHYSIQUE from "assets/images/app/iconMatiers/icon_physique_chimie.png";
import PHILO from "assets/images/app/iconMatiers/icon_sciences_sociales.png";
import SVT from "assets/images/app/iconMatiers/icon_svt.png";

interface AIIcon {
    topic: string;
}

const AITopicIcon: FC<AIIcon> = ({ topic }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <img
            height={isMobile ? 20 : 40}
            src={
                topic === "Mathematique"
                    ? MATH
                    : topic === "SVT"
                        ? SVT
                        : topic === "Physique-chimie"
                            ? PHYSIQUE
                            : topic === "Philosophie"
                                ? PHILO
                                : topic === "Histoire-geo"
                                    ? HISTOIREGEO
                                    : topic === "Francais"
                                        ? FRANCAIS
                                        : topic === "Anglais"
                                            ? ANGLAIS
                                            : Auto
            }
            style={{ marginRight: "10px" }}
        />
    );
};

export default AITopicIcon;