import * as AUTH_SERVICES from "api/services/auth/auth.services";
import ParentOrganismeStudentStats from "components/ParentOrganismeStudentPerformance";
import Breadcrumbs from "components/breadcrumbs";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppScreen from "screens/teacher/ScreenWithSidebar";

const StudentStatisques = () => {

    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const { user } = useSelector((state) => state.auth);
    const params = useParams<"id">();
    const navigate = useNavigate();
    const location = useLocation();
    const sponsorData = location.state;

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const [loading, setLoading] = useState<boolean>(true);
    const [statsData, setStatsData] = useState<any>([]);
    const [userData, setUserData] = useState<any>();
    const [studentId, setStudentId] = useState<any>('');

    const getStudentData = async () => {
        try {
            const finalUser = await AUTH_SERVICES.getUserByUid(params.id!);
            if (finalUser?.data) {
                setUserData(finalUser['data']);
                setStudentId(finalUser['data']['_id']);
            } else {
                throw new Error("L'utilisateur n'existe pas");
            }
        } catch (error) { }
    }

    const fetchStatsData = async () => {
        try {
            const res = await AUTH_SERVICES.getStudentStats(studentId);
            const data = res?.data?.userStatsData;

            setStatsData(data);

        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getStudentData();
    }, [params]);

    useEffect(() => {
        if (studentId !== '') {
            fetchStatsData();
        }
    }, [studentId]);

    return (
        <React.Fragment>
            <Breadcrumbs isStudents isBack={true} />
            <AppScreen>
                <ParentOrganismeStudentStats userData={userData} statsData={statsData} studentId={params?.id} loading={loading} user={user} generalStatsData={sponsorData} />
            </AppScreen>
        </React.Fragment>
    );

}

export default StudentStatisques;