import * as AUTH_SERVICES from "api/services/auth/auth.services";
import * as CHALLENGES_SERVICES from "api/services/challenges/challenges.services";
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import Breadcrumbs from "components/breadcrumbs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!STYLES
import styles from "../Quizz/assets/quiz.module.css";
import s from "./style.module.css";

//IMAGES
import { Button, Col, Drawer, Form, Input, Pagination, Popconfirm, Rate, Row, Select, Skeleton, Tabs, Tooltip, message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBack from "../../../assets/images/app/arrow_back.svg";
import ClockIcon from "../../../assets/images/app/clock_icon.svg";
import EllipseTeacherProfileOne from "../../../assets/images/app/ellipse_teacher_profile1.png";
import EllipseTeacherProfileTwo from "../../../assets/images/app/ellipse_teacher_profile2.png";
import EllipseTeacherProfileFive from "../../../assets/images/app/ellipse_teacher_profile5.png";
import HistoryIcons from "../../../assets/images/app/student_history_icon.svg";
import PerformanceIcons from "../../../assets/images/app/student_performance_icon.svg";

import { StatusCode } from "common/enums";
import AppResult from "components/appResults";

import { RedoOutlined } from "@ant-design/icons";

import { getStatsData } from "api/services/studentQuizz/studentQuizz.services";
import ParentOrganismeStudentStats from "components/ParentOrganismeStudentPerformance";
import AppDrawer from "components/appDrawer";
import CardMenu from "components/libs/readyToUse/challengerClasse/card/CardMenu";
import CardMenuLoader from "components/libs/readyToUse/challengerClasse/card/CardMenuLoader";
import AppSelectGroup from "components/libs/selectGroup";
import H2 from "components/libs/subtitle";
import H1 from "components/libs/title";
import Matiers from "components/matiers";
import {
    Icourses,
    useGetAllQuizzMutation,
} from "store/services/teacher/courses";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";
import Check from "../../../assets/images/app/check.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/app/search.svg";
import Star from "../../../assets/images/app/start.svg";

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

const TeacherStudentHistory = () => {

    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const { user } = useSelector((state) => state.auth);
    const params = useParams<"id">();
    const navigate = useNavigate();
    const location = useLocation();
    const studentData = location.state;
    const [formInfos] = Form.useForm();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1050);
    };
    window.addEventListener('resize', handleResize);

    const [loading, setLoading] = useState<boolean>(true);
    const [statsData, setStatsData] = useState<any>([]);
    const [userData, setUserData] = useState<any>();
    const [studentId, setStudentId] = useState<any>('');
    const [studentLevelId, setStudentLevelId] = useState<any>('');
    const [page, setPage] = useState<any>("1");

    const [userCourseRate, setCourseRate] = useState<any>(0);
    const [userApplictionRate, setApplictionRate] = useState<any>(0);
    const [userExerciceRate, setExerciceRate] = useState<any>(0);

    const [historyData, setHistoryData] = useState<any>([]);
    const [currentTab, handleTabChange] = useState<string>("1");

    const [rateDrawerVisibility, setRateDrawerVisibility] = useState<boolean>(false);
    const closeRateDrawer = async () => {
        setRateDrawerVisibility(false);
        setCourseDate("");
        setCourseTopic("");
        setCourseRating(0);
        setApplicationRating(0);
        setExerciceRating(0);
        formInfos.resetFields();
    }

    const [courseDate, setCourseDate] = useState<any>('');
    const [courseTopic, setCourseTopic] = useState<any>('');
    const [historyID, setHistoryID] = useState<any>('');

    const openRateDrawer = async (date: any, topic: any, id: any) => {
        setCourseDate(date);
        setCourseTopic(topic);
        setHistoryID(id);
    }

    const [courseRating, setCourseRating] = useState<any>(0);
    const [applicationRating, setApplicationRating] = useState<any>(0);
    const [exerciceRating, setExerciceRating] = useState<any>(0);

    const [generalStatsData, setGeneralStatsData] = useState<any>([]);
    const [radarAvg, setRadarAvg] = useState<any>();

    const handleCourseChange = (value: any) => {
        setCourseRating(value);
    };
    const handleApplicationChange = (value: any) => {
        setApplicationRating(value);
    };
    const handleExerciceChange = (value: any) => {
        setExerciceRating(value);
    };

    const [isDataLoading, setIsLoading] = useState<boolean>(false);

    const [isDrawerChallenge, setIsDrawerChallenge] = useState<boolean>(false);

    const [drawerConfirm, setDrawerConfirm] = useState<boolean>(false);
    const [tempsChallenge, setTempsChallenge] = useState<number>(0);
    const [classe, setClasse] = useState<any>();

    const fetchClasse = useCallback(async (id: string | undefined) => {
        try {
            const response = await ROOMS_SERVICES.findOne(id);
            if (response?.statusCode === StatusCode.OK) setClasse(response.data);
        } catch (error: any) {
        }
    }, []);

    const sendRemarks = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await AUTH_SERVICES.rateStudent(historyID, values?.courseRating, values?.applicationRating, values?.exerciceRating, values?.message);
            if (response?.statusCode === StatusCode.CREATED) {
                message.success({
                    content: "Note envoyée ",
                });
                setIsLoading(false);
                setCourseRate(response?.data['rateCours']);
                setApplictionRate(response?.data['rateApplication']);
                setExerciceRate(response?.data['rateExercice']);
                setRateDrawerVisibility(false);
                formInfos.resetFields();
                setCourseRating(0);
                setApplicationRating(0);
                setExerciceRating(0);
            }
            else {
                message.warning({
                    content: "Ooops, Une erreur est survenue",
                });
                setIsLoading(false);
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        }
        finally {
            getStudentHistoryData();
        }
    }

    const getStudentStatData = async () => {
        try {

            const res = await getStatsData(studentId, studentLevelId);
            const data = res?.data;
            const generalData = data.courseData;

            setGeneralStatsData(generalData);

            if (data.avg !== null) {
                const floatValue: number = data.avg;
                const stringValue: string = floatValue.toFixed(2);
                setRadarAvg(stringValue);
            }

        } catch (error) { }
    }

    const getStudentHistoryData = async () => {
        try {
            const history = await AUTH_SERVICES.getTeacherStudentHistoryData(studentId, user?._id, page);
            if (history?.data) {
                setHistoryData(history['data']['results']);
            } else {
                throw new Error("L'utilisateur n'existe pas");
            }
        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    const getStudentData = async () => {
        try {
            const finalUser = await AUTH_SERVICES.getUserByUid(params.id!);
            if (finalUser?.data) {
                setUserData(finalUser['data']);
                setCourseRate(finalUser['data']['rateCours']);
                setApplictionRate(finalUser['data']['rateApplication']);
                setExerciceRate(finalUser['data']['rateExercice']);
                setStudentId(finalUser['data']['_id']);
                setStudentLevelId(finalUser['data']['levelId']);
            } else {
                throw new Error("L'utilisateur n'existe pas");
            }
        } catch (error) { }
    }

    const fetchStatsData = async () => {
        try {
            const res = await AUTH_SERVICES.getStudentStats(studentId);
            const data = res?.data?.userStatsData;
            setStatsData(data);
        } catch (error) {
        }
    };

    const [tempTopic, setTempTopic] = useState<any>();
    const [currentPage, setcurrentPage] = useState<number>(1);
    const submit = useRef<any>(null);

    const [getAllQuizz, { data, isLoading, isError }] = useGetAllQuizzMutation();

    const [form] = Form.useForm();

    const [hidePanel, setHidePanel] = useState(true);
    const [challenge, setChallenge] = useState<any>();

    const filterQuiz = useCallback(async (values: any) => {
        if (values?.levelId === undefined) {
            values.topicId = undefined
            form.setFieldsValue({
                ['topicId']: undefined,
            });
        }
        await getAllQuizz(values);
    }, []);

    const onReset = () => {
        form.resetFields();
        getAllQuizz({
            title: "",
            levelId: "",
            topicId: "",
            status: "",
            self: false,
            page: 1,
            duration: "",
            quizzLevel: ""
        } as Icourses);
    };

    const OnSelectLevel = useCallback(
        async (levelId: any) => {
            if (levelId) {
                const response = await TOPICS_SERVICES.findAll(levelId);
                if (response?.statusCode === StatusCode.OK) {
                    setTempTopic(response?.data);
                    form.setFieldsValue({
                        ['topicId']: undefined,
                    });
                    //call static get quizz
                    getAllQuizz({
                        title: form.getFieldValue('title') === undefined ? "" : form.getFieldValue('title'),
                        levelId: levelId,
                        topicId: "",
                        status: "",
                        self: false,
                        page: currentPage,
                        duration: form.getFieldValue('duration') === undefined ? "" : form.getFieldValue('duration'),
                        quizzLevel: form.getFieldValue('quizzLevel') === undefined ? "" : form.getFieldValue('quizzLevel')
                    } as Icourses);
                }
            }
        },
        [tempTopic]
    );

    const OnSubmitChallengeHandler = (infos: any) => {
        setHidePanel(!hidePanel);
        setChallenge(infos);
    };

    const fetchChallengesByIdClass = useCallback(
        async (id: string | undefined) => {
            try {
                const response = await CHALLENGES_SERVICES.findByIdClasse(id);
                if (response?.statusCode === StatusCode.OK) {
                    setHidePanel(!hidePanel);
                    setChallenge(response?.data);
                }
            } catch (error: any) { }
        },
        []
    );

    useEffect(() => {
        getAllQuizz({
            title: "",
            levelId: "",
            topicId: "",
            status: "",
            self: false,
            page: currentPage,
            duration: "",
            quizzLevel: ""
        } as Icourses);
        window.scrollTo(0, 0);
    }, [currentPage]);

    /* useEffect(() => {
      if (window.location.pathname.includes("challenge"))
        setIsDrawerChallenge(true);
      fetchClasse(id);
  
      fetchChallengesByIdClass(id);
    }, []); */

    useEffect(() => {
        getStudentData();
    }, [params]);

    useEffect(() => {
        if (studentId !== '' && studentLevelId !== '') {
            fetchStatsData();
            getStudentStatData();
            getStudentHistoryData();
        }
    }, [studentId, studentLevelId]);

    useEffect(() => {
        if (courseDate !== "" && courseTopic !== "" && historyID !== "") {
            setRateDrawerVisibility(true);
        }
    }, [courseDate, courseTopic, historyID]);

    return (
        <React.Fragment>
            <Breadcrumbs isStudents isBack={true} />
            <AppScreen>
                <div style={{ display: "flex", flexDirection: "column", margin: "0px", padding: "0px", position: "relative", top: "-30px", zIndex: "0" }}>
                    {!isMobile && <img src={EllipseTeacherProfileOne} height={64} style={{ position: "absolute", top: "20px", left: "-10px", zIndex: "-1" }}></img>}
                    {!isMobile && <img src={EllipseTeacherProfileTwo} height={20} style={{ position: "absolute", top: "30px", right: "60px" }}></img>}
                    {!isMobile && <img src={EllipseTeacherProfileFive} height={70} style={{ position: "absolute", top: "10px", right: "0", zIndex: "-1" }}></img>}
                    {loading ? <Skeleton active /> : <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: isMobile ? "center" : "space-between", height: isMobile ? "auto" : "170px", width: "100%", marginTop: "40px", boxShadow: "4px 9px 18px #98989829", border: "1px solid #DFDDDD", borderRadius: "24px", zIndex: "1", background: "#FFF" }}>
                        <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", justifyContent: isMobile ? "center" : "flex-start", zIndex: "1", background: "#FFF", paddingTop: isMobile ? "20px" : "0px", paddingBottom: isMobile ? "20px" : "0px" }}>
                            <img src={userData['profile']} height={isMobile ? 55 : 80} style={{ background: "#FFF2E0", border: "1.5px solid #FFF", boxShadow: "0px 3px 6px #00000029", marginLeft: isMobile ? "0%" : "7%", marginRight: isMobile ? "0%" : "7%", borderRadius: "50%" }}></img>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: isMobile ? "center" : "flex-start", justifyContent: isMobile ? "center" : "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: isMobile ? "0.8em" : "1.3em", fontWeight: "bold", color: "#8AB0B1", textTransform: "capitalize", marginTop: isMobile ? "10px" : "0px" }}>{userData['firstname']} {userData['lastname']}</div>
                                {studentData !== null ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#EBEAEA", paddingTop: "2.5px", paddingBottom: "2.5px", borderRadius: "8px", width: isMobile ? "80px" : "100px", color: "#707070", marginTop: isMobile ? "5px" : "0px", fontSize: isMobile ? "0.75em" : "12px" }}>
                                    {studentData['levelId']['title']}
                                </div> : <div></div>}
                                <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", marginTop: isMobile ? "10px" : "20px" }}>
                                    <div className={s.rateBox}>
                                        <div className={s.dashedBox}>
                                            <span style={{ color: "#7FB1B2" }}>Cours</span>
                                            <span className={s.rateBoxSpan}> {Number(userCourseRate).toString().includes(".") ? Number(userCourseRate).toFixed(2) : userCourseRate}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
                                        </div>
                                        <div className={s.dashedBox}>
                                            <span style={{ color: "#7FB1B2" }}>Application</span>
                                            <span className={s.rateBoxSpan}> {Number(userApplictionRate).toString().includes(".") ? Number(userApplictionRate).toFixed(2) : userApplictionRate}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
                                        </div>
                                        <div className={s.dashedBox}>
                                            <span style={{ color: "#7FB1B2" }}>Exercice</span>
                                            <span className={s.rateBoxSpan}> {Number(userExerciceRate).toString().includes(".") ? Number(userExerciceRate).toFixed(2) : userExerciceRate}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ display: "flex", flexDirection: "row", visibility: "hidden" }}>
                            <AppButton
                                className={`${s.__btn__challenge}`}
                                icon={
                                    <ChallengeIcon
                                        style={{
                                            position: "absolute",
                                            top: "4px",
                                            left: "10%",
                                            width: "18px",
                                        }}
                                    />
                                }
                                onClick={() => setIsDrawerChallenge(!isDrawerChallenge)}
                            >
                                Challenger cet élève
                            </AppButton>
                        </div> */}
                    </div>}
                    <Tabs
                        activeKey={currentTab}
                        onChange={(key) => { handleTabChange(key) }}
                        style={{ marginTop: "3%" }}
                        type="line"
                    >
                        <TabPane
                            tab={
                                <div className={currentTab == "1" ? s.tabTitleBoxActive : s.tabTitleBox}>
                                    <img src={HistoryIcons} height={18} style={{ marginRight: "10px" }}></img> Historique
                                </div>
                            }
                            key="1"
                        >
                            <div className={s.lineBox}>
                                {loading && <Skeleton active />}
                                {!loading && !isMobile && historyData.length > 0 && (
                                    historyData.map((course: any) => (
                                        <div key={course.id} className={s.classeCard}>
                                            <div className={s.cardClassBox}>
                                                <span style={{ color: "green", fontWeight: "600", width: "20%" }}><img src={Check} height={15}></img> Cours terminé </span>
                                                {isMobile && <Matiers
                                                    cours={course?.topic}
                                                    level={studentData?.levelId?.title || "null"}
                                                />}
                                                <div className={s.detailsBox}>
                                                    <div className={s.timeBox}>
                                                        {!isMobile && <img src={ClockIcon} height={15} style={{ marginRight: "10px" }}></img>}
                                                        <div className={s.times}>
                                                            <span className={s.time}>{isMobile && <img src={ClockIcon} height={10} style={{ marginRight: "5px" }}></img>} À {course?.date.substring(14, 18)}</span>
                                                            <span className={s.timeDescription}>{course?.date.substring(0, 10)}</span>
                                                        </div>
                                                    </div>
                                                    {!isMobile && <Matiers
                                                        cours={course?.topic}
                                                        level={studentData?.levelId?.title || "null"}
                                                    />}
                                                </div>

                                                {course?.noted ? <div className={s.rateBoxDetails}>
                                                    <div className={s.dashedBox}>
                                                        <span style={{ color: "#7FB1B2" }}>Cours</span>
                                                        <span className={s.rateBoxSpan}> {course?.rateCours}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
                                                    </div>
                                                    <div className={s.dashedBox}>
                                                        <span style={{ color: "#7FB1B2" }}>Application</span>
                                                        <span className={s.rateBoxSpan}> {course?.rateApplication}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
                                                    </div>
                                                    <div className={s.dashedBox}>
                                                        <span style={{ color: "#7FB1B2" }}>Exercice</span>
                                                        <span className={s.rateBoxSpan}> {course?.rateExercice}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
                                                    </div>
                                                </div> : <div className={s.rateBoxDetails}>
                                                    <Button className={s.newButton} onClick={() => openRateDrawer(course?.date, course?.topic, course?.id)}>
                                                        Noter l'élève sur ce cours
                                                    </Button>
                                                </div>}
                                            </div>
                                        </div>
                                    ))
                                )}
                                {!loading && isMobile && historyData.length > 0 && <div className={s.cardDisplay}>
                                    {historyData.map((course: any) => (
                                        <div key={course.id} className={s.classeCard}>
                                            <div className={s.cardClassBox}>
                                                <span style={{ color: "green", fontWeight: "600" }}><img src={Check} height={15}></img> Cours terminé </span>
                                                {isMobile && <Matiers
                                                    cours={course?.topic}
                                                    level={studentData?.levelId?.title || "null"}
                                                />}
                                                <div className={s.detailsBox}>
                                                    <div className={s.timeBox}>
                                                        {!isMobile && <img src={ClockIcon} height={15} style={{ marginRight: "10px" }}></img>}
                                                        <div className={s.times}>
                                                            <span className={s.time}>{isMobile && <img src={ClockIcon} height={10} style={{ marginRight: "5px" }}></img>} À {course?.date.substring(14, 18)}</span>
                                                            <span className={s.timeDescription}>{course?.date.substring(0, 10)}</span>
                                                        </div>
                                                    </div>
                                                    {!isMobile && <Matiers
                                                        cours={course?.topic}
                                                        level={studentData?.levelId?.title || "null"}
                                                    />}
                                                </div>

                                                {course?.noted ? <div className={s.rateBox}>
                                                    <div className={s.dashedBox}>
                                                        <span style={{ color: "#7FB1B2" }}>Cours</span>
                                                        <span className={s.rateBoxSpan}> {course?.rateCours}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
                                                    </div>
                                                    <div className={s.dashedBox}>
                                                        <span style={{ color: "#7FB1B2" }}>Application</span>
                                                        <span className={s.rateBoxSpan}> {course?.rateApplication}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
                                                    </div>
                                                    <div className={s.dashedBox}>
                                                        <span style={{ color: "#7FB1B2" }}>Exercice</span>
                                                        <span className={s.rateBoxSpan}> {course?.rateExercice}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
                                                    </div>
                                                </div> : <Button className={s.newButton} onClick={() => openRateDrawer(course?.date, course?.topic, course?.id)}>
                                                    Noter l'élève sur ce cours
                                                </Button>}
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                                {!loading && historyData.length <= 0 && <AppResult status={"info"} title="Aucune réservation" />}
                            </div>
                        </TabPane>
                        <TabPane
                            tab={
                                <div className={currentTab == "2" ? s.tabTitleBoxActive : s.tabTitleBox}>
                                    <img src={PerformanceIcons} height={18} style={{ marginRight: "10px" }}></img> Performance
                                </div>
                            }
                            key="2"
                        >
                            {generalStatsData.length > 0 ? <div className={s.statsBox}><ParentOrganismeStudentStats userData={userData} statsData={statsData} studentId={studentId} loading={loading} user={user} generalStatsData={generalStatsData} avgGeneral={radarAvg} /></div> : <div></div>}
                        </TabPane>
                    </Tabs>
                </div>

                <Drawer
                    className="drawer"
                    onClose={closeRateDrawer}
                    visible={rateDrawerVisibility}
                    placement="right"
                    width={isMobile ? "100%" : "50%"}
                    title={<div style={{ fontSize: isMobile ? "0.8em" : "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>Noter ce cours</div>}
                    headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
                    closeIcon={
                        <img
                            src={ArrowBack}
                            height={isMobile ? 10 : 18}
                            style={{}}
                        ></img>
                    }
                    drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "50px", borderBottomLeftRadius: isMobile ? "0px" : "50px", boxShadow: "-3px 3px 34px #39393929" }}
                    bodyStyle={{ zIndex: "1" }}
                >
                    {loading ? <Skeleton active /> : <div style={{ margin: "40px auto", width: "100%", height: "auto" }}>
                        {!isMobile && <img src={EllipseTeacherProfileOne} height={64} style={{ position: "absolute", top: "14%", left: "5%", zIndex: "-1" }}></img>}
                        {!isMobile && <img src={EllipseTeacherProfileTwo} height={20} style={{ position: "absolute", top: "30%", right: "50px", zIndex: "-1" }}></img>}
                        {!isMobile && <img src={EllipseTeacherProfileFive} height={70} style={{ position: "absolute", top: "14%", right: "5%", zIndex: "-1" }}></img>}
                        {!isMobile && <div className={s.studentDetailsDrawer} style={{ zIndex: "1" }}>
                            <img src={userData['profile']} height={isMobile ? 50 : 80} style={{ marginRight: "5%", background: "#FFF2E0", border: "1.5px solid #FFF", boxShadow: "0px 3px 6px #00000029", borderRadius: "50%" }}></img>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: isMobile ? "1em" : "1.3em", fontWeight: "bold", color: "#8AB0B1", textTransform: "uppercase", marginTop: isMobile ? "0px" : "10px" }}>{userData['firstname']} {userData['lastname']}</div>
                                {studentData !== null ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#D5D5D5", paddingTop: "2.5px", paddingBottom: "2.5px", borderRadius: "8px", width: isMobile ? "80px" : "100px", fontSize: isMobile ? "0.75em" : "12px" }}>
                                    {studentData['levelId']['title']}
                                </div> : <div></div>}
                                <div className={s.drawerTimeBox}>
                                    <span style={{ color: "green", fontWeight: "600" }}><img src={Check} height={15}></img> Cours </span>
                                    <div className={s.timeBox} style={{ marginLeft: isMobile ? "0px" : "40px" }}>
                                        <div className={s.times}>
                                            <span className={s.time}>{isMobile && <img src={ClockIcon} height={10} style={{ marginRight: "5px" }}></img>} À {courseDate.substring(14, 18)}</span>
                                            <span className={s.timeDescription}>{courseDate.substring(0, 10)}</span>
                                        </div>
                                    </div>
                                    <Matiers
                                        cours={courseTopic}
                                        level={studentData?.levelId?.title || "null"}
                                    />
                                </div>
                            </div>
                        </div>}

                        {isMobile && <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", background: "#FFF", boxShadow: "4px 9px 18px #98989829", border: "1px solid #DFDDDD", borderRadius: "18px", width: "90%", margin: "0px auto", padding: "20px" }}>
                            <div className={s.studentDetailsDrawer} style={{ zIndex: "1" }}>
                                <img src={userData['profile']} height={isMobile ? 50 : 80} style={{ marginRight: "5%", background: "#FFF2E0", border: "1.5px solid #FFF", boxShadow: "0px 3px 6px #00000029", borderRadius: "50%" }}></img>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: isMobile ? "1em" : "1.3em", fontWeight: "bold", color: "#8AB0B1", textTransform: "capitalize", marginTop: isMobile ? "0px" : "10px" }}>{userData['firstname']} {userData['lastname']}</div>
                                    {studentData !== null ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#D5D5D5", paddingTop: "2.5px", paddingBottom: "2.5px", borderRadius: "8px", width: isMobile ? "80px" : "100px", fontSize: isMobile ? "0.75em" : "12px" }}>
                                        {studentData['levelId']['title']}
                                    </div> : <div></div>}
                                </div>
                            </div>
                            <div className={s.drawerTimeBox}>
                                <span style={{ color: "green", fontWeight: "600" }}><img src={Check} height={15}></img> Cours </span>
                                <div className={s.timeBox} style={{ marginLeft: isMobile ? "0px" : "40px" }}>
                                    <div className={s.times}>
                                        <span className={s.time}>{isMobile && <img src={ClockIcon} height={10} style={{ marginRight: "5px" }}></img>} À {courseDate.substring(14, 18)}</span>
                                        <span className={s.timeDescription}>{courseDate.substring(0, 10)}</span>
                                    </div>
                                </div>
                                <Matiers
                                    cours={courseTopic}
                                    level={studentData?.levelId?.title || "null"}
                                />
                            </div>
                        </div>}

                        <div className={s.formBox}>
                            <Form
                                validateTrigger={["onFinish"]}
                                name="registerParent"
                                autoComplete="off"
                                form={formInfos}
                                onFinish={sendRemarks}
                            >
                                <div className={s.formTitle}>Évalue chaque aspect de ton cours en attribuant une note à l'élève.</div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: isMobile ? "0.8em" : "1.1em", marginLeft: "20px", marginTop: "10px" }}>
                                    <span style={{ color: "#7FB1B2" }}>Cours</span>
                                    <Form.Item
                                        name="courseRating"
                                        rules={[{ required: true, message: 'Choisi une note' }]}
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <Rate value={courseRating} onChange={(value) => {
                                            setCourseRating(value);
                                            formInfos.setFieldsValue({ courseRating: value });
                                        }} />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: isMobile ? "0.8em" : "1.1em", marginLeft: "20px", marginTop: "10px" }}>
                                    <span style={{ color: "#7FB1B2" }}>Application</span>
                                    <Form.Item
                                        name="applicationRating"
                                        rules={[{ required: true, message: 'Choisi une note' }]}
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <Rate value={applicationRating} onChange={(value) => {
                                            setApplicationRating(value);
                                            formInfos.setFieldsValue({ applicationRating: value });
                                        }} />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: isMobile ? "0.8em" : "1.1em", marginLeft: "20px", marginTop: "10px" }}>
                                    <span style={{ color: "#7FB1B2" }}>Exercice</span>
                                    <Form.Item
                                        name="exerciceRating"
                                        rules={[{ required: true, message: 'Choisi une note' }]}
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <Rate value={exerciceRating} onChange={(value) => {
                                            setExerciceRating(value);
                                            formInfos.setFieldsValue({ exerciceRating: value });
                                        }} />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: isMobile ? "0.8em" : "1.1m", marginLeft: "20px", marginTop: "10px" }}>
                                    <span style={{ color: "#7FB1B2" }}>Mon avis sur ce cours</span>
                                </div>
                                <div style={{ width: "80%", marginLeft: "20px", marginTop: "10px" }}>
                                    <Form.Item
                                        name="message"
                                        rules={[{ required: true }]}
                                        style={{ marginBottom: "20px" }}
                                    >
                                        <Input.TextArea
                                            className={s.inputField}
                                            maxLength={255}
                                            rows={3}
                                            placeholder="Écris ton avis ici..."
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <Form.Item>
                                        <Popconfirm
                                            placement="top"
                                            title={
                                                "Es-tu sur de vouloir confirmer cet avis ?"
                                            }
                                            onConfirm={() => formInfos.submit()}
                                            okText="Oui"
                                            cancelText="Non"
                                        >

                                            <Button className={s.newButton}>Envoyer mon avis</Button>
                                        </Popconfirm>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>

                    </div>}
                </Drawer>

                {hidePanel && <AppDrawer
                    title={<H2>CHALLENGER L'ÉLÈVE</H2>}
                    headerStyle={{ border: "none" }}
                    onClose={() => setIsDrawerChallenge(!isDrawerChallenge)}
                    visible={isDrawerChallenge}
                >
                    <div className={s.__first_drawer}>
                        <div className={s.drawer__content}>

                            <Form
                                layout="inline"
                                name="search"
                                autoComplete="off"
                                onFinish={filterQuiz}
                                form={form}
                            >
                                <div className={styles.filter}>
                                    <Tooltip placement="left" title={"Réinitialiser tous les filtres"}>
                                        <Button
                                            className={styles.__reset__btn}
                                            onClick={onReset}
                                            shape="circle"
                                            htmlType="button"
                                        >
                                            <RedoOutlined />
                                        </Button>
                                    </Tooltip>

                                    <Row gutter={12}>
                                        <Col>
                                            <div className={styles.filed__search}>
                                                <Form.Item name="title">
                                                    <Search
                                                        onChange={() => submit.current.click()}
                                                        placeholder="Tape ici ta recherche"
                                                        size="large"
                                                        bordered={false}
                                                        enterButton={
                                                            <button type="submit">
                                                                <SearchIcon
                                                                    style={{
                                                                        position: "relative",
                                                                        top: "3px",
                                                                        left: "10px",
                                                                    }}
                                                                />
                                                            </button>
                                                        }
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className={`${styles.select}`}>
                                                <Form.Item name="levelId">
                                                    <AppSelectGroup
                                                        placeholder="Niveau"
                                                        style={{ width: "150px" }}
                                                        onChange={(value: string) => (
                                                            OnSelectLevel(value)
                                                        )}
                                                        onClear={() => (setTempTopic(undefined), submit.current.click())}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col>
                                            <div className={`${styles.select}`}>
                                                <Form.Item name="topicId">
                                                    <Select
                                                        placeholder="Matière"
                                                        allowClear
                                                        onChange={(value: string) => (
                                                            submit.current.click()
                                                        )}
                                                        bordered={false}
                                                        style={{ width: "150px" }}
                                                    >
                                                        {tempTopic?.map((topic: any) => (
                                                            <Option key={topic._id} value={topic._id}>
                                                                {topic.title}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col>
                                            <div className={`${styles.select}`}>
                                                <Form.Item name="quizzLevel">
                                                    <Select
                                                        onChange={(e: string) => {
                                                            submit.current.click()
                                                        }}
                                                        placeholder="Difficulté"
                                                        allowClear
                                                        bordered={false}
                                                    >
                                                        <Option key={"1"} value={"Facile"}>
                                                            Facile
                                                        </Option>
                                                        <Option key={"2"} value={"Moyen"}>
                                                            Moyen
                                                        </Option>
                                                        <Option key={"3"} value={"Difficile"}>
                                                            Difficile
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col>
                                            <div className={`${styles.select}`}>
                                                <Form.Item name="duration">
                                                    <Select
                                                        placeholder="Durée"
                                                        allowClear
                                                        bordered={false}
                                                        onChange={(e: string) => {
                                                            submit.current.click()
                                                        }}
                                                    >
                                                        <Option key={"topic._id"} value={"5"}>
                                                            5 mins
                                                        </Option>
                                                        <Option key={"topic._id"} value={"10"}>
                                                            10 mins
                                                        </Option>
                                                        <Option key={"topic._id"} value={"15"}>
                                                            15 mins
                                                        </Option>
                                                        <Option key={"topic._id"} value={"20"}>
                                                            20 mins
                                                        </Option>
                                                        <Option key={"topic._id"} value={"30"}>
                                                            30 mins
                                                        </Option>
                                                        <Option key={"topic._id"} value={"45"}>
                                                            45 mins
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <Button
                                    ref={submit}
                                    htmlType="submit"
                                    className={styles.__submit__hidden}
                                ></Button>
                            </Form>
                        </div>
                        {!isDataLoading ? (
                            <>
                                <CardMenu
                                    setDrawerConfirm={setDrawerConfirm}
                                    setTempsChallenge={setTempsChallenge}
                                    data={data?.data}
                                    someClasseDataNeeded={classe}
                                    handler={OnSubmitChallengeHandler}
                                />
                                <Row justify="center" style={{ marginTop: "2em" }}>
                                    <Pagination
                                        showSizeChanger={false}
                                        onChange={(curr: number) => setcurrentPage(curr)}
                                        defaultCurrent={currentPage}
                                        total={data?.data?.count * 10}
                                    />
                                </Row>
                            </>
                        ) : (
                            <CardMenuLoader />
                        )}
                    </div>
                </AppDrawer>}

                <AppDrawer
                    onClose={() => setDrawerConfirm(false)}
                    visible={drawerConfirm}
                >
                    <H1>
                        Challenge valide pendant {tempsChallenge}{" "}
                        {tempsChallenge > 1 ? "heures" : "heure"}{" "}
                    </H1>

                    <AppResult status="success" title=" Challenge envoyé aux élèves" />
                </AppDrawer>
            </AppScreen>
        </React.Fragment>
    );

}

export default TeacherStudentHistory;