import { Select } from "antd";
import * as TOPICS_SERVICES from "api/services/topics/topics.services";
import { TypeMatiers } from "common/enums";
import * as appRoutes from "config/routes.config";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
//!COMPONENTS

//IMAGES
import ANGLAIS from "assets/images/app/iconMatiers/Anglais.png";
import FRANCAIS from "assets/images/app/iconMatiers/Francais.png";
import HISTOIREGEO from "assets/images/app/iconMatiers/Histoire-geo.png";
import MATH from "assets/images/app/iconMatiers/Mathematiques.png";
import PHILO from "assets/images/app/iconMatiers/Philosophie.png";
import PHYSIQUE from "assets/images/app/iconMatiers/Physique-chimie.png";
import SVT from "assets/images/app/iconMatiers/SVT.png";

import { ReactComponent as Arrow } from "assets/images/app/arrow.svg";

//!STYLES
import CardTracked from "screens/student/components/cardTracked";
import s from "../../assets/style.module.css";

// import Chapitre from "../chapitre";
// import Level from "../level";
// import EasyQuiz from "../ListesQuiz";
// import StudentQuiz from "../coursQuiz";
// import Stats from "../stats";
import {
  findLevelsByIdStudent
} from "api/services/levels/levels.services";
import { getAvancement } from "api/services/quizz/quizz.services";
import { StatusCode } from "common/enums";
import AppResult from "components/appResults";
import { useGetAllLevelsQuery } from "store/services/app/levels.services";

//
enum levelIdDefault {
  keyId = "6264946a05f4ec0a9c61cffc",
}
const { Option, OptGroup } = Select;

const MainCourses = () => {
  //!HOOKS
  const { data: levelsData, isLoading, isError } = useGetAllLevelsQuery("");
  const levels = useSelector((state) => state.levels);
  const { user } = useSelector((state) => state.auth);
  const [progress, setProgress] = useState<any>([]);
  let array = ["1", "2", "3", "4", "5", "6", "7", "8"];
  //!STATE
  const [tempTopic, setTempTopic] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tempLevelId, setTempLevelId] = useState<any | null>("");

  const OnSelectLevel = useCallback(
    async (levelId: string | any) => {
      if (user) {
        setLoading(true);
        const response = await TOPICS_SERVICES.findAll(levelId);
        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
          getProgression(user?._id, levelId);
          setLoading(false);
        }
      }
    },
    [tempTopic, user]
  );

  const onFetchLevel = async (idLvel: string | any) => {
    try {
      const response = await findLevelsByIdStudent(idLvel);
      setTempLevelId(response.data);
      return response.data;
    } catch (error) {
    }
  };

  const getProgression = useCallback(
    async (id: string | any, level: string | any) => {
      if (user) {
        const response = await getAvancement(id, level);
        if (response?.statusCode === StatusCode.OK) {
          setProgress(response?.data);
        }
      }
    },
    [user]
  );

  useEffect(() => {
    var idLevel: any = localStorage.getItem("tempLevelId");
    if (idLevel === '' || idLevel === null || idLevel === "null") {
      if (user?.nivMat[0]?.niveau?._id !== undefined) {
        idLevel = user?.nivMat[0]?.niveau?._id;
      } else {
        idLevel = "62e00770bbf0e80cf966552d";
      }
      localStorage.setItem("tempLevelId", idLevel);
    }
    OnSelectLevel(idLevel || user?.nivMat[0]?.niveau?._id || "62e00770bbf0e80cf966552d");
    onFetchLevel(idLevel);
    setTempLevelId(idLevel);
  }, [localStorage, user]);

  const allLevels = levelsData?.data;

  return (
    <>
      <div className={s.dahboard}>
        <div className={s.header}>
          <Select
            bordered={false}
            loading={isLoading}
            defaultValue={localStorage.getItem("tempLevelId") || user?.nivMat[0]?.niveau?._id || "62e00770bbf0e80cf966552d"}
            suffixIcon={
              <Arrow
                style={{
                  position: "relative",
                  right: "10px",
                  top: "-5px",
                }}
              />
            }
            placeholder="Selectionner un niveau"
            className={s.__select}
            style={{
              width: "300px",
              marginBottom: "var(--mb-2)",
            }}
            onClick={() => setTempLevelId("")}
            // allowClear
            onChange={(id: string) => {
              OnSelectLevel(id);
              localStorage.setItem("tempLevelId", id);
            }}
          >
            <OptGroup label={"Primaire"}>
              {allLevels?.Primaire?.map((college: any) => (
                <Option key={college?._id} value={college?._id}>
                  {tempLevelId?.title || college?.title}
                </Option>
              ))}
            </OptGroup>

            <OptGroup label={"Collège"}>
              {allLevels?.Collège?.slice().reverse().map((college: any) => (
                <Option key={college?._id} value={college?._id}>
                  {tempLevelId?.title || college?.title}
                </Option>
              ))}
            </OptGroup>

            <OptGroup label={"Lycée"}>
              {allLevels?.Lycée?.map((college: any) => (
                <Option key={college?._id} value={college?._id}>
                  {tempLevelId?.title || college?.title}
                </Option>
              ))}
            </OptGroup>

            <OptGroup label={"Prépa"}>
              {allLevels?.Prépa?.map((college: any) => (
                <Option key={college?._id} value={college?._id}>
                  {tempLevelId?.title || college?.title}
                </Option>
              ))}
            </OptGroup>
          </Select>
        </div>

        <div className={s.main}>
          {!loading ? (
            tempTopic.length === 0 ? (
              <AppResult
                status="info"
                title="Oups nous n'avons pas trouvé de matière !"
              />
            ) : (
              <div className={s.row}>
                {tempTopic
                  ?.map((course: any) => (
                    <Link
                      to={`${appRoutes.GETCHAPTERS}/${course?._id}`}
                      className={s.__links}
                    >
                      <CardTracked
                        skillBarClassName={s.__skillbar}
                        name={course?.title}
                        id={course?._id}
                        progress={progress}
                        icon={
                          course?.description == TypeMatiers.mathematique
                            ? MATH
                            : course?.description == TypeMatiers.SVT
                              ? SVT
                              : course?.description == TypeMatiers.PhysiqueChimie
                                ? PHYSIQUE
                                : course?.description == TypeMatiers.Philosophie
                                  ? PHILO
                                  : course?.description == TypeMatiers.HistoireGeo
                                    ? HISTOIREGEO
                                    : course?.description == TypeMatiers.Francais
                                      ? FRANCAIS
                                      : course?.description == TypeMatiers.Anglais
                                        ? ANGLAIS
                                        : ""
                        }
                        widthImage={70}
                      />
                    </Link>
                  ))
                  .reverse()}
              </div>
            )
          ) : (
            <React.Fragment>
              <div className={s.row}>
                {array
                  ?.map((i) => (
                    <CardTracked
                      id=""
                      skillBarClassName={s.__skillbar}
                      widthImage={70}
                    />
                  ))
                  .reverse()}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </>
  );
};

export default MainCourses;
