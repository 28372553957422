import { Button, Checkbox, DatePicker, Drawer, Form, Popconfirm, Popover, Select, Spin } from "antd";
import { FC, useEffect, useRef, useState } from "react";


//!STYLE
import { useDispatch, useSelector } from "react-redux";
import s from "./style.module.css";

//!Images
import { setCouponDrawer } from "store/auth/authSlice";
import AlbertEmptyData from "../../../../assets/images/albert/albert_empty_data.svg";
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";
import Delete from "../../../../assets/images/app/delete_article.svg";
import Desactivate from "../../../../assets/images/app/desactivate_icon.svg";
import Edit from "../../../../assets/images/app/edit_article.svg";
import MoreOptions from "../../../../assets/images/app/more_options.svg";

const { Option } = Select;

const CouponsDrawer: FC = () => {

    //!HOOKS
    const dispatch = useDispatch();
    const { user, openCouponDrawer, newCouponAdded } = useSelector((state) => state.auth);

    const [teacherCoupons, setTeacherCoupons] = useState<any>([]);
    const [addCoupon, setAddCoupon] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [couponNature, setCouponNature] = useState<any>();
    const [showCoursesNumber, setShowCoursesNumber] = useState<boolean>(false);
    const [showDiscount, setShowDiscount] = useState<boolean>(false);

    const handleCloseDrawer = () => {
        setAddCoupon(false);
        dispatch(setCouponDrawer(false));
    }

    const handleFormSubmit = (values: any) => {
        console.log("Form Values:", values);
        setAddCoupon(false);
        form.resetFields();
    };

    const popoverRef = useRef<HTMLDivElement>(null);
    const [visibleIndex, setVisibleIndex] = useState<number | null>(null);
    const [isEditingCoupon, setIsEditingCoupon] = useState<boolean>(false);
    const [isDesactivatingCoupon, setIsDesactivatingCoupon] = useState<boolean>(false);
    const [isDeletingCoupon, setIsDeletingCoupon] = useState<boolean>(false);
    const [visibleEdit, setVisibleEditIndex] = useState<number | null>(null);
    const [visibleDesactivate, setVisibleDesactivateIndex] = useState<number | null>(null);
    const [visibleDelete, setVisibleDeleteIndex] = useState<number | null>(null);
    /* const [visibleOptions, setVisibleOptions] = useState(false); */

    const togglePopover = (index: number) => {
        setVisibleIndex(visibleIndex === index ? null : index);
    };

    const showPopconfirmEdit = () => {
        setVisibleEditIndex(visibleIndex);
        setVisibleIndex(null);
    };

    const showPopconfirmDesactivate = () => {
        setVisibleDesactivateIndex(visibleIndex);
        setVisibleIndex(null);
    };

    const showPopconfirmDelete = () => {
        setVisibleDeleteIndex(visibleIndex);
        setVisibleIndex(null);
    };

    const handleCancelEdit = () => {
        setVisibleEditIndex(null);
    };

    const handleCancelDesactivate = () => {
        setVisibleDesactivateIndex(null);
    };

    const handleCancelDelete = () => {
        setVisibleDeleteIndex(null);
    };

    const editCoupon = () => {
        form.setFieldsValue({
            numberOfSessions: "3",
            discount: "30%"
        });
        setVisibleEditIndex(null);
        setAddCoupon(true);
    }

    const desactivateCoupon = async (values: any) => {
        try {
            setVisibleDesactivateIndex(null);
            setIsDesactivatingCoupon(true);
            /* const response = await axios.delete(`${API_RESSOURCES}/posts/${info?._id}`);
            if (response.status === StatusCode.OK) {
              message.success("Post supprimé avec succès");
              setIsPostDeleted(true);
            } */
        } catch (error) { }
        finally {
            setIsDesactivatingCoupon(false);
        }
    }

    const deleteCoupon = async (values: any) => {
        try {
            setVisibleDeleteIndex(null);
            setIsDeletingCoupon(true);
            /* const response = await axios.delete(`${API_RESSOURCES}/posts/${info?._id}`);
            if (response.status === StatusCode.OK) {
              message.success("Post supprimé avec succès");
              setIsPostDeleted(true);
            } */
        } catch (error) { }
        finally {
            setIsDeletingCoupon(false);
        }
    }

    const content = (
        <div>
            <div onClick={showPopconfirmEdit} style={{ fontSize: "15px", color: "#393E3E", cursor: "pointer" }}><img src={Edit} height={15}></img> Modifier</div>
            <div style={{ height: "1px", width: "90%", margin: "0px auto", background: "#BFBCBC", marginTop: "5px", marginBottom: "5px" }}></div>
            <div onClick={showPopconfirmDesactivate} style={{ fontSize: "15px", color: "#393E3E", cursor: "pointer", marginTop: "5px", marginBottom: "5px" }}><img src={Desactivate} height={15}></img> Désactiver</div>
            <div style={{ height: "1px", width: "90%", margin: "0px auto", background: "#BFBCBC", marginTop: "5px", marginBottom: "5px" }}></div>
            <div onClick={showPopconfirmDelete} style={{ fontSize: "15px", color: "#F56666", cursor: "pointer", marginTop: "5px", marginBottom: "5px" }}><img src={Delete} height={15}></img> Supprimer</div>
        </div>
    );

    const coupons = [
        {
            "couponType": "nombre_seances",
            "validUntil": "2025-01-31T11:20:19.909Z",
            "validUntilLife": false,
            "targetUsers": "tout_le_monde",
            "applyAutomatically": true,
            "discount": "30%",
            "numberOfSessions": 3,
            "unlimitedUse": false,
        },
        {
            "couponType": "cours_gratuit",
            "validUntil": "2025-01-30T11:23:23.953Z",
            "validUntilLife": false,
            "targetUsers": "mes_eleves",
            "applyAutomatically": true,
            "discount": "0%",
            "numberOfSessions": 1,
            "unlimitedUse": false,
        },
        {
            "couponType": "remise_speciale",
            "validUntil": "",
            "validUntilLife": true,
            "targetUsers": "mes_eleves",
            "applyAutomatically": true,
            "discount": "20%",
            "numberOfSessions": 1,
            "unlimitedUse": false,
        }
    ];

    const handleClickOutside = (event: MouseEvent) => {
        setTimeout(() => {
            if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
                if (visibleDelete || visibleDesactivate || visibleEdit) {
                    console.log("Action selected!");
                } else {
                    setVisibleIndex(null);
                }
            }
        }, 200);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [visibleDelete, visibleDesactivate, visibleEdit]);

    useEffect(() => {
        console.log("newCouponAdded :", newCouponAdded);
        form.setFieldsValue({
            numberOfSessions: "3",
            discount: "30%"
        });
    }, [newCouponAdded]);

    useEffect(() => {
        if (couponNature && couponNature === "nombre_seances") {
            setShowCoursesNumber(true);
            setShowDiscount(true);
        }
        else if (couponNature && couponNature === "remise_speciale") {
            setShowCoursesNumber(true);
            setShowDiscount(true);
        }
        else {
            setShowCoursesNumber(true);
            setShowDiscount(false);
        }
    }, [couponNature]);

    useEffect(() => {
        setTeacherCoupons(coupons);
    }, []);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
    const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
    const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
        setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
        setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
        setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
        setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
    };
    window.addEventListener('resize', handleResize);

    return (
        <Drawer
            className="drawer"
            onClose={handleCloseDrawer}
            visible={openCouponDrawer}
            width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "65%" : isMediumScreen ? "65%" : isNormalScreen ? "45%" : "45%"}
            title={<div style={{ fontSize: isMobile ? "0.8em" : "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>{<h3>Mes coupons</h3>}</div>}
            headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
            closeIcon={
                <img
                    src={ArrowBack}
                    height={isMobile ? 10 : 18}
                    style={{}}
                ></img>
            }
            drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "30px", borderBottomLeftRadius: isMobile ? "0px" : "30px", boxShadow: "-3px 3px 34px #39393929" }}
        >
            {addCoupon ? <div className={s.drawerCouponBody}>
                {isMobile ? <p>Crée des coupons pour attirer de nouveaux élèves et augmenter ta visibilité sur la plateforme ! Offre des réductions et fais découvrir tes cours à plus de monde.</p> : <p>Crée des coupons pour attirer de nouveaux élèves et augmenter ta visibilité sur<br /> la plateforme ! Offre des réductions et fais découvrir tes cours à plus de monde.</p>}
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFormSubmit}
                    style={{
                        width: "100%",
                        background: "#f9f9f9",
                        paddingTop: "20px",
                        borderRadius: "10px",
                    }}
                >
                    {/* Nature du coupon */}
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <div style={{ width: isMobile ? "50%" : "40%", fontSize: isMobile ? "0.8em" : "14px", fontFamily: "Poppins", fontWeight: "500", color: "#707070", marginTop: isMobile ? "8px" : "5px" }}>Nature du coupon</div>
                        <Form.Item
                            name="couponType"
                            rules={[{ required: true, message: "Merci de sélectionner une option" }]}
                            style={{ width: "50%", border: "1px solid #707070", borderRadius: "8px" }}
                        >
                            <Select placeholder="Sélectionne la nature du coupon" bordered={false} onChange={(value) => setCouponNature(value)}>
                                <Option value="cours_gratuit">Cours gratuit</Option>
                                <Option value="nombre_seances">Nombre de séances</Option>
                                <Option value="remise_speciale">Remise spéciale</Option>
                            </Select>
                        </Form.Item>
                    </div>

                    {/* Valide jusqu'à */}
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginTop: isMobile ? "5px" : "10px" }}>
                        <div style={{ width: isMobile ? "50%" : "40%", fontSize: isMobile ? "0.8em" : "14px", fontFamily: "Poppins", fontWeight: "500", color: "#707070", marginTop: isMobile ? "8px" : "5px" }}>Valide jusqu’au</div>
                        <div style={{ width: isMobile ? "50%" : "60%", display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            <Form.Item
                                name="validUntil"
                                rules={[{ required: false, message: "Merci de sélectionner une date" }]}
                                style={{ width: isMobile ? "100%" : "60%", border: "1px solid #707070", borderRadius: "8px" }}
                            >
                                <DatePicker style={{ width: "100%" }} placeholder="Sélectionne une date" bordered={false} />
                            </Form.Item>
                            <Form.Item name="validUntilLife" valuePropName="checked" style={{ width: isMobile ? "100%" : "20%", marginLeft: isMobile ? "0px" : "10px" }}>
                                <Checkbox style={{ fontSize: isMobile ? "0.75em" : "12px" }}>À vie</Checkbox>
                            </Form.Item>
                        </div>
                    </div>

                    {/* Nombre de séances */}
                    {showCoursesNumber && <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginTop: isMobile ? "5px" : "10px" }}>
                        <div style={{ width: isMobile ? "50%" : "40%", fontSize: isMobile ? "0.8em" : "14px", fontFamily: "Poppins", fontWeight: "500", color: "#707070", marginTop: isMobile ? "8px" : "5px" }}>Nombre de séances</div>
                        <div style={{ width: isMobile ? "50%" : "60%", display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            <Form.Item
                                name="numberOfSessions"
                                rules={[{ required: true, message: "Merci de choisir un nombre" }]}
                                style={{ width: isMobile ? "100%" : "30%", border: "1px solid #707070", borderRadius: "8px" }}
                            >
                                <Select bordered={false}>
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                </Select>
                            </Form.Item>

                            {/* Utilisation illimitée */}
                            <Form.Item name="unlimitedUse" valuePropName="checked" style={{ width: isMobile ? "100%" : "50%", marginLeft: isMobile ? "0px" : "10px" }}>
                                <Checkbox style={{ fontSize: isMobile ? "0.75em" : "12px" }}>Utilisation Illimitée</Checkbox>
                            </Form.Item>
                        </div>
                    </div>}

                    {/* Remise */}
                    {showDiscount && <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginTop: isMobile ? "5px" : "10px" }}>
                        <div style={{ width: isMobile ? "50%" : "40%", fontSize: isMobile ? "0.8em" : "14px", fontFamily: "Poppins", fontWeight: "500", color: "#707070", marginTop: isMobile ? "8px" : "5px" }}>Remise</div>
                        <div style={{ width: isMobile ? "50%" : "60%", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            <Form.Item
                                name="discount"
                                rules={[{ required: true, message: "Merci de sélectionner une remise" }]}
                                style={{ width: isMobile ? "100%" : "30%", border: "1px solid #707070", borderRadius: "8px" }}
                            >
                                <Select bordered={false}>
                                    <Option value="10%">10%</Option>
                                    <Option value="20%">20%</Option>
                                    <Option value="30%">30%</Option>
                                    <Option value="50%">50%</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>}

                    {/* Qui peut l’utiliser */}
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginTop: isMobile ? "5px" : "10px" }}>
                        <div style={{ width: isMobile ? "50%" : "40%", fontSize: isMobile ? "0.8em" : "14px", fontFamily: "Poppins", fontWeight: "500", color: "#707070", marginTop: isMobile ? "8px" : "5px" }}>À destination de</div>
                        <Form.Item
                            name="targetUsers"
                            rules={[{ required: true, message: "Merci de sélectionner une option" }]}
                            style={{ width: "50%", border: "1px solid #707070", borderRadius: "8px" }}
                        >
                            <Select placeholder="Sélectionnez les utilisateurs" bordered={false}>
                                <Option value="tout_le_monde">Tout le monde</Option>
                                <Option value="mes_eleves">Mes élèves</Option>
                                <Option value="offre_a_un_eleve">Offre à un élève spécifique</Option>
                            </Select>
                        </Form.Item>
                    </div>

                    {/* Appliquer ce coupon automatiquement */}
                    <Form.Item name="applyAutomatically" valuePropName="checked" style={{ marginTop: isMobile ? "5px" : "10px" }}>
                        <Checkbox style={{ fontSize: isMobile ? "0.75em" : "12px", color: "#868686" }}>Appliquer ce coupon automatiquement</Checkbox>
                    </Form.Item>

                    {/* Submit Button */}
                    <Form.Item>
                        <Button htmlType="submit" className={s.newCouponButton}>
                            Créer le coupon
                        </Button>
                    </Form.Item>
                </Form>
            </div> : <div className={s.drawerBody}>
                {teacherCoupons.length > 0 ? <div>
                    {isMobile ? <p>Voici tous tes coupons actifs, prêts à être utilisés par tes élèves pour profiter pleinement de tes offres !</p> : <p>Voici tous tes coupons actifs, prêts à être utilisés par tes élèves<br />pour profiter pleinement de tes offres !</p>}
                    {teacherCoupons.map((coupon: any, index: number) => {
                        return (
                            <>
                                <div className={coupon.couponType === "cours_gratuit" ? s.ticket : coupon.couponType === "nombre_seances" ? s.ticketSessions : s.ticketDiscount}>
                                    <div style={{ height: "25px", width: isMobile ? "8px" : "25px", background: "#FFF", position: "absolute", left: isMobile ? "-10px" : "-25px", zIndex: "2" }}></div>
                                    <div className={s.type}><div style={{ height: "10px", width: "10px", background: coupon.couponType === "cours_gratuit" ? "#B88F4B" : coupon.couponType === "nombre_seances" ? "#4BA8B9" : "#60CD95", margin: "auto", borderRadius: "50%", marginRight: "5px" }}></div>{coupon.couponType === "cours_gratuit" ? "Cours gratuit" : coupon.couponType === "nombre_seances" ? "Nombre de séances" : "Remise spéciale"}</div>
                                    <span className={s.details}>{coupon.numberOfSessions} {coupon.numberOfSessions < 2 ? "séance" : "séances"}</span>
                                    <span className={s.discount}>-{coupon.discount}</span>
                                    <div style={{ display: "flex", flexDirection: "row", height: "50px !important", width: "50px !important" }}>
                                        <Popconfirm
                                            placement="top"
                                            title={
                                                "Veux-tu modifier ce coupon ?"
                                            }
                                            onConfirm={editCoupon}
                                            visible={visibleEdit === index}
                                            /* onVisibleChange={(visible) => {
                                                if (!isEditingCoupon) {
                                                    setVisibleDeleteIndex(index);
                                                }
                                            }} */
                                            okText={isEditingCoupon ? <Spin size="small" /> : "Oui"}
                                            cancelText="Non"
                                            onCancel={handleCancelEdit}
                                            okButtonProps={{ disabled: isEditingCoupon }}
                                        >
                                            <Button style={{ display: visibleEdit === index ? "block" : "none", width: "5px !important" }}></Button>
                                        </Popconfirm>

                                        <Popconfirm
                                            placement="top"
                                            title={
                                                "Veux-tu désactiver ce coupon ?"
                                            }
                                            onConfirm={desactivateCoupon}
                                            visible={visibleDesactivate === index}
                                            /* onVisibleChange={(visible) => {
                                                if (!isDesactivatingCoupon) {
                                                    setVisibleDesactivateIndex(index);
                                                }
                                            }} */
                                            okText={isDesactivatingCoupon ? <Spin size="small" /> : "Oui"}
                                            cancelText="Non"
                                            onCancel={handleCancelDesactivate}
                                            okButtonProps={{ disabled: isDesactivatingCoupon }}
                                        >
                                            <Button style={{ display: visibleDesactivate === index ? "block" : "none", width: "5px !important" }}></Button>
                                        </Popconfirm>

                                        <Popconfirm
                                            placement="top"
                                            title={
                                                "Veux-tu supprimer ce coupon ?"
                                            }
                                            onConfirm={deleteCoupon}
                                            visible={visibleDelete === index}
                                            /* onVisibleChange={(visible) => {
                                                if (!isDeletingCoupon) {
                                                    setVisibleDeleteIndex(index);
                                                }
                                            }} */
                                            okText={isDeletingCoupon ? <Spin size="small" /> : "Oui"}
                                            cancelText="Non"
                                            onCancel={handleCancelDelete}
                                            okButtonProps={{ disabled: isDeletingCoupon }}
                                        >
                                            <Button style={{ display: visibleDelete === index ? "block" : "none", width: "5px !important" }}></Button>
                                        </Popconfirm>

                                        <Popover
                                            key={index}
                                            visible={visibleIndex === index}
                                            placement="left"
                                            content={content}
                                            trigger="click"
                                            style={{ height: "70px !important", borderRadius: "8px", boxShadow: "4px 8px 16px #8B8B8B38", border: "0.5px solid #BFBCBC" }}
                                            className={s.customPopover}
                                            overlayClassName={s.customPopover}
                                        >
                                            <div ref={popoverRef} style={{ height: "20px", width: "25px" }} onClick={(e: any) => { e.stopPropagation(); togglePopover(index); }}><img src={MoreOptions} height={4} ></img></div>
                                        </Popover>
                                    </div>
                                    <div style={{ height: "25px", width: isMobile ? "8px" : "25px", background: "#FFF", position: "absolute", right: isMobile ? "-10px" : "-25px", zIndex: "2" }}></div>
                                </div>
                            </>
                        );
                    })}
                    <Button className={s.newCouponButton} onClick={() => setAddCoupon(true)}>Ajouter un coupon</Button>
                </div> : <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", textAlign: "center", fontSize: isMobile ? "0.8em" : "16px" }}>
                    Aucun coupon actif
                    <Button className={s.newCouponButton} onClick={() => setAddCoupon(true)}>Créer ton premier coupon</Button>
                    <img src={AlbertEmptyData} height={isMobile ? 150 : 350}></img>
                </div>}
            </div>}
        </Drawer>
    );
};

export default CouponsDrawer;
