import { useCallback, useState } from "react";

import { CopyOutlined, ScanOutlined } from "@ant-design/icons";
import { message, Spin, Tooltip } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import Dropzone from "react-dropzone";

//!STYLES
import s from "./styles/style.module.css";

const MathScan = () => {
  const [isVisibleCopy, setIsVisibleCopy] = useState<boolean>(false);
  const [dataMathPix, setDataMathPix] = useState<any>(null);
  const [loadingMath, setLoadingMath] = useState<boolean>(false);

  //!FUNCTION
  const successMessageCopy = () => {
    message.success("Copier avec succès");
  };

  //Upload
  const getBase64 = async (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    };
    reader.onerror = (error) => { };
  };

  const fileUpload = useCallback(
    async (file: any) => {
      try {
        setLoadingMath(true);
        await getBase64(file, (base64string: any) => {
          fetch("https://api.mathpix.com/v3/text", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              app_key:
                "760e3a7115f6373a0307ed2c118abd5794c3b4a1d1e77aa75c7174f92253fc58",
            },
            body: JSON.stringify({
              src: base64string,
              formats: ["text", "data", "html"],
              data_options: {
                include_asciimath: true,
                include_latex: true,
              },
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              setDataMathPix(res);
              setIsVisibleCopy(true);
              setLoadingMath(false);
            })
            .catch((error) => { });
        });
      } catch (error) {
      }
    },
    [loadingMath, dataMathPix]
  );

  const uploaded = useCallback(
    async (acceptedFile: any) => {
      await fileUpload(acceptedFile[0]);
    },
    [loadingMath, dataMathPix]
  );

  return (
    <div className={s.__upload__with__ck__content}>
      {loadingMath ? (
        <Spin />
      ) : (
        <>
          {!loadingMath && isVisibleCopy && (
            <>
              <div className={s.__copy}>
                <CopyToClipboard text={dataMathPix?.text}>
                  <div className={s.__ck__math__content}>
                    <span>{dataMathPix?.text}</span>
                  </div>
                </CopyToClipboard>
              </div>

              <div className={s.__round__copy}>
                <CopyToClipboard text={dataMathPix?.text}>
                  <Tooltip placement="top" title={"Copier le text"}>
                    <CopyOutlined
                      onClick={successMessageCopy}
                      style={{
                        margin: "auto",
                        cursor: "copy",
                        color: "var(--first-color)",
                        fontSize: "15px",
                      }}
                    />
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </>
          )}
        </>
      )}

      <div className={s.__scaner__math}>
        <Dropzone
          onDrop={(acceptedFiles) => { }}
          onDropAccepted={uploaded}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={s.__ck__math__content}>
              <input {...getInputProps()} />
              <span> Scan math</span>
              <div className={s.__ck__scaner}>
                <Tooltip placement="top" title={"Uploader l'image"}>
                  <ScanOutlined
                    style={{
                      margin: "auto",
                      color: "var(--first-color)",
                      fontSize: "15px",
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    </div>
  );
};

export default MathScan;
