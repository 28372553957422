import { FC, useState } from "react";

//!COMPONENTS
import FooterFooter from "./components/footer";

//!STYLES
import { useSelector } from "react-redux";
import s from "./assets/footer.module.css";

const Footer: FC = () => {

  const { isFullscreen, isRecording } = useSelector((state) => state.app);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return (
    <footer className={s.footer}>
      {(!isFullscreen || !isRecording) && !isMobile && <FooterFooter />}
    </footer>
  );
};

export default Footer;
