import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { FIND_ALL, GET_LEVELS_BY_ID } from "./levels.api.routes";

export const findAll = async (): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(FIND_ALL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findLevelsById = async (idLevel: any) => {
  try {
    const response = await axios.get(GET_LEVELS_BY_ID(idLevel));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findLevelsByIdStudent = async (idLevel: any) => {
  try {
    const response = await axios.get(GET_LEVELS_BY_ID(idLevel));
    return response.data;
  } catch (error) {
    throw error;
  }
};
