import axios from "axios";
import * as apiRoutes from "./studentReservation.routes";

export const findReservationStudent = async (idAds: string, values: any) => {
  try {
    const response = await axios.patch(apiRoutes.API_BASE_URL(idAds), values);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findProgrammeStudent = async (studentId: string | any) => {
  try {
    const response = await axios.get(apiRoutes.API_BASE_URL_ADS(studentId));
    return response.data;
  } catch (error) {
    throw error;
  }
};
