// EditParagraphDrawer.tsx
import { Col, Drawer, Form, Space, message } from 'antd';
import { sendCourseProposal } from 'api/services/studentQuizz/studentQuizz.services';
import { CKEditor } from 'ckeditor4-react';
import { HandlerInputError, StatusCode } from 'common/enums';
import AppButton from 'components/libs/button';
import MathScan from 'components/maths';
import UploadCloudinary from 'components/uploadCloudinary';
import React, { useEffect, useState } from 'react';
import Latex from 'react-latex-next';
import s from "./style.module.css";

interface EditParagraphDrawerProps {
    visible: boolean;
    content: string;
    onClose: () => void;
    onSave: (editedContent: string) => void;
    idTeacher: string;
    idChapter: string;
    idSection: string;
}

const EditParagraphDrawer: React.FC<EditParagraphDrawerProps> = ({ visible, content, onClose, onSave, idTeacher, idChapter, idSection }) => {

    const [editedContent, setEditedContent] = useState(content);
    const [isEditingCourse, setIsEditingCourse] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [initialData, setInitialData] = useState<any>({});

    useEffect(() => {
        setEditedContent(content);
        setInitialData({ contents: content });
    }, [content]);

    const handleSave = async (values: any) => {
        try {
            const response = await sendCourseProposal(
                idTeacher,
                idChapter,
                idSection,
                editedContent,
            );

            if (response?.statusCode === StatusCode.CREATED) {
                const successMessage = "Modifications envoyées pour vérification";
                message.success(successMessage);
            }
            else {
                message.warning("Une erreur est survenue lors de la modification du cours");
            }
        } catch (error) {
            message.error("Une erreur est survenue lors de la modification du cours");
        } finally {
            setIsEditingCourse(false);
            onSave(editedContent);
            onClose();
        }
    };


    return (
        <Drawer
            title="Amélioration du cours"
            visible={visible}
            onClose={onClose}
            width="70%"
            destroyOnClose
        >
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "50%" }}>
                    <Form autoComplete="off" form={form} onFinish={handleSave} initialValues={initialData}>
                        <div className={s.__custom__ck_editor}>
                            <Space>
                                <UploadCloudinary />
                                <MathScan />
                            </Space>

                            <Form.Item
                                name="content"
                                valuePropName="data"
                                getValueFromEvent={(event) => { const data = event?.editor?.getData(); return data; }}
                                rules={[{ required: true, message: HandlerInputError.content }]}
                            >
                                <CKEditor
                                    data={editedContent}
                                    initData={content}
                                    config={{
                                        language: 'fr',
                                    }}
                                    onChange={(changes: any) => { const content = changes.editor.getData(); setEditedContent(content); }}
                                    style={{ border: "none" }}
                                />
                            </Form.Item>
                        </div>

                        <Col style={{ textAlign: "center" }}>
                            <AppButton
                                loading={isEditingCourse}
                                htmlTypeSubmit={true}
                            >
                                Soumettre pour validation
                            </AppButton>
                        </Col>
                    </Form>
                </div>
                <div style={{ width: "50%" }}>
                    <div className={s.appercue__continute}>
                        <h3>Aperçu : </h3>
                        <div className={s.__latext__content}>
                            <Latex>
                                {editedContent
                                    ? editedContent
                                        ?.replaceAll("amp;", "")
                                        ?.replaceAll("<br />", "")
                                    : " "}
                            </Latex>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default EditParagraphDrawer;
