import "katex/dist/katex.min.css";
import { FC } from "react";
import Latex from "react-latex-next";

//!STYLE
import s from "./assets/style.module.css";

interface Props {
  data: any;
  exercice: any;
  previewTitle?: string;
  previewType?: string;
  previewDescription?: string;
  previewIndice?: string;
  previewSolution?: string;
  className?: string;
  classNameContainer?: string;
}
const ExercicePreviewUpdate: FC<Props> = ({
  data,
  exercice,
  previewTitle,
  previewType,
  previewDescription,
  previewIndice,
  previewSolution,
  className,
  classNameContainer,
}) => {
  return (
    <div className={s.container}>
      <div className={s.__title}>
        <h3>Title : {previewTitle ? previewTitle : exercice?.title}</h3>
      </div>
      {/* <div className={s.__title}>
        <h3>
          Type de l’exercice : {previewType ? previewType : exercice?.type}
        </h3>
      </div> */}
      <div className={`${s.timeline} ${className}`}>
        <div
          className={`${s.timeline_container} ${s.primary} ${classNameContainer}`}
        >
          <div className={s.timeline_icon}>
            <i className="far fa-grin-wink"></i>
          </div>
          <div className={s.timeline_body}>
            <h4 className={s.timeline_title}>
              <span className={s.badge}>Contenu de l’énoncé</span>
            </h4>

            <Latex>
              {previewDescription
                ? previewDescription
                  ?.replaceAll("amp;", "")
                  ?.replaceAll("<br />", "")
                : ""}
            </Latex>
          </div>
        </div>

        <div className={`${s.timeline_container} ${s.warning}`}>
          {" "}
          <div className={s.timeline_icon}>
            <i className="far fa-grin-hearts"></i>
          </div>
          <div className={s.timeline_body}>
            <h4 className={s.timeline_title}>
              <span className={s.badge}>Indice</span>
            </h4>
            <Latex>
              {previewIndice
                ? previewIndice
                  ?.replaceAll("amp;", "")
                  ?.replaceAll("<br />", "")
                : ""}
            </Latex>
          </div>
        </div>

        <div className={`${s.timeline_container} ${s.success}`}>
          <div className={s.timeline_icon}>
            <i className="far fa-grin-tears"></i>
          </div>
          <div className={s.timeline_body}>
            <h4 className={s.timeline_title}>
              <span className={s.badge}>Solution</span>
            </h4>
            <Latex>
              {previewSolution
                ? previewSolution
                  ?.replaceAll("amp;", "")
                  ?.replaceAll("<br />", "")
                : ""}
            </Latex>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExercicePreviewUpdate;
