import Breadcrumbs from "components/breadcrumbs";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetProfileTeacherQuery } from "store/services/teacher/profileTeacher";

//!COMPONENTS
import { UserRoles } from "common/enums";
import { setSeeAvis } from "store/auth/authSlice";
import AppScreen from "../ScreenWithSidebar";
import AboutProfileTeacher from "./components/about";
import HeaderProfileTeacher from "./components/header";

type Props = {
  displayBreadcrumbs?: boolean;
  displayButtonProfile?: boolean;
};

const ProfileTeacher: FC<Props> = ({

  displayBreadcrumbs = true,
  displayButtonProfile,
}) => {
  //!HOOKS
  const params = useParams<"id">();
  const { user, idTeacher } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const query = {
    profId: params.id! || (user.roles.includes("teacher") && user?._id),
    studId: user?._id! || params.id,
  };
  const { data, isLoading, isError } = useGetProfileTeacherQuery(query);
  const isStudent = user?.roles?.includes(UserRoles.STUDENT);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setSeeAvis(false));
    }, 1000);
  }, []);

  return (
    <>
      {isMobile && user?.accountManaged !== "" && <div style={{ height: "70px", width: "100%" }}></div>}
      {displayBreadcrumbs && isStudent ? <Breadcrumbs isBack={true} isSearchProf={true} /> : <Breadcrumbs isBack={true} isProfil={true} />}
      <AppScreen>
        <HeaderProfileTeacher
          teacher={data?.data}
          displayButtonProfile={displayButtonProfile}
        />
        <AboutProfileTeacher teacher={data?.data} />
      </AppScreen>
    </>
  );
};

export default ProfileTeacher;
