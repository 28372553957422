import { Drawer, Form, Select, message } from "antd";
import { FC, useEffect, useState } from "react";

//!STYLE
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import s from "./style.module.css";

//!ICONS
import { displayReportDrawer } from "store/adventissements";

//!IMAGES
import TextArea from "antd/lib/input/TextArea";
import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import axios from "axios";
import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import ArrowBack from "../../assets/images/app/arrow_back.svg";


const DrawerReport: FC = () => {

    //!HOOKS
    const { t } = useTranslation();
    const { reportDrawerVisible, reportingUserData, reportedUserData } = useSelector((state) => state.drawerAds);
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    //!STATE    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const [isReporting, setIsReporting] = useState<boolean>(false);
    const [reportingUser, setReportingUser] = useState<any>({});
    const [reportedUser, setReportedUser] = useState<any>({});

    const reasonsList = [
        "Comportement inapproprié ou abusif",
        "Non-respect des règles de la plateforme",
        "Fausses informations de profil",
        "Non-réponse ou retard fréquent dans les cours",
        "Comportement irrespectueux ou malhonnête pendant les cours",
        "Contenu inapproprié ou offensant",
        "Problème de communication ou mauvaise expérience d'apprentissage",
        "Autre (à préciser)"
    ];

    const reportSomething = async (values: any) => {
        try {
            const reason = values?.reason;
            const userMessage = values?.message;
            setIsReporting(true);
            const response = await axios.post(`${API_RESSOURCES}/abuse-reports`, { reportingUser, reportedUser, reason, message: userMessage });
            if (response.status === StatusCode.CREATED) {
                message.success("Message envoyé , notre équipe s'en charge rapidement, Merci");
            }
        } catch (error) { }
        finally {
            setIsReporting(false);
            handleClose();
        }
    }

    const handleClose = () => {
        dispatch(displayReportDrawer(false));
        form.resetFields();
    };

    useEffect(() => {
        if (reportingUserData && reportedUserData) {
            setReportingUser(reportingUserData);
            setReportedUser(reportedUserData);
        }
    }, [reportingUserData, reportedUserData]);

    return (
        <Drawer
            className="drawer"
            maskClosable={true}
            onClose={handleClose}
            visible={reportDrawerVisible}
            width={isMobile ? "100%" : "40%"}
            title={
                <div className={s.drawerTitle}>
                    Signaler un abus
                </div>
            }
            headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: "40px", backgroundColor: "transparent" }}
            closeIcon={
                <img
                    src={ArrowBack}
                    height={18}
                    style={{}}
                ></img>
            }
            drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px", boxShadow: "-3px 3px 34px #39393929" }}
        >
            <div className={s.formContainer}>
                <Form
                    validateTrigger={["onFinish"]}
                    form={form}
                    onFinish={reportSomething}
                >
                    <div className={s.formBox}>
                        <div className={s.title}>Signaler</div>
                        <div className={s.subtitle}>Choisis un motif pour signaler ce profil :</div>
                        <Form.Item
                            style={{ marginBottom: "20px" }}
                            name="reason"
                            rules={[{ required: true }]}
                        >
                            <Select
                                style={{ padding: "5px 0px 5px 0px" }}
                                className={s.selectInput}
                                placeholder={<div className={s.placeholderStyle}>Choisis un motif</div>}
                                bordered={false}
                                allowClear
                                dropdownStyle={{ padding: "3px" }}
                                showArrow={true}
                            >
                                {reasonsList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="message"
                            rules={[{ required: false }]}
                            style={{ marginBottom: "20px" }}
                        >
                            <TextArea
                                className={s.field}
                                placeholder={"Merci de préciser pourquoi tu signales ce profil. Donne-nous autant de détails que possible pour nous aider à traiter ta demande."}
                                rows={3}
                                cols={50}
                                required={true}
                            />
                        </Form.Item>
                        <div className={s.buttonBox}>
                            <Form.Item style={{ margin: "0px auto", padding: 0 }}>
                                <AppButton loading={isReporting} htmlTypeSubmit={true} className={s.newMoveButton}>Envoyer</AppButton>
                            </Form.Item>
                        </div>

                    </div>
                </Form>
            </div>
        </Drawer>
    );
};

export default DrawerReport;
