import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
} from "antd";
import { registerUserDto } from "api/services/auth/dto/login-user.dto";
import Container from "components/container";
import * as appRoutes from "config/routes.config";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { registerStudentWithEmailAndPassword } from "store/auth/authSlice";

//!COMPONENTS
import H1 from "components/libs/title";

//!IMAGES
import AvatarStudent from "../../../assets/images/students/student.svg";

//!STYLES
import s from "./assets/registerByMAil.module.css";

const RegisterStudentByMail: FC = () => {
  //!HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.appChat);

  const [sponsorCode, setSponsorCode] = useState<any>("");

  //!CONSTANTS
  const validateMessages = {
    required: t("auth.error.required"),
    types: {
      email: t("auth.error.emailInvalid"),
    },
  };

  //!FUNCTIONS
  const registerUser = async (values: registerUserDto) => {
    await dispatch(registerStudentWithEmailAndPassword(values));
    await socket?.emit("subscribe", auth.user?._id);
  };

  //!EFFECTS
  useEffect(() => {
    if (auth.isLoggedIn) navigate(appRoutes.STUDENT_DASHBOARD);
  }, [auth.isLoggedIn, navigate]);

  const { Option } = Select;

  return (
    <div>
      <div className={s.register__teacher}>
        <Container>
          <H1 className={s.title}>Créez votre compte</H1>

          <div className={s.content__action}>
            <div className={s.student__action}>
              <div className={s.avatar}>
                <Image
                  preview={false}
                  width={150}
                  src={AvatarStudent}
                  alt="teacher avatar"
                />
              </div>
              <div className={s.text}>
                <p>
                  Je suis un élève<strong>de 3e</strong>{" "}
                  <Link to={"/"}>Modifier</Link>
                </p>
              </div>
            </div>
          </div>

          <div className={s.form__container}>
            <Form
              validateTrigger={["onFinish"]}
              validateMessages={validateMessages}
              initialValues={{ acceptedTermsAndConditions: true }}
              name="registerTeacher"
              autoComplete="off"
              onFinish={registerUser}
            >
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item name="firstname" rules={[{ required: true }]}>
                    <Input
                      className={s.field}
                      placeholder={t("auth.firstname")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="lastname" rules={[{ required: true }]}>
                    <Input
                      className={s.field}
                      placeholder={t("auth.lastname")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="email"
                rules={[{ required: true }, { type: "email" }]}
              >
                <Input
                  className={s.field}
                  type="email"
                  placeholder={t("auth.email")}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true },
                  {
                    pattern: /^.{6,}$/,
                    message:
                      "Le mot de passe doit contenir au moins 6 caractères !",
                  },
                ]}
              >
                <Input.Password
                  className={s.field}
                  placeholder={t("auth.password")}
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Les mots de passe ne correspondent :(")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  className={s.field}
                  placeholder={t("auth.confirmPassword")}
                />
              </Form.Item>

              <Form.Item
                name="referredBy"
                rules={[
                  { required: false },
                ]}
                style={{ marginBottom: "20px" }}
              >
                <Input
                  className={s.field}
                  placeholder={"Code parrainage"}
                  onChange={(value) => setSponsorCode(value)}
                />
              </Form.Item>

              {/* <Select defaultValue="lucy" className={`${s.select} ${s.left}`}>
                <Option value="lucy">19</Option>
              </Select>
              <Select defaultValue="lucy" className={s.select}>
                <Option value="lucy">2</Option>
              </Select>
              <Select defaultValue="lucy" className={`${s.select} ${s.right}`}>
                <Option value="lucy">2010</Option>
              </Select> */}

              <div className={s.termes__legals}>
                <Form.Item
                  name="acceptedTermsAndConditions"
                  valuePropName="checked"
                >
                  <Checkbox>
                    {" "}
                    J'accepte de recevoir des conseils pédagogiques et des
                    offres <br /> promotionnelles de Mon ami Albert par e-mail.{" "}
                  </Checkbox>
                </Form.Item>
              </div>

              <Button
                loading={auth.isRegistering}
                block
                type="primary"
                className={s.button}
                htmlType="submit"
                style={{ display: "block" }}
              >
                Terminer
              </Button>

              <div className={s.info}>
                <span>
                  En cliquant sur terminer, vous acceptez les conditions
                  générales d'utilisation et la politique de confidentialité de
                  Mon ami Albert.
                </span>
              </div>
              <div className={s.info}>
                <p>
                  Vous avez déjà un compte ?{" "}
                  <Link to={"/student/dashboard"}> Connecte-toi</Link>{" "}
                </p>
              </div>
            </Form>
            {auth.registerErrors.length > 0 &&
              auth.registerErrors.map((error, index) => (
                <Alert key={index} message={error} type="error" showIcon />
              ))}
          </div>

          {/* <RegisterTeacherForm /> */}
        </Container>
      </div>
    </div>
  );
};

export default RegisterStudentByMail;
