import {
  Alert,
  Drawer,
  Form,
  FormInstance,
  Image,
  Input,
  Row,
  Select
} from "antd";
import AppButton from "components/libs/button";
import AppSelectGroup from "components/libs/selectGroup";
import React, { FC, useCallback, useState } from "react";

//!API
import * as TOPICS_SERVICES from "api/services/topics/topics.services";

//!IMAGES
import LogoClasses from "assets/images/teacher/teacher_classes.svg";
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";

//!STYLE
import { StatusCode } from "common/enums";
import s from "./style.module.css";

type Props = {
  addCourse: (values: any) => void;
  showAddClasse: boolean;
  setShowAddClasse: React.Dispatch<React.SetStateAction<boolean>>;
  addCourseForm: FormInstance<any>;
  addCourseLoading: boolean;
};

const { Option } = Select;

const AddRoom: FC<Props> = ({
  addCourse,
  showAddClasse,
  setShowAddClasse,
  addCourseForm,
  addCourseLoading,
}) => {
  //!STATES
  const [tempTopic, setTempTopic] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addCourseError, setAddCourseError] = useState<string>("");

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      setLoading(true);
      try {
        const response = await TOPICS_SERVICES.findAll(levelId);

        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [tempTopic, loading]
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Drawer
      className="drawer"
      title={false}
      placement="right"
      width={isMobile ? "100%" : "50%"}
      onClose={() => setShowAddClasse(!showAddClasse)}
      visible={showAddClasse}
      closable={true}
      headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
      closeIcon={
        <img
          src={ArrowBack}
          height={isMobile ? 10 : 18}
          style={{}}
        ></img>
      }
      drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "50px", borderBottomLeftRadius: isMobile ? "0px" : "50px", boxShadow: "-3px 3px 34px #39393929" }}
    >
      <div className={s.drawer}>
        <div className={s.drawer__content}>
          <div className={s.drawer___header}>
            <Image preview={false} src={LogoClasses} width={80} />
            <h3>Créer une classe</h3>
          </div>

          <Form
            name="create-room"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            autoComplete="off"
            validateTrigger={["onFinish"]}
            onFinish={addCourse}
            form={addCourseForm}
          >
            <div className={s.fieldBox}>
              <div>
                <label htmlFor="title">Nom de la classe :</label>
              </div>
              <div>
                <div className={s.field}>
                  <Form.Item label="" name="title">
                    <Input className={s.fieldInput} bordered={false} placeholder="6eme, Terminale" />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className={s.fieldBox}>
              <div>
                <label htmlFor="levelId">Niveau :</label>
              </div>
              <div className={s.fieldSelect}>
                <Form.Item name="levelId">
                  <AppSelectGroup
                    className={s.appSelect}
                    style={{
                      width: "100%",
                    }}
                    placeholder="Choisir un niveau"
                    onChange={(levelId: string) => OnSelectLevel(levelId)}
                  />
                </Form.Item>
              </div>
            </div>

            <div className={s.fieldBox}>
              <div>
                <label htmlFor="Matiere">matière :</label>
              </div>
              <div className={s.fieldSelect}>
                <Form.Item name="courseId">
                  <Select
                    placeholder={<div className={s.selectPlaceholder}>Choisir une matière</div>}
                    allowClear
                    bordered={false}
                    style={{
                      width: "100%",
                    }}
                    loading={loading}
                  >
                    {tempTopic?.map((topic: any) => (
                      <Option key={topic._id} value={topic._id}>
                        {topic.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            {addCourseError && (
              <Form.Item>
                <Alert
                  message="Une erreur est survenue !"
                  description={addCourseError}
                  type="error"
                  closable
                />
              </Form.Item>
            )}

            <Row justify="center">
              <AppButton htmlTypeSubmit={true} loading={addCourseLoading} className={s.newButton}>
                valider
              </AppButton>
            </Row>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

export default AddRoom;
