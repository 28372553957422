import React, { useState } from 'react';
import Latex from 'react-latex-next';
import editIcon from '../../assets/images/app/editicon.png';
import EditParagraphDrawer from './editParagraphDrawer';
import s from "./style.module.css";

interface ArticleProps {
    idTeacher: string;
    content: string;
    idChapter: string;
    idSection: string;
}

const ArticleEditor: React.FC<ArticleProps> = ({ idTeacher, content, idChapter, idSection }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleEditClick = () => {
        setIsDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerVisible(false);
    };

    const handleSave = (editedContent: string) => {
        // Implement your save logic here
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.articleContainer}>
            <p
                className={`s.articleContent ${isHovered ? 'hovered' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {/* {content} */}
                <Latex>
                    {content
                        ? content
                            ?.replaceAll("amp;", "")
                            ?.replaceAll("<br />", "")
                        : ""
                    }
                </Latex>
                {isHovered && !isMobile && (
                    <button className={s.editButton} onClick={handleEditClick}>
                        <img src={editIcon} width={15} className={s.buttonIcon}></img>
                        Améliorer
                    </button>
                )}
            </p>
            <EditParagraphDrawer
                visible={isDrawerVisible}
                content={content}
                onClose={handleDrawerClose}
                onSave={handleSave}
                idTeacher={idTeacher}
                idChapter={idChapter}
                idSection={idSection}
            />
        </div>
    );
};

export default ArticleEditor;
