import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { API_BASE_URL, API_FETCH_HELP, API_RESSOURCES } from "./faq.routes";

export const findAllFaq = async (type: string) => {
  try {
    const response = await axios.get(API_BASE_URL(type));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchHelp = async (
  emplacement: string,
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_FETCH_HELP}/${emplacement}`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const fetchFaq = async (type: string) => {
  try {
    const response = await axios.get(`${API_RESSOURCES}/faqs?type=${type}`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
