import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Image,
  Modal,
  Popover,
  Row,
  Select,
  Skeleton,
  Typography,
  message
} from "antd";
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SOCIAL_SHARING from "../../../components/libs/readyToUse/socialNetworks";
import styles from "./style.module.css";

import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import CardAddCours from "components/libs/cardAddCours";
import H2 from "components/libs/subtitle";
import H1 from "components/libs/title";
import * as appRoutes from "config/routes.config";
import { useNavigate } from "react-router-dom";
import CardClasses from "screens/teacher/components/cardClasses";
import AppScreen from "../ScreenWithSidebar";

//!IMAGES
import AlbertImage from "../../../assets/images/app/levieuxalbert.svg";
//
import AppResult from "components/appResults";
import Breadcrumbs from "components/breadcrumbs";
import HelpPopover from "components/help";
import Flex from "components/libs/flex";
import CopyToClipboard from "react-copy-to-clipboard";
import { useGetRoomsQuery } from "store/services/teacher/classes.services";
import Close from "../../../assets/images/app/close.svg";
import Success from "../../../assets/images/teacher/success.svg";
import Matiers from "../../../components/matiers";
import AddRoom from "../components/addRoom";
import PublicationsDashboard from "../components/myPublications";
import TeacherContent from "../content";

const { Paragraph, Text } = Typography;
const { Option } = Select;
const MESSAGE_PREFIX_PARTAGE_RESEAUX_SOCIAUX =
  "Bonjour ! Rejoins ma classe sur Monamilabert.com en utilisant ce code :";

const TeacherDashboard: FC = () => {
  //!HOOKS
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const { data: rooms, isLoading, isError, refetch } = useGetRoomsQuery(auth?.user?._id);
  const courses = useSelector((state) => state.courses);
  const quizzes = useSelector((state) => state.quizz);
  const exercices = useSelector((state) => state.exercises);
  const [addCourseForm] = Form.useForm();
  const navigate = useNavigate();
  const hide = () => setVisibleSocialMedia(false);
  const { user } = useSelector((state) => state.auth);
  //!STATE
  const [showAddClasse, setShowAddClasse] = useState<boolean>(false);
  const [isModalSuccessAddCours, setIsModalSuccessAddCours] =
    useState<boolean>(false);
  const [tempRoom, setTempRoom] = useState<any>(null);
  const [code, setCode] = useState(null);

  //!ADD COURSE
  const [addCourseLoading, setAddCourseLoading] = useState(false);

  //FUNCTIONS
  const goToTeacherRooms = () => navigate(appRoutes.TEACHER_ROOMS);
  const [visibleSocialMedia, setVisibleSocialMedia] = useState(false);
  const handleVisibleChange = () => {
    setVisibleSocialMedia(!visibleSocialMedia);
  };

  const addCourse = useCallback(
    async (course: any) => {
      try {
        setAddCourseLoading(true);
        course.teacherId = auth.user?._id;
        const response = await ROOMS_SERVICES.createRoom(course);
        if (response?.statusCode === StatusCode.CREATED) {
          setTempRoom(response?.data);
          setShowAddClasse(false);
          refetch();
          message.success("créé avec succès !");
          addCourseForm.resetFields();
          setTimeout(() => {
            setIsModalSuccessAddCours(true);
            setCode(response.data);
          }, 1500);
        }
      } catch (error: any) {
        message.error(error?.message || "error");
      } finally {
        setAddCourseLoading(false);
      }
    },
    [addCourseForm]
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1)); // Remove the '#' from the hash
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });

        // Add a small delay to allow scrollIntoView to complete before applying scroll offset
        setTimeout(() => {
          window.scrollBy({
            top: 750, // Scroll 100px more upwards
            behavior: 'smooth',
          });
        }, 300);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Breadcrumbs isDashboard={true} />
      {user?.isActive && user?.step === "completed" ? <AppScreen className={styles.dashboard}>
        <H1 className={styles.title}>Bienvenue {auth?.user?.firstname}</H1>
        <div className={styles.header}>
          <div className={styles.left}>
            <H2 className={styles.subtitle}>Mes classes</H2>
          </div>
          <div style={{ position: "relative", top: "15px", marginLeft: "10px" }}><HelpPopover id={"dashboard_mes_classes"} /></div>

          <div className={styles.center} />

          <div className={styles.right}>
            <AppButton
              icon={<PlusOutlined />}
              onClick={() => setShowAddClasse(!showAddClasse)}
            >
              Créer une classe
            </AppButton>
          </div>
        </div>
        <div className={styles.main}>
          {isLoading ? (
            <>
              <Skeleton active />
              <Skeleton active />
            </>
          ) : (
            <Fragment>
              {!isError ? (
                <Flex>
                  <CardAddCours
                    onClick={() => setShowAddClasse(!showAddClasse)}
                  />
                  <Fragment>
                    {rooms?.data.slice(0, 7)?.map((course: any) => (
                      <CardClasses course={course} />
                    ))}
                  </Fragment>
                </Flex>
              ) : (
                <AppResult title="Une erreur est survenue !" status="404" />
              )}
            </Fragment>
          )}

          <Row justify="center" style={{ margin: "1em 0" }}>
            {rooms?.data.length > 7 && (
              <AppButton
                className={styles.annonces__btn}
                onClick={goToTeacherRooms}
              >
                Gérer mes classes
              </AppButton>
            )}
          </Row>

          <TeacherContent
            courses={courses}
            quiz={quizzes}
            exercices={exercices}
          />

          <div id="publications-section">
            <PublicationsDashboard />
          </div>

          {/* <TeacherCalendar /> */}
          {/* <TeacherCalendarSpecific /> */}
        </div>

        {/* Modal Ajout Classe */}
        <AddRoom
          addCourse={addCourse}
          addCourseLoading={addCourseLoading}
          showAddClasse={showAddClasse}
          setShowAddClasse={setShowAddClasse}
          addCourseForm={addCourseForm}
        />

        <Modal
          title={null}
          closable={false}
          centered
          visible={isModalSuccessAddCours}
          onCancel={() => setIsModalSuccessAddCours(false)}
          footer={null}
          width={800}
        >
          <div className={styles.__success__modal_container}>
            <div className={styles.modal__card}>
              <h3 className={styles.modal__card__title}>
                Classe de {tempRoom?.title}
              </h3>
              <Matiers
                level={tempRoom?.level?.title}
                cours={tempRoom?.course?.title}
                className={styles.__matier}
                classNameIcon={styles.__icon_matiere}
              />
              <div className={styles.__modal__img__success}>
                <Image preview={false} src={Success} width={100} />
              </div>

              <span className={styles.__code__access__title}>
                Code d’accès{" "}
              </span>

              <div className={styles.__code}>
                <span>
                  {tempRoom?.accessCode.slice(0, 3) +
                    " - " +
                    tempRoom?.accessCode.slice(3, 6)}
                </span>
              </div>

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <div>
                  <CopyToClipboard
                    text={tempRoom?.accessCode}
                    onCopy={() =>
                      message.success({
                        content: "copié dans le presse-papiers",
                      })
                    }
                  >
                    <AppButton className={styles.__btn}>Copier</AppButton>
                  </CopyToClipboard>{" "}
                </div>
                <div>
                  <Popover
                    content={
                      <>
                        <div>
                          {" "}
                          <SOCIAL_SHARING
                            prefix={MESSAGE_PREFIX_PARTAGE_RESEAUX_SOCIAUX}
                            codeAcces={tempRoom?.accessCode + ", à bientôt"}
                          />
                        </div>
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              hide();
                            }}
                            href="/"
                          >
                            Fermer
                          </a>
                        </div>
                      </>
                    }
                    trigger="click"
                    visible={visibleSocialMedia}
                    onVisibleChange={handleVisibleChange}
                  >
                    {" "}
                    <AppButton className={styles.__btn}>partager</AppButton>
                  </Popover>{" "}
                </div>
              </div>
            </div>

            <div
              className={styles.close}
              onClick={() => setIsModalSuccessAddCours(false)}
            >
              <Image preview={false} src={Close} width={25} />
            </div>
          </div>
        </Modal>
      </AppScreen> : <AppScreen className={styles.dashboard}>
        <H1 className={styles.title}>Bienvenue {auth?.user?.firstname}</H1>
        <div className={styles.dashboardUserBox}>
          <img src={AlbertImage} width={240} height={240} style={{ marginTop: "20px", marginBottom: "10px" }}></img>
          <p className={styles.dashboardUserMessage} style={{ fontFamily: "CartoonFont" }}>Oups, tu n'as pas accès à cette fonctionnalité !<br /> ton compte est en cours de validation </p>
          {user?.step !== "completed" && <AppButton onClick={() => navigate(appRoutes.COMPLETE_PROFILE)}>Completer mon profil</AppButton>}
        </div>
      </AppScreen>
      }
    </React.Fragment>
  );
};

export default TeacherDashboard;
