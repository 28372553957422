import { ZoomMtg } from '@zoom/meetingsdk';
import { FC, useEffect } from 'react';
import './zoom.css';

interface ZoomMeetingProps {
    meetingNumber: string;
    userName: string;
    sdkKey: string;
    sdkSecret: string;
    onClose: () => void;
}

const ZoomMeeting: FC<ZoomMeetingProps> = ({
    meetingNumber,
    userName,
    sdkKey,
    sdkSecret,
    onClose
}) => {
    useEffect(() => {
        const initZoom = async () => {
            // Set the correct version
            const ZOOM_VERSION = '3.11.0';

            // Configure Zoom globals
            ZoomMtg.setZoomJSLib(`https://source.zoom.us/${ZOOM_VERSION}/lib`, '/av');
            ZoomMtg.preLoadWasm();
            ZoomMtg.prepareWebSDK();
            ZoomMtg.i18n.load('fr-FR');

            // Configure styles
            const zoomContainer = document.getElementById('zmmtg-root');
            if (zoomContainer) {
                zoomContainer.style.display = 'block';
                // Add necessary styles
                const style = document.createElement('link');
                style.rel = 'stylesheet';
                style.type = 'text/css';
                style.href = `https://source.zoom.us/${ZOOM_VERSION}/css/bootstrap.css`;
                document.head.appendChild(style);

                const style2 = document.createElement('link');
                style2.rel = 'stylesheet';
                style2.type = 'text/css';
                style2.href = `https://source.zoom.us/${ZOOM_VERSION}/css/react-select.css`;
                document.head.appendChild(style2);
            }

            try {
                // Initialize Zoom
                await new Promise((resolve, reject) => {
                    ZoomMtg.init({
                        leaveUrl: window.location.origin,
                        success: (success: any) => {
                            console.log('Init success', success);
                            resolve(success);
                        },
                        error: (error: any) => {
                            console.log('Init error', error);
                            reject(error);
                        }
                    });
                });

                // Generate signature
                const signature = ZoomMtg.generateSDKSignature({
                    meetingNumber: meetingNumber,
                    sdkKey: sdkKey,
                    sdkSecret: sdkSecret,
                    role: '0',
                });

                // Join meeting
                await new Promise((resolve, reject) => {
                    ZoomMtg.join({
                        meetingNumber: meetingNumber,
                        userName: userName,
                        signature: signature,
                        passWord: "123456",
                        sdkKey: sdkKey,
                        success: (success: any) => {
                            console.log('Join meeting success', success);
                            resolve(success);
                        },
                        error: (error: any) => {
                            console.log('Join meeting error', error);
                            reject(error);
                            onClose(); // Close on error
                        }
                    });
                });

            } catch (error) {
                console.error('Error in Zoom meeting:', error);
                onClose();
            }
        };

        initZoom();

        // Cleanup
        return () => {
            const zoomContainer = document.getElementById('zmmtg-root');
            if (zoomContainer) {
                zoomContainer.style.display = 'none';
            }
            // Remove added style tags
            document.querySelectorAll('link[href*="source.zoom.us"]').forEach(el => el.remove());
            ZoomMtg.leaveMeeting({});
        };
    }, [meetingNumber, userName, sdkKey, sdkSecret]);

    return null;
};

export default ZoomMeeting;