import {
  Alert,
  Checkbox,
  Form,
  Image,
  Input,
  message,
  Row,
} from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import Container from "components/container";
import * as appRoutes from "config/routes.config";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useCheckEmailMutation } from "store/services/app/user.services";

//!COMPONENTS
import AppButton from "components/libs/button";
import H1 from "components/libs/title";

//!IMAGES
import AvatarTeacher from "../../../assets/images/teacher/teacher.svg";

//!STYLES
import Breadcrumbs from "components/breadcrumbs";
import H2 from "components/libs/subtitle";
import OTPForm from "components/OTP";
import { registerTeacherWithEmailAndPassword } from "store/auth/authSlice";
import AnimatedPage from "utils/animatedPage";
import s from "./assets/register.module.css";


const RegisterByEmail: FC = () => {
  //!HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state: RootStateOrAny) => state.auth);
  const [checkEmail, { isSuccess, isError, data }] =
    useCheckEmailMutation();

  //!STATES JOFREY
  const [registrationData, setRegistrationData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [current, setCurrent] = useState<number>(1);
  const [userEmail, setUserEmail] = useState<string>("");
  const [sponsorCode, setSponsorCode] = useState<any>("");

  //!CONSTANTS
  const validateMessages = {
    required: t("auth.error.required"),
    types: {
      email: t("auth.error.emailInvalid"),
    },
  };

  //!FUNCTIONS JOFREY
  const registerUser = async (values: any) => {
    setRegistrationData(values);
    checkEmail({ email: values.email });
    try {
      setIsLoading(true);
      const finalData = {
        ...registrationData,
        ...values,
        nivMat: [],
        roles: ["teacher"],
        step: "",
      };

      await dispatch(registerTeacherWithEmailAndPassword(finalData));
    } catch (error) {
    } finally {
      setIsLoading(false);
      setUserEmail(values.email);
      setCurrent(2);
    }
  }

  const handleOtpSubmit = async (otp: string) => {
    try {
      const response = await AUTH_SERVICES.checkOTP(userEmail, otp);
      if (response?.data?.success === true) {
        navigate(appRoutes.COMPLETE_PROFILE);
      }
      else {
        message.warning({
          content: "Ce code est invalide !, consulte tes mails et entre le code reçu.",
        });
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
    }
  };

  return (
    <AnimatedPage>
      <Breadcrumbs />
      <div className={s.register__teacher}>
        <Container>

          <div className={s.content__action}>
            <div className={s.teacher}>
              <div className={s.avatar}>
                <Image
                  preview={false}
                  width={60}
                  src={AvatarTeacher}
                  alt="teacher avatar"
                />
              </div>
              <div className={s.header__text}>
                <H1 className={s.title}>Crée ton compte PROFESSEUR</H1>
                <p>
                  Je suis un <strong>professeur</strong>.{" "}
                  <Link to={appRoutes.LOGINS}>Modifier</Link>
                </p>
              </div>
            </div>
          </div>

          <div className={s.accountFormBox}>
            <div className={s.form__container}>

              {current === 1 && <Form
                validateTrigger={["onFinish"]}
                validateMessages={validateMessages}
                initialValues={{ acceptedTermsAndConditions: true }}
                name="registerTeacher"
                autoComplete="off"
                onFinish={registerUser}
              >
                <Form.Item
                  name="email"
                  rules={[{ required: true }, { type: "email" }]}
                  style={{ marginBottom: "20px" }}
                >
                  <Input
                    className={s.field}
                    type="email"
                    placeholder={t("auth.email")}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true },
                    {
                      pattern: /^.{6,}$/,
                      message:
                        "Le mot de passe doit contenir au moins 6 caractères !",
                    },
                  ]}
                  style={{ marginBottom: "20px" }}
                >
                  <Input.Password
                    className={s.field}
                    placeholder={t("auth.password")}
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                      validator(_: any, value: any) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Les mots de passe ne correspondent :(")
                        );
                      },
                    }),
                  ]}
                  style={{ marginBottom: "20px" }}
                >
                  <Input.Password
                    className={s.field}
                    placeholder={t("auth.confirmPassword")}
                  />
                </Form.Item>
                <Form.Item
                  name="referredBy"
                  rules={[
                    { required: false },
                  ]}
                  style={{ marginBottom: "20px" }}
                >
                  <Input
                    className={s.field}
                    placeholder={"Code parrainage"}
                    onChange={(value) => setSponsorCode(value)}
                  />
                </Form.Item>
                <div className={s.termes__legals}>
                  <Form.Item
                    name="acceptedTermsAndConditions"
                    valuePropName="checked"
                    style={{ marginBottom: "20px" }}
                  >
                    <Checkbox
                      style={{ color: "#636363" }}
                      className={s.policy}
                    >
                      {" "}
                      J'accepte de recevoir des conseils pédagogiques et des offres promotionnelles de<br />Mon ami Albert par e-mail.{" "}
                    </Checkbox>
                  </Form.Item>
                </div>
                <Row justify="end" style={{ marginTop: "30px" }}>
                  <Form.Item>
                    <AppButton loading={isLoading} htmlTypeSubmit={true}><a className={s.nextButton}>CONTINUER</a></AppButton>
                  </Form.Item>
                </Row>
              </Form>}

              {current === 2 && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "0px auto" }}>
                <H2>Code de Vérification</H2>
                <OTPForm onSubmitOtp={handleOtpSubmit} />
              </div>}

              {auth.registerErrors.length > 0 &&
                auth.registerErrors.map((error: any, index: any) => (
                  <Alert key={index} message={error} type="error" showIcon />
                ))}

              <div
                className={s.text}
                style={{ marginTop: "1em", textAlign: "center" }}
              >
                <p>
                  Tu as déjà un compte ?{" "}
                  <Link to={appRoutes.LOGINS}>Connecte-toi</Link>
                </p>
              </div>
            </div>
          </div>

        </Container>
      </div>
    </AnimatedPage>
  );
};

export default RegisterByEmail;