import logo from "assets/images/app/header/logo.svg";
import Container from "components/container";
import NavMobile from "components/navMobile";
import * as appRoutes from "config/routes.config";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import s from "./assets/headers.module.css";

interface Props { }

const WhenParentOrganismeNotConnected: FC = () => {
    //!HOOKS

    const { isRegistering } = useSelector((state) => state.app);

    return (
        isRegistering ? <header className={s.app__header}>
            <Container>
                <div className={s.nav}>
                    <div className={s.logo}>
                        <Link to="/">
                            <img src={logo} height={50} alt="logo" ></img>
                        </Link>
                    </div>

                    {!isRegistering && <NavMobile />}

                    {!isRegistering && <ul className={s.nav__links}>
                        <li className={s.nav__item}>
                            <Link to="/">Accueil</Link>
                        </li>
                        <li className={s.nav__item}>
                            <Link to="/login/teacher">
                                <div
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        padding: "0 var(--mb-2)",
                                    }}
                                >
                                    <span>Donnez cours et devenez </span> <br />
                                    <span>un ami d’albert</span>
                                </div>
                            </Link>
                        </li>
                        <li className={s.nav__item}>
                            <Link to={appRoutes.STUDENT_SEARCH_BEST_TEACHER}>
                                Trouvez un prof
                            </Link>
                        </li>
                        <li className={s.nav__item}>
                            <Link to={appRoutes.STUDENT_MY_CONTENT}>Nos ressources</Link>
                        </li>
                        <li className={s.nav__item}>
                            <Link to={appRoutes.LOGINS}>
                                <button className={s.button}>
                                    Se connecter/ <br /> S’inscrire
                                </button>
                            </Link>
                        </li>
                    </ul>}
                </div>
            </Container>
        </header> : <div></div>
    );
};

export default WhenParentOrganismeNotConnected;