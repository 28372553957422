import { Button, Form, Input } from "antd";
import React, { FC, useEffect, useState } from "react";

//!REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllRoomsQuery,
  useSendMessageMutation,
} from "store/services/chat";

//!STYLE
import s from "./style/message.module.css";

//!IMAGES
import SendIcon from "../../../../assets/images/app/ai_send_icon.svg";

type Props = {
  conversation: any;
  setConversation: React.Dispatch<any>;
  setIsTyping: React.Dispatch<any>;
  isBlocked: boolean;
};

const Message: FC<Props> = ({ conversation, setConversation, setIsTyping, isBlocked }) => {
  //!HOOKS
  const dispatch = useDispatch();
  const {
    talkId,
    idReceiver,
    socket,
    idSender,
    conversation: conversationStore,
  } = useSelector((state) => state.appChat);
  const { user } = useSelector((state) => state.auth);
  const [sendMessage, { data, isLoading, isError }] = useSendMessageMutation();

  const { refetch } = useGetAllRoomsQuery(user?._id);

  const [lastMessage, setLastMessage] = useState([]);
  const [form] = Form.useForm();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  const onSendMessage = async (values?: any) => {
    if (values) {
      const bodySendMessage = {
        talkId: talkId,
        sender: conversationStore?.receiverIdC
          ? user?._id
          : user?._id == idSender
            ? idSender
            : idReceiver,
        receiver: user?._id != idSender ? idSender : idReceiver,
        message: values.msg,
        flag: conversationStore?.receiverIdC ? "GROUP_FLAG" : "SINGLE_FLAG",
        instaprof: conversationStore?.instaProf ? "find" : "",
      };

      const bodySendMessageGroupe: any = {
        ...bodySendMessage,
        receiver: conversationStore?.receiverIdC?._id,
      };

      // delete bodySendMessageGroupe?.receiver;

      const checkBody = conversationStore?.receiverIdC
        ? bodySendMessageGroupe
        : bodySendMessage;

      const idUserGetReceiver = user?._id != idSender ? idSender : idReceiver;

      let tempMsg = {
        senderId: user,
        receiverId: {
          _id: idUserGetReceiver,
        },
        message: values.msg,
        seen: true,
      };

      socket?.connect();
      await socket.emit("sendMessage", checkBody);

      setConversation((res: any) => {
        return [...res, tempMsg];
      });

      form.resetFields();

      // refetch();
    }
  };

  const handleTyping = async () => {
    socket?.connect();
    await socket.emit("isTyping", talkId);
  };

  useEffect(() => {
    socket?.on("newMessage", async (msgResponse: any) => {
      setConversation((res: any) => {
        return [...res, msgResponse?.data];
      });
      refetch();
      setIsTyping(false);
    });
  }, [socket]);

  useEffect(() => {
    socket?.on("userTyping", async (msgResponse: any) => {
      setIsTyping(true);
    });
  }, [socket]);

  return (
    <Form onFinish={onSendMessage} form={form}>
      {!isBlocked && <div className={s.inputTextarea}>
        <Form.Item
          name={"msg"}
          style={{ marginTop: 0, padding: 0, width: "100%" }}
          rules={[
            { required: true, message: "Merci de renseigner un message" },
          ]}
        >
          <Input.TextArea
            className={s.messageInput}
            onChange={handleTyping}
            placeholder="Écris ton message ici…"
            rows={(isMobile || isXsScreen || isSmallScreen) ? 1 : 2}
            style={{ width: "100%", marginTop: (isMobile || isXsScreen || isSmallScreen) ? "25px" : 0 }}
            bordered={false}
          />
        </Form.Item>
        <Button type="link" htmlType="submit" style={{ width: (isMobile || isXsScreen || isSmallScreen) ? "30px" : "50px", height: (isMobile || isXsScreen || isSmallScreen) ? "30px" : "50px" }}>
          <img
            src={SendIcon}
            style={{
              position: "relative",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
            height={(isMobile || isXsScreen || isSmallScreen) ? 30 : 40}
          >
          </img>
        </Button>
      </div>}
      {isBlocked && <div className={s.BlockedmessageBox}>Cette conversation est bloquée</div>}
    </Form>
  );
};

export default Message;
