import { getToken } from "api";
import axios from "axios";
import {
  API_BASE_URL,
  API_BASE_URL_EXO,
  API_BASE_URL_EXO_CLUES,
  API_BASE_URL_EXO_SOLUTION,
} from "./studentExercice.routes";

export const findExercices = async (idChapter: string) => {
  try {
    const token = await getToken();
    const response = await axios.get(API_BASE_URL(idChapter), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response) {
      return response.data;
    }
  } catch (error: any) {
    throw error;
  }
};

export const findExercice = async (idExercice: string) => {
  try {
    const token = await getToken();
    const response = await axios.get(API_BASE_URL_EXO(idExercice), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const findExerciceSolution = async (idExercice: string) => {
  try {
    const token = await getToken();
    const response = await axios.get(API_BASE_URL_EXO_SOLUTION(idExercice), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const findExerciceClues = async (idExercice: string) => {
  try {
    const token = await getToken();
    const response = await axios.get(API_BASE_URL_EXO_CLUES(idExercice), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
